<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：CorpMaster.vue
  モジュール名：企業マスタ画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <title-card title="企業マスタ">
      <v-btn color="teal" dark depressed @click="btnCreateNew()">新規登録</v-btn>
    </title-card>
    <data-card>
      <v-data-table :headers="headers" :items="kigyo_list" item-key="item.key" :fixed-header="true" :hide-default-footer="true" :items-per-page="-1" no-data-text="企業が登録されていません。" height="calc(100vh - 188px)" width="940" @click:row="clickRow" style="white-space: pre-wrap" disable-pagination>
        <template v-slot:[`item.status`]="{ item }">
          <strong :class="item.num_status_flg == 1 ? 'blue--text' : 'red--text'">
            {{ item.status }}
          </strong>
        </template>
        <template v-slot:[`item.plan`]="{ item }">
          <v-chip :color="item.plan_color" dark outlined>
            <strong>{{ item.plan }}</strong>
          </v-chip>
        </template>
      </v-data-table>
    </data-card>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** 編集用ダイアログ ここから *** -->
    <!-- ダイアログ -->
    <v-dialog v-model="dialog" width="800" persistent no-click-animation>
      <v-card @change="change_flg = true">
        <v-card-title>企業情報</v-card-title>
        <v-form ref="select_form" class="mx-5 w-50">
          <!-- 企業情報 -->
          <v-row>
            <v-col cols="6">
              <div>企業コード<span class="red--text" v-if="!modeEdit">*</span></div>
              <v-text-field outlined dense v-model="txtKigyoCd" maxlength="6" :rules="kigyocd_rule" :disabled="modeEdit"></v-text-field>
              <div>企業名<span class="red--text">*</span></div>
              <v-text-field outlined dense v-model="txtKigyoName" maxlength="50" :rules="kigyoname_rule"></v-text-field>
              <div>郵便番号<span class="red--text">*</span></div>
              <v-text-field outlined dense v-model="txtPost" maxlength="8" hint="ハイフンありで入力してください。" :rules="post_rule"></v-text-field>
              <div>住所<span class="red--text">*</span></div>
              <v-text-field outlined dense v-model="txtAddress" maxlength="50" :rules="address_rule"></v-text-field>
              <div>担当者名（カナ）<span class="red--text">*</span></div>
              <v-text-field outlined dense v-model="txtTantoKana" maxlength="20" :rules="tanto_kana_rule"></v-text-field>
              <div>担当者名<span class="red--text">*</span></div>
              <v-text-field outlined dense v-model="txtTantoName" maxlength="20" :rules="tanto_rule"></v-text-field>
            </v-col>
            <v-col cols="6">
              <div>所属・グループ名（担当者）<span class="red--text">*</span></div>
              <v-text-field outlined dense v-model="txtBushoName" maxlength="15" :rules="busho_rule"></v-text-field>
              <div>電話番号１<span class="red--text">*</span></div>
              <v-text-field outlined dense v-model="txtTel1" maxlength="15" hint="ハイフンありで入力してください。" :rules="tel1_rule"></v-text-field>
              <div>電話番号２</div>
              <v-text-field outlined dense v-model="txtTel2" maxlength="15" hint="ハイフンありで入力してください。" :rules="tel2_rule"></v-text-field>
              <div>FAX番号</div>
              <v-text-field outlined dense v-model="txtFax" maxlength="15" hint="ハイフンありで入力してください。" :rules="fax_rule"></v-text-field>
              <div>内線番号</div>
              <v-text-field outlined dense v-model="txtNaisen" maxlength="6" :rules="naisen_rule"></v-text-field>
              <div>メールアドレス<span class="red--text">*</span></div>
              <v-text-field outlined dense v-model="txtMail" maxlength="50" :rules="mail_rule"></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <!-- 管理者情報 -->
          <v-row>
            <v-col cols="6">
              <div>管理者ID（企業コード）<span class="red--text" v-if="!modeEdit">*</span></div>
              <v-text-field outlined dense v-model="txtAdminId" maxlength="20" disabled></v-text-field>
              <div class="d-flex">
                <span v-show="modeEdit">新しい</span>
                <span>管理者パスワード<span class="red--text" v-show="pass_enable">*</span></span>
                <v-spacer></v-spacer>
                <v-btn class="px-1" height="24" text outlined @click="generatePassword" :disabled="!pass_enable">ランダム生成</v-btn>
              </div>
              <v-text-field outlined dense v-model="txtAdminPass" maxlength="20" :rules="pass_rule" :disabled="!pass_enable"></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="d-flex align-center">プランの選択<span class="red--text">*</span></div>
              <v-select outlined v-model="selectPlan" :items="plan" item-text="label" item-value="value" placeholder="プランを選択してください。" :rules="plan_rule" dense></v-select>
              <v-checkbox class="mt-7" v-show="modeEdit" v-model="chkPassReset" label="管理者パスワードのリセット"></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
        <!-- ボタン群 -->
        <v-col align="right">
          <v-btn v-show="modeInsert" height="40" width="70" color="blue darken-1" text @click="btnInsert()" class="mr-5">登録</v-btn>
          <v-btn v-show="modeEdit" height="40" width="70" color="green darken-1" text @click="btnUpdate()" class="mr-5">保存</v-btn>
          <v-btn v-show="modeEdit" height="40" color="red darken-1" text @click="btnDelete()" class="mr-5">利用登録解除</v-btn>
          <v-btn height="40" width="70" color="blue darken-1" text @click="dialogdisp(false)">閉じる</v-btn>
        </v-col>
      </v-card>
    </v-dialog>
    <!-- *** 編集用ダイアログ ここまで *** -->
  </v-container>
</template>

<script>
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "CorpMaster",
  data() {
    return {
      // 企業リスト
      kigyo_list: [],
      // 変更フラグ
      change_flg: false,
      // スナックバー
      // snackbar: false,
      // snackbar_msg: "",
      // snackbar_color: "",
      // timeout: 3000,
      // 入力項目
      txtKigyoCd: "",
      txtKigyoName: "",
      txtPost: "",
      txtAddress: "",
      txtTantoKana: "",
      txtTantoName: "",
      txtBushoName: "",
      txtTel1: "",
      txtTel2: "",
      txtFax: "",
      txtNaisen: "",
      txtMail: "",
      txtAdminId: "",
      txtAdminPass: "",
      selectPlan: 0,
      chkPassReset: false,
      // ポップアップ
      dialog: false,
      // 編集モード
      modeInsert: false,
      modeEdit: false,
    };
  },
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    //----------------------------------------------------------------
    // 初期化処理
    //----------------------------------------------------------------
    init() {
      // 企業リスト取得
      this.getKigyoList();
    },

    //----------------------------------------------------------------
    // ボタン押下時処理
    //----------------------------------------------------------------
    // 新規登録ボタン押下時
    btnCreateNew() {
      this.initForm();
      this.modeInsert = true;
      this.modeEdit = false;
      this["dialog"] = true;
    },

    // 登録ボタン押下時
    async btnInsert() {
      // 入力チェック
      if (!this.$refs.select_form.validate()) {
        return;
      }
      // 存在チェック
      if (await this.getKigyoExist()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("入力された企業コードは既に使用されています。");
        this.$root.snackbar.showMessage("入力された企業コードは既に使用されています。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 登録処理
      if (await this.insertKigyo()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("登録しました。");
        this.$root.snackbar.showMessage("登録しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      } else {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("登録時にエラーが発生しました。");
        this.$root.snackbar.showMessage("登録時にエラーが発生しました。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 変更フラグを初期化
      this.resetChangeFlg();
      // 企業一覧を再取得
      this.getKigyoList();
      // ダイアログを閉じる
      this.dialogdisp(false);
    },

    // 保存ボタン押下時
    async btnUpdate() {
      // 入力チェック
      if (!this.$refs.select_form.validate()) {
        return;
      }
      // 修正処理
      if (await this.updateKigyo()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("保存しました。");
        this.$root.snackbar.showMessage("保存しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      } else {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("保存時にエラーが発生しました。");
        this.$root.snackbar.showMessage("保存時にエラーが発生しました。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 変更フラグを初期化
      this.resetChangeFlg();
      // 企業一覧を再取得
      this.getKigyoList();
      // ダイアログを閉じる
      this.dialogdisp(false);
    },

    // 利用登録解除ボタン押下時
    async btnDelete() {
      // 入力チェック
      if (!window.confirm("再度利用するには新規登録が必要になります。\r\n利用登録を解除してもよろしいですか？")) {
        return;
      }
      // 利用登録解除処理
      if (await this.deleteKigyo()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("利用登録を解除しました。");
        this.$root.snackbar.showMessage("利用登録を解除しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      } else {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("利用登録の解除時にエラーが発生しました。");
        this.$root.snackbar.showMessage("利用登録の解除時にエラーが発生しました。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 変更フラグを初期化
      this.resetChangeFlg();
      // 企業一覧を再取得
      this.getKigyoList();
      // ダイアログを閉じる
      this.dialogdisp(false);
    },

    //----------------------------------------------------------------
    // イベント処理
    //----------------------------------------------------------------
    //テーブル行選択時
    clickRow(row) {
      this.initForm();
      if (row.num_status_flg == 1) {
        this.txtKigyoCd = row.str_kigyo_cd;
        this.txtKigyoName = row.str_kigyo_name;
        this.txtPost = row.str_post;
        this.txtAddress = row.str_address;
        this.txtTantoKana = row.str_tanto_kana;
        this.txtTantoName = row.str_tanto_name;
        this.txtBushoName = row.str_busho_name;
        this.txtTel1 = row.str_tel1;
        this.txtTel2 = row.str_tel2;
        this.txtFax = row.str_fax;
        this.txtNaisen = row.str_naisen;
        this.txtMail = row.str_mail;
        this.txtAdminId = row.str_kigyo_cd;
        this.selectPlan = row.num_plan;
        this.resetChangeFlg();
        this.dialogdisp(true);
        this.$nextTick(() => {
          if (this.$refs["select_form"]) {
            this.$refs.select_form.resetValidation();
          }
        });
      } else {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("契約が終了しているため、変更できません。");
        this.$root.snackbar.showMessage("契約が終了しているため、変更できません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      }
    },

    //----------------------------------------------------------------
    // メソッド
    //----------------------------------------------------------------
    // 入力フォーム初期化
    initForm() {
      this.txtKigyoCd = "";
      this.txtKigyoName = "";
      this.txtPost = "";
      this.txtAddress = "";
      this.txtTantoKana = "";
      this.txtTantoName = "";
      this.txtBushoName = "";
      this.txtTel1 = "";
      this.txtTel2 = "";
      this.txtFax = "";
      this.txtNaisen = "";
      this.txtMail = "";
      this.txtAdminId = "";
      this.txtAdminPass = "";
      this.selectPlan = 0;
      this.chkPassReset = false;
      this.$nextTick(() => {
        if (this.$refs["select_form"]) {
          this.$refs.select_form.resetValidation();
        }
      });
    },

    // 入力ダイアログ表示、非表示
    dialogdisp(dispflg) {
      if (dispflg) {
        this.modeInsert = false;
        this.modeEdit = true;
        this.dialog = true; //入力ダイアログを表示
      } else {
        if (this.change_flg == true) {
          if (window.confirm("保存せずに終了します。\n入力内容は破棄されますが、よろしいですか？")) {
            this.dialog = false; //入力ダイアログを非表示
          }
        } else {
          this.dialog = false; //入力ダイアログを非表示
        }
      }
    },

    // ランダムパスワード生成
    async generatePassword() {
      let wkPassword = "";
      const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const n = 8;
      wkPassword = Array.from(crypto.getRandomValues(new Uint32Array(n)))
        .map((v) => S[v % S.length])
        .join("");
      this.txtAdminPass = wkPassword;
    },

    // 企業一覧取得
    async getKigyoList() {
      let data = await this.$axios
        .get("/m_kigyo_get/list")
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      // 形式変換
      let wkArray = [];
      if (data.length != 0) {
        data.forEach(function (element) {
          // 契約状態
          let status = "";
          switch (element.num_status_flg) {
            case 0:
              status = "契約終了";
              break;
            case 1:
              status = "契約中";
              break;
            default:
              status = "不明";
              break;
          }
          element["status"] = status;
          // プラン
          let plan = "";
          let plan_color = "";
          /* -- 2024.05.15 MCS)KONNO MOD START -- */
          // switch (element.num_plan) {
          //   case 1:
          //     /* -- 2024.04.12 MCS)SAITO MOD START -- */
          //     // plan = "フリープラン";
          //     plan = "10プラン";
          //     /* -- 2024.04.12 MCS)SAITO MOD END -- */
          //     plan_color = "orange";
          //     break;
          //   case 2:
          //     plan = "50プラン";
          //     plan_color = "green";
          //     break;
          //   case 3:
          //     plan = "100プラン";
          //     plan_color = "blue";
          //     break;
          //   case 4:
          //     plan = "専用サーバープラン";
          //     plan_color = "red";
          //     break;
          //   default:
          //     plan = "不明";
          //     plan_color = "black";
          //     break;
          // }
          const list = [10, 50, 100, 150, 200, 250, 300];
          if (list.includes(element.num_plan)) {
            plan = String(element.num_plan) + "プラン";
            plan_color = "black";
          } else {
            plan = "不明";
            plan_color = "black";
          }
          /* -- 2024.05.15 MCS)KONNO MOD END -- */
          element["plan"] = plan;
          element["plan_color"] = plan_color;
          // 住所
          let corp = [];
          if (element.str_kigyo_cd != "" && element.str_kigyo_cd != null) corp.push(element.str_kigyo_cd);
          if (element.str_kigyo_name != "" && element.str_kigyo_name != null) corp.push(element.str_kigyo_name);
          element["corp"] = corp.join("\r\n");
          // 住所
          let address = [];
          if (element.str_post != "" && element.str_post != null) address.push("〒 " + element.str_post);
          if (element.str_address != "" && element.str_address != null) address.push(element.str_address);
          element["address"] = address.join("\r\n");
          // 担当者
          let tanto = [];
          if (element.str_busho_name != "" && element.str_busho_name != null) tanto.push(element.str_busho_name);
          let kana = element.str_tanto_kana != "" && element.str_tanto_kana != null ? "（" + element.str_tanto_kana + "）" : "";
          if (element.str_tanto_name != "" && element.str_tanto_name != null) tanto.push(element.str_tanto_name + kana);
          element["tanto"] = tanto.join("\r\n");
          // 連絡先
          let contact = [];
          if (element.str_tel1 != "" && element.str_tel1 != null) contact.push("TEL1: " + element.str_tel1);
          if (element.str_tel2 != "" && element.str_tel2 != null) contact.push("TEL2: " + element.str_tel2);
          if (element.str_fax != "" && element.str_fax != null) contact.push("FAX : " + element.str_fax);
          if (element.str_naisen != "" && element.str_naisen != null) contact.push("内線 : " + element.str_naisen);
          if (element.str_mail != "" && element.str_mail != null) contact.push("E-mail : " + element.str_mail);
          element["contact"] = contact.join("\r\n");
          wkArray.push(element);
        });
      }
      this.kigyo_list = wkArray;
    },

    // 企業存在チェック
    async getKigyoExist() {
      let wkExist = await this.$axios
        .get("/m_kigyo_get/exist" + "?KigyoCd=" + this.txtKigyoCd)
        .then(function (response) {
          return response.data["exist"];
        })
        .catch(function (error) {
          console.log(error);
          return true;
        });
      return wkExist;
    },

    // 企業登録処理
    async insertKigyo() {
      const params = new URLSearchParams();
      params.append("KigyoCd", this.txtKigyoCd);
      params.append("KigyoName", this.txtKigyoName);
      params.append("Post", this.txtPost);
      params.append("Address", this.txtAddress);
      params.append("TantoKana", this.txtTantoKana);
      params.append("TantoName", this.txtTantoName);
      params.append("BushoName", this.txtBushoName);
      params.append("Tel1", this.txtTel1);
      params.append("Tel2", this.txtTel2);
      params.append("Fax", this.txtFax);
      params.append("Naisen", this.txtNaisen);
      params.append("Mail", this.txtMail);
      params.append("Plan", this.selectPlan);
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      params.append("AdminId", this.txtAdminId);
      params.append("AdminPass", this.txtAdminPass);
      let result = await this.$axios
        .post("/m_kigyo_post/insert", params)
        .then((response) => {
          return response.status == 200;
        })
        .catch((error) => {
          console.log(error.response.data.error);
          return false;
        });
      return result;
    },

    // 企業更新処理
    async updateKigyo() {
      const params = new URLSearchParams();
      params.append("KigyoName", this.txtKigyoName);
      params.append("Post", this.txtPost);
      params.append("Address", this.txtAddress);
      params.append("TantoKana", this.txtTantoKana);
      params.append("TantoName", this.txtTantoName);
      params.append("BushoName", this.txtBushoName);
      params.append("Tel1", this.txtTel1);
      params.append("Tel2", this.txtTel2);
      params.append("Fax", this.txtFax);
      params.append("Naisen", this.txtNaisen);
      params.append("Mail", this.txtMail);
      params.append("Plan", this.selectPlan);
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      params.append("tKigyoCd", this.txtKigyoCd);
      params.append("AdminPass", this.txtAdminPass);
      params.append("tAdminId", this.txtAdminId);
      let result = await this.$axios
        .post("/m_kigyo_post/update", params)
        .then((response) => {
          return response.status == 200;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
      return result;
    },

    // 企業削除処理
    async deleteKigyo() {
      const params = new URLSearchParams();
      params.append("tKigyoCd", this.txtKigyoCd);
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      let result = await this.$axios
        .post("/m_kigyo_post/delete", params)
        .then((response) => {
          return response.status == 200;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
      return result;
    },

    // 変更フラグ処理
    resetChangeFlg() {
      this.change_flg = false;
    },

    // 空文字判定
    isNullOrWhiteSpace(input) {
      return typeof input !== "string" || input.replace(/\s+/g, "").length === 0;
    },

    // メッセージ表示（正常）
    // noticeOK(msg) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "green";
    //   this.snackbar = true;
    // },

    // メッセージ表示（エラー）
    // noticeNG(msg) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "red";
    //   this.snackbar = true;
    // },
  },

  computed: {
    headers() {
      return [
        { text: "企業", sortable: true, value: "corp", align: "start", width: "300px" },
        { text: "住所", sortable: true, value: "address", align: "start", width: "300px" },
        { text: "担当者", sortable: true, value: "tanto", align: "start", width: "300px" },
        { text: "連絡先", sortable: true, value: "contact", align: "start", width: "300px" },
        { text: "契約状態", sortable: true, value: "status", align: "start", width: "100px" },
        { text: "プラン", sortable: true, value: "plan", align: "start", width: "160px" },
      ];
    },
    plan() {
      return [
        /* -- 2024.05.15 MCS)KONNO MOD START -- */
        // /* -- 2024.04.12 MCS)SAITO MOD START -- */
        // // { label: "フリープラン", value: 1 },
        // { label: "10プラン", value: 1 },
        // /* -- 2024.04.12 MCS)SAITO MOD END -- */
        // { label: "50プラン", value: 2 },
        // { label: "100プラン", value: 3 },
        // { label: "専用サーバー", value: 4 },
        { label: "10プラン", value: 10 },
        { label: "50プラン", value: 50 },
        { label: "100プラン", value: 100 },
        { label: "150プラン", value: 150 },
        { label: "200プラン", value: 200 },
        { label: "250プラン", value: 250 },
        { label: "300プラン", value: 300 },
        /* -- 2024.05.15 MCS)KONNO MOD END -- */
      ];
    },
    pass_enable() {
      if (this.modeEdit) {
        return this.chkPassReset;
      }
      return true;
    },
    //----------------------------------------------------------------
    // バリデーションルール
    //----------------------------------------------------------------
    // 企業コード
    kigyocd_rule() {
      return [(value) => !!value || "企業コードは必須入力です。", (value) => !!value.match("^[0-9a-zA-Z]+$") || "半角英数字のみ入力可能です。", (value) => (!!value && value.length == 6) || "企業コードは6桁で入力してください。"];
    },
    // 企業名
    kigyoname_rule() {
      return [(value) => !!value || "企業名は必須入力です。"];
    },
    // 郵便番号
    post_rule() {
      return [(value) => !!value || "郵便番号は必須入力です。", (value) => !!value.match(/^[0-9]{3}-[0-9]{4}$/) || "郵便番号の形式が正しくありません。"];
    },
    // 住所
    address_rule() {
      return [(value) => !!value || "住所は必須入力です。"];
    },
    // 担当者名カナ
    tanto_kana_rule() {
      return [(value) => !!value || "担当者名カナは必須入力です。"];
    },
    // 担当者名
    tanto_rule() {
      return [(value) => !!value || "担当者名は必須入力です。"];
    },
    // 所属・グループ名
    busho_rule() {
      return [(value) => !!value || "所属・グループ名は必須入力です。"];
    },
    // 電話番号1
    tel1_rule() {
      return [(value) => !!value || "電話番号１は必須入力です。", (value) => !!value.match(/^[0-9/-]*$/) || "電話番号は半角数字を入力してください。"];
    },
    // 電話番号2
    tel2_rule() {
      if (this.txtTel2 != "" && this.txtTel2 != null) {
        return [(value) => !!value.match(/^[0-9/-]*$/) || "電話番号は半角数字を入力してください。"];
      } else {
        return [];
      }
    },
    // FAX番号
    fax_rule() {
      if (this.txtFax != "" && this.txtFax != null) {
        return [(value) => !!value.match(/^[0-9/-]*$/) || "FAX番号は半角数字を入力してください。"];
      } else {
        return [];
      }
    },
    // 内線番号
    naisen_rule() {
      if (this.txtNaisen != "" && this.txtNaisen != null) {
        return [(value) => !!value.match(/^[0-9]*$/) || "内線番号は半角数字を入力してください。"];
      } else {
        return [];
      }
    },
    // メールアドレス
    mail_rule() {
      return [(value) => !!value || "メールアドレスは必須入力です。", (value) => !!value.match(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/) || "メールアドレスは半角英数字または半角記号を入力してください。"];
    },
    // プラン
    plan_rule() {
      return [(value) => !(value == 0) || "プランを選択してください。"];
    },
    // 管理者パスワード
    pass_rule() {
      if (this.modeInsert || this.chkPassReset) {
        return [(value) => !!value || "管理者パスワードは必須入力です。", (value) => !!value.match("^[0-9a-zA-Z]+$") || "半角英数字のみ入力可能です。", (value) => value.length >= 8 || "8文字以上入力してください。"];
      } else {
        return [];
      }
    },
  },

  watch: {
    txtKigyoCd: function () {
      if (this.modeInsert) {
        this.txtAdminId = this.txtKigyoCd;
      }
    },
    chkPassReset: function () {
      this.txtAdminPass = "";
    },
  },

  mounted() {
    this.init();
  },
};
</script>
