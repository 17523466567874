<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：EventCalendar.vue
  モジュール名：年間予定管理画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <!-- タイトル -->
    <title-card title="年間予定管理">
      <v-btn color="teal" dark depressed @click="btnCreateNew()">新規登録</v-btn>
    </title-card>
    <!-- データ -->
    <data-card>
      <v-sheet class="px-4 py-2 d-flex" style="align-items: center">
        <year-selector v-model="year" @change="changeYear()"></year-selector>
      </v-sheet>
      <v-divider></v-divider>
      <v-sheet class="pa-2 overflow-y-auto" height="calc(100vh - 241px)">
        <span v-show="event_list.length == 0">予定が登録されていません。</span>
        <template v-for="(event, i) in event_list">
          <v-card class="mb-2" @click="selectEvent(i)" :key="i" outlined>
            <v-card-title>{{ event.str_title }}</v-card-title>
            <v-card-text style="white-space: pre-wrap">{{ event.period }}</v-card-text>
          </v-card>
        </template>
      </v-sheet>
    </data-card>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** 編集用ダイアログ ここから *** -->
    <v-dialog v-model="dialog_edit" width="600px" persistent no-click-animation>
      <v-card>
        <v-card-title>イベント情報</v-card-title>
        <v-form ref="input_form" class="mx-5 w-50">
          <!-- お知らせ情報 -->
          <div>タイトル<span class="red--text">*</span></div>
          <v-text-field outlined dense v-model="txtTitle" maxlength="60" counter="60" :rules="title_rule" @change="change_flg = true"></v-text-field>
          <v-row>
            <v-col cols="6">
              <!-- 開始日 -->
              <div class="mx-2 mt-1">
                <div class="d-flex align-center">開始日<span class="red--text">*</span></div>
                <v-menu v-model="dialog_dtp_st" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="pt-0" :value="comp_date_yyyyMD(dateSt)" :rules="date_st_rule" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="dateSt" @input="(dialog_dtp_st = false), (change_flg = true)" locale="jp-ja" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col cols="6">
              <!-- 終了日 -->
              <div class="mx-2 mt-1">
                <div class="d-flex align-center">終了日<span class="red--text">*</span></div>
                <v-menu v-model="dialog_dtp_ed" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="pt-0" :value="comp_date_yyyyMD(dateEd)" :rules="date_ed_rule" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="dateEd" @input="(dialog_dtp_ed = false), (change_flg = true)" locale="jp-ja" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <!-- ボタン群 -->
        <v-col align="right">
          <v-btn v-show="modeInsert" height="40" width="70" color="blue darken-1" text @click="btnInsert()" class="mr-5">登録</v-btn>
          <v-btn v-show="!modeInsert" height="40" width="70" color="green darken-1" text @click="btnUpdate()" class="mr-5">保存</v-btn>
          <v-btn v-show="!modeInsert" height="40" width="70" color="red darken-1" text @click="btnDelete()" class="mr-5">削除</v-btn>
          <v-btn height="40" width="70" color="blue darken-1" text @click="closeDialog()">閉じる</v-btn>
        </v-col>
      </v-card>
    </v-dialog>
    <!-- *** 編集用ダイアログ ここまで *** -->
  </v-container>
</template>

<script>
import moment from "moment";
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";
import YearSelector from "@/components/common/BaseYearSelector.vue";

export default {
  name: "EventCalendar",
  data: () => ({
    // 共通
    today: moment().format("yyyy-MM-DD"),
    year: moment().format("yyyy"), // 年
    event_list: [], // カレンダー
    // snackbar: false, // スナックバー状態
    // snackbar_msg: "", // スナックバー内容
    // snackbar_color: "", // スナックバー色
    // timeout: 3000, // スナックバー表示時間
    jp_week: ["日", "月", "火", "水", "木", "金", "土"], // 曜日
    // ダイアログ
    dialog_edit: false,
    dialog_dtp_st: false,
    dialog_dtp_ed: false,
    // 選択項目
    selectSeq: 0,
    // 入力項目
    txtTitle: "",
    dateSt: "",
    dateEd: "",
    // 編集モード
    modeInsert: false,
    // 変更フラグ
    change_flg: false,
  }),
  components: {
    TitleCard,
    DataCard,
    YearSelector,
  },
  methods: {
    //----------------------------------------------------------------
    // 初期化処理
    //----------------------------------------------------------------
    async init() {
      // カレンダー読み込み
      await this.getEvent();
    },

    //----------------------------------------------------------------
    // ボタン押下時処理
    //----------------------------------------------------------------

    // 新規登録ボタン押下時
    async btnCreateNew() {
      // フォーム初期化
      this.initForm();
      // 日付をセット
      const date = moment(this.year + moment().format("MMDD")).format("yyyy-MM-DD");
      this.dateSt = date;
      this.dateEd = date;
      // 変更フラグを初期化
      this.resetChangeFlg();
      // モード切替
      this.modeInsert = true;
      // ダイアログを開く
      this.dialog_edit = true;
    },

    // 登録ボタン押下時
    async btnInsert() {
      // 入力チェック
      if (!this.$refs.input_form.validate()) {
        return;
      }
      // 登録処理
      if (await this.insertEvent()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("登録しました。");
        this.$root.snackbar.showMessage("登録しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      } else {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("登録時にエラーが発生しました。");
        this.$root.snackbar.showMessage("登録時にエラーが発生しました。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 変更フラグを初期化
      this.resetChangeFlg();
      // イベント一覧を再取得
      this.getEvent();
      // ダイアログを閉じる
      this.dialog_edit = false;
    },

    // 保存ボタン押下時
    async btnUpdate() {
      // 入力チェック
      if (!this.$refs.input_form.validate()) {
        return;
      }
      // 修正処理
      if (await this.updateEvent()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("保存しました。");
        this.$root.snackbar.showMessage("保存しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      } else {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("保存時にエラーが発生しました。");
        this.$root.snackbar.showMessage("保存時にエラーが発生しました。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 変更フラグを初期化
      this.resetChangeFlg();
      // イベント一覧を再取得
      this.getEvent();
      // ダイアログを閉じる
      this.dialog_edit = false;
    },

    // 削除ボタン押下時
    async btnDelete() {
      // 入力チェック
      if (!window.confirm("削除します。よろしいですか？")) {
        return;
      }
      // 削除処理
      if (await this.deleteEvent()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("削除しました。");
        this.$root.snackbar.showMessage("削除しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      } else {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("削除時にエラーが発生しました。");
        this.$root.snackbar.showMessage("削除時にエラーが発生しました。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 変更フラグを初期化
      this.resetChangeFlg();
      // イベント一覧を再取得
      this.getEvent();
      // ダイアログを閉じる
      this.dialog_edit = false;
    },

    //----------------------------------------------------------------
    // イベント処理
    //----------------------------------------------------------------

    // カレンダー変更時処理
    /* -- 2024.05.08 MCS)KONNO MOD START --*/
    // async changeYear(num) {
    //   // 年を変更
    //   this.year = moment(this.year).add(num, "y").format("yyyy");
    //   // イベント情報を取得
    //   await this.getEvent();
    // },
    async changeYear() {
      // 年を変更
      this.year = moment(this.year).format("yyyy");
      // イベント情報を取得
      await this.getEvent();
    },
    /* -- 2024.05.08 MCS)KONNO MOD END --*/

    // イベント選択時処理
    async selectEvent(i) {
      // フォーム初期化
      this.initForm();
      // イベント内容をセット
      this.selectSeq = this.event_list[i].num_seq_no;
      this.txtTitle = this.event_list[i].str_title;
      this.dateSt = moment(this.event_list[i].str_date_st).format("yyyy-MM-DD");
      this.dateEd = moment(this.event_list[i].str_date_ed).format("yyyy-MM-DD");
      // 変更フラグを初期化
      this.resetChangeFlg();
      // モード切替
      this.modeInsert = false;
      // ダイアログを開く
      this.dialog_edit = true;
    },

    //----------------------------------------------------------------
    // メソッド
    //----------------------------------------------------------------

    // 入力フォーム初期化
    initForm() {
      this.txtTitle = "";
      this.dateSt = moment().format("yyyy-MM-DD");
      this.dateEd = moment().format("yyyy-MM-DD");
      this.$nextTick(() => {
        if (this.$refs["input_form"]) {
          this.$refs.input_form.resetValidation();
        }
      });
    },

    // カレンダー情報取得処理
    async getEvent() {
      const KigyoCd = this.$store.state.kigyoCode;
      /* -- 2024.05.08 MCS)KONNO MOD START --*/
      // const Year = this.year;
      const Year = moment(this.year).format("yyyy");
      /* -- 2024.05.08 MCS)KONNO MOD END --*/
      let data = await this.$axios
        .get("/t_calendar_get/list" + "?KigyoCd=" + KigyoCd + "&Year=" + Year)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      // イベント形式に変換
      let wkArray = [];
      if (data.length != 0) {
        data.forEach(
          function (element) {
            let start = this.comp_date_yyyyMD(element.str_date_st);
            let end = this.comp_date_yyyyMD(element.str_date_ed);
            if (start == end) {
              element["period"] = start;
            } else {
              element["period"] = start + " ～   " + end;
            }
            element["color"] = "grey lighten-5";
            wkArray.push(element);
          }.bind(this)
        );
      }
      this.event_list = wkArray;
    },

    // イベント登録処理
    async insertEvent() {
      const params = new URLSearchParams();
      params.append("KigyoCd", this.$store.state.kigyoCode);
      params.append("Title", this.txtTitle);
      params.append("DateSt", moment(this.dateSt).format("yyyyMMDD"));
      params.append("DateEd", moment(this.dateEd).format("yyyyMMDD"));
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      let result = await this.$axios
        .post("/t_calendar_post/insert", params)
        .then((response) => {
          return response.status == 200;
        })
        .catch((error) => {
          console.log(error.response.data.error);
          return false;
        });
      return result;
    },

    // イベント更新処理
    async updateEvent() {
      const params = new URLSearchParams();
      params.append("Title", this.txtTitle);
      params.append("DateSt", moment(this.dateSt).format("yyyyMMDD"));
      params.append("DateEd", moment(this.dateEd).format("yyyyMMDD"));
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      params.append("tKigyoCd", this.$store.state.kigyoCode);
      params.append("tSeqNo", this.selectSeq);
      let result = await this.$axios
        .post("/t_calendar_post/update", params)
        .then((response) => {
          return response.status == 200;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
      return result;
    },

    // イベント削除処理
    async deleteEvent() {
      const params = new URLSearchParams();
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      params.append("tKigyoCd", this.$store.state.kigyoCode);
      params.append("tSeqNo", this.selectSeq);
      let result = await this.$axios
        .post("/t_calendar_post/delete", params)
        .then((response) => {
          return response.status == 200;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
      return result;
    },

    // 入力ダイアログを閉じる
    closeDialog() {
      if (this.change_flg == true) {
        if (window.confirm("保存せずに終了します。\n入力内容は破棄されますが、よろしいですか？")) {
          this.dialog_edit = false; //入力ダイアログを非表示
        }
      } else {
        this.dialog_edit = false; //入力ダイアログを非表示
      }
    },

    //変更フラグ処理
    resetChangeFlg() {
      this.change_flg = false;
    },

    // 日付を"yyyy年 M月 D日"形式で返却
    fmtYYYYMMDDW(date) {
      if (date != "") {
        let fmtDate = moment(date).format("YYYY年 M月 D日");
        let fmtWeek = "（" + this.jp_week[moment(date).day()] + "）";
        return fmtDate + fmtWeek;
      } else {
        return "";
      }
    },

    // メッセージ表示（正常）
    // noticeOK(msg) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "green";
    //   this.snackbar = true;
    // },

    // メッセージ表示（エラー）
    // noticeNG(msg) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "red";
    //   this.snackbar = true;
    // },
  },

  computed: {
    // 日付を"yyyy年"形式で返却
    comp_date_yyyy() {
      return (date) => {
        if (date != "") {
          return moment(date).format("YYYY年");
        } else {
          return "";
        }
      };
    },

    // 日付を"yyyy年 M月 D日"形式で返却
    comp_date_yyyyMD() {
      return (date) => {
        if (date != "") {
          let fmtDate = moment(date).format("YYYY年 M月 D日");
          let fmtWeek = "（" + this.jp_week[moment(date).day()] + "）";
          return fmtDate + fmtWeek;
        } else {
          return "";
        }
      };
    },
    //----------------------------------------------------------------
    // バリデーションルール
    //----------------------------------------------------------------
    // タイトル
    title_rule() {
      return [(value) => !!value || "タイトルは必須入力です。"];
    },
    // 開始日
    date_st_rule() {
      if (!this.chkForever) {
        return [() => this.dateSt <= this.dateEd || "開始日は終了日より前に設定してください。"];
      }
      return [];
    },
    // 終了日
    date_ed_rule() {
      if (!this.chkForever) {
        return [() => this.dateEd >= this.dateSt || "終了日は開始日より後に設定してください。"];
      }
      return [];
    },
  },

  mounted() {
    // 初期化
    this.init();
  },
};
</script>
