<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：ReserveCheck.vue
  モジュール名：予約状況確認画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <!-- タイトル -->
    <title-card title="予約状況確認">
      <v-btn color="teal" dark depressed @click="clickOrder()">新規予約</v-btn>
    </title-card>
    <!-- データ -->
    <data-card>
      <!-- カレンダーパネル -->
      <!-- ヘッダ -->
      <v-sheet class="px-4 py-2">
        <month-selector v-model="calendar"> </month-selector>
      </v-sheet>
      <v-divider></v-divider>
      <!-- 絞り込み条件 -->
      <v-sheet class="px-4 py-2">
        <v-form class="d-flex" style="align-items: center" ref="select_form">
          <span>注文場所：</span>
          <v-responsive>
            <v-select outlined dense v-model="select_jigyo" item-text="str_jigyo_name" item-value="num_jigyo_no" style="width: 200px" :items="filter_jigyo" backgroundColor="white" no-data-text="データがありません。" hide-details></v-select>
          </v-responsive>
        </v-form>
      </v-sheet>
      <!-- カレンダー -->
      <v-sheet height="calc(100vh - 297px)" width="calc(100% - 1px)">
        <v-calendar ref="calendar" v-model="calendar" :events="comp_calendar" locale="ja-jp" :day-format="(timestamp) => new Date(timestamp.date).getDate()" :month-format="(timestamp) => new Date(timestamp.date).getMonth() + 1 + ' /'" :event-ripple="false" @change="changeCalendar()" @click:day="selectDate" @click:date="selectDate" event-more-text="他{0}件"></v-calendar>
      </v-sheet>
    </data-card>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** 一覧表示用ダイアログ ここから *** -->
    <v-dialog v-model="dialog_daily" max-width="1000px" persistent no-click-animation>
      <v-card>
        <v-card-title>{{ fmtDate_YYYYMD(select_date) }}の予約一覧</v-card-title>
        <v-card-text style="height: 60vh" class="overflow-y-auto py-0">
          <v-data-table :headers="daily_headers" :items="order_list_daily" @click:row="selectOrder" sort-by="num_seq_no" no-data-text="予約が登録されていません。"></v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <p class="my-0 grey--text">&#9655; 注文内容を変更したいユーザーを選択してください。</p>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_daily = false"> 閉じる </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *** 一覧表示用ダイアログ ここまで *** -->
  </v-container>
</template>

<script>
import moment from "moment";
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";
import MonthSelector from "@/components/common/BaseMonthSelector.vue";

export default {
  name: "ReserveCheck",
  data: () => ({
    // 共通
    today: moment().format("yyyy-MM-DD"),
    calendar: moment().format("yyyy-MM-DD"), // カレンダー表示月
    order_list_monthly: [], // 予約リスト（３か月）
    order_list_daily: [], // 予約リスト（１日）
    holiday_list: [], // 祝日リスト
    event_list: [], // イベントリスト
    jigyo_list: [], // 事業所リスト
    jp_week: ["日", "月", "火", "水", "木", "金", "土"], // 曜日
    select_date: moment().format("yyyy-MM-DD"), // 選択した日付
    select_jigyo: 0, // 選択した事業所（絞り込み条件）
    daily_headers: [
      { text: "ユーザー名", align: "start", sortable: false, value: "str_user_name" },
      {
        text: "注文場所",
        align: "start",
        sortable: false,
        value: "str_jigyo_name",
        // filter: (value) => {
        //   if (this.select_jigyo == 0) return true;
        //   return value == this.jigyo_list.find((i) => i.num_jigyo_no == this.select_jigyo).str_jigyo_name;
        // },
      },
      { text: "弁当業者名", align: "start", sortable: false, value: "str_gyosha_name" },
      { text: "弁当名", align: "start", sortable: false, value: "str_bento_name" },
      { text: "金額（円）", align: "end", sortable: false, value: "char_kingaku" },
      { text: "個数（個）", align: "end", sortable: false, value: "char_kosuu" },
      { text: "合計（円）", align: "end", sortable: false, value: "char_goukei" },
    ], // 予約一覧
    // ダイアログ
    dialog_daily: false,
    // スナックバー
    // snackbar: false, // スナックバー状態
    // snackbar_msg: "", // スナックバー内容
    // snackbar_color: "", // スナックバー色
    // timeout: 3000, // スナックバー表示時間
  }),
  components: {
    TitleCard,
    DataCard,
    MonthSelector,
  },
  methods: {
    //----------------------------------------------------------------
    // 初期化処理
    //----------------------------------------------------------------
    async init() {
      // 事業所リスト読み込み
      this.getJigyo();

      // 祝日リスト読み込み
      this.getHoliday(moment().format("yyyy"));
    },

    //----------------------------------------------------------------
    // ボタン押下時処理
    //----------------------------------------------------------------

    // 「新規予約」ボタン押下時
    async clickOrder() {
      // パラメタ取得
      let user = "";
      let date = moment(this.$store.state.nowDate).format("yyyy-MM-DD");
      let jigyo = 0;
      this.$router.push({ name: "ReservationStatus", params: { yoyaku_user: user, yoyaku_date: date, yoyaku_jigyo: jigyo, from: "check" } });
    },

    //----------------------------------------------------------------
    // イベント処理
    //----------------------------------------------------------------

    // カレンダー変更時処理
    async changeCalendar() {
      // イベントリスト読み込み
      await this.getEvent();
      // 予約情報を取得
      await this.getOrdersMonthly();
    },

    // 日付選択時処理
    async selectDate({ date }) {
      // 日付をセット
      this.select_date = moment(date).format("yyyy-MM-DD");
      // 予約一覧を取得
      await this.getOrdersDaily();
      // ダイアログを開く
      this.dialog_daily = true;
    },

    // 予約選択時処理
    async selectOrder(row) {
      // パラメタ取得
      let user = row.str_user_cd;
      let date = moment(row.str_chumonbi).format("yyyy-MM-DD");
      let jigyo = row.num_jigyo_no;
      this.$router.push({ name: "ReservationStatus", params: { yoyaku_user: user, yoyaku_date: date, yoyaku_jigyo: jigyo, from: "check" } });
    },

    //----------------------------------------------------------------
    // メソッド
    //----------------------------------------------------------------

    // 祝日情報取得処理
    async getJigyo() {
      const KigyoCd = this.$store.state.kigyoCode;
      this.jigyo_list = await this.$axios
        .get("/m_jigyo_get/jigyosyo_select" + "?KigyoCd=" + KigyoCd)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    // 祝日情報取得処理
    async getHoliday(year) {
      if (year < moment().add(2, "y").format("yyyy")) {
        let data = await this.$axios
          .get("https://holidays-jp.github.io/api/v1/" + year + "/date.json")
          .then(function (response) {
            return response.data;
          })
          .catch(function (error) {
            console.log(error);
            return [];
          });
        // イベント形式に変換
        let wkArray = [];
        let wkObj = JSON.parse(JSON.stringify(data));
        if (wkObj.length != 0) {
          Object.keys(wkObj).forEach(function (key) {
            let event = {};
            event["name"] = wkObj[key];
            event["start"] = moment(key).toDate();
            event["end"] = moment(key).toDate();
            event["color"] = "grey";
            event["timed"] = false;
            event["jigyo"] = "";
            event["seq"] = "";
            event["holiday"] = true;
            wkArray.push(event);
          });
        }
        this.holiday_list = wkArray;
      }
    },

    // イベント情報取得処理
    async getEvent() {
      const KigyoCd = this.$store.state.kigyoCode;
      const Year = moment(this.calendar).format("yyyy");
      let data = await this.$axios
        .get("/t_calendar_get/list" + "?KigyoCd=" + KigyoCd + "&Year=" + Year)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      // イベント形式に変換
      let wkArray = [];
      if (data.length != 0) {
        data.forEach(function (element) {
          let event = {};
          event["name"] = element.str_title;
          event["start"] = moment(element.str_date_st).toDate();
          event["end"] = moment(element.str_date_ed).toDate();
          event["color"] = "grey";
          event["timed"] = false;
          event["seq"] = element.num_seq_no;
          event["holiday"] = false;
          wkArray.push(event);
        });
      }
      this.event_list = wkArray;
    },

    // 予約情報取得処理（３か月）
    async getOrdersMonthly() {
      const KigyoCd = this.$store.state.kigyoCode;
      const Chumonbi = moment(this.calendar).format("yyyyMMDD");
      let data = await this.$axios
        .get("/t_chu_yoyaku_get/yoyaku_list_gyosha_m" + "?KigyoCd=" + KigyoCd + "&Chumonbi=" + Chumonbi)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      // イベント形式に変換
      let wkArray = [];
      if (data.length != 0) {
        data.forEach(function (element) {
          let order = {};
          order["name"] = element.str_gyosha_name + " " + element.total + "個";
          order["start"] = moment(element.str_chumonbi).toDate();
          order["end"] = moment(element.str_chumonbi).toDate();
          order["color"] = "orange lighten-1";
          order["timed"] = false;
          order["holiday"] = false;
          order["jigyo"] = element.num_jigyo_no;
          wkArray.push(order);
        });
      }
      this.order_list_monthly = wkArray;
    },

    // 予約情報取得処理（１日）
    async getOrdersDaily() {
      const KigyoCd = this.$store.state.kigyoCode;
      const Chumonbi = moment(this.select_date).format("yyyyMMDD");
      this.order_list_daily = await this.$axios
        .get("/t_chu_yoyaku_get/yoyaku_list_gyosha_d" + "?KigyoCd=" + KigyoCd + "&Chumonbi=" + Chumonbi)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    // メッセージ表示（正常）
    // noticeOK(msg) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "green";
    //   this.snackbar = true;
    // },

    // メッセージ表示（エラー）
    // noticeNG(msg) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "red";
    //   this.snackbar = true;
    // },

    // 日付を"yyyy年 M月"形式で返却
    fmtDate_YYYYM(date) {
      if (date != "") {
        return moment(date).format("YYYY年 M月");
      } else {
        return "";
      }
    },

    // 日付を"yyyy年 M月 D日"形式で返却
    fmtDate_YYYYMD(date) {
      if (date != "") {
        let fmtDate = moment(date).format("YYYY年 M月 D日");
        let fmtWeek = "（" + this.jp_week[moment(date).day()] + "）";
        return fmtDate + fmtWeek;
      } else {
        return "";
      }
    },
  },

  computed: {
    // ストアの現在時刻を返却
    comp_time() {
      return this.$store.getters.getTime;
    },

    // 祝日リストと予約リストを返却
    comp_calendar() {
      let wkEventList = [];
      let wkOrderList = [];
      if (this.select_jigyo == 0) {
        wkOrderList = this.order_list_monthly;
      } else {
        wkOrderList = this.order_list_monthly.filter((item) => item.jigyo == this.select_jigyo);
      }
      return wkEventList.concat(this.holiday_list, this.event_list, wkOrderList);
    },

    // 絞り込み条件（事業所）を返却
    filter_jigyo() {
      let wkArray = [];
      let allSelect = [{ num_jigyo_no: 0, str_jigyo_name: "すべて" }];
      return wkArray.concat(allSelect, this.jigyo_list);
    },
  },

  watch: {
    // 祝日情報取得（年が変わった場合のみ）
    calendar: function (newVal, oldVal) {
      let newYear = moment(newVal).format("yyyy");
      let oldYear = moment(oldVal).format("yyyy");
      if (newYear != oldYear) {
        this.getHoliday(newYear);
      }
    },
  },

  mounted() {
    // 初期化
    this.init();
  },
};
</script>
