<template>
  <v-card class="mb-2" style="background-color: #f5f5f5" outlined>
    <v-card-title class="py-0" style="height: 64px">
      <h3>{{ title }}</h3>
      <v-spacer></v-spacer>
      <slot></slot>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: "TitleBar",
  props: {
    // タイトル
    title: {
      type: String,
      default: "",
      required: true,
    },
  },
};
</script>
