<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：OrderStatus.vue
  モジュール名：注文状況一覧画面
  備考：
-------------------------------------------------->
<template>
  <v-container class="d-print-none" fluid>
    <!-- *** 表示部 ここから *** -->
    <title-card title="注文状況一覧">
      <v-btn color="teal" dark depressed @click="reload()">再表示</v-btn>
    </title-card>
    <data-card>
      <!-- 日付選択 -->
      <v-sheet class="px-4 py-2 d-flex">
        <base-date-selector v-model="value" @change="datechange"></base-date-selector>
        <v-spacer></v-spacer>
        <v-btn width="100" color="teal" dark class="mr-2" @click="send_mail()" :disabled="chkToday" depressed>メール送信</v-btn>
        <v-btn width="100" color="teal" dark class="mr-2" @click="print_fax()" :disabled="chkToday" depressed>注文書発行</v-btn>
        <v-btn width="100" color="teal" dark class="mr-2" @click="viewChumon()" :disabled="chkUketuke" depressed> 注文内容 </v-btn>
        <v-btn width="100" color="teal" dark @click="update()" :disabled="chkUketuke" depressed>注文済更新</v-btn>
      </v-sheet>
      <v-divider></v-divider>
      <!-- 絞り込み条件 -->
      <v-sheet class="px-4 py-2">
        <v-form class="d-flex" style="align-items: center" ref="select_form">
          <!-- 事業所選択 -->
          <span>注文場所：</span>
          <v-responsive>
            <v-select outlined dense v-model="jigyosyo" item-text="str_jigyo_name" item-value="num_jigyo_no" :items="filter_jigyo" style="width: 200px" backgroundColor="white" no-data-text="データがありません。" hide-details></v-select>
          </v-responsive>
        </v-form>
      </v-sheet>
      <v-divider></v-divider>
      <!-- テーブル -->
      <v-data-table v-model="select_data" :headers="headers" :items="datas" item-key="seq" :fixed-header="true" :hide-default-footer="true" :items-per-page="-1" no-data-text="事業所を選択してください。" height="calc(100vh - 298px)" show-select single-select style="white-space: pre-line" @click:row="rowClick">
        <!-- チェックボックス -->
        <template>
          <v-simple-checkbox :value="isSelected" @input="select($event)"></v-simple-checkbox>
        </template>
        <!-- 連絡先 -->
        <template v-slot:[`item.address`]="{ item }">
          <!-- 2024.04.08 MCS)SAITO MOD START -->
          <!-- <span v-if="item.str_tel1 != '' || item.str_tel2 != ''">
            <span v-if="item.str_tel2 == ''">
              <div>TEL: {{ item.str_tel1 }}</div>
            </span>
            <span v-else>
              <div>TEL1: {{ item.str_tel1 }}</div>
              <div>TEL2: {{ item.str_tel2 }}</div>
            </span>
          </span>
          <span v-if="item.str_fax != ''">
            <div>FAX: {{ item.str_fax }}</div>
          </span> -->
          <div>{{ item.address }}</div>
          <!-- 2024.04.08 MCS)SAITO MOD END -->
        </template>
        <!-- 締切時間 -->
        <template v-slot:[`item.str_simetime`]="{ item }">
          <span>
            {{ fmtHHMM(item.str_simetime) }}
          </span>
        </template>
        <!-- 受付状況 -->
        <template v-slot:[`item.reservation`]="{ item }">
          <div :class="item.num_chu_jokyo == null ? 'blue--text' : 'red--text'">
            <!-- 2024.04.08 MCS)SAITO MOD START -->
            <!-- <span v-if="item.num_chu_jokyo == null"> 受付中 </span>
            <span v-else> 締切 </span> -->
            <div>{{ item.reservation }}</div>
            <!-- 2024.04.08 MCS)SAITO MOD END -->
          </div>
        </template>
        <!-- 注文方法 -->
        <template v-slot:[`item.method`]="{ item }">
          <!-- 2024.04.08 MCS)SAITO MOD START -->
          <!-- <span v-if="item.num_chu_kbn == 1 && item.num_auto_flg == 1">自動メール</span>
          <span v-else-if="item.num_chu_kbn == 1 && item.num_auto_flg == 0">メール</span>
          <span v-else-if="item.num_chu_kbn == 2"> 電話 </span>
          <span v-else-if="item.num_chu_kbn == 3"> FAX </span>
          <span v-else> その他 </span> -->
          <div>{{ item.method }}</div>
          <!-- 2024.04.08 MCS)SAITO MOD END -->
        </template>
        <!-- 注文時間 -->
        <template v-slot:[`item.str_chu_time`]="{ item }">
          <span>
            {{ fmtHHMMSS(item.str_chu_time) }}
          </span>
        </template>
        <!-- 注文状況 -->
        <template v-slot:[`item.str_order_jyokyo`]="{ item }">
          <div :class="item.num_chu_jokyo == 3 ? 'red--text' : 'blue--text'">
            <!-- 2024.04.08 MCS)SAITO MOD START -->
            <!-- <span v-if="item.num_chu_jokyo == 3"> 注文済 </span>
            <span v-else> 未注文 </span> -->
            <div>{{ item.str_order_jyokyo }}</div>
            <!-- 2024.04.08 MCS)SAITO MOD END -->
          </div>
        </template>
        <!-- 注文内容表示ボタン -->
        <!-- 2024.03.06 MCS)SAITO DEL START -->
        <!-- <template v-slot:[`item.display`]="{ item }">
          <v-btn depressed @click.stop="viewChumon(item)" :disabled="item.total_count == 0 ? true : false"> 詳細 </v-btn>
        </template> -->
        <!-- 2024.03.06 MCS)SAITO DEL END -->
      </v-data-table>
    </data-card>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** 注文書用ダイアログ ここから *** -->
    <v-dialog v-model="showModal" fullscreen height="250" width="250">
      <v-card style="background: gray" class="mt-40">
        <v-card-actions class="d-print-none">
          <v-spacer></v-spacer>
          <v-btn class="d-print-none" height="35" width="95" color="teal" dark @click="print()">印刷</v-btn>
          <v-btn class="d-print-none" height="35" width="95" color="teal" dark @click="close_fax()">閉じる</v-btn>
        </v-card-actions>
        <OrderSheet :today="this.value" :gyosha_data="this.select_data" :jigyo_data="this.jigyosyo" v-if="reloadFlg" />
      </v-card>
    </v-dialog>
    <!-- *** 注文書用ダイアログ ここまで *** -->

    <!-- *** 注文内容用ダイアログ ここから *** -->
    <v-dialog v-model="dialog_info" max-width="1000px" persistent no-click-animation>
      <v-card>
        <!-- タイトル -->
        <v-card-title>【{{ select_jigyo }}】{{ select_gyosha }}の予約内容</v-card-title>
        <v-card-text class="px-8 py-0 black--text">
          <v-chip class="mr-2" v-if="info_tel1 != ''">{{ info_tel1 }}</v-chip>
          <v-chip class="mr-2" v-if="info_tel2 != ''">{{ info_tel2 }}</v-chip>
          <v-chip class="mr-2" v-if="info_fax != ''">{{ info_fax }}</v-chip>
        </v-card-text>
        <!-- 内容 -->
        <v-card-text style="height: 60vh" class="overflow-y-auto py-0">
          <v-data-table :headers="info_headers" :items="order_list" no-data-text="予約が登録されていません。"></v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_info = false"> 閉じる </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *** 注文内容用ダイアログ ここまで *** -->
  </v-container>
</template>

<script>
import moment from "moment";
import OrderSheet from "@/components/admin/OrderSheet.vue";
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";
import BaseDateSelector from "@/components/common/BaseDateSelector.vue";

export default {
  name: "OrderStatus",
  data() {
    return {
      today: moment().format("YYYY-MM-DD"), //ボタン抑制用の日付(避難)
      value: moment().format("YYYY-MM-DD"),
      displaydate: moment().format("YYYY年MM月DD日"),
      datas: [],
      /*-- 2024.03.08 MCS)SAITO ADD START --*/
      kojindatas: [],
      FaxFlg: false,
      /*-- 2024.03.08 MCS)SAITO ADD END --*/
      jigyosyos: [],
      jigyosyo: 0,
      select_data: [],
      order: [],
      order_list: [],
      mail_title: "",
      mail: "",
      // msg: "",
      // snackbar: false,
      // snackbar_color: "",
      //予約内容表示項目
      select_jigyo: "",
      select_gyosha: "",
      info_tel1: "",
      info_tel2: "",
      info_fax: "",
      //エラー表示時間
      timeout: 3000,
      //ポップアップ表示、非表示
      showModal: false,
      dialog_mail: false,
      dialog_update: false,
      dialog_date: false,
      dialog_info: false,
      isSelected: false,
      reloadFlg: true,
    };
  },
  components: {
    OrderSheet,
    TitleCard,
    DataCard,
    BaseDateSelector,
  },
  methods: {
    // setToday() {
    //   this.value = moment().format("YYYY-MM-DD");
    //   this.displaydate = moment(this.value).format("YYYY年MM月DD日");
    //   this.serchdata();
    // },
    // datereduce() {
    //   this.value = moment(this.value).add(-1, "days").format("YYYY-MM-DD");
    //   this.displaydate = moment(this.value).format("YYYY年MM月DD日");
    //   this.serchdata();
    // },
    // dateadd() {
    //   this.value = moment(this.value).add(1, "days").format("YYYY-MM-DD");
    //   this.displaydate = moment(this.value).format("YYYY年MM月DD日");
    //   this.serchdata();
    // },
    // datechange() {
    //   this.dialog_date = false;
    //   this.displaydate = moment(this.value).format("YYYY年MM月DD日");
    //   if (this.jigyosyo != "") {
    //     this.serchdata();
    //   }
    // },
    datechange() {
      this.serchdata();
    },
    reload() {
      this.datas = [];
      this.serchdata();
    },
    //VBTEST→print_faxに変更
    print_fax() {
      /*-- 2024.03.27 MCS)SAITO MOD START --*/
      //   alert('1');
      // /*-- 2024.03.08 MCS)SAITO MOD START --*/
      // // // WebView2にメッセージを送信
      // // window.chrome.webview.postMessage({
      // //     mode: "chumonsyo",
      // //     kigyoCode: this.$store.state.kigyoCode,
      // //     jigyosyo: this.select_data[0].num_jigyo_no,
      // //     numGyoshaNo: this.select_data[0].num_gyosha_no,
      // //     formattedDate: moment(self.value).format("yyyyMMDD"),
      // //     gyoshaname:this.select_data[0].str_gyosha_name,
      // //     kigyoName:this.$store.state.kigyoName
      // // });
      // // WebView2にメッセージを送信
      // if (this.dialog_info == false){
      //   window.chrome.webview.postMessage({
      //     mode: "chumonsyo",
      //     kigyoCode: this.$store.state.kigyoCode,
      //     jigyosyo: this.select_data[0].num_jigyo_no,
      //     numGyoshaNo: this.select_data[0].num_gyosha_no,
      //     formattedDate: moment(this.value).format("yyyyMMDD"),
      //     gyoshaname:this.select_data[0].str_gyosha_name,
      //     kigyoName:this.$store.state.kigyoName
      //   });
      // }else{
      //   window.chrome.webview.postMessage({
      //     mode: "nitijilist",
      //     kigyoCode: this.$store.state.kigyoCode,
      //     jigyosyo: this.select_data[0].num_jigyo_no,
      //     numGyoshaNo: this.select_data[0].num_gyosha_no,
      //     formattedDate: moment(this.value).format("yyyyMMDD"),
      //     gyoshaname:this.select_data[0].str_gyosha_name
      //   });
      // }
      // /*-- 2024.03.08 MCS)SAITO MOD END --*/
      if (this.select_data != "") {
        if (this.select_data[0].total_count == 0) {
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // this.snackbar_color = "error";
          // this.msg = "注文が登録されていません。";
          // this.snackbar = true;
          this.$root.snackbar.showMessage("注文が登録されていません。", "warning");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
          return;
        }
        try {
          window.chrome.webview.postMessage({
            mode: "chumonsyo",
            kigyoCode: this.$store.state.kigyoCode,
            jigyosyo: this.select_data[0].num_jigyo_no,
            numGyoshaNo: this.select_data[0].num_gyosha_no,
            formattedDate: moment(this.value).format("yyyyMMDD"),
            gyoshaname: this.select_data[0].str_gyosha_name,
            kigyoName: this.$store.state.kigyoName,
          });
        } catch (error) {
          // エラーが発生した場合の処理
          /* -- 2024.04.03 MCS)SAITO MOD START -- */
          // alert("レポートはクライアントアプリからのみ作成可能です。");
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // alert("最新の弁当注文書はクライアントアプリを利用してください。");
          this.$root.snackbar.showMessage("最新の弁当注文書はクライアントアプリを利用してください。", "info");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
          /*-- 2024.04.03 MCS)SAITO ADD START --*/
          /*-- 2024.04.03 MCS)SAITO ADD START --*/
          //WEBの注文書を発行
          this.reloadFlg = false;
          this.$nextTick(() => (this.reloadFlg = true));
          this.showModal = true;
          /*-- 2024.04.03 MCS)SAITO ADD START --*/
        }
      } else {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.snackbar_color = "error";
        // this.msg = "業者が選択されていません。";
        // this.snackbar = true;
        this.$root.snackbar.showMessage("業者が選択されていません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      }
      /*-- 2024.03.27 MCS)SAITO MOD END --*/
    },
    //メール送信
    send_mail() {
      if (this.$refs.select_form.validate()) {
        if (this.select_data != "") {
          console.log(this.select_data[0]);
          if (this.select_data[0].num_chu_jokyo == null) {
            /* -- 2024.05.08 MCS)KONNO MOD START --*/
            // this.snackbar_color = "error";
            // this.msg = "予約受付中のため送信できません。";
            // this.snackbar = true;
            this.$root.snackbar.showMessage("予約受付中のため送信できません。", "warning");
            /* -- 2024.05.08 MCS)KONNO MOD END --*/
            return;
          }
          if (this.select_data[0].total_count == 0) {
            /* -- 2024.05.08 MCS)KONNO MOD START --*/
            // this.snackbar_color = "error";
            // this.msg = "注文が登録されていません。";
            // this.snackbar = true;
            this.$root.snackbar.showMessage("注文が登録されていません。", "warning");
            /* -- 2024.05.08 MCS)KONNO MOD END --*/
            return;
          }
          if (window.confirm("注文メールを送信します。よろしいですか？")) {
            this.sendmail();
          }
          //this.dialog_mail = true
        } else {
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // this.snackbar_color = "error";
          // this.msg = "業者が選択されていません。";
          // this.snackbar = true;
          this.$root.snackbar.showMessage("業者が選択されていません。", "warning");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        }
      }
    },
    /* -- 2024.03.27 MCS)SAITO DEL START -- */
    //FAX注文書
    // print_fax() {
    //   if (this.select_data != "") {
    //     if (this.select_data[0].num_chu_jokyo == null) {
    //       this.snackbar_color = "error";
    //       /*-- 2024.03.22 MCS)SAITO MOD START --*/
    //       // this.msg = "予約受付中のため送信できません。";
    //       this.msg = "予約受付中のため発行できません。";
    //       /*-- 2024.03.22 MCS)SAITO MOD END --*/
    //       this.snackbar = true;
    //       return;
    //     } else if (this.select_data[0].total_count == 0) {
    //       this.snackbar_color = "error";
    //       this.msg = "注文が登録されていません。";
    //       this.snackbar = true;
    //       return;
    //     } else {
    //       this.reloadFlg = false;
    //       this.$nextTick(() => (this.reloadFlg = true));
    //       this.showModal = true;
    //     }
    //   } else {
    //     this.snackbar_color = "error";
    //     this.msg = "業者が選択されていません。";
    //     this.snackbar = true;
    //   }
    // },
    /* -- 2024.03.27 MCS)SAITO DEL END -- */
    close_fax() {
      this.showModal = false;
    },
    /*-- 2024.03.08 MCS)SAITO ADD START --*/
    sendFax() {},
    /*-- 2024.03.08 MCS)SAITO ADD END --*/
    // send() {
    //   this.sendmail()
    //   this.dialog_mail = false
    // },
    update() {
      if (this.select_data != "") {
        if (this.select_data[0].str_order_jyokyo == "注文済") {
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // this.snackbar_color = "error";
          // this.msg = "既に注文済みです。";
          // this.snackbar = true;
          this.$root.snackbar.showMessage("既に注文済みです。", "warning");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
          return;
          /*-- 2024.04.10 MCS)SAITO MOD START --*/
          // } else if (this.select_data[0].count == 0) {
        } else if (this.select_data[0].total_count == 0) {
          /*-- 2024.04.10 MCS)SAITO MOD END --*/
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // this.snackbar_color = "error";
          // this.msg = "注文が登録されていません。";
          // this.snackbar = true;
          this.$root.snackbar.showMessage("注文が登録されていません。", "warning");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
          return;
        } else {
          if (window.confirm("注文済みに更新します。よろしいですか？")) {
            this.updatesendflg();
          }
          //this.dialog_update = true
        }
      } else {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.snackbar_color = "error";
        // this.msg = "業者が選択されていません。";
        // this.snackbar = true;
        this.$root.snackbar.showMessage("業者が選択されていません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      }
    },
    // update_data() {
    //   this.updatesendflg()
    //   this.dialog_update = false
    // },
    // close_dialog() {
    //   this.dialog_mail = false
    //   this.dialog_update = false
    // },
    print() {
      window.print();
    },
    rowClick: function (item, row) {
      row.select(!row.isSelected);
    },
    /*API*/
    // async serchdata() {
    //   const self = this;
    //   await self.$axios
    //     .get("/m_gyosha_get/order_status", {
    //       params: {
    //         KigyoCd: self.$store.state.kigyoCode, //セッション(Vuex)
    //         JigyoNo: self.jigyosyo,
    //         Chumonbi: moment(self.value).format("yyyyMMDD"),
    //       },
    //     })
    //     .then(function (response) {
    //       Promise.resolve(response.data).then(function (d) {
    //         if (Object.keys(d["data"]).length != 0) {
    //           self.datas = d["data"];
    //         } else {
    //           self.datas = [];
    //         }
    //         self.select_data = [];
    //       });
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    async serchdata() {
      const KigyoCd = this.$store.state.kigyoCode;
      const Chumonbi = moment(this.value).format("yyyyMMDD");
      /* -- 2024.04.11 MCS)SAITO ADD START -- */
      const NowDate = moment().format("yyyyMMDD");
      /* -- 2024.04.11 MCS)SAITO ADD END -- */
      this.datas = await this.$axios
        /* -- 2024.04.11 MCS)SAITO MOD START -- */
        // .get("/t_chu_jokyo_get/jokyo_list" + "?KigyoCd=" + KigyoCd + "&Chumonbi=" + Chumonbi)
        .get("/t_chu_jokyo_get/jokyo_list" + "?KigyoCd=" + KigyoCd + "&Chumonbi=" + Chumonbi + "&NowDate=" + NowDate)
        /* -- 2024.04.11 MCS)SAITO MOD END -- */
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },
    async serchjigyosho() {
      const self = this;
      await this.$axios
        .get("/m_jigyo_get/jigyosyo_select", {
          params: {
            KigyoCd: this.$store.state.kigyoCode, //セッション(Vuex)
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              self.jigyosyos = d["data"];
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    /*-- 2024.03.08 MCS)SAITO ADD START --*/
    async searchkigyo() {
      const self = this;
      const KigyoCd = this.$store.state.kigyoCode;
      await this.$axios
        .get("/m_kigyo_get/info" + "?KigyoCd=" + KigyoCd)
        .then(function (response) {
          if (response.data["data"].num_fax_option == 1) {
            self.FaxFlg = true;
          } else {
            self.FaxFlg = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },
    /*-- 2024.03.08 MCS)SAITO ADD END --*/
    async updatesendflg() {
      const self = this;
      const axios = this.$axios;
      const store = this.$store;
      const params = new URLSearchParams();
      params.append("KigyoNo", self.$store.state.kigyoCode);
      /*-- 2024.03.22 MCS)SAITO MOD START --*/
      // params.append("JigyoNo", this.jigyosyo);
      params.append("JigyoNo", self.select_data[0].num_jigyo_no);
      /*-- 2024.03.22 MCS)SAITO MOD END --*/
      params.append("GyoshaNo", self.select_data[0].num_gyosha_no);
      params.append("Chumonbi", moment(self.value).format("yyyyMMDD"));
      params.append("ChuTime", store.state.nowTime);
      params.append("sUser", store.state.userCode);
      params.append("sAccess", "WEB");
      await axios
        .post("/t_chu_jokyo_post/jokyo_update", params)
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (d.msg == "UPDATE OK") {
              /* -- 2024.05.08 MCS)KONNO MOD START --*/
              // self.snackbar_color = "safe";
              // self.msg = "更新しました。";
              // self.snackbar = true;
              self.$root.snackbar.showMessage("更新しました。", "success");
              /* -- 2024.05.08 MCS)KONNO MOD END --*/
              self.serchdata();
            }
          });
        })
        .catch(function (error) {
          console.log(error);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.snackbar_color = "error";
          // self.msg = "更新できませんでした。";
          // self.snackbar = true;
          self.$root.snackbar.showMessage("更新できませんでした。", "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        });
    },
    /*mail*/
    async sendmail() {
      const self = this;
      await this.$axios
        .get("/mail/send_mail", {
          params: {
            KigyoNo: this.$store.state.kigyoCode, //セッション(Vuex)
            /*-- 2024.03.22 MCS)SAITO MOD START --*/
            // JigyoNo: this.jigyosyo,
            JigyoNo: this.select_data[0].num_jigyo_no,
            /*-- 2024.03.22 MCS)SAITO MOD END --*/
            GyoshaNo: this.select_data[0].num_gyosha_no,
            Chumonbi: moment(this.value).format("yyyyMMDD"),
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (d["data"] == true) {
              /* -- 2024.05.08 MCS)KONNO MOD START --*/
              // self.snackbar_color = "safe";
              // self.msg = "メールが送信されました。";
              // self.snackbar = true;
              self.$root.snackbar.showMessage("メールが送信されました。", "success");
              /* -- 2024.05.08 MCS)KONNO MOD END --*/
              self.serchdata();
            }
          });
        })
        .catch(function (error) {
          console.log(error);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.snackbar_color = "error";
          // self.msg = "メールが送信できませんでした。";
          // self.snackbar = true;
          self.$root.snackbar.showMessage("メールが送信できませんでした。", "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        });
    },

    // 予約情報取得処理
    async getOrdersBento(JigyoNo, Chumonbi, GyoshaNo) {
      const KigyoCd = this.$store.state.kigyoCode;
      this.order_list = await this.$axios
        .get("/t_chu_yoyaku_get/yoyaku_list_bento_d" + "?KigyoCd=" + KigyoCd + "&JigyoNo=" + JigyoNo + "&Chumonbi=" + Chumonbi + "&GyoshaNo=" + GyoshaNo)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },
    //2024.03.08 MCS)SAITO MOD START
    // async viewChumon(item) {
    // this.initSelect();
    // //選択項目退避
    // this.select_gyosha = item.str_gyosha_name;
    // this.select_jigyo = item.str_jigyo_name;
    // if (item.str_tel1 != "" || item.str_tel2 != "") {
    //   if (item.str_tel2 == "") {
    //     this.info_tel1 = "TEL：" + item.str_tel1;
    //   } else {
    //     this.info_tel1 = "TEL1：" + item.str_tel1;
    //     this.info_tel2 = "TEL2：" + item.str_tel2;
    //   }
    // }
    // if (item.str_fax != "") {
    //   this.info_fax = "FAX：" + item.str_fax;
    // }
    // //予約内容検索
    // const JigyoNo = item.num_jigyo_no;
    // const Chumonbi = item.str_chumonbi;
    // const GyoshaNo = item.num_gyosha_no;
    // await this.getOrdersBento(JigyoNo, Chumonbi, GyoshaNo);
    // this.dialog_info = true;
    async viewChumon() {
      this.initSelect();
      /* -- 2024.03.27 MCS)SAITO ADD START --*/
      if (this.select_data == "") {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.snackbar_color = "error";
        // this.msg = "業者が選択されていません。";
        // this.snackbar = true;
        this.$root.snackbar.showMessage("業者が選択されていません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      /* -- 2024.03.27 MCS)SAITO ADD END --*/
      if (this.select_data[0].total_count == 0) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.snackbar_color = "error";
        // this.msg = "注文が登録されていません。";
        // this.snackbar = true;
        this.$root.snackbar.showMessage("注文が登録されていません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      //選択項目退避
      if (this.select_data != "") {
        this.select_gyosha = this.select_data[0].str_gyosha_name;
        this.select_jigyo = this.select_data[0].str_jigyo_name;
        if (this.select_data[0].str_tel1 != "" || this.select_data[0].str_tel2 != "") {
          if (this.select_data[0].str_tel2 == "") {
            this.info_tel1 = "TEL：" + this.select_data[0].str_tel1;
          } else {
            this.info_tel1 = "TEL1：" + this.select_data[0].str_tel1;
            this.info_tel2 = "TEL2：" + this.select_data[0].str_tel2;
          }
        }
        if (this.select_data[0].str_fax != "") {
          this.info_fax = "FAX：" + this.select_data[0].str_fax;
        }
        //予約内容検索
        const JigyoNo = this.select_data[0].num_jigyo_no;
        const Chumonbi = this.select_data[0].str_chumonbi;
        const GyoshaNo = this.select_data[0].num_gyosha_no;
        await this.getOrdersBento(JigyoNo, Chumonbi, GyoshaNo);
        this.dialog_info = true;
      }
      //2024.03.08 MCS)SAITO MOD END
    },

    initSelect() {
      //選択項目初期化
      this.select_jigyo = "";
      this.select_gyosha = "";
      this.info_tel1 = "";
      this.info_tel2 = "";
      this.info_fax = "";
    },

    fmtHHMM(time) {
      if (!time) return "-";
      return Number(time.substring(0, 2)) + "時" + Number(time.substring(2, 4)) + "分";
    },

    fmtHHMMSS(time) {
      if (!time) return "-";
      return Number(time.substring(0, 2)) + "時" + Number(time.substring(2, 4)) + "分" + Number(time.substring(4, 6)) + "秒";
    },

    /*--- 2024.03.27 MCS)SAITO DEL START ---*/
    // /*--- 2024.03.11 MCS)SAITO ADD START ---*/
    // async downloadCSV() {
    //   var csv = "";
    //   //データの取得
    //   const KigyoCd = this.$store.state.kigyoCode;
    //   const JigyoNo = this.select_data[0].num_jigyo_no
    //   const Chumonbi = moment(this.value).format("yyyyMMDD")
    //   const GyoshaNo = this.select_data[0].num_gyosha_no
    //   this.kojindatas = await this.$axios
    //     .get("/t_chu_yoyaku_get/yoyaku_list_kojin_d" + "?KigyoCd=" + KigyoCd + "&JigyoNo=" + JigyoNo + "&Chumonbi=" + Chumonbi + "&GyoshaNo=" + GyoshaNo)
    //     .then(function (response) {
    //       return response.data["data"];
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //       return [];
    //     });
    //   //月別個別集計表
    //   csv += "\ufeff" + this.value  +"\n";
    //   csv += "\ufeff" + "利用者コード,所 属,氏 名,金 額\n";
    //   this.kojindatas.forEach((el) => {
    //     var line = el["str_user_cd"] + "," + el["str_busho_name"] + "," + el["str_user_name"] + "," + el["total_price"] + "\n";
    //     csv += line;
    //   });
    //   let blob = new Blob([csv], { type: "text/csv" });
    //   let link = document.createElement("a");
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = this.$store.state.nowDate + "" + this.$store.state.nowTime + "日次個人別リスト.csv";
    //   link.click();
    // },
    // /*--- 2024.03.11 MCS)SAITO ADD END ---*/
    /*--- 2024.03.27 MCS)SAITO DEL END ---*/
  },
  async created() {
    await this.serchjigyosho();
    await this.serchdata();
    /*--- 2024.03.08 MCS)SAITO ADD START ---*/
    await this.searchkigyo();
    /*--- 2024.03.08 MCS)SAITO ADD END ---*/
  },
  computed: {
    headers() {
      return [
        {
          text: "注文場所",
          /*--- 2024.04.05 MCS)SAITO MOD START ---*/
          // sortable: false,
          sortable: true,
          /*--- 2024.04.05 MCS)SAITO MOD END ---*/
          value: "str_jigyo_name",
          align: "start",
          width: "200px",
          filter: (value) => {
            if (this.jigyosyo == 0) return true;
            return value == this.jigyosyos.find((i) => i.num_jigyo_no == this.jigyosyo).str_jigyo_name;
          },
        },
        /*--- 2024.03.25 MCS)SAITO MOD START ---*/
        // { text: "業者", sortable: false, value: "str_gyosha_name", align: "start", width: "200px" },
        /*--- 2024.04.05 MCS)SAITO MOD START ---*/
        // { text: "弁当業者", sortable: false, value: "str_gyosha_name", align: "start", width: "200px" },
        { text: "弁当業者", sortable: true, value: "str_gyosha_name", align: "start", width: "200px" },
        /*--- 2024.04.05 MCS)SAITO MOD END ---*/
        /*--- 2024.03.25 MCS)SAITO MOD END ---*/
        // { text: "TEL", sortable: false, value: "str_tel", align: "start", width: "130px" },
        // { text: "FAX", sortable: false, value: "str_fax", align: "start", width: "130px" },
        /*--- 2024.04.05 MCS)SAITO MOD START ---*/
        // { text: "連絡先", sortable: false, value: "address", align: "start", width: "190px" },
        // { text: "締切時間", sortable: false, value: "str_simetime", align: "start", width: "100px" },
        // { text: "受付状況", sortable: false, value: "reservation", align: "start", width: "80px" },
        // { text: "注文方法", sortable: false, value: "method", align: "start", width: "110px" },
        { text: "連絡先", sortable: true, value: "address", align: "start", width: "190px" },
        { text: "締切時間", sortable: true, value: "str_simetime", align: "start", width: "100px" },
        { text: "受付状況", sortable: true, value: "reservation", align: "start", width: "80px" },
        { text: "注文方法", sortable: true, value: "method", align: "start", width: "110px" },
        /*--- 2024.04.05 MCS)SAITO MOD END ---*/
        // { text: "自動送信", sortable: false, value: "num_auto_flg", align: "start", width: "100px" },
        /*--- 2024.04.05 MCS)SAITO MOD START ---*/
        // { text: "注文状況", sortable: false, value: "str_order_jyokyo", align: "start", width: "80px" },
        // { text: "注文時間", sortable: false, value: "str_chu_time", align: "start", width: "130px" },
        { text: "注文状況", sortable: true, value: "str_order_jyokyo", align: "start", width: "80px" },
        { text: "注文時間", sortable: true, value: "str_chu_time", align: "start", width: "130px" },
        /*--- 2024.04.05 MCS)SAITO MOD END ---*/
        // { text: "注文個数", sortable: false, value: "count", align: "end", width: "100px" },
        // { text: "注文金額", sortable: false, value: "total", align: "end", width: "100px" },
        /* -- 2024.04.03 MCS)SAITO DEL START -- */
        // { text: "注文内容", sortable: false, value: "display", align: "center" },
        /* -- 2024.04.03 MCS)SAITO DEL END -- */
      ];
    },
    info_headers() {
      return [
        { text: "弁当名", align: "start", sortable: false, value: "str_bento_name" },
        { text: "金額（円）", align: "end", sortable: false, value: "char_kingaku" },
        { text: "個数（個）", align: "end", sortable: false, value: "char_count" },
        { text: "合計（円）", align: "end", sortable: false, value: "char_price" },
      ];
    },
    chkToday() {
      /*-- 2024.03.27 MCS)SAITO MOD START --*/
      // return moment(this.value).format("yyyyMMDD") != this.$store.state.nowDate;
      if (moment(this.value).format("yyyyMMDD") != this.$store.state.nowDate || this.chkUketuke) {
        return true;
      } else {
        return false;
      }
      /*-- 2024.03.27 MCS)SAITO MOD END --*/
    },
    /*-- 2024.03.08 MCS)SAITO ADD START --*/
    chkFax() {
      if (this.FaxFlg == true && this.chkToday == false) {
        return false;
      } else {
        return true;
      }
    },
    /*-- 2024.03.08 MCS)SAITO ADD END --*/
    /*-- 2024.03.22 MCS)SAITO ADD START --*/
    // 選択されているデータが受付中かどうか
    chkUketuke() {
      if (this.select_data.length != 0) {
        if (this.select_data[0].num_chu_jokyo == null) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    /*-- 2024.03.22 MCS)SAITO ADD END --*/
    filter_jigyo() {
      let wkArray = [];
      let allSelect = [{ num_jigyo_no: 0, str_jigyo_name: "すべて" }];
      return wkArray.concat(allSelect, this.jigyosyos);
    },
  },
  /*-- 2024.03.08 MCS)SAITO ADD START --*/
  watch: {
    // itemsが変更されたときに選択状態を保持
    datas: {
      handler(newItems, oldItems) {
        oldItems;
        if (this.select_data != "") {
          this.select_data = newItems.filter((newItem) => {
            return newItem.num_gyosha_no === this.select_data[0].num_gyosha_no && newItem.num_jigyo_no === this.select_data[0].num_jigyo_no && newItem.str_chumonbi === moment(this.value).format("yyyyMMDD");
          });
        }
      },
      deep: true, // ネストされたプロパティまで監視
    },
  },
  /*-- 2024.03.08 MCS)SAITO ADD END --*/
};
</script>
