import Vue from "vue";
import Router from "vue-router";
import Store from "@/store/index.js";

// レイアウト
import LoginLayout from "../layouts/LoginLayout.vue";
import UserLayout from "../layouts/UserLayout.vue";
import AdminLayout from "../layouts/AdminLayout.vue";

// 共通画面
import NotFound from "../views/common/NotFound.vue"; // 404エラー
import StartPage from "../views/common/StartPage.vue"; // スタートページ
import UserLogin from "../views/common/UserLogin.vue"; // ログイン

// 利用者画面
import OrderReservation from "../views/user/OrderReservation.vue"; // 予約

// 管理者画面 - 予約
import ReserveCheck from "../views/admin/reservation/ReserveCheck.vue"; // 予約状況確認
import ReserveList from "../views/admin/reservation/ReserveList.vue"; // 予約状況一覧
import ReservationStatus from "../views/admin/reservation/ReservationStatus.vue"; // 予約状況編集
import MultipleOrder from "../views/admin/reservation/MultipleOrder.vue"; // 一括予約登録

// 管理者画面 - 注文
import OrderStatus from "../views/admin/order/OrderStatus.vue"; // 注文状況一覧
import OrderHistory from "../views/admin/order/OrderHistory.vue"; // 個人注文履歴

// 管理者画面 - 月次
import MonthlyReport from "../views/admin/monthly/MonthlyReport.vue"; // 月別集計表出力
// import PreviewReports from "../views/admin/monthly/PreviewReports.vue"; // 月別集計表出力(プレビュー)
import MonthlyClosing from "../views/admin/monthly/MonthlyClosing.vue"; // 月次締め処理
import UserSeikyu from "../views/admin/monthly/UserSeikyu.vue"; // 請求処理
import GyoshaSiharai from "../views/admin/monthly/GyoshaSiharai.vue"; // 弁当業者支払

// 管理者画面 - マスタ
import CorpMaster from "../views/admin/master/CorpMaster.vue"; // 企業マスタ
import OfficeMaster from "../views/admin/master/OfficeMaster.vue"; // 事業所マスタ
import TraderMaster from "../views/admin/master/TraderMaster.vue"; // 弁当業者マスタ
import BentoMaster from "../views/admin/master/BentoMaster.vue"; // 弁当マスタ
import UserMaster from "../views/admin/master/UserMaster.vue"; // 利用者マスタ
import DeptsMaster from "../views/admin/master/DeptsMaster.vue"; // 部署マスタ
import EmailMaster from "../views/admin/master/EmailMaster.vue"; // メール定型文マスタ
import SimebiUpdate from "../views/admin/master/SimebiUpdate.vue"; // 締日登録
// import MenuCapture from "../views/admin/master/MenuCapture.vue"; // 献立表取込

// 管理者画面 - その他
import SystemNotice from "../views/admin/other/SystemNotice.vue"; // 運営からのお知らせ
import InternalNotice from "../views/admin/other/InternalNotice.vue"; // 社内連絡
import EventCalendar from "../views/admin/other/EventCalendar.vue"; // カレンダー
import SystemLog from "../views/admin/other/SystemLog.vue"; // ログイン履歴
import ClientApp from "../views/admin/other/ClientApp.vue"; // クライアントアプリ
import PrintQR from "../views/admin/other/PrintQR.vue"; // 受取確認用QRコード
import ManualList from "../views/admin/other/ManualList.vue"; // マニュアル一覧
import EmailSend from "../views/admin/other/EmailSend.vue"; // 一斉メール送信
import ContractDetail from "../views/admin/other/ContractDetail.vue"; // 契約情報

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // ログイン画面用レイアウト
    {
      path: "/",
      component: LoginLayout,
      children: [
        // 認証画面
        {
          path: "/",
          component: StartPage,
          name: "StartPage",
          meta: {
            requiresAuth: false,
          },
        },
        // ログイン画面
        {
          path: "/login/:kigyoCd",
          component: UserLogin,
          name: "UserLogin",
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },

    // 注文画面用レイアウト
    {
      path: "/user",
      component: UserLayout,
      children: [
        // 注文予約画面
        {
          path: "/reservation",
          component: OrderReservation,
          name: "OrderReservation",
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },

    // 管理画面用レイアウト
    {
      path: "/admin",
      component: AdminLayout,
      children: [
        // 運営からのお知らせ
        {
          path: "system_notice",
          component: SystemNotice,
          name: "SystemNotice",
          meta: {
            requiresAuth: true,
          },
        },
        // 予約状況確認
        {
          path: "check",
          component: ReserveCheck,
          name: "ReserveCheck",
          meta: {
            requiresAuth: true,
          },
        },
        // 予約状況一覧
        {
          path: "list",
          component: ReserveList,
          name: "ReserveList",
          meta: {
            requiresAuth: true,
          },
          // 2024.05.07 MCS)KONNO ADD START
          props: true,
          // 2024.05.07 MCS)KONNO ADD END
        },
        // 予約状況編集
        {
          path: "status",
          component: ReservationStatus,
          name: "ReservationStatus",
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        // 注文状況一覧
        {
          path: "order",
          component: OrderStatus,
          name: "OrderStatus",
          meta: {
            requiresAuth: true,
          },
        },
        // 個人注文履歴
        {
          path: "history",
          component: OrderHistory,
          name: "OrderHistory",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.05.02 MCS)KONNO ADD START
        // 一括予約登録
        {
          path: "multiple-order",
          component: MultipleOrder,
          name: "MultipleOrder",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.05.02 MCS)KONNO ADD END
        // 献立表取込 2022.07.05 コメントアウト
        // {
        //   path: "menu",
        //   component: MenuCapture,
        //   name: "MenuCapture",
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },
        // 月別集計表出力
        {
          path: "report",
          component: MonthlyReport,
          name: "MonthlyReport",
          meta: {
            requiresAuth: true,
          },
        },
        // 月別集計表出力(プレビュー)
        // {
        //   path: "preview",
        //   component: PreviewReports,
        //   name: "PreviewReports",
        //   props: true,
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },
        // 2024.01.24 MCS)SAITO ADD START
        {
          path: "closing",
          component: MonthlyClosing,
          name: "MonthlyClosing",
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.01.24 MCS)SAITO ADD END
        // 企業マスタ
        {
          path: "corp",
          component: CorpMaster,
          name: "CorpMaster",
          meta: {
            requiresAuth: true,
          },
        },
        // 事業所マスタ
        {
          path: "office",
          component: OfficeMaster,
          name: "OfficeMaster",
          meta: {
            requiresAuth: true,
          },
        },
        // 弁当業者マスタ
        {
          path: "trader",
          component: TraderMaster,
          name: "TraderMaster",
          meta: {
            requiresAuth: true,
          },
        },
        // 弁当マスタ
        {
          path: "bento",
          component: BentoMaster,
          name: "BentoMaster",
          meta: {
            requiresAuth: true,
          },
        },
        // 利用者マスタ
        {
          path: "user",
          component: UserMaster,
          name: "UserMaster",
          meta: {
            requiresAuth: true,
          },
        },
        // 部署マスタ
        {
          path: "depts",
          component: DeptsMaster,
          name: "DeptsMaster",
          meta: {
            requiresAuth: true,
          },
        },
        // メール定型文マスタ
        {
          path: "email",
          component: EmailMaster,
          name: "EmailMaster",
          meta: {
            requiresAuth: true,
          },
        },
        // お知らせ管理
        {
          path: "internal_notice",
          component: InternalNotice,
          name: "InternalNotice",
          meta: {
            requiresAuth: true,
          },
        },
        // 年間予定管理
        {
          path: "calendar",
          component: EventCalendar,
          name: "EventCalendar",
          meta: {
            requiresAuth: true,
          },
        },
        // ログイン履歴
        {
          path: "log",
          component: SystemLog,
          name: "SystemLog",
          meta: {
            requiresAuth: true,
          },
        },
        // 請求処理
        {
          path: "seikyu",
          component: UserSeikyu,
          name: "UserSeikyu",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.02.09 MCS)SAITO ADD START
        {
          path: "siharai",
          component: GyoshaSiharai,
          name: "GyoshaSiharai",
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.02.09 MCS)SAITO ADD END
        // 2024.02.15 MCS)SAITO ADD START
        {
          path: "simebi",
          component: SimebiUpdate,
          name: "SimebiUpdate",
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.02.15 MCS)SAITO ADD END
        // 2024.03.28 MCS)KONNO ADD START
        {
          path: "client",
          component: ClientApp,
          name: "ClientApp",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "qr",
          component: PrintQR,
          name: "PrintQR",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.03.28 MCS)KONNO ADD END
        // 2024.04.09 MCS)KONNO ADD START
        {
          path: "manual",
          component: ManualList,
          name: "ManualList",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.04.09 MCS)KONNO ADD END
        // 2024.04.22 MCS)SAITO ADD START
        {
          path: "sendemail",
          component: EmailSend,
          name: "EmailSend",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.04.22 MCS)SAITO ADD END
        // 2024.05.15 MCS)KONNO ADD START
        {
          path: "contract",
          component: ContractDetail,
          name: "ContractDetail",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.05.15 MCS)KONNO ADD END
      ],
    },

    // 存在しないページ
    {
      path: "/:notFound(.*)",
      component: NotFound,
      name: "NotFound",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (Store.state.changeFlg) {
    if (window.confirm("保存せずに終了します。\n入力内容は破棄されますが、よろしいですか？") == false) {
      next(from);
    }
    Store.state.changeFlg = false;
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Store.state.isLogin) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
