<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：SystemLog.vue
  モジュール名：ログイン履歴画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <!-- タイトル -->
    <title-card title="ログイン履歴">
      <v-responsive min-width="360px" max-width="360px">
        <v-text-field class="pt-0" v-model="search" append-icon="search" label="検索するキーワードを入力" style="background-color: white" single-line hide-details outlined dense></v-text-field>
      </v-responsive>
    </title-card>
    <!-- データ -->
    <data-card>
      <v-sheet class="px-4 py-2 d-flex" style="align-items: center">
        <span class="black--text">期間：</span>
        <v-menu v-model="dialog_dtp_st" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-responsive class="mr-2" max-width="160px">
              <v-text-field :value="date_yyyyMD(dateSt)" hide-details outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
            </v-responsive>
          </template>
          <v-date-picker v-model="dateSt" @input="changeDateSt()" :max="dateEd" locale="jp-ja" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
        </v-menu>
        <div class="mr-2">～</div>
        <v-menu v-model="dialog_dtp_ed" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-responsive class="mr-2" max-width="160px">
              <v-text-field :value="date_yyyyMD(dateEd)" hide-details outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
            </v-responsive>
          </template>
          <v-date-picker v-model="dateEd" @input="changeDateEd()" :min="dateSt" :max="today" locale="jp-ja" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
        <span>{{ fmtNumber(log_list.length) }} 件</span>
      </v-sheet>
      <v-divider></v-divider>
      <v-data-table :headers="headers" :items="log_list" item-key="item.key" :fixed-header="true" :hide-default-footer="true" :items-per-page="-1" no-data-text="ログイン履歴が存在しません。" height="calc(100vh - 245px)" width="940" :search="search" no-results-text="該当するデータがありません。" disable-pagination> </v-data-table>
    </data-card>
    <!-- *** 表示部 ここまで *** -->
  </v-container>
</template>

<script>
import moment from "moment";
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "SystemLog",
  data() {
    return {
      // ログリスト
      log_list: [],
      // 入力項目
      dateSt: "",
      dateEd: "",
      // ポップアップ
      dialog_dtp_st: false,
      dialog_dtp_ed: false,
      // フィルタ
      search: "",
      // スナックバー
      // snackbar: false,
      // snackbar_msg: "",
      // snackbar_timeout: 3000,
      // 今日の日付（制御用）
      today: moment().format("yyyy-MM-DD"),
    };
  },
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    //----------------------------------------------------------------
    // 初期化処理
    //----------------------------------------------------------------
    init() {
      // 日付範囲初期化
      this.initDateRange();
      // ログリスト取得
      this.getLog();
    },

    //----------------------------------------------------------------
    // イベント処理
    //----------------------------------------------------------------
    // 開始日変更時
    changeDateSt() {
      // ログリスト取得
      this.getLog();
      // ダイアログを閉じる
      this.dialog_dtp_st = false;
    },

    // 終了日変更時
    changeDateEd() {
      // ログリスト取得
      this.getLog();
      // ダイアログを閉じる
      this.dialog_dtp_ed = false;
    },

    //----------------------------------------------------------------
    // メソッド
    //----------------------------------------------------------------
    // 日付範囲初期化
    initDateRange() {
      this.dateSt = moment().add(-1, "w").format("yyyy-MM-DD");
      this.dateEd = moment().format("yyyy-MM-DD");
    },

    // ログ一覧取得
    async getLog() {
      const KigyoCd = this.$store.state.kigyoCode;
      const DateSt = moment(this.dateSt).format("yyyyMMDD");
      const DateEd = moment(this.dateEd).format("yyyyMMDD");
      let data = await this.$axios
        .get("/t_login_get/list" + "?KigyoCd=" + KigyoCd + "&DateSt=" + DateSt + "&DateEd=" + DateEd)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      // 形式変換
      let wkArray = [];
      if (data.length != 0) {
        data.forEach(function (element) {
          if (element.str_s_date != null && element.str_s_time != null) {
            element["datetime"] = moment(element.str_s_date).format("yyyy年 M月 D日") + " " + element.str_s_time.substring(0, 2) + "時 " + element.str_s_time.substring(2, 4) + "分 " + element.str_s_time.substring(4, 6) + "秒";
          } else {
            element["datetime"] = "不明";
          }
          wkArray.push(element);
        });
      }
      this.log_list = wkArray;
    },

    // メッセージ表示（エラー）
    // noticeNG(msg, time = 3000) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "red";
    //   this.snackbar_timeout = time;
    //   this.snackbar = true;
    // },

    // 数値をカンマ区切りで返却
    fmtNumber(num) {
      return Number(num).toLocaleString();
    },
  },

  computed: {
    headers() {
      return [
        { text: "利用者コード", sortable: false, value: "str_user_cd", align: "start", width: "200px" },
        { text: "所属・グループ", sortable: false, value: "char_busho", align: "start", width: "200px" },
        { text: "ユーザー名", sortable: false, value: "char_user", align: "start", width: "200px" },
        { text: "日時", sortable: false, value: "datetime", align: "start", width: "240px" },
        { text: "アクセス", sortable: false, value: "str_s_access", align: "start", width: "100px" },
        { text: "IPアドレス", sortable: false, value: "char_ip", align: "start", width: "100px" },
      ];
    },
    // 日付を"yyyy年 M月 D日"形式で返却
    date_yyyyMD() {
      return (date) => {
        if (date != "") {
          return moment(date).format("yyyy年 M月 D日");
        } else {
          return "";
        }
      };
    },
  },

  mounted() {
    this.init();
  },
};
</script>
