<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：PrintQR.vue
  モジュール名：受取確認用QRコードの発行画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <!-- タイトル -->
    <title-card title="受取確認用QRコードの発行">
      <v-btn color="teal" dark depressed @click="onClickPrint()">印刷</v-btn>
    </title-card>
    <!-- データ -->
    <data-card>
      <v-sheet class="pa-4">
        <h3>印刷イメージ</h3>
        <div id="printarea" class="d-inline-block" style="padding: 2cm; border: 1px solid; border-bottom-left-radius: initial; border-bottom-right-radius: initial">
          <img src="@/assets/images/logo_full.webp" alt="ビフォサック" width="256px" />
          <h2 style="margin: 16px 0px">受取確認用 QRコード</h2>
          <div style="margin: 16px 0px">
            <h3>企業コード：{{ $store.state.kigyoCode }}</h3>
            <h3>企業名&emsp;&emsp;：{{ $store.state.kigyoName }}</h3>
          </div>
          <canvas id="canvas"></canvas>
        </div>
      </v-sheet>
    </data-card>
    <!-- *** 表示部 ここまで *** -->
  </v-container>
</template>

<script>
import qrcode from "qrcode";
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "PrintQR",
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    onClickPrint() {
      window.document.body.innerHTML = document.getElementById("printarea").innerHTML;
      this.generateQR();
      window.print();
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    generateQR() {
      var canvas = document.getElementById("canvas");
      var kigyoCode = this.$store.state.kigyoCode;
      var options = {
        margin: 1,
        width: 256,
      };
      qrcode.toCanvas(canvas, kigyoCode, options, function (error) {
        if (error) console.error(error);
      });
    },
  },
  mounted() {
    this.generateQR();
  },
};
</script>

<style scoped>
@page {
  size: "A4";
  margin: 2cm;
}
#canvas {
  border: 1px solid;
}
</style>
