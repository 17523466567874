<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：OrderHistory.vue
  モジュール名：個人注文履歴画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <!-- タイトル -->
    <title-card title="個人注文履歴">
      <v-responsive min-width="360px" max-width="360px">
        <v-text-field class="pt-0" v-model="search" append-icon="search" label="検索するキーワードを入力" style="background-color: white" single-line hide-details outlined dense @change="getfilterdItems()"></v-text-field>
      </v-responsive>
    </title-card>
    <!-- データ -->
    <data-card>
      <v-sheet class="px-4 py-2 d-flex" style="align-items: center">
        <span class="black--text">利用者：</span>
        <v-responsive class="mr-4" max-width="160px">
          <v-select @change="changeUser()" class="select-input-none" v-model="select_user" :items="filter_user" item-text="str_user_name" item-value="str_user_cd" no-data-text="データがありません。" outlined hide-details dense></v-select>
        </v-responsive>

        <span class="black--text">注文場所：</span>
        <v-responsive class="mr-4" max-width="160px">
          <v-select @change="changeJigyo()" class="select-input-none" v-model="jigyosyo" :items="filter_jigyo" item-text="str_jigyo_name" item-value="num_jigyo_no" no-data-text="データがありません。" outlined hide-details dense></v-select>
        </v-responsive>

        <span class="black--text">注文日：</span>
        <v-menu v-model="dialog_dtp_st" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-responsive class="mr-2" max-width="160px">
              <v-text-field :value="date_yyyyMD(dateSt)" hide-details outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
            </v-responsive>
          </template>
          <v-date-picker v-model="dateSt" @input="changeDateSt()" :max="dateEd" locale="jp-ja" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
        </v-menu>
        <div class="mr-2">～</div>
        <v-menu v-model="dialog_dtp_ed" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-responsive class="mr-2" max-width="160px">
              <v-text-field :value="date_yyyyMD(dateEd)" hide-details outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
            </v-responsive>
          </template>
          <v-date-picker v-model="dateEd" @input="changeDateEd()" :min="dateSt" locale="jp-ja" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
        </v-menu>

        <v-btn @click="clickDownloadBtn()" class="mx-1" color="teal" dark depressed>CSVダウンロード</v-btn>
        <v-spacer></v-spacer>
        <span>{{ fmtNumber(order_list.length) }} 件</span>
      </v-sheet>
      <v-divider></v-divider>
      <v-data-table :headers="headers" :items="order_list" item-key="item.key" :fixed-header="true" :hide-default-footer="true" :items-per-page="-1" no-data-text="注文履歴が存在しません。" height="calc(100vh - 245px)" :search="search" no-results-text="該当するデータがありません。" disable-pagination ref="ref"> </v-data-table>
    </data-card>
    <!-- *** 表示部 ここまで *** -->
  </v-container>
</template>

<script>
import moment from "moment";
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "SystemLog",
  data() {
    return {
      // ユーザー
      user_list: [],
      // 注文履歴
      order_list: [],
      /*-- 2024.03.28 MCS)SAITO MOD START --*/
      // フィルター後の注文履歴
      Filterdorder_list: [],
      /*-- 2024.03.28 MCS)SAITO MOD END --*/
      // 入力項目
      select_user: "",
      dateSt: "",
      dateEd: "",
      // ポップアップ
      dialog_dtp_st: false,
      dialog_dtp_ed: false,
      // フィルタ
      search: "",
      // スナックバー
      // snackbar: false,
      // snackbar_msg: "",
      // snackbar_timeout: 3000,
      // 今日の日付（制御用）
      today: moment().format("yyyy-MM-DD"),
      /*-- 2024.04.08 MCS)SAITO ADD START --*/
      jigyosyos: [],
      jigyosyo: 0,
      /*-- 2024.04.08 MCS)SAITO ADD END --*/
    };
  },
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    //----------------------------------------------------------------
    // 初期化処理
    //----------------------------------------------------------------
    init() {
      // 利用者一覧取得
      this.getUser();
      /*-- 2024.04.08 MCS)SAITO ADD START --*/
      // 注文場所一覧取得
      this.serchjigyosho();
      /*-- 2024.04.08 MCS)SAITO ADD END --*/
      // 日付範囲初期化
      this.initDateRange();
      // 注文履歴取得
      this.getHistory();
    },

    //----------------------------------------------------------------
    // イベント処理
    //----------------------------------------------------------------
    // 表示ボタン押下時処理
    async clickViewBtn() {
      // 注文履歴取得
      await this.getHistory();
    },

    // 利用者変更時処理
    changeUser() {
      // 注文履歴取得
      this.getHistory();
    },

    // 事業所変更時処理
    changeJigyo() {
      // 注文履歴取得
      this.getHistory();
    },

    // 表示ボタン押下時処理
    changeDateSt() {
      // 注文履歴取得
      this.getHistory();
      // ダイアログを閉じる
      this.dialog_dtp_st = false;
    },

    // 表示ボタン押下時処理
    changeDateEd() {
      // 注文履歴取得
      this.getHistory();
      // ダイアログを閉じる
      this.dialog_dtp_ed = false;
    },

    // CSVダウンロードボタン押下時処理
    async clickDownloadBtn() {
      // 注文履歴取得
      /* -- 2024.05.08 MCS)KONNO DEL START --*/
      // await this.getHistory();
      /* -- 2024.05.08 MCS)KONNO DEL END --*/
      // データチェック
      if (this.order_list.length == 0) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("ダウンロード可能なデータがありません。");
        this.$root.snackbar.showMessage("ダウンロード可能なデータがありません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // CSVダウンロード
      await this.downloadCSV();
    },

    //----------------------------------------------------------------
    // メソッド
    //----------------------------------------------------------------
    // 日付範囲初期化
    initDateRange() {
      this.dateSt = moment().add(-1, "M").format("yyyy-MM-DD");
      this.dateEd = moment().format("yyyy-MM-DD");
    },

    // 利用者一覧取得
    async getUser() {
      const KigyoCd = this.$store.state.kigyoCode;
      this.user_list = await this.$axios
        .get("/m_user_get/user_list" + "?KigyoCd=" + KigyoCd)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    // 注文履歴取得
    async getHistory() {
      const KigyoCd = this.$store.state.kigyoCode;
      const UserCd = this.select_user != "" ? this.select_user : "*";
      const DateSt = moment(this.dateSt).format("yyyyMMDD");
      const DateEd = moment(this.dateEd).format("yyyyMMDD");
      /*--- 2024.04.08 MCS)SAITO ADD START ---*/
      const JigyoNo = this.jigyosyo != 0 ? this.jigyosyo : "*";
      /*--- 2024.04.08 MCS)SAITO ADD END ---*/
      /*--- 2024.03.28 MCS)SAITO ADD START ---*/
      //フィルターの初期化
      this.getfilterdItems();
      /*--- 2024.03.28 MCS)SAITO ADD END ---*/
      let data = await this.$axios
        /*--- 2024.03.28 MCS)SAITO MOD START ---*/
        // .get("/t_chu_yoyaku_get/history" + "?KigyoCd=" + KigyoCd + "&UserCd=" + UserCd + "&DateSt=" + DateSt + "&DateEd=" + DateEd)
        .get("/t_chu_yoyaku_get/history" + "?KigyoCd=" + KigyoCd + "&UserCd=" + UserCd + "&DateSt=" + DateSt + "&DateEd=" + DateEd + "&JigyoNo=" + JigyoNo)
        /*--- 2024.03.28 MCS)SAITO MOD END ---*/
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      // 形式変換
      let wkArray = [];
      if (data.length != 0) {
        data.forEach(function (element, index) {
          element["no"] = index + 1;
          if (element.str_chumonbi != null) {
            element["fmt_chumonbi"] = moment(element.str_chumonbi).format("yyyy年 M月 D日");
          } else {
            element["fmt_chumonbi"] = "不明";
          }
          if (element.num_kosuu != null) {
            element["fmt_kosuu"] = element.num_kosuu + "個";
          } else {
            element["fmt_kosuu"] = "不明";
          }
          if (element.num_goukei != null) {
            element["fmt_goukei"] = element.num_goukei + "円";
          } else {
            element["fmt_goukei"] = "不明";
          }
          if (element.str_s_date != null && element.str_s_time != null) {
            element["fmt_datetime"] = moment(element.str_s_date).format("yyyy年 M月 D日") + " " + element.str_s_time.substring(0, 2) + "時 " + element.str_s_time.substring(2, 4) + "分 " + element.str_s_time.substring(4, 6) + "秒";
          } else {
            element["fmt_datetime"] = "不明";
          }
          wkArray.push(element);
        });
      }
      this.order_list = wkArray;
    },

    // CSVダウンロード
    async downloadCSV() {
      /*--- 2024.03.28 MCS)SAITO ADD START ---*/
      var output = [];
      if (this.search != "" && JSON.stringify(this.order_list) != JSON.stringify(this.Filterdorder_list)) {
        output = this.Filterdorder_list;
      } else {
        output = this.order_list;
      }
      /*--- 2024.03.28 MCS)SAITO ADD END ---*/
      /*--- 2024.03.25 MCS)SAITO MOD START ---*/
      // let csv = "\ufeff" + "No.,利用者コード,ユーザー名,所属・グループ,注文場所,注文日,メニュー,個数,金額,注文登録日時\n";
      let csv = "\ufeff" + "No.,利用者コード,ユーザー名,所属・グループ,注文場所,弁当業者,注文日,メニュー,個数,金額,注文登録日時,受取\n";
      /*--- 2024.03.25 MCS)SAITO MOD END ---*/
      /*--- 2024.03.28 MCS)SAITO MOD START ---*/
      // if (this.order_list.length != 0) {
      //  this.order_list.forEach(function (element) {
      if (output.length != 0) {
        output.forEach(function (element) {
          /*--- 2024.03.28 MCS)SAITO MOD END ---*/
          csv += element.no + ",";
          csv += element.str_user_cd + ",";
          csv += element.str_user_name + ",";
          csv += element.str_busho_name + ",";
          csv += element.str_jigyo_name + ",";
          /*--- 2024.03.25 MCS)SAITO ADD START ---*/
          csv += element.str_gyosha_name + ",";
          /*--- 2024.03.25 MCS)SAITO ADD END ---*/
          csv += moment(element.str_chumonbi).format("yyyy/MM/DD") + ",";
          csv += element.str_bento_name + ",";
          csv += element.num_kosuu + ",";
          csv += element.num_goukei + ",";
          /*--- 2024.03.25 MCS)SAITO MOD START ---*/
          // csv += moment(element.str_s_date).format("yyyy/MM/DD") + " " + element.str_s_time.substring(0, 2) + ":" + element.str_s_time.substring(2, 4) + ":" + element.str_s_time.substring(4, 6) + "\n";
          csv += moment(element.str_s_date).format("yyyy/MM/DD") + " " + element.str_s_time.substring(0, 2) + ":" + element.str_s_time.substring(2, 4) + ":" + element.str_s_time.substring(4, 6) + ",";
          csv += element.str_uketori + "\n";
          /*--- 2024.03.25 MCS)SAITO MOD END ---*/
        });
      }
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      /*--- 2024.03.25 MCS)SAITO MOD START ---*/
      // let date = moment().format("yyyyMMDD");
      // let timestamp = moment().format("X");
      // let file_name = "注文履歴_" + date + "_" + timestamp + ".csv";
      let file_name = "個人注文履歴_" + this.$store.state.nowDate + this.$store.state.nowTime + ".csv";
      /*--- 2024.03.25 MCS)SAITO MOD END ---*/
      link.href = window.URL.createObjectURL(blob);
      link.download = file_name;
      link.click();
    },

    // メッセージ表示（エラー）
    // noticeNG(msg, time = 3000) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "red";
    //   this.snackbar_timeout = time;
    //   this.snackbar = true;
    // },

    // 数値をカンマ区切りで返却
    fmtNumber(num) {
      return Number(num).toLocaleString();
    },

    /*--- 2024.03.28 MCS)SAITO ADD START ---*/
    //v-data-tableのfilter後の検索結果を取得
    getfilterdItems() {
      let items = this.$refs.ref.$children[0].filteredItems;
      let sorted = this.$refs.ref.$children[0].sortItems(items);
      this.Filterdorder_list = sorted;
    },
    /*--- 2024.03.28 MCS)SAITO ADD END ---*/

    /*--- 2024.04.08 MCS)SAITO ADD START ---*/
    async serchjigyosho() {
      const self = this;
      await this.$axios
        .get("/m_jigyo_get/jigyosyo_select", {
          params: {
            KigyoCd: this.$store.state.kigyoCode, //セッション(Vuex)
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              self.jigyosyos = d["data"];
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    /*--- 2024.04.08 MCS)SAITO ADD END ---*/
  },

  computed: {
    headers() {
      return [
        /*--- 2024.04.08 MCS)SAITO MOD START ---*/
        // { text: "No.", sortable: false, value: "no", align: "start", width: "60px" },
        // { text: "利用者コード", sortable: false, value: "str_user_cd", align: "start", width: "120px" },
        // { text: "ユーザー名", sortable: false, value: "str_user_name", align: "start", width: "120px" },
        // { text: "所属・グループ", sortable: false, value: "str_busho_name", align: "start", width: "180px" },
        // { text: "注文場所", sortable: false, value: "str_jigyo_name", align: "start", width: "120px" },
        { text: "No.", sortable: true, value: "no", align: "start", width: "80px" },
        { text: "利用者コード", sortable: true, value: "str_user_cd", align: "start", width: "130px" },
        { text: "ユーザー名", sortable: true, value: "str_user_name", align: "start", width: "120px" },
        { text: "所属・グループ", sortable: true, value: "str_busho_name", align: "start", width: "180px" },
        { text: "注文場所", sortable: true, value: "str_jigyo_name", align: "start", width: "120px" },
        /*--- 2024.04.08 MCS)SAITO MOD END ---*/
        /*--- 2024.04.08 MCS)SAITO MOD START ---*/
        /*--- 2024.03.26 MCS)SAITO ADD START ---*/
        // { text: "弁当業者", sortable: false, value: "str_gyosha_name", align: "start", width: "120px" },
        { text: "弁当業者", sortable: true, value: "str_gyosha_name", align: "start", width: "140px" },
        /*--- 2024.03.26 MCS)SAITO ADD END ---*/
        /*--- 2024.04.08 MCS)SAITO MOD END ---*/
        /*--- 2024.04.08 MCS)SAITO MOD START ---*/
        // { text: "注文日", sortable: false, value: "fmt_chumonbi", align: "start", width: "150px" },
        // { text: "メニュー", sortable: false, value: "str_bento_name", align: "start", width: "150px" },
        // { text: "個数", sortable: false, value: "fmt_kosuu", align: "end", width: "80px" },
        // { text: "金額", sortable: false, value: "fmt_goukei", align: "end", width: "100px" },
        // { text: "注文登録日時", sortable: false, value: "fmt_datetime", align: "start", width: "240px" },
        {
          text: "注文日",
          sortable: true,
          value: "fmt_chumonbi",
          align: "start",
          width: "140px",
          sort: (a, b) => {
            var numA = 0;
            var numB = 0;
            if (a != "不明") {
              numA = a.replace(/年 /g, "-");
              numA = numA.replace(/月 /g, "-");
              numA = numA.replace(/日/g, "");
              numA = moment(numA).format("yyyyMMDD");
            }
            if (b != "不明") {
              numB = b.replace(/年 /g, "-");
              numB = numB.replace(/月 /g, "-");
              numB = numB.replace(/日/g, "");
              numB = moment(numB).format("yyyyMMDD");
            }
            return numA - numB;
          },
        },
        { text: "メニュー", sortable: true, value: "str_bento_name", align: "start", width: "120px" },
        {
          text: "個数",
          sortable: true,
          value: "fmt_kosuu",
          align: "end",
          width: "80px",
          sort: (a, b) => {
            var numA = 0;
            var numB = 0;
            if (a != "不明") {
              numA = a.replace(/個/g, "");
            }
            if (b != "不明") {
              numB = b.replace(/個/g, "");
            }
            return numA - numB;
          },
        },
        {
          text: "金額",
          sortable: true,
          value: "fmt_goukei",
          align: "end",
          width: "100px",
          sort: (a, b) => {
            var numA = 0;
            var numB = 0;
            if (a != "不明") {
              numA = a.replace(/円/g, "");
            }
            if (b != "不明") {
              numB = b.replace(/円/g, "");
            }
            return numA - numB;
          },
        },
        {
          text: "注文登録日時",
          sortable: true,
          value: "fmt_datetime",
          align: "start",
          width: "240px",
          sort: (a, b) => {
            var numA = 0;
            var numB = 0;
            if (a != "不明") {
              numA = a.replace(/年 /g, "-");
              numA = numA.replace(/月 /g, "-");
              numA = numA.replace(/日 /g, " ");
              numA = numA.replace(/時 /g, ":");
              numA = numA.replace(/分 /g, ":");
              numA = numA.replace(/秒/g, "");
              numA = moment(numA).format("yyyyMMDDHHmmss");
            }
            if (b != "不明") {
              numB = b.replace(/年 /g, "-");
              numB = numB.replace(/月 /g, "-");
              numB = numB.replace(/日 /g, " ");
              numB = numB.replace(/時 /g, ":");
              numB = numB.replace(/分 /g, ":");
              numB = numB.replace(/秒/g, "");
              numB = moment(numB).format("yyyyMMDDHHmmss");
            }
            return numA - numB;
          },
        },
        /*--- 2024.04.08 MCS)SAITO MOD END ---*/
        /*--- 2024.04.08 MCS)SAITO MOD START ---*/
        /*--- 2024.03.26 MCS)SAITO ADD START ---*/
        // { text: "受取", sortable: false, value: "str_uketori", align: "start", width: "120px" },
        { text: "受取", sortable: true, value: "str_uketori", align: "start", width: "80px" },
        /*--- 2024.03.26 MCS)SAITO ADD END ---*/
        /*--- 2024.04.08 MCS)SAITO MOD END ---*/
      ];
    },
    // 日付を"yyyy年 M月 D日"形式で返却
    date_yyyyMD() {
      return (date) => {
        if (date != "") {
          return moment(date).format("yyyy年 M月 D日");
        } else {
          return "";
        }
      };
    },
    // 絞り込み条件（利用者）を返却
    filter_user() {
      let wkArray = [];
      let allSelect = [{ str_user_cd: "", str_user_name: "すべて" }];
      return wkArray.concat(allSelect, this.user_list);
    },
    // 絞り込み条件（事業所）を返却
    filter_jigyo() {
      let wkArray = [];
      let allSelect = [{ num_jigyo_no: 0, str_jigyo_name: "すべて" }];
      return wkArray.concat(allSelect, this.jigyosyos);
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.select-input-none >>> .v-select__selections input {
  width: 0;
}
</style>
