<template>
  <v-navigation-drawer :mini-variant.sync="$store.state.menuView" color="teal lighten-1" dark width="310" app clipped permanent touchless>
    <v-list class="pt-5" expand>
      <!-- システム -->
      <v-list-item v-for="item in system" :key="item.title" :to="item.to" :disabled="item.disable" v-show="item.show" @click="autoClose" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <!-- 予約 -->
      <v-list-group :value="submenu_open" prepend-icon="post_add" active-class="white--text" v-show="authShow" no-action>
        <template v-slot:activator>
          <v-list-item-title>予約</v-list-item-title>
        </template>

        <v-list-item v-for="item in reservation" :key="item.title" :to="item.to" :disabled="item.disable" v-show="item.show" @click="autoClose" active-class="white--text" link>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>

      <v-divider v-show="authShow"></v-divider>

      <!-- 注文 -->
      <v-list-group :value="submenu_open" prepend-icon="send" active-class="white--text" v-show="authShow" no-action>
        <template v-slot:activator>
          <v-list-item-title>注文</v-list-item-title>
        </template>

        <v-list-item v-for="item in order" :key="item.title" :to="item.to" :disabled="item.disable" v-show="item.show" @click="autoClose" active-class="white--text" link>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>

      <v-divider v-show="authShow"></v-divider>

      <!-- 月次処理 -->
      <v-list-group :value="submenu_open" prepend-icon="calendar_month" active-class="white--text" v-show="authShow" no-action>
        <template v-slot:activator>
          <v-list-item-title>月次</v-list-item-title>
        </template>

        <v-list-item v-for="item in monthly" :key="item.title" :to="item.to" :disabled="item.disable" v-show="item.show" @click="autoClose" active-class="white--text" link>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>

      <v-divider v-show="authShow"></v-divider>

      <!-- マスタ -->
      <!-- 利用者の場合、グループ化する -->
      <template v-if="this.$store.state.adminAuth == 1">
        <v-list-group :value="submenu_open" prepend-icon="storage" active-class="white--text" no-action>
          <template v-slot:activator>
            <v-list-item-title>マスタ</v-list-item-title>
          </template>

          <v-list-item v-for="item in master" :key="item.title" :to="item.to" :disabled="item.disable" v-show="item.show" @click="autoClose" active-class="white--text" link>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </template>

      <!-- システム管理者の場合、グループ化しない -->
      <template v-if="this.$store.state.adminAuth == 2">
        <v-list-item v-for="item in master" :key="item.title" :to="item.to" :disabled="item.disable" v-show="item.show" @click="autoClose" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider v-if="this.$store.state.adminAuth == 1"></v-divider>

      <!-- その他 -->
      <v-list-group :value="submenu_open" prepend-icon="more" active-class="white--text" v-show="authShow" no-action>
        <template v-slot:activator>
          <v-list-item-title>その他</v-list-item-title>
        </template>

        <v-list-item v-for="item in other" :key="item.title" :to="item.to" :disabled="item.disable" v-show="item.show" @click="autoClose" active-class="white--text" link>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      submenu_open: false,
      system: [
        {
          title: "運営からのお知らせ",
          icon: "notifications",
          to: "/admin/system_notice",
          disable: false,
          show: true,
        },
        /*-- 2024.04.22 MCS)SAITO ADD START --*/
        {
          title: "一斉メール送信",
          icon: "send",
          to: "/admin/sendemail",
          disable: false,
          show: this.$store.state.adminAuth == 2,
        },
        /*-- 2024.04.22 MCS)SAITO ADD END --*/
      ],
      reservation: [
        {
          title: "一括予約登録",
          icon: "checklist",
          to: "/admin/multiple-order",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        {
          title: "予約状況確認",
          icon: "event_note",
          to: "/admin/check",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        {
          title: "予約状況一覧",
          icon: "format_list_numbered",
          to: "/admin/list",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
      ],
      order: [
        {
          title: "注文状況一覧",
          icon: "format_list_bulleted",
          to: "/admin/order",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        {
          title: "個人注文履歴",
          icon: "history",
          to: "/admin/history",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
      ],
      monthly: [
        /*-- 2024.03.25 MCS)SAITO MOD START */
        /*-- 2024.01.24 MCS)SAITO ADD START */
        // {
        //   title: "月次締め処理",
        //   icon: "import_contacts",
        //   to: "/admin/closing",
        //   disable: false,
        //   show: this.$store.state.adminAuth == 1,
        // },
        // /*-- 2024.01.24 MCS)SAITO ADD END */
        // {
        //   title: "月別集計表出力",
        //   icon: "print",
        //   to: "/admin/report",
        //   disable: false,
        //   show: this.$store.state.adminAuth == 1,
        // },
        {
          title: "月別集計表出力",
          icon: "print",
          to: "/admin/report",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        {
          title: "月次締め処理",
          icon: "import_contacts",
          to: "/admin/closing",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        /*-- 2024.03.25 MCS)SAITO MOD END */
        {
          title: "請求情報",
          icon: "attach_money",
          to: "/admin/seikyu",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        /*-- 2024.02.09 MCS)SAITO ADD START --*/
        {
          title: "支払情報",
          icon: "store",
          to: "/admin/siharai",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        /*-- 2024.02.09 MCS)SAITO ADD END --*/
      ],
      master: [
        {
          title: "企業マスタ",
          icon: "domain",
          to: "/admin/corp",
          disable: false,
          show: this.$store.state.adminAuth == 2,
        },
        {
          title: "事業所マスタ",
          icon: "place",
          to: "/admin/office",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        {
          title: "弁当業者マスタ",
          icon: "local_shipping",
          to: "/admin/trader",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        {
          title: "メニューマスタ",
          icon: "bento",
          to: "/admin/bento",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        {
          title: "所属・グループマスタ",
          icon: "badge",
          to: "/admin/depts",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        {
          title: "利用者マスタ",
          icon: "people",
          to: "/admin/user",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        {
          title: "メール定型文マスタ",
          icon: "email",
          to: "/admin/email",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        /*-- 2024.02.15 MCS)SAITO ADD START --*/
        {
          title: "締日登録",
          icon: "done_outline",
          to: "/admin/simebi",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        /*-- 2024.02.15 MCS)SAITO ADD END --*/
      ],
      other: [
        {
          title: "お知らせ管理",
          icon: "edit_notifications",
          to: "/admin/internal_notice",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        {
          title: "年間予定管理",
          icon: "edit_calendar",
          to: "/admin/calendar",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        {
          title: "ログイン履歴",
          icon: "history",
          to: "/admin/log",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        // 2024.03.28 MCS)KONNO ADD START
        {
          title: "クライアントアプリ",
          icon: "get_app",
          to: "/admin/client",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        {
          title: "受取確認用QRコード",
          icon: "qr_code",
          to: "/admin/qr",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        // 2024.03.28 MCS)KONNO ADD END
        // 2024.04.09 MCS)KONNO ADD START
        {
          title: "マニュアル一覧",
          icon: "description",
          to: "/admin/manual",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        // 2024.04.09 MCS)KONNO ADD END
        // 2024.05.15 MCS)KONNO ADD START
        {
          title: "契約情報",
          icon: "info",
          to: "/admin/contract",
          disable: false,
          show: this.$store.state.adminAuth == 1,
        },
        // 2024.05.15 MCS)KONNO ADD END
      ],
    };
  },
  methods: {
    // サイドメニュー自動クローズ
    autoClose() {
      if (window.innerWidth < 1280) {
        this.$store.dispatch("view", true);
      }
    },
  },
  computed: {
    authShow() {
      if (this.$store.state.adminAuth == 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$store.dispatch("icon", true);
    // サイドメニュー自動開閉
    window.addEventListener("resize", (e) => {
      if (!(e instanceof UIEvent)) {
        if (window.innerWidth < 1280 && this.$store.state.menuView == false) {
          this.$store.dispatch("view", true);
        } else if (window.innerWidth > 1264 && this.$store.state.menuView == true) {
          this.$store.dispatch("view", false);
        }
      }
    });
  },
};
</script>
