<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：MonthlyReport.vue
  モジュール名：月別集計表出力画面
  備考：
-------------------------------------------------->
<style lang="scss" scoped>
@import "./src/assets/css/OrderSheet.scss";
</style>

<template>
  <v-container class="d-print-none" fluid>
    <!-- *** 表示部 ここから *** -->
    <title-card title="月別集計表出力">
      <v-btn class="ml-2" color="teal" dark depressed @click="reportcreate_vb()">印刷プレビュー</v-btn>
      <v-btn class="ml-2" color="teal" dark depressed @click="downloadCSV()">CSVダウンロード</v-btn>
    </title-card>
    <data-card class="py-2">
      <v-form ref="select_form">
        <v-sheet class="px-4 py-2 d-flex" style="align-items: center" height="56px">
          <span>処理年月：</span>
          <v-responsive class="mr-2" max-width="120px">
            <v-text-field hide-details outlined dense v-bind:value="this.year" maxlength="4" type="number" onkeydown="if( event.keyCode === 69 || event.keyCode === 189 || event.keyCode === 110 || event.keyCode === 190) {return false;}" oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" v-model.number="year" :rules="[required, limit_length]" v-model="year" backgroundColor="white" suffix="年"></v-text-field>
          </v-responsive>
          <v-responsive class="mr-2" max-width="120px">
            <v-select outlined hide-details dense v-model="month" v-bind:value="this.month" :items="months" backgroundColor="white"></v-select>
          </v-responsive>
        </v-sheet>

        <v-sheet class="px-4 py-2 d-flex" style="align-items: center" height="56px">
          <span>注文日&emsp;：</span>
          <v-menu v-model="dialog_dtp_st" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-responsive class="mr-2" max-width="160px">
                <v-text-field :value="date_yyyyMD(sDate)" hide-details outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
              </v-responsive>
            </template>
            <v-date-picker v-model="sDate" @input="dialog_dtp_st = false" :max="eDate" locale="jp-ja" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
          </v-menu>
          <span class="mr-2">～</span>
          <v-menu v-model="dialog_dtp_ed" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-responsive class="mr-2" max-width="160px">
                <v-text-field :value="date_yyyyMD(eDate)" hide-details outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
              </v-responsive>
            </template>
            <v-date-picker v-model="eDate" @input="dialog_dtp_ed = false" :min="sDate" locale="jp-ja" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
          </v-menu>
        </v-sheet>

        <v-sheet class="px-4 py-2 d-flex" style="align-items: top">
          <span class="mt-1">出力帳票：</span>
          <v-radio-group v-model="ReportType" :rules="[select_reporttype]" class="mt-0">
            <v-radio label="弁当業者毎個人品名別集計表" value="kojinhinmei" color="black"></v-radio>
            <v-radio label="事業所毎個人別集計表" value="kojin" color="black"></v-radio>
          </v-radio-group>
        </v-sheet>
      </v-form>
    </data-card>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** プレビュー用ダイアログ ここから *** -->
    <v-dialog v-model="showModal" fullscreen>
      <v-card style="background: gray" class="mt-40">
        <v-card-actions class="d-print-none">
          <v-spacer></v-spacer>
          <v-btn class="d-print-none" height="35" width="95" color="teal" dark @click="downloadCSV()">CSV</v-btn>
          <v-btn class="d-print-none" height="35" width="95" color="teal" dark @click="print()">印刷</v-btn>
          <v-btn class="d-print-none" height="35" width="95" color="teal" dark @click="close_fax()">閉じる</v-btn>
        </v-card-actions>
        <PreviewReports :title="this.ReportType" :list="this.data" :year="Number(this.year)" :month="this.select" :btnpushflg="this.btnpushflg" :sDate="this.psDate" :eDate="this.peDate" :jigyosyono="0" />
      </v-card>
    </v-dialog>
    <!-- *** プレビュー用ダイアログ ここまで *** -->
  </v-container>
</template>

<style>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<script>
import store from "@/store";
import PreviewReports from "@/components/admin/PreviewReports.vue";
/*--- 2024.02.08 MCS)SAITO ADD START ---*/
import moment from "moment";
/*--- 2024.02.08 MCS)SAITO ADD END ---*/
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "MonthlyReport",
  data() {
    return {
      // msg: "",
      // snackbar: false,
      // timeout: 3000,
      ReportType: "kojinhinmei",
      months: [1 + "月", 2 + "月", 3 + "月", 4 + "月", 5 + "月", 6 + "月", 7 + "月", 8 + "月", 9 + "月", 10 + "月", 11 + "月", 12 + "月"], //実際に表示される月
      year: String(new Date().getFullYear()), //今年
      month: new Date().getMonth() + 1 + "月", //今月
      select: "",
      total: [],
      data: [],
      showModal: false,
      reloadFlg: true,
      required: (value) => !!value || "必ず入力してください。",
      limit_length: (value) => value.length >= 4 || "西暦を入力してください。",
      select_reporttype: (value) => !!value || "「弁当業者毎個人品名別集計表」「事業所毎個人別集計表」「月別所属グループ別個人集計表」のどれかを選択してください。",
      /*--- 2023.12.07 MCS)SAITO ADD START ---*/
      btnpushflg: false,
      /*--- 2023.12.07 MCS)SAITO ADD END ---*/
      /*--- 2023.12.13 MCS)SAITO ADD START ---*/
      kigyo: [],
      simebi: "",
      sDate: "",
      eDate: "",
      /*--- 2024.02.08 MCS)SAITO DEL START ---*/
      // ,JigyoFilterItems: []
      // ,JigyoFilterSelect: 0
      /*--- 2024.02.08 MCS)SAITO DEL END ---*/
      /*--- 2023.12.13 MCS)SAITO ADD END ---*/
      /*--- 2024.02.08 MCS)SAITO ADD START ---*/
      // ポップアップ
      dialog_dtp_st: false,
      dialog_dtp_ed: false,
      // 印刷画面で使う日付
      psDate: "",
      peDate: "",
      /*--- 2024.02.08 MCS)SAITO ADD END ---*/
    };
  },
  components: {
    PreviewReports,
    TitleCard,
    DataCard,
  },
  /*--- 2023.12.13 MCS)SAITO ADD START ---*/
  async created() {
    const self = this;
    //締日の取得
    const KigyoCd = this.$store.state.kigyoCode;
    this.kigyo = await this.$axios
      .get("/m_kigyo_get/info" + "?KigyoCd=" + KigyoCd)
      .then(function (response) {
        return response.data["data"];
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
    if (self.kigyo.length != 0) {
      if (self.kigyo["num_simebi"] === null) {
        self.simebi = 0;
      } else {
        self.simebi = self.kigyo["num_simebi"].toString();
      }
    }
    //注文日範囲初期化
    this.initDateRange();
    //締め日範囲計算
    await this.CalcSimebi();
    this.$watch(
      // 2つのプロパティを含めた値を評価させる
      () => [this.$data.year, this.$data.month],
      (value, oldValue) => {
        if (value[0] != oldValue[0] || value[1] != oldValue[1]) {
          //締め日範囲計算
          this.CalcSimebi();
        }
      }
    );
    /*--- 2024.02.08 MCS)SAITO ADD END ---*/
  },
  /*--- 2023.12.13 MCS)SAITO ADD END ---*/
  methods: {
    reportcreate() {
      this.btnpushflg = false;
      if (this.msg != "") {
        this.msg = "";
      }
      this.serchReport(this.ReportType);
    },
    print() {
      window.print();
    },
    close_fax() {
      this.showModal = false;
    },
    async serchReport(ReportType) {
      const self = this;
      /*--- 2024.02.08 MCS)SAITO ADD START ---*/
      var strsDate = "";
      var streDate = "";
      var strresult = "";
      strsDate = this.sDate;
      strresult = this.sDate.replace("-", "");
      while (strsDate !== strresult) {
        strsDate = strsDate.replace("-", "");
        strresult = strresult.replace("-", "");
      }
      this.psDate = strsDate;
      streDate = this.eDate;
      strresult = this.eDate.replace("-", "");
      while (streDate !== strresult) {
        streDate = streDate.replace("-", "");
        strresult = strresult.replace("-", "");
      }
      this.peDate = streDate;
      /*--- 2024.02.08 MCS)SAITO ADD END ---*/
      if (ReportType == "kojinhinmei") {
        await this.$axios
          .get("/t_chu_yoyaku_get/monthly", {
            params: {
              KigyoCd: this.$store.state.kigyoCode,
              /*--- 2023.12.13 MCS)SAITO MOD START ---*/
              // Chumonbi: this.year + this.select + "%",
              /*--- 2024.02.08 MCS)SAITO MOD START ---*/
              // Chumonbi_s:this.sDate,
              // Chumonbi_e:this.eDate,
              Chumonbi_s: this.psDate,
              Chumonbi_e: this.peDate,
              /*--- 2024.02.08 MCS)SAITO MOD END ---*/
              /*--- 2024.02.08 MCS)SAITO MOD START ---*/
              // ChumonJigyo:this.JigyoFilterItems[this.JigyoFilterSelect]["liJigyoNo"]
              ChumonJigyo: 0,
              /*--- 2024.02.08 MCS)SAITO MOD END ---*/
              /*--- 2023.12.13 MCS)SAITO MOD END ---*/
            },
          })
          .then(function (response) {
            Promise.resolve(response.data).then(function (d) {
              if (Object.keys(d["data"]).length != 0) {
                self.data = d["data"];
                // self.$router.push({ name: "PreviewReports", params: { title: ReportType, list: self.data, year: Number(self.year), month: self.select} });
                // self.showModal = true;
              } else {
                /* -- 2024.05.08 MCS)KONNO MOD START --*/
                // self.msg = self.year + "年" + self.month + "の弁当業者毎個人品名別集計表に該当するデータがありません。";
                // self.snackbar = true;
                self.$root.snackbar.showMessage(self.year + "年" + self.month + "の弁当業者毎個人品名別集計表に該当するデータがありません。", "warning");
                /* -- 2024.05.08 MCS)KONNO MOD END --*/
              }
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      } else if (ReportType == "kojin") {
        await this.$axios
          .get("/t_chu_yoyaku_get/personal", {
            params: {
              KigyoCd: this.$store.state.kigyoCode,
              /*--- 2023.12.13 MCS)SAITO MOD START ---*/
              // Chumonbi: this.year + this.select + "%",
              /*--- 2024.02.08 MCS)SAITO MOD START ---*/
              // Chumonbi_s:this.sDate,
              // Chumonbi_e:this.eDate,
              Chumonbi_s: this.psDate,
              Chumonbi_e: this.peDate,
              /*--- 2024.02.08 MCS)SAITO MOD END ---*/
              /*--- 2024.02.08 MCS)SAITO MOD START ---*/
              // ChumonJigyo:this.JigyoFilterItems[this.JigyoFilterSelect]["liJigyoNo"]
              ChumonJigyo: 0,
              /*--- 2024.02.08 MCS)SAITO MOD END ---*/
              YuusenFlg: 0,
              /*--- 2023.12.13 MCS)SAITO MOD END ---*/
            },
          })
          .then(function (response) {
            Promise.resolve(response.data).then(function (d) {
              if (Object.keys(d["data"]).length != 0) {
                self.data = d["data"];
                // self.$router.push({ name: "PreviewReports", params: { title: ReportType, list: self.data, year: Number(self.year), month: self.select} });
                // self.showModal = true;
              } else {
                /* -- 2024.05.08 MCS)KONNO MOD START --*/
                // self.msg = self.year + "年" + self.month + "の事業所毎個人別集計表に該当するデータがありません。";
                // self.snackbar = true;
                self.$root.snackbar.showMessage(self.year + "年" + self.month + "の事業所毎個人別集計表に該当するデータがありません。", "warning");
                /* -- 2024.05.08 MCS)KONNO MOD END --*/
              }
            });
          })
          .catch(function (error) {
            console.log(error);
          });
        /*--- 2024.03.05 MCS)SAITO ADD START ---*/
      } else if (ReportType == "jigyo") {
        await this.$axios
          .get("/t_chu_yoyaku_get/group", {
            params: {
              KigyoCd: this.$store.state.kigyoCode,
              Chumonbi_s: this.psDate,
              Chumonbi_e: this.peDate,
              ChumonJigyo: 0,
              Jigyoshoflg: "True",
            },
          })
          .then(function (response) {
            Promise.resolve(response.data).then(function (d) {
              if (Object.keys(d["data"]).length != 0) {
                self.data = d["data"];
                // self.$router.push({ name: "PreviewReports", params: { title: ReportType, list: self.data, year: Number(self.year), month: self.select} });
                // self.showModal = true;
              } else {
                /* -- 2024.05.08 MCS)KONNO MOD START --*/
                // self.msg = self.year + "年" + self.month + "の月次事業所別集計表に該当するデータがありません。";
                // self.snackbar = true;
                self.$root.snackbar.showMessage(self.year + "年" + self.month + "の月次事業所別集計表に該当するデータがありません。", "warning");
                /* -- 2024.05.08 MCS)KONNO MOD END --*/
              }
            });
          })
          .catch(function (error) {
            console.log(error);
          });
        /*--- 2024.03.05 MCS)SAITO ADD END ---*/
      } else {
        await this.$axios
          .get("/t_chu_yoyaku_get/group", {
            params: {
              KigyoCd: this.$store.state.kigyoCode,
              Chumonbi_s: this.psDate,
              Chumonbi_e: this.peDate,
              ChumonJigyo: 0,
              Jigyoshoflg: "False",
            },
          })
          .then(function (response) {
            Promise.resolve(response.data).then(function (d) {
              if (Object.keys(d["data"]).length != 0) {
                self.data = d["data"];
              } else {
                /* -- 2024.05.08 MCS)KONNO MOD START --*/
                // self.msg = self.year + "年" + self.month + "の月次弁当業者別集計表に該当するデータがありません。";
                // self.snackbar = true;
                self.$root.snackbar.showMessage(self.year + "年" + self.month + "の月次弁当業者別集計表に該当するデータがありません。", "warning");
                /* -- 2024.05.08 MCS)KONNO MOD END --*/
              }
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      /*--- 2023.12.07 MCS)SAITO ADD START ---*/
      // this.btnpushflg = true
      /*--- 2023.12.07 MCS)SAITO ADD END ---*/
    },
    async downloadCSV() {
      var csv = "";
      var varmonth = "";
      if (this.month.substring(0, this.month.length - 1).length != 2) {
        //月が一桁の時、先頭に0を追加する
        varmonth = "0" + this.month.substring(0, this.month.length - 1);
      } else {
        varmonth = this.month.substring(0, this.month.length - 1);
      }
      /*--- 2024.03.05 MCS)SAITO ADD START ---*/
      //データの取得
      await this.serchReport(this.ReportType);
      /*--- 2024.03.05 MCS)SAITO ADD END ---*/
      if (this.ReportType == "kojinhinmei") {
        /*--- 2024.01.23 MCS)SAITO ADD START ---*/
        csv = "\ufeff" + this.year + "年" + varmonth + "月分" + "（注文日：" + this.psDate.substring(0, 4) + "年" + this.psDate.substring(4, 6) + "月" + this.psDate.substring(6, 8) + "日" + "～" + this.peDate.substring(0, 4) + "年" + this.peDate.substring(4, 6) + "月" + this.peDate.substring(6, 8) + "日）\n";
        /*--- 2024.01.23 MCS)SAITO ADD END ---*/
        /*--- 2024.01.23 MCS)SAITO MOD START ---*/
        // csv = "\ufeff" + "利用者コード,所 属,氏 名,品 名,単 価,個 数,金 額\n";
        csv += "\ufeff" + "弁当業者名,利用者コード,所 属,氏 名,品 名,単 価,個 数,金 額\n";
        /*--- 2024.01.23 MCS)SAITO MOD END ---*/
        this.data.forEach((el) => {
          /*--- 2024.01.23 MCS)SAITO MOD START ---*/
          // var line = el["str_user_cd"] + "," + el["str_busho_name"] + "," + el["str_user_name"] + "," + el["str_bento_name"] + "," + el["num_kingaku"] + "," + el["sum_kosuu"] + "," + el["sum_goukei"] + "\n";
          var line = el["str_gyosha_name"] + "," + el["str_user_cd"] + "," + el["str_busho_name"] + "," + el["str_user_name"] + "," + el["str_bento_name"] + "," + el["num_kingaku"] + "," + el["sum_kosuu"] + "," + el["sum_goukei"] + "\n";
          /*--- 2024.01.23 MCS)SAITO MOD END ---*/
          csv += line;
        });
        let blob = new Blob([csv], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        /*--- 2024.03.27 MCS)SAITO MOD START ---*/
        // link.download = store.state.nowDate + "" + store.state.nowTime + "弁当業者毎個人品名別集計表_" + this.year + "" + varmonth + ".csv";
        link.download = "弁当業者毎個人品名別集計表" + this.year + "" + varmonth + "_" + store.state.nowDate + store.state.nowTime + ".csv";
        /*--- 2024.03.27 MCS)SAITO MOD END ---*/
        link.click();
      } else if (this.ReportType == "kojin") {
        //月別個別集計表
        /*--- 2024.01.23 MCS)SAITO ADD START ---*/
        csv = "\ufeff" + this.year + "年" + varmonth + "月分" + "（注文日：" + this.psDate.substring(0, 4) + "年" + this.psDate.substring(4, 6) + "月" + this.psDate.substring(6, 8) + "日" + "～" + this.peDate.substring(0, 4) + "年" + this.peDate.substring(4, 6) + "月" + this.peDate.substring(6, 8) + "日）\n";
        /*--- 2024.01.23 MCS)SAITO ADD END ---*/
        /*--- 2024.01.23 MCS)SAITO MOD START ---*/
        // csv = "\ufeff" + "利用者コード,所 属,氏 名,金 額\n";
        csv += "\ufeff" + "事業所名,利用者コード,所 属,氏 名,金 額\n";
        /*--- 2024.01.23 MCS)SAITO MOD END ---*/
        this.data.forEach((el) => {
          /*--- 2024.01.23 MCS)SAITO MOD START ---*/
          // var line = el["str_user_cd"] + "," + el["str_busho_name"] + "," + el["str_user_name"] + "," + el["total"] + "\n";
          var line = el["str_jigyo_name"] + "," + el["str_user_cd"] + "," + el["str_busho_name"] + "," + el["str_user_name"] + "," + el["total"] + "\n";
          /*--- 2024.01.23 MCS)SAITO MOD END ---*/
          csv += line;
        });
        let blob = new Blob([csv], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        /*--- 2024.03.27 MCS)SAITO MOD START ---*/
        /*--- 2024.01.23 MCS)SAITO MOD START ---*/
        // link.download = this.nowToday + "" + store.state.nowTime + "月次個人別集計表.csv";
        //link.download = store.state.nowDate + "" + store.state.nowTime + "事業所毎個人別集計表.csv";
        // link.download = store.state.nowDate + "" + store.state.nowTime + "事業所毎個人別集計表" + this.year + "" + varmonth + ".csv";
        /*--- 2024.01.23 MCS)SAITO MOD END ---*/
        link.download = "事業所毎個人別集計表" + this.year + "" + varmonth + "_" + store.state.nowDate + store.state.nowTime + ".csv";
        /*--- 2024.03.27 MCS)SAITO MOD END ---*/
        link.click();
      } else if (this.ReportType == "jigyo") {
        //月別個別集計表
        /*--- 2024.01.23 MCS)SAITO ADD START ---*/
        csv = "\ufeff" + this.year + "" + varmonth + "月分" + "\n";
        /*--- 2024.01.23 MCS)SAITO ADD END ---*/
        /*--- 2024.01.23 MCS)SAITO MOD START ---*/
        // csv = "\ufeff" + "利用者コード,氏 名,金 額\n";
        csv += "\ufeff" + "事業所名,金 額\n";
        /*--- 2024.01.23 MCS)SAITO MOD END ---*/
        this.data.forEach((el) => {
          /*--- 2024.01.23 MCS)SAITO MOD START ---*/
          // var line = el["str_user_cd"] + "," + el["str_user_name"] + "," + el["total"] + "\n";
          var line = el["str_jigyo_name"] + "," + el["total"] + "\n";
          /*--- 2024.01.23 MCS)SAITO MOD END ---*/
          csv += line;
        });
        let blob = new Blob([csv], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        /*--- 2024.01.23 MCS)SAITO MOD START ---*/
        // link.download = this.nowToday + "" + store.state.nowTime + "月別所属グループ別個人集計表.csv";
        //link.download = store.state.nowDate + "" + store.state.nowTime + "月次事業所別集計表.csv";
        link.download = store.state.nowDate + "" + store.state.nowTime + "月次事業所別集計表" + this.year + "" + varmonth + ".csv";
        /*--- 2024.01.23 MCS)SAITO MOD END ---*/
        link.click();
      } else if (this.ReportType == "gyosha") {
        /*--- 2024.03.05 MCS)SAITO ADD START ---*/
        //月次弁当業者別集計表
        csv = "\ufeff" + this.year + "" + varmonth + "月分" + "\n";
        csv += "\ufeff" + "弁当業者名,金 額\n";
        this.data.forEach((el) => {
          var line = el["str_gyosha_name"] + "," + el["total"] + "\n";
          csv += line;
        });
        let blob = new Blob([csv], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = store.state.nowDate + "" + store.state.nowTime + "月次弁当業者別集計表" + this.year + "" + varmonth + ".csv";
        link.click();
      }
      /*--- 2024.03.05 MCS)SAITO ADD END ---*/
    },
    /*--- 2024.02.08 MCS)SAITO ADD START ---*/
    // 締め日範囲計算
    async CalcSimebi() {
      var wkYear = 0;
      var wkMonth = 0;
      var wkDay = 0;
      var wkDate = new Date();
      if (this.$refs.select_form.validate()) {
        wkYear = Number(this.year);
        if (this.month.substring(0, this.month.length - 1) == "1") {
          wkYear = Number(this.year) - 1;
          wkMonth = 12;
        }
        if (wkMonth != 12) {
          wkMonth = Number(this.month.substring(0, this.month.length - 1)) - 1;
        }
        //月末締めか
        if (this.simebi == 0) {
          if (wkMonth == 12) {
            wkYear = Number(this.year);
          }
          wkMonth = Number(this.month.substring(0, this.month.length - 1));
          wkDay = 1;
        } else {
          if (wkMonth != 12) {
            wkMonth = Number(this.month.substring(0, this.month.length - 1)) - 1;
          }
          wkDay = this.kigyo["num_simebi"] + 1;
        }
        //もし日付が正しくない場合は、翌月の1日
        wkDate = new Date(wkYear.toString(), (wkMonth - 1).toString(), wkDay.toString());
        if (wkDate.getFullYear() != wkYear || wkDate.getMonth() + 1 != wkMonth || wkDate.getDate() != wkDay) {
          wkMonth += 1;
          wkDay = 1;
        }
        if (wkMonth.toString().length != 2) {
          this.sDate = wkYear.toString() + "0" + wkMonth.toString();
        } else {
          this.sDate = wkYear.toString() + wkMonth.toString();
        }
        if (wkDay.toString().length != 2) {
          this.sDate = this.sDate + "0" + wkDay.toString();
        } else {
          this.sDate = this.sDate + wkDay.toString();
        }
        if (this.simebi.length != 2 && this.simebi != 0) {
          //締め日が一桁の時、先頭に0を追加する
          wkDay = "0" + this.simebi;
        } else {
          if (this.simebi == 0) {
            wkDay = new Date(wkYear, wkMonth, 0).getDate();
          } else {
            wkDay = this.simebi;
          }
        }
        //もし日付が正しくない場合は、翌月の1日
        wkMonth = Number(this.month.substring(0, this.month.length - 1));
        wkDate = new Date(this.year.toString(), Number(this.month.substring(0, this.month.length - 1) - 1).toString(), wkDay.toString());
        if (wkDate.getFullYear() != this.year || wkDate.getMonth() + 1 != Number(this.month.substring(0, this.month.length - 1)) || wkDate.getDate() != wkDay) {
          if (wkMonth != 12) {
            wkMonth += 1;
          } else {
            wkMonth = 1;
          }
          wkDay = 1;
        }
        if (wkMonth.toString().length != 2) {
          //月が一桁の時、先頭に0を追加する
          this.select = "0" + wkMonth;
        } else {
          this.select = wkMonth;
        }
        if (wkDay.toString().length != 2) {
          wkDay = "0" + wkDay;
        }
        this.eDate = this.year + this.select + wkDay;
        this.sDate = moment(this.sDate).format("yyyy-MM-DD");
        this.eDate = moment(this.eDate).format("yyyy-MM-DD");
      } else {
        return;
      }
    },
    // 日付範囲初期化
    initDateRange() {
      this.dateSt = moment().add(-1, "M").format("yyyy-MM-DD");
      this.dateEd = moment().format("yyyy-MM-DD");
    },
    /*--- 2024.02.08 MCS)SAITO ADD END ---*/
    /*--- 2024.02.29 MCS)SAITO ADD START ---*/
    //VB
    reportcreate_vb() {
      var strsDate = "";
      var streDate = "";
      var strresult = "";
      strsDate = this.sDate;
      strresult = this.sDate.replace("-", "");
      while (strsDate !== strresult) {
        strsDate = strsDate.replace("-", "");
        strresult = strresult.replace("-", "");
      }
      this.psDate = strsDate;
      streDate = this.eDate;
      strresult = this.eDate.replace("-", "");
      while (streDate !== strresult) {
        streDate = streDate.replace("-", "");
        strresult = strresult.replace("-", "");
      }
      this.peDate = streDate;

      var varmonth = "";
      if (this.month.substring(0, this.month.length - 1).length != 2) {
        //月が一桁の時、先頭に0を追加する
        varmonth = "0" + this.month.substring(0, this.month.length - 1);
      } else {
        varmonth = this.month.substring(0, this.month.length - 1);
      }

      var varmode = "";
      if (this.ReportType == "kojin") {
        varmode = "kojin";
      } else if (this.ReportType == "kojinhinmei") {
        varmode = "kojinhinmei";
      } else if (this.ReportType == "jigyo") {
        varmode = "jigyo";
      } else if (this.ReportType == "gyosha") {
        varmode = "gyosha";
      }
      try {
        // WebView2にメッセージを送信
        window.chrome.webview.postMessage({
          mode: varmode,
          kigyoCode: this.$store.state.kigyoCode,
          Chumonbi_s: this.psDate,
          Chumonbi_e: this.peDate,
          Simeituki: this.year + varmonth,
        });
      } catch (error) {
        // エラーが発生した場合の処理
        /* -- 2024.04.03 MCS)SAITO MOD START -- */
        // alert("レポートはクライアントアプリからのみ作成可能です。");
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // alert("月別集計表はクライアントアプリを利用してください。");
        this.$root.snackbar.showMessage("月別集計表はクライアントアプリを利用してください。", "info");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        /* -- 2024.04.03 MCS)SAITO MOD END -- */
      }
    },
    /*--- 2024.02.29 MCS)SAITO ADD END ---*/
  },
  /*--- 2024.02.08 MCS)SAITO ADD START ---*/
  computed: {
    // 日付を"yyyy年 M月 D日"形式で返却
    date_yyyyMD() {
      return (date) => {
        if (date != "") {
          return moment(date).format("yyyy年 M月 D日");
        } else {
          return "";
        }
      };
    },
  },
  /*--- 2024.02.08 MCS)SAITO ADD END ---*/
};
</script>
