<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：TraderMaster.vue
  モジュール名：業者マスタ画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <title-card title="弁当業者マスタ">
      <v-btn color="teal" dark depressed @click="fncNewRow">新規登録</v-btn>
    </title-card>
    <data-card>
      <v-sheet class="px-4 py-2 d-flex" style="align-items: center" height="56px">
        <span>注文場所：</span>
        <v-responsive class="mr-4" max-width="200px">
          <v-select backgroundColor="white" hide-details dense outlined item-text="liJigyoName" item-value="liJigyoNo" :items="JigyoFilterItems" v-model="JigyoFilterSelect"></v-select>
        </v-responsive>
      </v-sheet>
      <v-divider></v-divider>
      <v-data-table
        :items="datas"
        :fixed-header="true"
        :hide-default-footer="true"
        :items-per-page="-1"
        height="calc(100vh - 245px)"
        style="white-space: pre-wrap"
        @click:row="fncSelectRow"
        :headers="[
          {
            text: '注文場所',
            value: 'dtJigyoName',
            width: '280px',
            sortable: true,
            filter: (value) => {
              if (this.JigyoFilterSelect == 0) return true;
              return value == this.JigyoFilterItems.find((i) => i.liJigyoNo == this.JigyoFilterSelect).liJigyoName;
            },
          },
          { text: '弁当業者名称', value: 'dtGyosha', width: '280px', sortable: true },
          { text: '住所', value: 'dtJusho', width: '360px', sortable: true },
          { text: '連絡先', value: 'dtRenrakuSaki', width: '360px', sortable: true },
          { text: '注文区分', value: 'dtChumon', width: '120px', sortable: true },
          { text: '締切時間', value: 'dtSimeKiriTime', width: '100px', sortable: true },
        ]"
        no-data-text="弁当業者のデータがありません。"
        no-results-text="該当するデータがありません。"
      />
    </data-card>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** 編集用ダイアログ ここから *** -->
    <v-dialog v-model="dialog" width="900px" persistent no-click-animation>
      <v-card>
        <v-form ref="ref_form" class="pa-4">
          <v-container>
            <h4>基本情報</h4>
            <v-col class="py-0"
              >注文場所<span class="red--text" v-if="!JigyoSetDisable">*</span><br />
              <v-select backgroundColor="white" dense outlined style="width: 258px" item-text="liJigyoName" item-value="liJigyoNo" :items="JigyoSetItems" v-model="JigyoSetSelect" :disabled="JigyoSetDisable" :rules="[(value) => !!(value | (value != '')) || '注文場所が指定されていません。']"></v-select>
            </v-col>
            <v-col class="py-0"
              >業者名<span class="red--text">*</span><br />
              <v-text-field outlined class="text" v-model="txtGyoshaName" dense maxlength="50" @change="change_flg = true" :rules="[(value) => !!value || '業者名が入力されていません。']"
            /></v-col>
            <v-col class="py-0"
              >業者カナ<br />
              <v-text-field outlined class="text" dense v-model="txtGyoshaKana" maxlength="50" @change="change_flg = true" :rules="[(value) => !!value.match(/(^[ァ-ヶ|ー|\s]*$)|(^[ｦ-ﾟ\s]*$)/) || '業者名カナはカナ文字を入力してください。']"
            /></v-col>
            <v-col class="py-0"
              ><table>
                <tr>
                  <td>郵便番号</td>
                  <td>住所</td>
                </tr>
                <tr>
                  <td class="pr-5">
                    <v-text-field outlined class="text" dense v-model="txtYubinNo" maxlength="8" style="width: 180px" @change="change_flg = true" hint="ハイフンありで入力してください。" :rules="rule_post" />
                  </td>
                  <td class="pr-5">
                    <v-text-field outlined class="text" dense v-model="txtAddress" maxlength="50" style="width: 500px" @change="change_flg = true" />
                  </td>
                </tr></table
            ></v-col>
            <v-col class="py-0"
              ><table>
                <tr>
                  <td>締切時間<span class="red--text">*</span></td>
                  <td>注文方法<span class="red--text">*</span></td>
                </tr>
                <tr style="vertical-align: baseline">
                  <td class="pr-4">
                    <v-text-field outlined class="text" dense type="time" style="width: 100px" reverse v-model="txtSimekiri" @change="change_flg = true" :rules="[(value) => !!(value | (value != '')) || '締め切り時間が入力されていません。']" />
                  </td>
                  <td class="pr-5">
                    <v-select
                      backgroundColor="white"
                      dense
                      outlined
                      v-model="ddlChumonKbn"
                      class="ma-0 pa-0"
                      style="width: 250px"
                      :items="[
                        { text: 'メール', value: 1 },
                        { text: '電話', value: 2 },
                        { text: 'FAX', value: 3 },
                      ]"
                      @change="change_flg = true"
                      :rules="[(value) => !!((value == '1') | (value == '2') | (value == '3')) || '注文方法が指定されていません。']"
                    />
                  </td>
                  <td>
                    <v-checkbox v-model="chkChumonAuto" class="mt-0" @change="change_flg = true" hide-details :ripple="false" label="メールの自動送信（メールを選択した場合のみ）" :disabled="disableAutoMail" />
                  </td>
                </tr></table
            ></v-col>
            <v-col class="py-0"
              ><table>
                <tr>
                  <td>電話番号1<span class="red--text" v-show="ddlChumonKbn == 2">*</span></td>
                  <td>電話番号2</td>
                </tr>
                <tr>
                  <td class="pr-5">
                    <v-text-field outlined class="text" dense v-model="txtTel" maxlength="15" style="width: 230px" @change="change_flg = true" hint="ハイフンありで入力してください。" :rules="rule_tel" />
                  </td>
                  <td class="pr-5">
                    <v-text-field outlined class="text" dense v-model="txtTel2" maxlength="15" style="width: 230px" :disabled="disableTel2" @change="change_flg = true" hint="ハイフンありで入力してください。" :rules="[(value) => !!value.match(/^[0-9/-]*$/) || '電話番号は半角数字を入力してください。']" />
                  </td>
                </tr></table
            ></v-col>
            <v-col class="py-0"
              ><table>
                <tr>
                  <td>FAX<span class="red--text" v-show="ddlChumonKbn == 3">*</span></td>
                  <td>メールアドレス<span class="red--text" v-show="ddlChumonKbn == 1">*</span></td>
                </tr>
                <tr>
                  <td class="pr-5">
                    <v-text-field outlined class="text" dense v-model="txtFax" maxlength="15" style="width: 230px" @change="change_flg = true" hint="ハイフンありで入力してください。" :rules="rule_fax" />
                  </td>
                  <td class="pr-5">
                    <v-text-field outlined class="text" dense v-model="txtMail" maxlength="50" style="width: 500px" @change="change_flg = true" :rules="rule_email" />
                  </td>
                </tr>
              </table>
            </v-col>
            <!-- <v-col
            ><table>
              <tr style="vertical-align: top">
                <td><v-simple-checkbox v-model="chkChumonAuto" @change="change_flg = true" hide-details :ripple="false" /></td>
                <td>メールで自動注文をする</td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <table>
                    <tr>
                      <td class="pr-4">メール送信時間</td>
                      <td>注文件数が無いとき</td>
                    </tr>
                    <tr>
                      <td><v-text-field class="text" dense type="time" style="width: 80px" reverse v-model="txtMailSendTime" :disabled="!chkChumonAuto" @change="change_flg = true" :rules="rule_MailSendTime" /></td>
                      <td>
                        <v-select
                          backgroundColor="white"
                          dense
                          outlined
                          class="ma-0 pa-0"
                          style="width: 250px"
                          hide-details
                          v-model="ddlChumonNai"
                          @change="change_flg = true"
                          :disabled="!chkChumonAuto"
                          :items="[
                            { text: 'メール送信しない', value: 0 },
                            { text: 'メール送信する', value: 1 },
                          ]"
                        />
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table></v-col
          > -->
          </v-container>
          <!-- 下部のボタン群 -->
          <div v-bind:class="{ button: true }" align="right">
            <v-btn height="40" width="70" color="blue darken-1" text @click="btn_insert()" v-show="InsertDisplay" class="mr-5">登録</v-btn>
            <v-btn height="40" width="70" color="green darken-1" text @click="btn_update()" v-show="UpdateDisplay" class="mr-5">保存</v-btn>
            <v-btn height="40" width="70" color="red darken-1" text @click="btn_delete()" v-show="UpdateDisplay" class="mr-5">削除</v-btn>
            <v-btn height="40" width="70" color="blue darken-1" text @click="dialogdisp(false)">閉じる</v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- *** 編集用ダイアログ ここまで *** -->
  </v-container>
</template>

<script>
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "TraderMaster",
  data: function () {
    return {
      //エラーメッセージ
      // msg: "",
      datas: [],
      KigyoNo: 0,
      // snackbar: false,
      // snackbar_color: "",
      //エラー表示時間
      // timeout: 3000,
      //ポップアップ表示、非表示
      dialog: false,
      //ポップアップ上の登録ボタン表示非表示
      InsertDisplay: false,
      //ポップアップ上の修正ボタン表示非表示
      UpdateDisplay: false,

      change_flg: false,
      simekiri_zikan: "",
      JigyoSetItems: [],
      JigyoSetSelect: 0,
      JigyoSetDisable: false,
      JigyoFilterItems: [],
      JigyoFilterSelect: 0,

      txtGyoshaKana: "",
      txtGyoshaName: "",
      txtYubinNo: "",
      txtAddress: "",
      txtSimekiri: "",
      ddlChumonKbn: "",
      txtTel: "",
      txtTel2: "",
      disableTel2: true,
      txtFax: "",
      txtMail: "",
      txtMailSendTime: "",
      chkChumonAuto: false,
      ddlChumonNai: "",
      disableAutoMail: true,
    };
  },
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    async btn_insert() {
      if (this.$refs.ref_form.validate()) {
        //dataとセッション情報
        const store = this.$store;
        const params = new URLSearchParams();
        params.append("KigyoNo", store.state.kigyoCode);
        params.append("maxGyosha", this.GyoshaNo);
        params.append("JigyoNo", this.JigyoSetSelect);
        params.append("GyoshaName", this.txtGyoshaName);
        params.append("GyoshaKana", this.txtGyoshaKana);
        params.append("ChuKbn", this.ddlChumonKbn);
        params.append("Tel1", this.txtTel);
        params.append("Tel2", this.txtTel2);
        params.append("Fax", this.txtFax);
        params.append("Mail", this.txtMail);
        params.append("Simetime", this.txtSimekiri.replace(":", ""));
        params.append("MailSendTime", this.txtMailSendTime.replace(":", ""));
        params.append("Post", this.txtYubinNo);
        params.append("Address", this.txtAddress);
        params.append("AutoFlg", this.chkChumonAuto ? 1 : 0);
        params.append("NaiFlg", this.ddlChumonNai);
        params.append("sUser", store.state.userCode);
        params.append("sAccess", "WEB");

        //登録
        var results = await this.$axios
          .post("/m_gyosha_post/insert", params)
          .then(function () {
            return "";
          })
          .catch(function (error) {
            return error;
          });
        if (results == "") {
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // this.noticeOK("登録しました。");
          this.$root.snackbar.showMessage("登録しました。", "success");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
          //リスト再読み込み
          this.change_flg = false;
          this["dialog"] = false;
          this.SyokiSelect();
        } else {
          console.log(results);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // this.noticeNG(results);
          this.$root.snackbar.showMessage(results, "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        }
      }
    },
    //更新時処理
    async btn_update() {
      if (this.$refs.ref_form.validate()) {
        //dataとセッション情報
        const store = this.$store;
        const params = new URLSearchParams();
        params.append("KigyoNo", store.state.kigyoCode);
        params.append("JigyoNo", this.JigyoSetSelect);
        params.append("maxGyosha", this.GyoshaNo);
        params.append("GyoshaName", this.txtGyoshaName);
        params.append("GyoshaKana", this.txtGyoshaKana);
        params.append("ChuKbn", this.ddlChumonKbn);
        params.append("Tel1", this.txtTel);
        params.append("Tel2", this.txtTel2);
        params.append("Fax", this.txtFax);
        params.append("Mail", this.txtMail);
        params.append("Simetime", this.txtSimekiri.replace(":", ""));
        params.append("MailSendTime", this.txtMailSendTime.replace(":", ""));
        params.append("Post", this.txtYubinNo);
        params.append("Address", this.txtAddress);
        params.append("AutoFlg", this.chkChumonAuto ? 1 : 0);
        params.append("NaiFlg", this.ddlChumonNai);
        params.append("sUser", store.state.userCode);
        params.append("sAccess", "WEB");

        //登録
        var results = await this.$axios
          .post("/m_gyosha_post/update", params)
          .then(function () {
            return "";
          })
          .catch(function (error) {
            return error;
          });
        if (results == "") {
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // this.noticeOK("保存しました。");
          this.$root.snackbar.showMessage("保存しました。", "success");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
          this.change_flg = false;
          this.dialogdisp(false);
          this.SyokiSelect();
        } else {
          console.log(results);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // this.noticeNG(results);
          this.$root.snackbar.showMessage(results, "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        }
      }
    },
    //削除時処理
    async btn_delete() {
      if (window.confirm("削除します。よろしいですか？")) {
        /*--- 2024.02.26 MCS)KONNO ADD START ---*/
        // 締め切られていない注文がある場合、確認メッセージを取得
        if (!(await this.checkPreOrder())) {
          if (!window.confirm("既に登録されている注文予約データがあります。削除してもよろしいですか？")) return;
        }
        /*--- 2024.02.26 MCS)KONNO ADD END ---*/
        //dataとセッション情報
        const store = this.$store;

        var params = new URLSearchParams();
        params.append("KigyoNo", store.state.kigyoCode);
        params.append("GyoshaNo", this.GyoshaNo);
        params.append("JigyoNo", this.JigyoSetSelect);
        params.append("sUser", store.state.userCode);
        params.append("sAccess", "WEB");

        //削除
        var results = await this.$axios
          .post("/m_gyosha_post/delete", params)
          .then(function () {
            return "";
          })
          .catch(function (error) {
            return error;
          });
        if (results == "") {
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // this.noticeOK("削除しました。");
          this.$root.snackbar.showMessage("削除しました。", "success");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
          this.change_flg = false;
          this.dialogdisp(false);
          this.SyokiSelect();
        } else {
          console.log(results);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // this.noticeNG(results);
          this.$root.snackbar.showMessage(results, "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        }
      }
    },
    fncSelectRow(row) {
      //テキストボックス初期化
      this.txtGyoshaName = "";
      this.txtGyoshaKana = "";
      this.txtYubinNo = "";
      this.txtAddress = "";
      this.txtSimekiri = "";
      this.ddlChumonKbn = 0;
      this.txtTel = "";
      this.txtTel2 = "";
      this.txtFax = "";
      this.txtMail = "";
      this.txtMailSendTime = "";
      this.chkChumonAuto = false;
      this.ddlChumonNai = 0;

      this.JigyoSetSelect = String(row.dtJigyoNo);
      this.JigyoSetDisable = true;
      this.GyoshaNo = row.dtGyoshaNo;
      this.txtGyoshaName = row.dtGyoshaName;
      this.txtGyoshaKana = row.dtGyoshaKana;
      this.txtYubinNo = row.dtYubin;
      this.txtAddress = row.dtAddress;
      if ((row.dtSimeTime != "") & (row.dtSimeTime.length == 4)) {
        this.txtSimekiri = row.dtSimeTime.slice(0, 2) + ":" + row.dtSimeTime.slice(2, 4);
      }
      this.ddlChumonKbn = row.dtChumonKbn;
      this.txtTel = row.dtTel;
      this.txtTel2 = row.dtTel2;
      this.txtFax = row.dtFax;
      this.txtMail = row.dtMail;
      this.chkChumonAuto = row.dtChumonAuto == 1;
      if (this.chkChumonAuto) {
        if (row.dtMailSendTime != "" && row.dtMailSendTime.length == 4) {
          this.txtMailSendTime = row.dtMailSendTime.slice(0, 2) + ":" + row.dtMailSendTime.slice(2, 4);
        }
        this.ddlChumonNai = row.dtChumonNai;
      }
      this.$nextTick(() => {
        if (this.$refs["ref_form"]) {
          this.$refs.ref_form.resetValidation();
        }
      });
      //ポップアップ上の登録ボタン表示非表示
      (this["InsertDisplay"] = false),
        //ポップアップ上の修正ボタン表示非表示
        (this["UpdateDisplay"] = true),
        this.dialogdisp(true);
    },
    async fncNewRow() {
      //テキストボックス初期化
      this.JigyoSetSelect = ""; //String(this.$store.state.jigyoNo);
      this.JigyoSetDisable = false;
      this.txtGyoshaName = "";
      this.txtGyoshaKana = "";
      this.GyoshaNo = 0;
      this.txtTel = "";
      this.txtTel2 = "";
      this.txtFax = "";
      this.txtMail = "";
      this.txtSimekiri = "";
      this.txtMailSendTime = "";
      this.txtYubinNo = "";
      this.txtAddress = "";
      this.ddlChumonKbn = "";
      this.chkChumonAuto = false;
      this.ddlChumonNai = 0;
      /* -- 2024.03.26 MCS)SAITO ADD START -- */
      if (this.JigyoFilterSelect != 0) {
        this.JigyoSetSelect = this.JigyoFilterSelect;
      }
      /* -- 2024.03.26 MCS)SAITO ADD END -- */

      (this["InsertDisplay"] = true),
        //ポップアップ上の修正ボタン表示非表示
        (this["UpdateDisplay"] = false),
        this.dialogdisp(true);
      this.$nextTick(() => {
        if (this.$refs["ref_form"]) {
          this.$refs.ref_form.resetValidation();
        }
      });
    },
    //入力ダイアログ表示、非表示
    dialogdisp(dispflg) {
      if (dispflg) {
        this["dialog"] = true;
      } else {
        if (this.change_flg == true) {
          if (window.confirm("保存せずに終了します。\n入力内容は破棄されますが、よろしいですか？")) {
            this["dialog"] = false;
            this.change_flg = false;
          }
        } else {
          this["dialog"] = false;
        }
      }
    },
    async setJigyoList() {
      const self = this;
      await this.$axios
        .get("/m_jigyo_get/jigyosyo_list", {
          params: {
            KigyoCd: this.$store.state.kigyoCode, //セッション(Vuex)
          },
        })
        .then(function (response) {
          let response_data = response.data.data;
          self.JigyoSetItems = [];
          self.JigyoFilterItems = [];
          self.JigyoFilterItems.push({ liJigyoName: "すべて", liJigyoNo: 0 });
          response_data.forEach(function (rows) {
            self.JigyoSetItems.push({
              liJigyoName: rows["str_jigyo_name"],
              liJigyoNo: String(rows["num_jigyo_no"]),
            });
            self.JigyoFilterItems.push({
              liJigyoName: rows["str_jigyo_name"],
              liJigyoNo: String(rows["num_jigyo_no"]),
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // this.noticeNG(error);
          this.$root.snackbar.showMessage(error, "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        });
    },
    //データ読み込み(初期処理)
    async SyokiSelect() {
      const self = this;
      await this.$axios
        .get("/m_gyosha_get/gyosha_list2", {
          params: {
            KigyoCd: this.$store.state.kigyoCode, //セッション(Vuex)
          },
        })
        .then(function (response) {
          self.datas = [];
          let response_data = response.data.data;
          response_data.forEach(function (rows) {
            var strGyosha = [];
            // if (rows.str_gyosha_kana != "") strGyosha.push(rows.str_gyosha_kana);
            if (rows.str_gyosha_name != "") strGyosha.push(rows.str_gyosha_name);
            var strJusho = [];
            if (rows.str_post != "") strJusho.push("〒 " + rows.str_post);
            if (rows.str_address != "") strJusho.push("  " + rows.str_address);
            var strRenrakuSaki = [];
            if (rows.str_tel1 != "") strRenrakuSaki.push("Tel : " + rows.str_tel1);
            if (rows.str_tel2 != "") strRenrakuSaki.push("Tel2: " + rows.str_tel2);
            if (rows.str_fax != "") strRenrakuSaki.push("Fax : " + rows.str_fax);
            if (rows.str_mail != "") strRenrakuSaki.push("Mail: " + rows.str_mail);
            var wkSimeKiriTime = "";
            if ((rows.str_simetime != "") & (String(rows.str_simetime).length == 4)) {
              wkSimeKiriTime = String(rows.str_simetime).slice(0, 2) + ":" + String(rows.str_simetime).slice(2, 4);
            } else {
              wkSimeKiriTime = "--:--";
            }
            var wkSousinTime = "";
            if (Number(rows.num_auto_flg) == 1 && (rows.str_mail_send_time != "") & (String(rows.str_mail_send_time).length == 4)) {
              wkSousinTime = String(rows.str_mail_send_time).slice(0, 2) + ":" + String(rows.str_mail_send_time).slice(2, 4);
            } else {
              wkSousinTime = "--:--";
            }
            var wkChumon = "";
            switch (rows.num_chu_kbn) {
              case 1:
                if (Number(rows.num_auto_flg) == 1 && (rows.str_mail_send_time != "") & (String(rows.str_mail_send_time).length == 4)) {
                  wkChumon = "自動メール";
                } else {
                  wkChumon = "メール";
                }
                break;
              case 2:
                wkChumon = "電話";
                break;
              case 3:
                wkChumon = "FAX";
                break;
              default:
                wkChumon = "";
                break;
            }

            self.datas.push({
              dtGyoshaNo: rows.num_gyosha_no,
              dtGyoshaName: rows.str_gyosha_name,
              dtGyoshaKana: rows.str_gyosha_kana,
              dtYubin: rows.str_post,
              dtAddress: rows.str_address,
              dtSimeTime: rows.str_simetime,
              dtChumonKbn: Number(rows.num_chu_kbn),
              dtTel: rows.str_tel1,
              dtTel2: rows.str_tel2,
              dtFax: rows.str_fax,
              dtMail: rows.str_mail,
              dtMailSendTime: rows.str_mail_send_time,
              dtChumonAuto: Number(rows.num_auto_flg),
              dtChumonNai: Number(rows.num_nai_flg),
              dtJigyoNo: rows.num_jigyo_no,
              dtJigyoName: rows.str_jigyo_name,
              dtGyosha: strGyosha.join("\r\n"),
              dtJusho: strJusho.join("\r\n"),
              dtRenrakuSaki: strRenrakuSaki.join("\r\n"),
              dtSimeKiriTime: wkSimeKiriTime,
              dtSousinTime: wkSousinTime,
              dtChumon: wkChumon,
            });
          });
        })
        .catch(function (error) {
          console.log(error);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.noticeNG(error);
          self.$root.snackbar.showMessage(error, "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        });
    },

    /*--- 2024.02.26 MCS)KONNO ADD START ---*/
    // 未確定注文チェック
    async checkPreOrder() {
      let result = await this.getPreOrder();
      if (result.length > 0) {
        return false;
      }
      return true;
    },

    // 未確定注文取得
    async getPreOrder() {
      let result = await this.$axios
        .get("/t_chu_yoyaku_get/pre_order", {
          params: {
            KigyoCd: this.$store.state.kigyoCode, //セッション(Vuex)
            JigyoNo: this.JigyoSetSelect,
            GyoshaNo: this.GyoshaNo,
          },
        })
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      return result;
    },
    /*--- 2024.02.26 MCS)KONNO ADD END ---*/

    //業者連番最大値取得
    // async fncGyoshaNoMax(selected_jigyosyo) {
    //   let kigyoCode = this.$store.state.kigyoCode;
    //   let maxGyoshaNo = 0;
    //   await this.$axios
    //     .get("/m_gyosha_get/gyosha_max", {
    //       params: {
    //         KigyoCd: kigyoCode, //セッション(Vuex)
    //         JigyoNo: selected_jigyosyo, //セッション(Vuex)
    //       },
    //     })
    //     .then(function (response) {
    //       maxGyoshaNo = response.data.maxGyoshaNo;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //       return -1;
    //       // this.noticeNG(error);
    //     });
    //   return maxGyoshaNo;
    // },

    // メッセージ表示（正常）
    // noticeOK(msg) {
    //   this.msg = msg;
    //   this.snackbar_color = "green";
    //   this.snackbar = true;
    // },

    // メッセージ表示（エラー）
    // noticeNG(msg) {
    //   this.msg = msg;
    //   this.snackbar_color = "red";
    //   this.snackbar = true;
    // },
  },
  // async created (){
  // 	await this.SyokiSelect();
  // },
  created: function () {
    this.setJigyoList();
  },
  mounted: function () {
    // this.jigyo_select = this.$store.state.jigyoNo;
    this.SyokiSelect();
  },
  watch: {
    // jigyo_select: function () {
    //   // this.SyokiSelect(this.jigyo_select);

    // },
    // chkChumonAuto: function () {
    //   this.ddlChumonNai = 0;
    //   this.txtMailSendTime = "";
    //   // this.$nextTick(() => {
    //   //   if (this.$refs["ref_form"]) {
    //   //     this.$refs.ref_form.resetValidation();
    //   //   }
    //   // });
    // },
    txtTel: function () {
      this.disableTel2 = this.txtTel == "";
      // this.$nextTick(() => {
      //   if (this.$refs["ref_form"]) {
      //     this.$refs.ref_form.resetValidation();
      //   }
      // });
    },
    txtSimekiri: function (val) {
      this.txtMailSendTime = val;
    },
    ddlChumonKbn: function (val) {
      if (val != 1) {
        this.disableAutoMail = true;
        this.chkChumonAuto = false;
      } else {
        this.disableAutoMail = false;
      }
    },
  },
  computed: {
    rule_MailSendTime() {
      return [!(this.chkChumonAuto && !!(this.txtMailSendTime | (this.txtMailSendTime == ""))) || "メール送信時間が入力されていません。", !(this.chkChumonAuto && this.txtSimekiri > this.txtMailSendTime) || "メール送信時間は締切時間より後に設定してください。"];
    },
    rule_post() {
      if (this.txtYubinNo != "") {
        return [(value) => (!!value && !!value.match(/^[0-9]{3}-[0-9]{4}$/)) || "正しい形式を入力してください。"];
      } else {
        return [];
      }
    },
    rule_email() {
      if (this.ddlChumonKbn == 1) {
        return [(value) => !!value || "メールアドレスが入力されていません。", (value) => (value && !!value.match(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/)) || "メールアドレスは半角英数字または半角記号を入力してください。"];
      } else {
        return [];
      }
    },
    rule_tel() {
      if (this.ddlChumonKbn == 2) {
        return [(value) => !!value || "電話番号が入力されていません。", (value) => (value && !!value.match(/^[0-9/-]+$/)) || "電話番号は半角数字を入力してください。"];
      } else {
        return [(value) => !!value.match(/^[0-9/-]*$/) || "電話番号は半角数字を入力してください。"];
      }
    },
    rule_fax() {
      if (this.ddlChumonKbn == 3) {
        return [(value) => !!value || "FAXが入力されていません。", (value) => (value && !!value.match(/^[0-9/-]+$/)) || "FAXは半角数字を入力してください。"];
      } else {
        return [(value) => !!value.match(/^[0-9/-]*$/) || "FAXは半角数字を入力してください。"];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// @import "./src/assets/css/TraderMaster.scss";
</style>
