<template>
  <v-snackbar v-model="snackbar" :color="color" :timeout="timeout" transition="slide-y-transition" top multi-line>
    <v-layout align-center pr-4>
      <v-icon class="pr-3" dark large>{{ icon }}</v-icon>
      <v-layout column>
        <div>
          <strong>{{ title }}</strong>
        </div>
        <div style="white-space: pre-wrap">{{ text }}</div>
      </v-layout>
    </v-layout>
  </v-snackbar>
</template>

<script>
import * as script from "../../utils/script";

export default {
  name: "SnackBar",
  data: () => ({
    snackbar: false,
    icon: "",
    title: "",
    text: "",
    color: "",
    timeout: 5000,
  }),
  methods: {
    // **************************************************
    // 目的：初期化
    // **************************************************
    init() {
      this.snackbar = false;
      this.icon = "";
      this.title = "";
      this.text = "";
      this.color = "";
    },

    // **************************************************
    // 目的：メッセージ表示
    // 引数：text［文字列：メッセージ］, color［文字列：error or warnig or success］
    // **************************************************
    async showMessage(text, type) {
      this.$nextTick(async () => {
        // 現在の状態を取得
        const state = this.snackbar;
        // 初期化
        this.snackbar = false;
        // 既に表示していた場合、遅延させる（表示が上書きされてしまうため）
        if (state) {
          await script.sleep(500);
        }
        // メッセージ設定
        this.setMessage(text, type);
        // スナックバー表示
        this.snackbar = true;
      });
    },

    // **************************************************
    // 目的：メッセージ設定
    // 引数：text［文字列：メッセージ］, color［文字列：error or warnig or success］
    // **************************************************
    setMessage(text, type) {
      // パラメタ不足の場合、注意メッセージ表示
      if (text == null || type == null) {
        this.title = "通知";
        this.text = "［！］引数が不足しています。\n第一引数：メッセージ、第二引数：タイプ";
        this.color = "info";
        return;
      }
      // パラメタ不足の場合、注意メッセージ表示
      const typeList = ["error", "warning", "success", "info"];
      if (!typeList.includes(type)) {
        this.title = "通知";
        this.text = "［！］第二引数（タイプ）が正しくありません。\n設定可能文字列：error, warning, success, info";
        this.color = "info";
        return;
      }
      // パラメタセット
      this.text = text;
      switch (type) {
        case "error":
          this.title = "エラー";
          this.color = "error";
          this.icon = "error";
          break;
        case "warning":
          this.title = "注意";
          this.color = "warning";
          this.icon = "warning";
          break;
        case "success":
          this.title = "成功";
          this.color = "success";
          this.icon = "check_circle";
          break;
        case "info":
          this.title = "通知";
          this.color = "info";
          this.icon = "info";
          break;
      }
    },
  },
};
</script>
