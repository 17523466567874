<template>
  <v-sheet class="d-flex" style="align-items: center; background-color: transparent">
    <v-menu v-model="dialog" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="px-1" color="teal" v-bind="attrs" v-on="on" text>
          <span style="font-size: 1.2rem; line-height: 36px; white-space: nowrap">{{ fmtDateYYYYMD(value) }}</span>
        </v-btn>
      </template>
      <v-date-picker @change="(date) => onChangeCalendar(date)" :value="value" locale="jp-ja" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
    </v-menu>
    <!-- <span style="font-size: 1.2rem; line-height: 36px; white-space: nowrap">{{ fmtDateYYYYMD(value) }}</span> -->
    <v-divider vertical class="mx-2"></v-divider>
    <v-btn @click="onClickPrevious()" class="mx-1 px-2" color="teal" style="min-width: initial !important" dark depressed>
      <!-- <v-icon left>navigate_before</v-icon>
      <span>前日</span> -->
      <v-icon>navigate_before</v-icon>
    </v-btn>
    <v-btn @click="onClickToday()" class="mx-1" color="teal" dark depressed>
      <span>今日</span>
    </v-btn>
    <v-btn @click="onClickNext()" class="mx-1 px-2" color="teal" style="min-width: initial !important" dark depressed>
      <!-- <span>翌日</span>
      <v-icon right>navigate_next</v-icon> -->
      <v-icon>navigate_next</v-icon>
    </v-btn>
  </v-sheet>
</template>

<script>
import * as script from "@/utils/script.js";

export default {
  name: "BaseDateSelector",
  data: () => ({
    dialog: false,
    date: "",
  }),
  props: {
    value: {
      type: String,
      default: "",
      required: true,
    },
  },
  methods: {
    // カレンダー選択時
    async onChangeCalendar(date) {
      // ダイアログを閉じる
      this.dialog = false;
      // イベント発火
      this.$emit("input", date);
      this.$emit("change");
    },

    // 「今日」ボタン押下時
    async onClickToday() {
      const today = new Date();
      // 表示日を当日に変更
      const date = script.formatDate(today, "YYYY-MM-DD");
      // イベント発火
      this.$emit("input", date);
      this.$emit("change");
    },

    // 「前日」ボタン押下時
    async onClickPrevious() {
      // 表示日を前日に変更
      const date = script.formatDate(script.subtractDate(this.value, 1, "d"), "YYYY-MM-DD");
      // イベント発火
      this.$emit("input", date);
      this.$emit("change");
    },

    // 「翌日」ボタン押下時
    async onClickNext() {
      // 表示日を翌日に変更
      const date = script.formatDate(script.addDate(this.value, 1, "d"), "YYYY-MM-DD");
      // イベント発火
      this.$emit("input", date);
      this.$emit("change");
    },

    // 日付フォーマット（YYYY年M月D日（dd））
    fmtDateYYYYMD(string) {
      return script.formatDate(string, "YYYY年M月D日(dd)");
    },
  },
};
</script>
