<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：UserSeikyu.vue
  モジュール名：個人の請求情報画面
  備考：
-------------------------------------------------->
<template>
  <v-container class="d-print-none" fluid>
    <!-- *** 表示部 ここから *** -->
    <title-card title="個人の請求情報">
      <v-btn color="teal" dark depressed @click="clickDownloadBtn()">CSVダウンロード</v-btn>
    </title-card>
    <data-card>
      <!-- 検索条件 -->
      <v-sheet class="px-4 py-2 d-flex" style="align-items: center">
        <!-- 月選択 -->
        <month-selector v-model="value" @change="monthchange()"></month-selector>
        <v-spacer></v-spacer>
        <!-- 締め期間表示 -->
        <span class="black--text mr-5">注文日：</span>
        <span class="black--text mr-5">{{ this.simedate[0].kikan_from }}</span>
        <span class="black--text mr-5">～</span>
        <span class="black--text">{{ this.simedate[0].kikan_to }}</span>
      </v-sheet>

      <v-divider></v-divider>

      <!-- 絞り込み条件 -->
      <v-form ref="select_form">
        <v-sheet class="px-4 py-2 d-flex" style="align-items: center" height="56px">
          <span>事業所：</span>
          <v-responsive class="mr-4" max-width="160px">
            <v-select outlined hide-details dense v-model="jigyosyo" item-text="str_jigyo_name" item-value="num_jigyo_no" :items="filter_jigyo" backgroundColor="white" no-data-text="データがありません。" @change="getSeikyuData()"></v-select>
          </v-responsive>
          <span>所属・グループ名：</span>
          <v-responsive class="mr-4" max-width="160px">
            <v-select outlined hide-details dense v-model="busho" item-text="str_busho_name" item-value="num_busho_no" :items="filter_busho" backgroundColor="white" no-data-text="データがありません。" @change="getSeikyuData()"></v-select>
          </v-responsive>
          <span>消込処理：</span>
          <v-responsive class="mr-4" max-width="110px">
            <v-select outlined hide-details dense v-model="kesikomi" item-text="str_kesikomi" item-value="num_kesikomi_no" :items="filter_kesikomi" backgroundColor="white" @change="getSeikyuData()"></v-select>
          </v-responsive>
        </v-sheet>
      </v-form>

      <v-divider></v-divider>

      <!-- テーブル -->
      <v-data-table v-model="select_data" :headers="headers" :items="datas" item-key="no" :fixed-header="true" :hide-default-footer="true" :items-per-page="-1" no-data-text="請求のデータが存在しません。" height="calc(100vh - 298px)" style="white-space: pre-line">
        <!-- チェックボックス -->
        <template v-slot:[`item.kesikomi`]="{ item }">
          <v-simple-checkbox v-model="item.kesikomi" @click="update(item)"></v-simple-checkbox>
        </template>
      </v-data-table>
    </data-card>
    <!-- *** 表示部 ここまで *** -->
  </v-container>
</template>

<script>
import moment from "moment";
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";
import MonthSelector from "@/components/common/BaseMonthSelector.vue";

export default {
  name: "UserSeikyu",
  data() {
    return {
      value: moment().format("YYYY-MM-DD"),
      displaydate: moment().format("YYYY年MM月"),
      datas: [], //請求情報
      simedate: [{ kikan_from: "", kikan_to: "", str_kikan_from: "", str_kikan_to: "" }], //締め期間情報
      jigyosyos: [], //事業所名
      jigyosyo: 0, //事業所名
      bushos: [], //所属・グループ名
      busho: 0, //所属・グループ名
      kesikomis: [
        { num_kesikomi_no: 0, str_kesikomi: "" },
        { num_kesikomi_no: 1, str_kesikomi: "未", kesikomi: false },
        { num_kesikomi_no: 2, str_kesikomi: "済", kesikomi: true },
      ], //消込処理
      kesikomi: 0, //消込処理
      select_data: [],
      // msg: "",
      // snackbar: false,
      // snackbar_color: "",
      // timeout: 3000, //エラー表示時間
      dialog_date: false, //ポップアップ表示、非表示
    };
  },
  components: {
    TitleCard,
    DataCard,
    MonthSelector,
  },
  methods: {
    // 年・月選択表示
    // monthreduce() {
    //   this.value = moment(this.value).add(-1, "months").format("YYYY-MM-DD");
    //   this.displaydate = moment(this.value).format("YYYY年MM月");
    //   this.getSeikyuData();
    //   this.getSimeDate();
    // },
    // monthadd() {
    //   this.value = moment(this.value).add(1, "months").format("YYYY-MM-DD");
    //   this.displaydate = moment(this.value).format("YYYY年MM月");
    //   this.getSeikyuData();
    //   this.getSimeDate();
    // },
    // monthchange() {
    //   this.dialog_date = false;
    //   this.displaydate = moment(this.value).format("YYYY年MM月");
    //   if (this.jigyosyo != "") {
    //     this.getSeikyuData();
    //     this.getSimeDate();
    //   }
    // },
    monthchange() {
      this.getSeikyuData();
      this.getSimeDate();
    },

    // CSVダウンロードボタン押下処理
    async clickDownloadBtn() {
      // 請求情報取得
      await this.getSeikyuData();
      // データチェック
      if (this.datas.length == 0) {
        return;
      }
      // CSVダウンロード
      await this.downloadCSV();
    },

    /*API*/
    //更新処理消込
    async update(item) {
      const self = this;
      const axios = this.$axios;
      const store = this.$store;
      const params = new URLSearchParams();
      const SeikyuTuki = moment(this.value).format("yyyyMM");
      params.append("KigyoNo", store.state.kigyoCode);
      params.append("UserCd", item.str_user_cd);
      params.append("SimeTuki", SeikyuTuki);
      params.append("Kesikomi", item.kesikomi);
      params.append("UpdateUser", store.state.userCode);
      params.append("sAccess", "WEB");
      await axios
        .post("/t_user_seikyu_post/kesikomi_update", params)
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (d.msg == "UPDATE OK") {
              /* -- 2024.05.08 MCS)KONNO MOD START --*/
              // self.snackbar_color = "safe";
              // self.msg = "更新しました。";
              // self.snackbar = true;
              self.$root.snackbar.showMessage("更新しました。", "success");
              /* -- 2024.05.08 MCS)KONNO MOD END --*/
              self.getSeikyuData();
            }
          });
        })
        .catch(function (error) {
          console.log(error);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.snackbar_color = "error";
          // self.msg = "更新できませんでした。";
          // self.snackbar = true;
          self.$root.snackbar.showMessage("更新できませんでした。", "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        });
    },

    /*API*/
    //請求情報取得
    async getSeikyuData() {
      const KigyoCd = this.$store.state.kigyoCode;
      const SeikyuTuki = moment(this.value).format("yyyyMM");
      /* -- 2024.03.27 MCS)SAITO ADD START -- */
      const JigyoNo = this.jigyosyo;
      const BushoNo = this.busho;
      const Kesikomi = this.kesikomi;
      /* -- 2024.03.27 MCS)SAITO ADD END -- */
      this.datas = await this.$axios
        /* -- 2024.03.27 MCS)SAITO MOD START -- */
        // .get("/t_user_seikyu_get/seikyu_list" + "?KigyoCd=" + KigyoCd + "&SeikyuTuki=" + SeikyuTuki)
        .get("/t_user_seikyu_get/seikyu_list" + "?KigyoCd=" + KigyoCd + "&SeikyuTuki=" + SeikyuTuki + "&JigyoNo=" + JigyoNo + "&BushoNo=" + BushoNo + "&Kesikomi=" + Kesikomi)
        /* -- 2024.03.27 MCS)SAITO MOD END -- */
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });

      //No.
      if (this.datas.length != 0) {
        this.datas.forEach(function (element, index) {
          element["no"] = index + 1;
        });
      }
    },

    /*API*/
    //締め期間取得
    async getSimeDate() {
      const KigyoCd = this.$store.state.kigyoCode;
      const SeikyuTuki = moment(this.value).format("yyyyMM");
      this.simedate = await this.$axios
        .get("/t_user_seikyu_get/sime_date" + "?KigyoCd=" + KigyoCd + "&SeikyuTuki=" + SeikyuTuki)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });

      //締め期間情報がない場合
      if (this.simedate.length == 0) {
        this.simedate = [{ kikan_from: "", kikan_to: "", str_kikan_from: "", str_kikan_to: "" }];
      }
    },

    // CSVダウンロード
    async downloadCSV() {
      let csv = "\ufeff" + "No.,事業所名,所属・グループ名,利用者コード,利用者名,合計金額,消込\n";
      if (this.datas.length != 0) {
        this.datas.forEach(function (element) {
          csv += element.no + ",";
          csv += element.str_jigyo_name + ",";
          csv += element.str_busho_name + ",";
          csv += element.str_user_cd + ",";
          csv += element.str_user_name + ",";
          /* -- 2024.04.10 MCS)SAITO MOD START -- */
          // csv += element.str_goukei + ",";
          csv += element.str_goukei.replace(/,/g, "").replace(/円/g, "") + ",";
          /* -- 2024.04.10 MCS)SAITO MOD END -- */
          if (element.kesikomi) {
            csv += "済\n";
          } else {
            csv += "未\n";
          }
        });
      }
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      /* -- 2024.03.28 MCS)SAITO MOD START -- */
      // let file_name = this.displaydate + "分請求処理_" + this.simedate[0].str_kikan_from + "_" + this.simedate[0].str_kikan_to + ".csv";
      let file_name = this.displaydate + "分請求情報_" + this.simedate[0].str_kikan_from + "_" + this.simedate[0].str_kikan_to + "_" + this.$store.state.nowDate + this.$store.state.nowTime + ".csv";
      /* -- 2024.03.28 MCS)SAITO MOD END -- */
      link.href = window.URL.createObjectURL(blob);
      link.download = file_name;
      link.click();
    },

    /*API*/
    //事業所名取得
    async serchjigyosho() {
      const self = this;
      await this.$axios
        .get("/m_jigyo_get/jigyosyo_select", {
          params: {
            KigyoCd: this.$store.state.kigyoCode,
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              self.jigyosyos = d["data"];
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    /*API*/
    //所属・グループ名取得
    async serchbusho() {
      const self = this;
      await this.$axios
        .get("/m_busho_get/busho_select", {
          params: {
            KigyoNo: this.$store.state.kigyoCode,
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              for (var i = 0; i < Object.keys(d["data"]).length; i++) {
                var data = d["data"][i];
                self.bushos.push({
                  str_busho_name: data.str_busho_name,
                  num_busho_no: data.num_busho_no,
                });
              }
            } else {
              self.bushos.push("no data");
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },

  async created() {
    await this.serchjigyosho(); //事業所名
    await this.serchbusho(); //所属・グループ名
    await this.getSeikyuData(); //請求情報
    await this.getSimeDate(); //締め期間
  },

  computed: {
    headers() {
      return [
        { text: "No.", sortable: true, value: "no", align: "start", width: "80px" },
        {
          text: "事業所名",
          sortable: true,
          value: "str_jigyo_name",
          align: "start",
          width: "200px",
          filter: (value) => {
            if (this.jigyosyo == 0) return true;
            return value == this.jigyosyos.find((i) => i.num_jigyo_no == this.jigyosyo).str_jigyo_name;
          },
        },
        {
          text: "所属・グループ名",
          sortable: true,
          value: "str_busho_name",
          align: "start",
          width: "200px",
          filter: (value) => {
            if (this.busho == 0) return true;
            return value == this.bushos.find((i) => i.num_busho_no == this.busho).str_busho_name;
          },
        },
        { text: "利用者コード", sortable: true, value: "str_user_cd", align: "start", width: "200px" },
        { text: "利用者名", sortable: true, value: "str_user_name", align: "start", width: "150px" },
        { text: "合計金額", sortable: true, value: "str_goukei", align: "start", width: "150px" },
        {
          text: "消込",
          sortable: true,
          value: "kesikomi",
          align: "start",
          width: "80px",
          filter: (value) => {
            if (this.kesikomi == 0) return true;
            return value == this.kesikomis.find((i) => i.num_kesikomi_no == this.kesikomi).kesikomi;
          },
        },
      ];
    },

    //事業所名プルダウン
    filter_jigyo() {
      let wkArray = [];
      let allSelect = [{ num_jigyo_no: 0, str_jigyo_name: "すべて" }];
      return wkArray.concat(allSelect, this.jigyosyos);
    },

    //所属・グループ名プルダウン
    filter_busho() {
      let wkArray = [];
      let allSelect = [{ num_busho_no: 0, str_busho_name: "すべて" }];
      return wkArray.concat(allSelect, this.bushos);
    },

    //消込プルダウン
    filter_kesikomi() {
      let wkArray = [];
      let allSelect = [{ num_kesikomi_no: 0, str_kesikomi: "すべて" }];
      return wkArray.concat(allSelect, this.kesikomis);
    },
  },
};
</script>
