<template>
  <v-main>
    <!-- アプリバー -->
    <AppBar />
    <!-- サイドメニュー -->
    <SideMenu />
    <!-- 画面コンポーネント -->
    <router-view style="min-width: 960px" />
  </v-main>
</template>

<script>
import AppBar from "../components/common/AppBar";
import SideMenu from "../components/admin/SideMenu";

export default {
  components: {
    AppBar,
    SideMenu,
  },
};
</script>
