<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：EmailMaster.vue
  モジュール名：メール定型文マスタ画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <title-card title="メール定型文マスタ">
      <v-btn color="teal" dark depressed @click="btn_insert()">保存</v-btn>
    </title-card>
    <data-card>
      <v-container @change="change_flg = true" fluid>
        <v-form ref="select_form">
          <!-- 件名入力欄 -->
          <div class="text--primary">
            <span style="vertical-align: middle">件名</span>
            <span class="red--text">*</span>
          </div>
          <v-text-field single-line outlined dense v-model="Title" :rules="[required]" maxlength="60" counter="60"></v-text-field>

          <!-- 本文入力欄 -->
          <div class="text--primary">
            <span style="vertical-align: middle">本文</span>
            <span class="red--text">*</span>
          </div>
          <v-textarea single-line outlined rows="20" v-model="Honbun" ref="honbun" :rules="[required]" maxlength="3000" counter="3000"></v-textarea>

          <!-- 各種ボタン -->
          <v-sheet class="mb-2 d-flex">
            <span v-if="change_flg" class="grey--text">※内容が変更されています。編集した内容は保存ボタンを押すまで反映されません。</span>
            <v-spacer></v-spacer>
            <v-btn color="teal" outlined @click="showPreview">プレビュー表示</v-btn>
            <v-btn class="ml-2" color="teal" outlined @click="btn_disp(1)">初期設定に戻す</v-btn>
            <v-btn class="ml-2" color="teal" outlined @click="btn_disp(2)">キャンセル</v-btn>
          </v-sheet>

          <!-- 差し込み機能 -->
          <v-sheet>
            <div>差し込み機能&nbsp;<small class="grey--text">※メール送信時に注文内容に合わせて置き換えられます。実際の送信内容を確認するにはプレビュー表示を押してください。</small></div>
            <div>
              <v-chip v-for="item in items" :key="item.title" class="ma-2" @click="insertCode(item.code)">{{ item.title }}</v-chip>
            </div>
          </v-sheet>
        </v-form>
      </v-container>
    </data-card>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** 送信プレビュー用ダイアログ ここから *** -->
    <v-dialog v-model="dialog_sample" max-width="800px" persistent no-click-animation>
      <v-card class="px-3">
        <v-card-title class="px-1">送信プレビュー</v-card-title>
        <v-sheet outlined>
          <v-card-text class="black--text py-2">件名：{{ Title }}</v-card-text>
        </v-sheet>
        <v-sheet outlined>
          <v-card-text class="black--text" style="white-space: pre-wrap">{{ sample }}</v-card-text>
        </v-sheet>
        <v-card-text class="px-0 py-1">※注文内容の「〇〇〇ランチ」「〇〇〇弁当 1個」はイメージです。実際には登録された内容が表示されます。</v-card-text>
        <v-card-actions class="px-0">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_sample = false"> 閉じる </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *** 送信プレビュー用ダイアログ ここまで *** -->
  </v-container>
</template>

<script>
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "App",
  data() {
    return {
      //エラーメッセージ
      // msg: "",
      datas: [],
      KigyoCd: 0,
      MailtmpNo: 1,
      // snackbar0: false,
      // snackbar1: false,
      //エラー表示時間
      // timeout: 3000,
      //ポップアップ表示、非表示
      dialog: false,
      //テキストボックス
      Title: "",
      Honbun: "",
      required: (value) => !!value || "必ず入力してください。",
      //変更フラグ
      change_flg: false,
      //ボタン判別
      BtnVal: 0,
      //登録済み
      SavedTitle: "",
      SavedHonbun: "",
      //確認ダイアログメッセージ
      message1: "",
      message2: "",
      //置き換えコード
      items: [
        { title: "%弁当業者名%", code: "%弁当業者名%" },
        { title: "%企業名%", code: "%企業名%" },
        { title: "%日付%", code: "%日付%" },
        { title: "%注文内容%", code: "%注文内容%" },
        { title: "%事業所名%", code: "%事業所名%" },
        { title: "%事業所住所%", code: "%事業所住所%" },
        { title: "%事業所電話番号%", code: "%事業所電話番号%" },
        { title: "%事業所FAX番号%", code: "%事業所FAX番号%" },
        { title: "%事業所メールアドレス%", code: "%事業所メールアドレス%" },
      ],
      //サンプル
      sample: "",
      //ダイアログ
      dialog_sample: false,
      //事業所情報
      jigyo_info: [],
    };
  },
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    //表示ボタンが押された場合
    btn_disp(BtnVal) {
      self.BtnVal = BtnVal;
      if (this.Honbun == "") {
        //本文が空欄の場合
        if (self.BtnVal == 1) {
          //サンプル表示
          this.honbundisp(true);
        }
        if (self.BtnVal == 2) {
          //登録文表示
          this.honbundisp(true);
        }
        this.change_flg = true;
      } else {
        //本文が空欄ではない場合
        if (this.change_flg == true) {
          //入力中の場合
          if (window.confirm("入力内容が破棄されますが、よろしいですか？")) {
            switch (self.BtnVal) {
              //サンプル表示
              case 1:
                this.honbundisp(true);
                break;
              //登録文表示
              case 2:
                this.honbundisp(true);
                break;
            }
          }
        } else {
          //入力中ではない場合
          switch (self.BtnVal) {
            //サンプル表示
            case 1:
              if (window.confirm("初期設定に戻しますか？")) {
                this.honbundisp(true);
              }
              break;
            //登録文表示
            case 2:
              if (window.confirm("保存済みの定型文を表示しますか？")) {
                this.honbundisp(true);
              }
              break;
          }
        }
      }
    },

    //本文欄に表示
    honbundisp(dispflg) {
      if (dispflg) {
        if (self.BtnVal == 1) {
          this.Title = "弁当注文依頼";
          this.Honbun = "%弁当業者名%様\n\nいつもお世話になっております。\n%企業名%です。\n\n%日付%の注文数\n%注文内容%\nです。\n以上、よろしくお願いいたします。\n\n---------------------------------------------------------------------------------------------------------\n%企業名%\n%事業所名%\n%事業所住所%\n%事業所電話番号%\n%事業所FAX番号%\n%事業所メールアドレス%\n---------------------------------------------------------------------------------------------------------";
          this.change_flg = true;
        }
        if (self.BtnVal == 2) {
          this.Title = this.SavedTitle;
          this.Honbun = this.SavedHonbun;
          this.change_flg = false;
        }
        //自動スクロール
        // const scrolltop = document.getElementById("scrolltop");
        // scrolltop.scrollTo(0, 0);
      }
    },

    // 事業所情報取得処理
    async getJigyoInfo() {
      const KigyoCd = this.$store.state.kigyoCode;
      this.jigyo_info = await this.$axios
        .get("/m_jigyo_get/jigyosyo_sample" + "?KigyoCd=" + KigyoCd)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    //プレビュー表示
    async showPreview() {
      // 変数宣言
      let jigyo_name = "";
      let jigyo_address = "";
      let jigyo_mail = "";
      let jigyo_tel = "";
      let jigyo_fax = "";
      // 日付取得
      const today = this.$store.state.nowDate;
      // 事業所情報取得
      await this.getJigyoInfo();
      // 文章整形
      jigyo_name = this.jigyo_info.str_jigyo_name;
      jigyo_address = this.jigyo_info.str_address;
      jigyo_mail = "E-mail: " + this.jigyo_info.str_mail;
      if (this.jigyo_info.str_tel2 == null || this.jigyo_info.str_tel2 == "") {
        jigyo_tel = "TEL: " + this.jigyo_info.str_tel1;
      } else {
        jigyo_tel = "TEL1: " + this.jigyo_info.str_tel1 + " TEL2: " + this.jigyo_info.str_tel2;
      }
      jigyo_fax = "FAX: " + this.jigyo_info.str_fax;
      //本文置き換え
      this.sample = this.Honbun;
      //%弁当業者名%
      this.sample = this.sample.replace(/%弁当業者名%/g, "〇〇〇ランチ");
      //%企業名%
      this.sample = this.sample.replace(/%企業名%/g, this.$store.state.kigyoName);
      //%日付%
      this.sample = this.sample.replace(/%日付%/g, today.substr(0, 4) + "年" + today.substr(4, 2) + "月" + today.substr(-2) + "日");
      //%注文内容%
      this.sample = this.sample.replace(/%注文内容%/g, "〇〇〇弁当         1個");
      /* 以下ユーザー側の署名 */
      //%事業所名%
      this.sample = this.sample.replace(/%事業所名%/g, jigyo_name);
      //%事業所住所%
      this.sample = this.sample.replace(/%事業所住所%/g, jigyo_address);
      //%事業所電話番号%
      this.sample = this.sample.replace(/%事業所電話番号%/g, jigyo_tel);
      //%事業所FAX番号%
      this.sample = this.sample.replace(/%事業所FAX番号%/g, jigyo_fax);
      //%事業所メールアドレス%
      this.sample = this.sample.replace(/%事業所メールアドレス%/g, jigyo_mail);

      this.dialog_sample = true;
    },

    // //表示ボタンが押されたとき
    // btn_disp(BtnVal) {
    // 	self.BtnVal = BtnVal
    // 	if(this.Honbun === ""){
    // 		switch(self.BtnVal){
    // 			case 1: this.Honbun = "%弁当業者名%様\n\nいつもお世話になっております。\n%企業名%です。\n\n%日付%の注文数\n%注文内容%\nです。\n以上、よろしくお願いいたします。\n\n---------------------------------------------------------------------------------------------------------\n%企業名%\n%事業所名%\n%事業所住所%\n%事業所電話番号%\n%事業所FAX番号%\n%事業所メールアドレス%\n---------------------------------------------------------------------------------------------------------";
    // 			break;
    // 			case 2: this.Honbun = this.SavedHonbun;
    // 			break;
    // 		}
    // 		this.change_flg = true
    // 	}else{
    // 		if(this.change_flg == true){
    // 			this.message1 = "入力内容が破棄されますが、よろしいですか？";
    // 		}else{
    // 			switch(self.BtnVal){
    // 				case 1:
    // 					this.message1 = "サンプルを表示しますか？";
    // 				break;
    // 				case 2:
    // 					this.message1 = "登録文を表示しますか？";
    // 				break;
    // 			}
    // 		}
    // 		this.message2 = "※登録済みの内容は破棄されません。";
    // 		this.cfm_dialogdisp(true)
    // 	}
    // },

    // //表示確認ダイアログ表示、非表示
    // cfm_dialogdisp(dispflg){
    // 	if(dispflg){
    // 		this['dialog'] = true
    // 	}else{
    // 		this['dialog'] = false
    // 	}
    // },

    // //本文欄に表示
    // honbundisp(dispflg){
    // 	if(dispflg){
    // 		switch(self.BtnVal){
    // 			case 1: this.Honbun = "弁当注文依頼";
    // 			case 1: this.Honbun = "%弁当業者名%様\n\nいつもお世話になっております。\n%企業名%です。\n\n%日付%の注文数\n%注文内容%\nです。\n以上、よろしくお願いいたします。\n\n---------------------------------------------------------------------------------------------------------\n%企業名%\n%事業所名%\n%事業所住所%\n%事業所電話番号%\n%事業所FAX番号%\n%事業所メールアドレス%\n---------------------------------------------------------------------------------------------------------";
    // 			break;
    // 			case 2: this.Honbun = this.SavedHonbun;
    // 			break;
    // 		}
    // 		this['dialog'] = false
    // 		this.change_flg = true
    // 		//自動スクロール
    // 		const scrolltop = document.getElementById('scrolltop');
    // 		scrolltop.scrollTo(0, 0);
    // 	}else{
    // 		this['dialog'] = false
    // 	}
    // },

    //保存ボタンが押された時
    btn_insert() {
      //入力チェック
      if (this.$refs.select_form.validate()) {
        this.insertteikei();
      }
    },

    //メール定型文取得用
    async getteikei() {
      const self = this;
      await this.$axios
        .get("/m_mailtmp_get/teikei_get", {
          params: {
            KigyoCd: this.$store.state.kigyoCode,
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              self.datas = d["data"];
              self.data = self.datas["0"];
              self.Title = self.data["str_title"];
              self.Honbun = self.data["str_honbun"];
              self.SavedTitle = self.data["str_title"];
              self.SavedHonbun = self.data["str_honbun"];
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //メール定型文保存用
    async insertteikei() {
      const self = this;
      const store = this.$store;
      const params = new URLSearchParams();
      params.append("KigyoCd", store.state.kigyoCode);
      params.append("MailtmpNo", self.MailtmpNo);
      params.append("Title", self.Title);
      params.append("Honbun", self.Honbun);
      params.append("sUser", store.state.userCode);
      params.append("sAccess", "WEB");
      params.append("JigyoNo", store.state.jigyoNo);
      await this.$axios
        .post("/m_mailtmp_post/update", params)
        .then(function () {
          //再読み込み
          self.getteikei();
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.msg = "保存しました。";
          // self.snackbar0 = true;
          self.$root.snackbar.showMessage("保存しました。", "success");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
          //変更フラグをfalseに
          self.change_flg_save();
        })
        // .then(function (response) {
        // 	Promise.resolve(response.data).then(function(d){
        // 		if (Object.keys(d["data"]).length != 0) {
        // 			//登録（更新）
        // 			axios
        // 			.post("/m_mailtmp_post/update", params)
        // 			.then(response => {
        // 				console.log(response.data);
        // 				//再読み込み
        // 				self.getteikei();
        // 				self.msg = '登録が完了しました。';
        // 				self.snackbar = true;
        // 				//変更フラグをfalseに
        // 				self.change_flg_save();
        // 			})
        // 			.catch((error) => {
        // 				console.log(error.response.data.error.code);
        // 				self.msg = 'エラーが発生しました。 error:' + error.response.data.error;
        // 				self.snackbar = true;
        // 			});
        // 		} else {
        // 			//登録（初回）
        // 			axios
        // 			.post("/m_mailtmp_post/insert", params)
        // 			.then(response => {
        // 				console.log(response.data)
        // 				//再読み込み
        // 				self.getteikei();
        // 				self.msg = '登録が完了しました。';
        // 				self.snackbar = true;
        // 				//変更フラグをfalseに
        // 				self.change_flg_save();
        // 			})
        // 			.catch((error) => {
        // 				console.log(error.response.data.error.code);
        // 				self.msg = 'エラーが発生しました。 error:' + error.response.data.error;
        // 				self.snackbar = true;
        // 			});
        // 		}
        // 	});
        // })
        .catch(function (error) {
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.msg = "エラーが発生しました。 error:" + error.response.data.error;
          // self.snackbar1 = true;
          self.$root.snackbar.showMessage("エラーが発生しました。 error:" + error.response.data.error, "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
          console.log(error);
        });
    },

    //登録時の変更フラグ処理
    change_flg_save() {
      this.change_flg = false;
    },

    //差し込みコード挿入
    insertCode(code) {
      let textarea = this.$refs.honbun;
      textarea.focus();
      document.execCommand("insertText", false, code);
    },
  },

  //画面が読み込まれたとき
  async created() {
    await this.getteikei();
  },

  //画面遷移するとき
  beforeRouteLeave(to, from, next) {
    if (this.change_flg == true) {
      if (window.confirm("編集中のページから移動します。\n変更内容は保存されませんが、よろしいですか？")) {
        this.change_flg = false;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>
<style lang="scss" scoped>
// @import "./src/assets/css/TraderMaster.scss";
</style>
