<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：UserLogin.vue
  モジュール名：ログイン画面
  備考：
-------------------------------------------------->
<template>
  <v-container>
    <v-card width="500px" class="mx-auto py-2" outlined>
      <!-- タイトル -->
      <v-card-title class="px-8 justify-center">
        <h3>ログイン</h3>
      </v-card-title>
      <!-- フォーム -->
      <v-card-text class="px-8 py-0">
        <v-form ref="auth">
          <!-- 企業名 -->
          <div class="text--primary mb-6">
            <v-icon class="mr-2 d-inline-block" style="vertical-align: middle">business</v-icon>
            <!-- <span style="vertical-align: middle">企業</span> -->
            <h2 class="my-2 d-inline-block" style="vertical-align: middle">{{ $store.state.kigyoName != "" ? $store.state.kigyoName : "未登録" }}</h2>
          </div>
          <!-- ID -->
          <v-text-field dense :disabled="!canInput" :type="'url'" prepend-icon="account_circle" label="ID" v-model="auth_id" @keypress.prevent.enter.exact="clickLogin" :rules="id_rule" ref="id" outlined flat />
          <!-- パスワード -->
          <v-text-field dense :disabled="!canInput" :type="showPassword ? 'text' : 'password'" prepend-icon="lock" :append-icon="showPassword ? 'visibility' : 'visibility_off'" label="パスワード" @click:append="showPassword = !showPassword" v-model="auth_pass" @keypress.prevent.enter.exact="clickLogin" :rules="pass_rule" outlined flat />
        </v-form>
      </v-card-text>
      <!-- ボタン -->
      <v-card-actions class="px-8 justify-center">
        <v-btn :disabled="!canInput" class="info px-10" color="warning" @click="clickLogin" depressed block>ログイン</v-btn>
      </v-card-actions>
    </v-card>
    <!-- ダイアログ -->
    <v-dialog v-model="dialog_pass" max-width="800px" persistent no-click-animation>
      <v-card>
        <!-- タイトル -->
        <v-card-title> パスワードの変更 </v-card-title>
        <!-- 内容 -->
        <v-card-text>
          <v-container>
            <!-- 説明 -->
            <v-row>
              <p class="mb-0">新しいパスワードを入力して登録を押してください。</p>
              <p class="mb-0">※この画面は、初回ログイン時またはパスワードがリセットされた場合に表示されます。</p>
              <p class="mb-0">※新しいパスワードには半角英数または記号を使用し、8文字以上入力してください。</p>
            </v-row>
            <!-- フォーム -->
            <v-form ref="pass" class="mt-8">
              <v-row>
                <v-text-field class="my-1" v-model="new_pass" label="新しいパスワード" :type="'text'" :maxlength="pass_length" :counter="pass_length" :rules="new_pass_rule" pattern="^[0-9a-zA-Z]+$" ref="new_pass" @keypress.prevent.enter.exact="clickPassReset" outlined />
              </v-row>
              <v-row>
                <v-text-field class="my-1" v-model="new_pass_confirm" label="新しいパスワード（確認）" :type="'text'" :maxlength="pass_length" :counter="pass_length" :rules="new_pass_confirm_rule" pattern="^[0-9a-zA-Z]+$" @keypress.prevent.enter.exact="clickPassReset" outlined />
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <!-- ボタン -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="clickPassReset"> 登録 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import exManifest from "@/manifest.js";

export default {
  name: "UserLogin",
  data: () => ({
    canInput: false,
    showPassword: false,
    pass_length: 20,
    user: [],
    kigyo: [],
    auth_id: "",
    auth_pass: "",
    new_pass: "",
    new_pass_confirm: "",
    dialog_pass: false,
    // snackbar: false,
    // snackbar_msg: "",
    // snackbar_timeout: 3000,
    // attempts: 0,
  }),
  methods: {
    //----------------------------------------------------------------
    // 初期化処理
    //----------------------------------------------------------------
    async init() {
      // ストアの初期化
      this.$store.commit("reset");
      // サーバー接続チェック
      if (!(await this.checkServer())) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("サーバーにアクセスできません。", -1);
        this.$root.snackbar.showMessage("サーバーにアクセスできません。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // データベース接続チェック
      if (!(await this.checkDB())) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("データベースにアクセスできません。", -1);
        this.$root.snackbar.showMessage("データベースにアクセスできません。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 会社情報取得
      await this.getKigyo();
      // 登録チェック
      if (this.kigyo.length == 0) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("企業情報が登録されていません。", -1);
        this.$root.snackbar.showMessage("企業情報が登録されていません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // ストアに退避
      this.$store.dispatch("access", this.kigyo);
      // 入力項目のロック解除
      this.canInput = true;
      // ID入力欄にフォーカス
      this.$nextTick(() => this.$refs.id.focus());
    },

    //----------------------------------------------------------------
    // イベント処理
    //----------------------------------------------------------------

    // ログインボタン押下時処理
    async clickLogin() {
      // 入力チェック
      if (!this.$refs.auth.validate()) {
        return;
      }
      // ユーザー情報取得
      await this.auth();
      // 存在チェック
      if (Object.keys(this.user).length == 0) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("IDまたはパスワードが間違っています。入力内容を確認してください。");
        // this.attempts += 1;
        this.$root.snackbar.showMessage("IDまたはパスワードが間違っています。入力内容を確認してください。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return false;
      }
      // ログイン処理
      if (this.user.num_first_login_flg == 1) {
        // 初回ログイン時
        // パスワード変更
        this.openDialogPass();
      } else {
        // 2回目以降ログイン時
        // ストアに退避
        this.$store.dispatch("login", this.user);
        // ログ出力
        await this.log();
        // 画面遷移
        this.seni();
      }
    },

    // パスワード登録ボタン押下時処理
    async clickPassReset() {
      // 入力チェック
      if (!this.$refs.pass.validate()) {
        return;
      }
      // パスワード更新
      await this.pass();
      // ダイアログを閉じる
      this.dialog_pass = false;
      // ストアに退避
      this.$store.dispatch("login", this.user);
      // ログ出力
      await this.log();
      // 画面遷移
      this.seni();
    },

    //----------------------------------------------------------------
    // メソッド
    //----------------------------------------------------------------

    // パスワード変更ダイアログ
    openDialogPass() {
      // ダイアログを開く
      this.dialog_pass = true;
      // パスワード入力欄にフォーカス
      this.$nextTick(() => this.$refs.new_pass.focus());
    },

    // サーバー接続チェック
    async checkServer() {
      let wkBoolean = await this.$axios
        .get("/auth/server", { timeout: 10000 })
        .then(function (response) {
          return response.data["result"];
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
      return wkBoolean;
    },

    // データベース接続チェック
    async checkDB() {
      let wkBoolean = await this.$axios
        .get("/auth/db", { timeout: 10000 })
        .then(function (response) {
          return response.data["result"];
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
      return wkBoolean;
    },

    // 企業情報取得処理
    async getKigyo() {
      const KigyoCd = this.$route.params.kigyoCd;
      this.kigyo = await this.$axios
        .get("/m_kigyo_get/info" + "?KigyoCd=" + KigyoCd)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    // ユーザー情報取得処理
    async auth() {
      const params = new URLSearchParams();
      params.append("KigyoCd", this.kigyo.str_kigyo_cd);
      params.append("Login", this.auth_id);
      params.append("Password", this.auth_pass);
      this.user = await this.$axios
        .post("/auth/login", params)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    // パスワード更新処理
    async pass() {
      const params = new URLSearchParams();
      params.append("Password", this.new_pass);
      params.append("sUser", this.user.str_user_cd);
      params.append("sAccess", "WEB");
      params.append("KigyoCd", this.user.str_kigyo_cd);
      params.append("JigyoNo", this.user.num_jigyo_no);
      params.append("UserCd", this.user.str_user_cd);
      await this.$axios
        .post("/auth/pass", params)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    // ログ出力処理
    async log() {
      const params = new URLSearchParams();
      params.append("KigyoCd", this.user.str_kigyo_cd);
      params.append("JigyoNo", this.user.num_jigyo_no);
      params.append("UserCd", this.user.str_user_cd);
      params.append("sAccess", "WEB");
      await this.$axios
        .post("/t_login_post/insert", params)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    // 画面遷移処理
    async seni() {
      // 画面遷移
      switch (this.$store.state.adminAuth) {
        // 一般ユーザー
        case 0:
          this.$router.push("/reservation");
          break;
        // 管理者
        case 1:
          this.$router.push("/admin/system_notice");
          break;
        // システム管理者
        case 2:
          this.$router.push("/admin/system_notice");
          break;
        // 不明（例外）
        default:
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // this.noticeNG("不明なユーザーです。");
          this.$root.snackbar.showMessage("不明なユーザーです。", "warning");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
          this.init();
          break;
      }
    },

    // メッセージ表示（エラー）
    // noticeNG(msg, time = 3000) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "red";
    //   this.snackbar_timeout = time;
    //   this.snackbar = true;
    // },
  },
  computed: {
    //----------------------------------------------------------------
    // バリデーションルール
    //----------------------------------------------------------------

    // ID
    id_rule() {
      return [!!this.auth_id || "IDを入力してください。"];
    },

    // パスワード
    pass_rule() {
      return [!!this.auth_pass || "パスワードを入力してください。"];
    },

    // 新しいパスワード
    new_pass_rule() {
      return [!!this.new_pass || "新しいパスワードを入力してください。", !!this.new_pass.match("^[a-zA-Z0-9!-/:-@¥[-`{-~]*$") || "半角英数字と記号のみ入力可能です。", this.new_pass.length >= 8 || "8文字以上入力してください。"];
    },

    // 新しいパスワード（確認）
    new_pass_confirm_rule() {
      return [!!this.new_pass_confirm || "確認用パスワードを入力してください。", !!this.new_pass_confirm.match("^[a-zA-Z0-9!-/:-@¥[-`{-~]*$") || "半角英数字と記号のみ入力可能です。", this.new_pass == this.new_pass_confirm || "入力されたパスワードが一致しません。"];
    },
  },
  mounted() {
    if (this.$store.state.isLogin) {
      // ログイン状態の場合、リダイレクト
      this.seni();
    } else {
      // 本番環境のみ
      if (process.env.NODE_ENV == "production") {
        // manifest設定
        exManifest.setManifestURL(this.$route.params.kigyoCd);
      }
      // ログアウト状態の場合、初期化
      this.init();
    }
  },
};
</script>
