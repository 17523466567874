var setManifestURL = function (param = null) {
  var myUrl = "https://" + document.domain;
  var startUrl = param ? myUrl + "/login/" + param : myUrl;

  var manifest = {
    name: "befosc",
    short_name: "befosc",
    theme_color: "#fff2cc",
    background_color: "#eaffe7",
    display: "standalone",
    start_url: startUrl,
    icons: [
      {
        src: myUrl + "/img/icons/icon-72x72.png",
        sizes: "72x72",
        type: "image/png",
      },
      {
        src: myUrl + "/img/icons/icon-96x96.png",
        sizes: "96x96",
        type: "image/png",
      },
      {
        src: myUrl + "/img/icons/icon-128x128.png",
        sizes: "128x128",
        type: "image/png",
      },
      {
        src: myUrl + "/img/icons/icon-144x144.png",
        sizes: "144x144",
        type: "image/png",
      },
      {
        src: myUrl + "/img/icons/icon-152x152.png",
        sizes: "152x152",
        type: "image/png",
      },
      {
        src: myUrl + "/img/icons/icon-192x192.png",
        sizes: "192x192",
        type: "image/png",
      },
      {
        src: myUrl + "/img/icons/icon-384x384.png",
        sizes: "384x384",
        type: "image/png",
      },
      {
        src: myUrl + "/img/icons/icon-512x512.png",
        sizes: "512x512",
        type: "image/png",
      },
    ],
  };

  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);
  document.querySelector("#my-manifest").setAttribute("href", manifestURL);
};
export default {
  setManifestURL,
};
