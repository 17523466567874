<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：UserMaster.vue
  モジュール名：利用者マスタ画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <title-card title="利用者マスタ">
      <v-responsive min-width="360px" max-width="360px">
        <v-text-field class="pt-0 mr-5" v-model="search" append-icon="search" label="検索するキーワードを入力" style="background-color: white" single-line hide-details outlined dense></v-text-field>
      </v-responsive>
      <v-btn color="teal" dark depressed @click="table_select()">新規登録</v-btn>
    </title-card>
    <data-card>
      <v-sheet class="px-4 py-2 d-flex" style="align-items: center" height="56px">
        <span>事業所：</span>
        <v-responsive class="mr-4" max-width="200px">
          <v-select outlined v-model="jigyo_search" item-text="str_jigyo_name" item-value="num_jigyo_no" :items="filter_jigyo" dense backgroundColor="white" style="width: 300px" hide-details></v-select>
        </v-responsive>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dialog_import = true" depressed><v-icon class="mr-2">save_alt</v-icon>CSVインポート</v-btn>
      </v-sheet>
      <v-divider></v-divider>
      <v-card class="mx-auto">
        <v-data-table :headers="headers" :items="datas" item-key="item.key" :fixed-header="true" :hide-default-footer="true" :items-per-page="-1" no-data-text="利用者のデータがありません" height="calc(100vh - 245px)" :search="search" no-results-text="該当するデータがありません。" style="white-space: pre-line" @click:row="clickRow"></v-data-table>
      </v-card>
    </data-card>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** 編集用ダイアログ ここから *** -->
    <v-dialog v-model="dialog" width="900" persistent no-click-animation>
      <v-card @change="change_flg = true">
        <v-card-title>利用者情報</v-card-title>
        <v-form ref="select_form" class="mr-5 ml-5 w-50">
          <v-col class="py-0">
            <table>
              <tr>
                <td>利用者コード<span class="red--text" v-if="usercd_flg">*</span></td>
              </tr>
              <tr style="vertical-align: baseline">
                <td>
                  <v-text-field outlined v-model="riyocode" maxlength="20" backgroundColor="white" style="width: 350px" :rules="[required, usererror]" dense :disabled="!usercd_flg" hint="利用者コードは後から変更することはできません。" @input="syncID"> </v-text-field>
                </td>
              </tr>
            </table>
          </v-col>

          <v-col class="py-0">
            <table>
              <tr>
                <td>事業所<span class="red--text">*</span></td>
                <td>所属・グループ<span class="red--text">*</span></td>
              </tr>
              <tr style="vertical-align: baseline">
                <td>
                  <v-select outlined class="mr-10" v-model="jigyosyo" item-text="str_jigyo_name" item-value="num_jigyo_no" :rules="[select]" :items="jigyosyos" @change="jigyono($event)" dense backgroundColor="white" style="width: 350px"></v-select>
                </td>
                <td>
                  <v-select outlined class="mr-10" v-model="busho" item-text="str_busho_name" item-value="num_busho_no" :rules="[select]" :items="bushos" @change="bushono($event)" dense backgroundColor="white" style="width: 350px"></v-select>
                </td>
              </tr>
            </table>
          </v-col>

          <v-col class="py-0">
            <table>
              <tr>
                <td>氏名<span class="red--text">*</span></td>
                <td>氏名カナ<span class="red--text">*</span></td>
              </tr>
              <tr style="vertical-align: baseline">
                <td>
                  <v-text-field outlined class="mr-10" v-model="name" maxlength="20" backgroundColor="white" style="width: 350px" :rules="[required]" dense></v-text-field>
                </td>
                <td>
                  <v-text-field outlined class="mr-10" v-model="kananame" maxlength="20" backgroundColor="white" style="width: 350px" :rules="[required, kana]" dense></v-text-field>
                </td>
              </tr>
            </table>
          </v-col>

          <v-col class="py-0">
            <table>
              <tr>
                <td>
                  <div class="d-flex">
                    <span>ログインID<span class="red--text" v-if="!checkbox2">*</span></span>
                    <v-spacer></v-spacer>
                    <v-checkbox dense hide-details v-model="checkbox2" label="利用者コードと同じ" class="mt-0 mr-10 d-inline" @change="syncID"></v-checkbox>
                  </div>
                </td>
                <td v-if="insertflg">
                  <div class="d-flex">
                    <span>初回パスワード<span class="red--text">*</span></span>
                    <v-spacer></v-spacer>
                    <v-btn class="px-1" height="24" text outlined @click="generatePassword" :disabled="!checkbox">ランダム生成</v-btn>
                  </div>
                </td>
                <td v-if="!insertflg">
                  <div class="d-flex">
                    <span><v-checkbox v-model="checkbox" label="パスワードを初期化" class="mt-0 pt-0" hide-details></v-checkbox></span>
                    <v-spacer></v-spacer>
                    <v-btn class="px-1" height="24" text outlined @click="generatePassword" :disabled="!checkbox">ランダム生成</v-btn>
                  </div>
                </td>
              </tr>
              <tr style="vertical-align: baseline">
                <td>
                  <v-text-field outlined class="mr-10" v-model="id" maxlength="30" backgroundColor="white" style="width: 350px" :rules="[required, iderror]" dense :disabled="checkbox2"></v-text-field>
                </td>
                <td v-if="insertflg">
                  <v-text-field outlined maxlength="20" backgroundColor="white" v-model="password" style="width: 350px" :rules="[required, passerror]" dense hint="初回ログイン時に必要なパスワードです。一度のみ使用できます。" />
                </td>
                <td v-if="!insertflg">
                  <v-text-field :disabled="!checkbox" outlined maxlength="20" backgroundColor="white" v-model="password" style="width: 350px" :rules="checkbox ? [required, passerror] : []" dense hint="初回ログイン時に必要なパスワードです。一度のみ使用できます。" />
                </td>
              </tr>
            </table>
          </v-col>

          <v-col class="py-0">
            <table>
              <tr>
                <td>メールアドレス</td>
                <td>内線</td>
              </tr>
              <tr style="vertical-align: baseline">
                <td>
                  <v-text-field outlined class="mr-10" v-model="mail" maxlength="50" backgroundColor="white" style="width: 450px" :rules="mailerror" dense> </v-text-field>
                </td>
                <td>
                  <v-text-field outlined class="mr-10" v-model="naisen" maxlength="6" backgroundColor="white" style="width: 250px" :rules="[telephone]" dense> </v-text-field>
                </td>
              </tr>
            </table>
          </v-col>
        </v-form>

        <!-- 下部のボタン群 -->
        <v-col align="right">
          <div v-show="isEntry">
            <v-btn height="40" width="70" color="blue darken-1" text @click="btn_insert()" class="mr-5">登録</v-btn>
            <v-btn height="40" width="70" color="blue darken-1" text @click="btn_back()">閉じる</v-btn>
          </div>

          <div v-show="isFix">
            <v-btn height="40" width="70" color="green darken-1" text @click="btn_update()" class="mr-5">保存</v-btn>

            <v-btn height="40" width="70" color="red darken-1" text @click="btn_delete()" class="mr-5">削除</v-btn>

            <v-btn height="40" width="70" color="blue darken-1" text @click="btn_back()">閉じる</v-btn>
          </div>
        </v-col>
      </v-card>
    </v-dialog>
    <!-- *** 編集用ダイアログ ここまで *** -->

    <!-- *** CSVインポート用ダイアログ ここから *** -->
    <v-dialog v-model="dialog_import" :width="selectedFile == null ? '800px' : '95%'" persistent no-click-animation>
      <v-card @change="change_flg = true" :loading="isImport">
        <v-card-title>
          CSVインポート
          <v-spacer></v-spacer>
          <v-btn class="ma-2" outlined @click="downloadCSV()"><v-icon class="mr-2">file_download</v-icon> テンプレートファイルのダウンロード </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-file-input v-model="selectedFile" accept=".csv" label="ファイルを選択" prepend-icon="description" ref="input"></v-file-input>
          <!-- 2024.03.25 MCS)SAITO MOD START -->
          <!-- <v-data-table height="600px" v-if="selectedFile != null" :headers="headers_import" :items="import_list" item-key="item.key" :fixed-header="true" :hide-default-footer="true" :items-per-page="-1" no-data-text="登録可能なデータが存在しません。" disable-pagination style="white-space: pre-line"> -->
          <v-data-table height="50dvh" v-if="selectedFile != null" :headers="headers_import" :items="import_list" item-key="item.key" :fixed-header="true" :hide-default-footer="true" :items-per-page="-1" no-data-text="登録可能なデータが存在しません。" disable-pagination style="white-space: pre-line">
            <!-- 2024.03.25 MCS)SAITO MOD END -->
            <!-- 利用者コード -->
            <template v-slot:[`item.str_user_cd`]="{ item }">
              <span v-if="item.str_user_cd != ''">
                <span :class="checkUserCd(item.str_user_cd) == '' ? 'black--text' : 'red--text'">
                  {{ item.str_user_cd }}
                </span>
              </span>
              <span v-else class="red--text"> 不明 </span>
            </template>
            <!-- 氏名 -->
            <template v-slot:[`item.str_user_name`]="{ item }">
              <span v-if="item.str_user_name != ''">
                <span :class="checkUserName(item.str_user_name) == '' ? 'black--text' : 'red--text'">
                  {{ item.str_user_name }}
                </span>
              </span>
              <span v-else class="red--text"> 不明 </span>
            </template>
            <!-- 氏名カナ -->
            <template v-slot:[`item.str_user_kana`]="{ item }">
              <span v-if="item.str_user_kana != ''">
                <span :class="checkUserKana(item.str_user_kana) == '' ? 'black--text' : 'red--text'">
                  {{ item.str_user_kana }}
                </span>
              </span>
              <span v-else class="red--text"> 不明 </span>
            </template>
            <!-- 事業所名称 -->
            <template v-slot:[`item.str_jigyo_name`]="{ item }">
              <span v-if="jigyosyos.some((i) => i.num_jigyo_no == item.num_jigyo_no)">
                {{ jigyosyos.find((i) => i.num_jigyo_no == item.num_jigyo_no).str_jigyo_name }}
              </span>
              <span v-else class="red--text"> 不明 </span>
            </template>
            <!-- 所属・グループ名称 -->
            <template v-slot:[`item.str_busho_name`]="{ item }">
              <span v-if="bushos.some((i) => i.num_busho_no == item.num_busho_no)">
                {{ bushos.find((i) => i.num_busho_no == item.num_busho_no).str_busho_name }}
              </span>
              <span v-else class="red--text"> 不明 </span>
            </template>
            <!-- ログインID -->
            <!-- 2024.04.15 MCS)SAITO MOD START -->
            <!-- <template v-slot:[`item.str_login`]="{ item }"> -->
            <template v-slot:[`item.str_login`]="{ item, index }">
              <!-- 2024.04.15 MCS)SAITO MOD END -->
              <span v-if="item.str_login != ''">
                <!-- 2024.04.15 MCS)SAITO MOD START -->
                <!-- <span :class="checkLoginID(item.str_login) == '' ? 'black--text' : 'red--text'"> -->
                <span :class="checkLoginID(item.str_login, index) == '' ? 'black--text' : 'red--text'">
                  <!-- 2024.04.15 MCS)SAITO MOD END -->
                  {{ item.str_login }}
                </span>
              </span>
              <span v-else class="red--text"> 不明 </span>
            </template>
            <!-- 初期パスワード -->
            <template v-slot:[`item.str_password`]="{ item }">
              <span v-if="item.str_password != ''">
                <span :class="checkPassword(item.str_password) == '' ? 'black--text' : 'red--text'">
                  {{ item.str_password }}
                </span>
              </span>
              <span v-else class="red--text"> 不明 </span>
            </template>
            <!-- メールアドレス -->
            <template v-slot:[`item.str_mail`]="{ item }">
              <span :class="checkMail(item.str_mail) == '' ? 'black--text' : 'red--text'">
                {{ item.str_mail }}
              </span>
            </template>
            <!-- 内線 -->
            <template v-slot:[`item.str_naisen`]="{ item }">
              <span :class="checkNaisen(item.str_naisen) == '' ? 'black--text' : 'red--text'">
                {{ item.str_naisen }}
              </span>
            </template>
            <!-- エラー箇所 -->
            <template v-slot:[`item.error`]="{ item }">
              <span :class="item.integrity == true ? 'black--text' : 'red--text'">
                {{ item.error }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>

        <!-- ボタン群 -->
        <v-col align="right">
          <v-btn height="40" width="70" color="blue darken-1" text @click="btnImport()" v-if="import_list.length != 0" :disabled="isImport">登録</v-btn>
          <v-btn height="40" width="70" color="blue darken-1" text @click="importClear()" :disabled="isImport">閉じる</v-btn>
        </v-col>
      </v-card>
    </v-dialog>
    <!-- *** CSVインポート用ダイアログ ここまで *** -->
  </v-container>
</template>

<script>
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "UserMaster",
  props: ["value"],
  data() {
    return {
      // msg: "",
      datas: [],
      KigyoNo: 0,
      jigyoNo: 0,
      // snackbarO: false,
      // snackbarN: false,
      isEntry: "",
      isFix: "",
      all_user: [],
      // フラグ
      change_flg: false,
      insertflg: true,
      usercd_flg: true,
      //エラー表示時間
      // timeout: 3000,
      //ポップアップ表示、非表示
      dialog: false,
      //初期化
      jigyosyos: [],
      jigyosyo: "",
      setjigyono: "",
      name: "",
      kananame: "",
      bushos: [],
      busho: "",
      setbushono: "",
      id: "",
      password: "",
      checkbox: false,
      checkbox2: true,
      riyocode: "",
      mail: "",
      naisen: "",
      // フィルタ
      search: "",
      jigyo_search: 0,
      // CSVインポート
      dialog_import: false,
      selectedFile: null,
      import_list: [],
      isImport: false,
      // 2024.04.15 MCS)SATIO ADD START
      import_user_list: [],
      // 2024.04.15 MCS)SATIO ADD END
      //エラーメッセージ
      select: (value) => !!value || "選択してください。",
      required: (value) => !!value || "必ず入力してください。",
      kana: (value) => /^[ァ-ヶー\s]*$/.test(value) || ("氏名カナはカナ文字を入力してください。" && /^[ｦ-ﾟ\s]*$/.test(value)) || "氏名カナはカナ文字を入力してください。",
      iderror: (value) => /^[a-zA-Z0-9_.+-]*$/.test(value) || "ログインIDは英数字を入力してください。",
      passerror: (value) => /^[a-zA-Z0-9_.+-]*$/.test(value) || "パスワードは英数字を入力してください。",
      usererror: (value) => /^[a-zA-Z0-9_.+-]*$/.test(value) || "利用者コードは英数字を入力してください。",
      telephone: (value) => /^[0-9-]*$/.test(value) || "内線は半角数字を入力してください。",
    };
  },
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    //入力ダイアログ表示、非表示
    dialogdispE(dispflg) {
      if (dispflg) {
        this["isEntry"] = false;
        this["isFix"] = true;
        this["dialog"] = true;
      } else {
        this["dialog"] = false;
      }
    },
    dialogdispF(dispflg) {
      if (dispflg) {
        this["isEntry"] = true;
        this["isFix"] = false;
        this["dialog"] = true;
        this.resetValidation();
      } else {
        this["dialog"] = false;
      }
    },

    //検証エラーリセット
    resetValidation() {
      this.jigyosyo = "";
      this.name = "";
      this.kananame = "";
      this.busho = "";
      this.id = "";
      this.password = "";
      this.riyocode = "";
      this.mail = "";
      this.naisen = "";
      this.usercd_flg = true;
      this.insertflg = true;
      this.checkbox = true;
      this.$nextTick(() => {
        if (this.$refs["select_form"]) {
          this.$refs.select_form.resetValidation();
        }
      });
    },

    //テーブル行選択時
    clickRow(row) {
      this.jigyosyo = row["num_jigyo_no"];
      this.setjigyono = row["num_jigyo_no"];
      this.name = row["str_user_name"];
      this.kananame = row["str_user_kana"];
      this.busho = row["num_busho_no"];
      this.setbushono = row["num_busho_no"];
      this.id = row["str_login"];
      this.password = "";
      this.riyocode = row["str_user_cd"];
      this.mail = row["str_mail"];
      this.naisen = row["str_naisen"];
      this.usercd_flg = false;
      this.insertflg = false;
      this.checkbox = false;
      this.dialogdispE(true);
    },
    async btn_insert() {
      //入力チェック(フォーム)
      if (!this.$refs.select_form.validate()) {
        return;
      }
      //入力チェック(利用者コード)
      if (!(await this.inputcheckcode())) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.msg = "入力された利用者コードは既に登録されています。";
        // this.snackbarN = true;
        this.$root.snackbar.showMessage("入力された利用者コードは既に登録されています。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      //入力チェック(ID)
      if (!(await this.inputcheckid())) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.msg = "入力されたログインIDは既に登録されています。";
        // this.snackbarN = true;
        this.$root.snackbar.showMessage("入力されたログインIDは既に登録されています。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      /* -- 2024.04.12 MCS)SAITO ADD START -- */
      if (!this.checkPlan(1)) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.msg = "利用者の登録数が最大アカウント数を超えています。";
        // this.snackbarN = true;
        this.$root.snackbar.showMessage("利用者の登録数が最大アカウント数を超えています。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      /* -- 2024.04.12 MCS)SAITO ADD END -- */
      this.insertriyo();
    },
    btn_update() {
      //更新処理
      if (this.$refs.select_form.validate()) {
        this.updateriyo();
      }
    },
    btn_delete() {
      //削除処理確認ダイアログ
      var result = window.confirm("削除します。よろしいですか？");
      if (result) {
        this.deleteriyo();
      } else {
        return;
      }
    },

    btn_back() {
      if (this.change_flg == true) {
        if (window.confirm("保存せずに終了します。\n入力内容は破棄されますが、よろしいですか？")) {
          this["dialog"] = false;
          this.change_flg = false;
        }
      } else {
        this["dialog"] = false;
      }
    },

    table_select() {
      this.jigyosyo = "";
      this.name = "";
      this.kananame = "";
      this.busho = "";
      this.id = "";
      this.password = "";
      this.riyocode = "";
      this.mail = "";
      this.naisen = "";
      this.usercd_flg = true;
      this.insertflg = true;
      this.checkbox = true;
      this.dialogdispF(true);
      /* -- 2024.03.26 MCS)SAITO ADD START --*/
      if (this.jigyo_search != 0) {
        this.jigyosyo = this.jigyo_search;
      }
      /* -- 2024.03.26 MCS)SAITO ADD END --*/
    },
    //利用者検索用
    async serchriyo() {
      const self = this;
      const axios = this.$axios;
      await axios
        .get("/m_user_get/riyo_list", {
          params: {
            KigyoNo: this.$store.state.kigyoCode, //セッション(Vuex)
          },
        })

        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              self.datas = d["data"];
            } else {
              // self.bushos.push("no data");
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //入力チェック(利用者コード)
    async inputcheckcode() {
      const axios = this.$axios;
      let result = await axios
        .get("/m_user_get/checkcode", {
          params: {
            KigyoNo: this.$store.state.kigyoCode, //セッション(Vuex)
            UserCd: this.riyocode,
          },
        })
        .then(function (response) {
          if (response.data.LoginCount != 0) {
            return false;
          } else {
            return true;
          }
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
      return result;
    },

    //入力チェック(ID)
    async inputcheckid() {
      const axios = this.$axios;
      let result = await axios
        .get("/m_user_get/checkid", {
          params: {
            KigyoNo: this.$store.state.kigyoCode, //セッション(Vuex)
            Id: this.id,
          },
        })
        .then(function (response) {
          if (response.data.LoginCount != 0) {
            return false;
          } else {
            return true;
          }
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
      return result;
    },

    //利用者登録用
    async insertriyo() {
      const self = this;
      const axios = this.$axios;
      const store = this.$store;
      const params = new URLSearchParams();
      params.append("KigyoNo", store.state.kigyoCode);
      params.append("num_jigyo_no", self.setjigyono);
      params.append("str_user_cd", self.riyocode);
      params.append("str_login", self.id);
      params.append("str_password", self.password);
      params.append("str_user_kana", self.kananame);
      params.append("str_user_name", self.name);
      params.append("num_busho_no", self.setbushono);
      params.append("str_mail", self.mail);
      params.append("str_naisen", self.naisen);
      params.append("str_s_date", store.state.nowDate);
      params.append("str_s_time", store.state.nowTime);
      params.append("str_s_user", store.state.userCode);
      params.append("str_s_access", "WEB");
      await axios
        .post("/m_user_post/insert", params)
        .then(function () {
          self.serchriyo();
          self.getAllUser();
          self.dialogdispE(false);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.msg = "登録しました";
          // self.snackbarO = true;
          self.$root.snackbar.showMessage("登録しました。", "success");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //利用者更新用
    async updateriyo() {
      const self = this;
      const axios = this.$axios;
      const store = this.$store;
      const params = new URLSearchParams();
      params.append("KigyoNo", store.state.kigyoCode);
      params.append("num_jigyo_no", self.setjigyono);
      params.append("str_user_cd", self.riyocode);
      params.append("str_login", self.id);
      params.append("str_password", self.password);
      params.append("str_user_kana", self.kananame);
      params.append("str_user_name", self.name);
      params.append("num_busho_no", self.setbushono);
      params.append("str_mail", self.mail);
      params.append("str_naisen", self.naisen);
      params.append("num_first_login_flg", 1);
      params.append("str_s_date", store.state.nowDate);
      params.append("str_s_time", store.state.nowTime);
      params.append("str_s_user", store.state.userCode);
      params.append("str_s_access", "WEB");
      await axios
        .post("/m_user_post/update", params)
        .then(function () {
          self.serchriyo();
          self.getAllUser();
          self.dialogdispF(false);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.msg = "更新しました";
          // self.snackbarO = true;
          self.$root.snackbar.showMessage("更新しました。", "success");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //利用者削除
    async deleteriyo() {
      const self = this;
      const axios = this.$axios;
      const store = this.$store;
      const params = new URLSearchParams();
      params.append("KigyoNo", store.state.kigyoCode);
      params.append("str_user_cd", self.riyocode);
      params.append("num_del_flg", 1);
      params.append("str_s_date", store.state.nowDate);
      params.append("str_s_time", store.state.nowTime);
      params.append("str_s_user", store.state.userCode);
      params.append("str_s_access", "WEB");
      await axios
        .post("/m_user_post/delete", params)
        .then(function () {
          self.serchriyo();
          self.getAllUser();
          self.dialogdispF(false);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.msg = "削除しました";
          // self.snackbarO = true;
          self.$root.snackbar.showMessage("削除しました。", "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //事業所検索
    async serchjigyosho() {
      const self = this;
      await this.$axios
        .get("/m_jigyo_get/jigyosyo_select", {
          params: {
            KigyoCd: this.$store.state.kigyoCode, //セッション(Vuex)
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              for (var i = 0; i < Object.keys(d["data"]).length; i++) {
                var data = d["data"][i];
                self.jigyosyos.push({
                  str_jigyo_name: data.str_jigyo_name,
                  num_jigyo_no: data.num_jigyo_no,
                });
              }
            } else {
              self.gyosyas.push("no data");
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //所属・グループ検索
    async serchbusho() {
      const self = this;
      await this.$axios
        .get("/m_busho_get/busho_select", {
          params: {
            KigyoNo: this.$store.state.kigyoCode, //セッション(Vuex)
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              for (var i = 0; i < Object.keys(d["data"]).length; i++) {
                var data = d["data"][i];
                self.bushos.push({
                  str_busho_name: data.str_busho_name,
                  num_busho_no: data.num_busho_no,
                });
              }
            } else {
              self.bushos.push("no data");
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //事業所番号取得
    async jigyono(id) {
      const self = this;
      await this.$axios;
      self.setjigyono = id;
    },

    //所属・グループ番号取得
    async bushono(id) {
      const self = this;
      await this.$axios;
      self.setbushono = id;
    },

    //利用者コードとログインIDを同期
    syncID() {
      if (this.checkbox2) {
        this.id = this.riyocode;
      }
    },

    // ランダムパスワード生成
    async generatePassword() {
      let wkPassword = "";
      const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const n = 8;
      wkPassword = Array.from(crypto.getRandomValues(new Uint32Array(n)))
        .map((v) => S[v % S.length])
        .join("");
      this.password = wkPassword;
    },

    // 全ユーザー取得処理
    async getAllUser() {
      const KigyoCd = this.$store.state.kigyoCode;
      this.all_user = await this.$axios
        .get("/m_user_get/all_user" + "?KigyoCd=" + KigyoCd)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    // 登録ボタン押下（CSVインポート）
    async btnImport() {
      let err_count = 0;
      // データチェック
      if (this.import_list.some((i) => i.integrity == false)) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.msg = "CSVデータにエラーがあります。修正してからもう一度お試しください。";
        // this.snackbarN = true;
        this.$root.snackbar.showMessage("CSVデータにエラーがあります。修正してからもう一度お試しください。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      /* -- 2024.04.12 MCS)SAITO ADD START -- */
      if (!this.checkPlan(this.import_list.length)) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.msg = "利用者の登録数が最大アカウント数を超えています。";
        // this.snackbarN = true;
        this.$root.snackbar.showMessage("利用者の登録数が最大アカウント数を超えています。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      /* -- 2024.04.12 MCS)SAITO ADD END -- */
      // 登録処理
      this.isImport = true;
      for (var i = 0; i < this.import_list.length; i++) {
        const JigyoNo = this.import_list[i].num_jigyo_no;
        const UserCd = this.import_list[i].str_user_cd;
        const LoginId = this.import_list[i].str_login;
        const Password = this.import_list[i].str_password;
        const UserKana = this.import_list[i].str_user_kana;
        const UserName = this.import_list[i].str_user_name;
        const BushoNo = this.import_list[i].num_busho_no;
        const Mail = this.import_list[i].str_mail;
        const Naisen = this.import_list[i].str_naisen;
        if (!(await this.importCSV(JigyoNo, UserCd, LoginId, Password, UserKana, UserName, BushoNo, Mail, Naisen))) {
          err_count += 1;
        }
      }
      this.isImport = false;
      // エラー
      if (err_count != 0) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.msg = err_count + "件のエラーが発生したため、再度登録ボタンを押してください。";
        // this.snackbarN = true;
        this.$root.snackbar.showMessage(err_count + "件のエラーが発生したため、再度登録ボタンを押してください。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 正常
      /* -- 2024.05.08 MCS)KONNO MOD START --*/
      // this.msg = "登録しました。";
      // this.snackbarO = true;
      this.$root.snackbar.showMessage("登録しました。", "success");
      /* -- 2024.05.08 MCS)KONNO MOD END --*/
      // 処理終了
      this.importClear();
      // 利用者一覧再取得
      await this.serchriyo();
      await this.getAllUser();
    },

    // CSVインポート処理
    async importCSV(JigyoNo, UserCd, LoginId, Password, UserKana, UserName, BushoNo, Mail, Naisen) {
      const params = new URLSearchParams();
      params.append("KigyoCd", this.$store.state.kigyoCode);
      params.append("JigyoNo", JigyoNo);
      params.append("UserCd", UserCd);
      params.append("LoginId", LoginId);
      params.append("Password", Password);
      params.append("UserKana", UserKana);
      params.append("UserName", UserName);
      params.append("BushoNo", BushoNo);
      params.append("Mail", Mail);
      params.append("Naisen", Naisen);
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      let result = await this.$axios
        .post("/m_user_post/insert_csv", params)
        .then((response) => {
          return response.status == 200;
        })
        .catch((error) => {
          console.log(error.response.data.error);
          return false;
        });
      return result;
    },

    // CSVテンプレートダウンロード
    downloadCSV() {
      let csv = "\ufeff" + "利用者コード（必須）,事業所番号（必須）,所属・グループ番号（必須）,氏名（必須）,氏名カナ（必須）,ログインID（必須）,初期パスワード（必須）,メールアドレス,内線\n";
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "利用者登録用CSV.csv";
      link.click();
    },

    // 閉じる（CSVインポート）
    importClear() {
      this.dialog_import = false;
      this.selectedFile = null;
    },

    // CSV変換処理
    async csvToArray(file) {
      let array = await file.text().then((csv) => {
        const array = csv.trim().split("\n");
        // const header = array[0].split(",");
        const header = ["str_user_cd", "num_jigyo_no", "num_busho_no", "str_user_name", "str_user_kana", "str_login", "str_password", "str_mail", "str_naisen"];
        const body = array.slice(1).map((arr) => arr.split(","));
        /* -- 2024.04.15 MCS)SAITO ADD START -- */
        this.import_user_list = body;
        /* -- 2024.04.15 MCS)SAITO ADD END -- */
        /* -- 2024.04.15 MCS)SAITO MOD START -- */
        // const wkArray = body.map((b) => {
        const wkArray = body.map((b, index) => {
          /* -- 2024.04.15 MCS)SAITO MOD END -- */
          let result = {};
          /* -- 2024.04.15 MCS)SAITO MOD START -- */
          // header.forEach((head, index) => {
          //   result[[head]] = String(b[index]).trim();
          // });
          header.forEach((head, idx) => {
            result[[head]] = String(b[idx]).trim();
          });
          /* -- 2024.04.15 MCS)SAITO MOD END -- */
          let error_msg = "";
          error_msg += this.checkUserCd(result["str_user_cd"]);
          error_msg += this.checkJigyoCd(result["num_jigyo_no"]);
          error_msg += this.checkBushoCd(result["num_busho_no"]);
          error_msg += this.checkUserName(result["str_user_name"]);
          error_msg += this.checkUserKana(result["str_user_kana"]);
          error_msg += this.checkLoginID(result["str_login"], index);
          error_msg += this.checkPassword(result["str_password"]);
          error_msg += this.checkMail(result["str_mail"]);
          error_msg += this.checkNaisen(result["str_naisen"]);
          result["error"] = error_msg != "" ? error_msg.trim() : "なし";
          result["integrity"] = error_msg == "";
          return result;
        });
        return wkArray;
      });
      /* -- 2024.04.15 MCS)SAITO ADD START -- */
      this.list_index = 0;
      /* -- 2024.04.15 MCS)SAITO ADD END -- */
      return array;
    },

    // 利用者コードチェック
    checkUserCd(UserCd) {
      let msg = "";
      if (UserCd == "") {
        msg += "・利用者コード未入力\r\n";
      }
      if (!/^[a-zA-Z0-9_.+-]*$/.test(UserCd)) {
        msg += "・利用者コード文字種（半角英数のみ）\r\n";
      }
      if (this.all_user.some((i) => i.str_user_cd == UserCd)) {
        msg += "・利用者コード重複\r\n";
      }
      return msg;
    },

    // 事業所チェック
    checkJigyoCd(JigyoCd) {
      let msg = "";
      if (JigyoCd == "") {
        msg += "・事業所番号未入力\r\n";
      }
      if (!this.jigyosyos.some((i) => i.num_jigyo_no == JigyoCd)) {
        msg += "・事業所未登録\r\n";
      }
      return msg;
    },

    // 所属・グループチェック
    checkBushoCd(BushoCd) {
      let msg = "";
      if (BushoCd == "") {
        msg += "・所属・グループ番号未入力\r\n";
      }
      if (!this.bushos.some((i) => i.num_busho_no == BushoCd)) {
        msg += "・所属・グループ未登録\r\n";
      }
      return msg;
    },

    // 氏名チェック
    checkUserName(UserName = "") {
      let msg = "";
      if (UserName == "") {
        return "・氏名未入力\r\n";
      }
      return msg;
    },

    // 氏名カナチェック
    checkUserKana(UserKana) {
      let msg = "";
      if (UserKana == "") {
        msg += "・氏名カナ未入力\r\n";
      }
      if (!/^[ァ-ヶー\s]*$/.test(UserKana) || /^[ｦ-ﾟ\s]*$/.test(UserKana)) {
        msg += "・氏名カナ文字種（全角カナのみ）\r\n";
      }
      return msg;
    },

    // ログインIDチェック
    checkLoginID(LoginID, index) {
      let msg = "";
      if (LoginID == "") {
        msg += "・ログインID未入力\r\n";
      }
      if (!/^[a-zA-Z0-9_.+-]*$/.test(LoginID)) {
        msg += "・ログインID文字種（半角英数のみ）\r\n";
      }
      /* -- 2024.04.15 MCS)SAITO MOD START -- */
      // if (this.datas.some((i) => i.str_login == LoginID)) {
      //   msg += "・ログインID重複\r\n";
      // }
      if (this.datas.some((i) => i.str_login == LoginID) || !this.checkCSV(LoginID, index)) {
        msg += "・ログインID重複\r\n";
      }
      /* -- 2024.04.15 MCS)SAITO MOD END -- */
      return msg;
    },

    /* -- 2024.04.15 MCS)SAITO ADD START -- */
    //CSVの重複チェック
    checkCSV(value, idx) {
      var result = true;
      // ログインID
      this.import_user_list.map((val, index) => {
        if (index != idx && val[5] == value) {
          result = false;
        }
      });
      return result;
    },
    /* -- 2024.04.15 MCS)SAITO ADD END -- */

    // パスワードチェック
    checkPassword(Password) {
      let msg = "";
      if (Password == "") {
        msg += "・初期パスワード未入力\r\n";
      }
      if (!/^[a-zA-Z0-9_.+-]*$/.test(Password)) {
        msg += "・初期パスワード文字種（半角英数以外）\r\n";
      }
      return msg;
    },

    // メールアドレスチェック
    checkMail(Mail) {
      let msg = "";
      if (Mail != "") {
        if (!Mail.match(/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/)) {
          msg += "・メールアドレス形式\r\n";
        }
      }
      return msg;
    },

    // 内線チェック
    checkNaisen(Naisen) {
      let msg = "";
      if (Naisen != "") {
        if (!/^[0-9-]*$/.test(Naisen)) {
          msg += "・内線文字種（半角数字のみ）\r\n";
        }
      }
      return msg;
    },

    // プランチェック
    checkPlan(UserCount) {
      /* -- 2024.05.15 MCS)KONNO MOD START -- */
      // switch (this.$store.state.kigyoPlan) {
      //   case 1:
      //     //プラン10
      //     if (this.datas.length + UserCount > 10) {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   case 2:
      //     //プラン50
      //     if (this.datas.length + UserCount > 50) {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   case 3:
      //     //プラン100
      //     if (this.datas.length + UserCount > 100) {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   default:
      //     return true;
      // }
      if (this.datas.length + UserCount > Number(this.$store.state.kigyoPlan)) {
        return false;
      } else {
        return true;
      }
      /* -- 2024.05.15 MCS)KONNO MOD END -- */
    },
  },
  async created() {
    await this.serchriyo();
    await this.getAllUser();
    await this.serchjigyosho();
    await this.serchbusho();
  },

  computed: {
    headers() {
      return [
        {
          text: "利用者コード",
          sortable: true,
          value: "str_user_cd",
          align: "start",
          width: "130px",
        },
        {
          text: "氏名",
          sortable: true,
          value: "str_user_name",
          align: "start",
          width: "125px",
        },
        {
          text: "氏名カナ",
          sortable: true,
          value: "str_user_kana",
          align: "start",
          width: "150px",
        },
        {
          text: "事業所",
          sortable: true,
          value: "str_jigyo_name",
          align: "start",
          width: "150px",
          filter: (value) => {
            if (this.jigyo_search == 0) return true;
            return value == this.jigyosyos.find((i) => i.num_jigyo_no == this.jigyo_search).str_jigyo_name;
          },
        },
        {
          text: "所属・グループ",
          sortable: true,
          value: "str_busho_name",
          align: "start",
          width: "160px",
        },
        {
          text: "ログインID",
          sortable: true,
          value: "str_login",
          align: "start",
          width: "120px",
        },
        {
          text: "メールアドレス",
          sortable: true,
          value: "str_mail",
          align: "start",
          width: "160px",
        },
        {
          text: "内線",
          sortable: true,
          value: "str_naisen",
          align: "start",
          width: "100px",
        },
      ];
    },
    headers_import() {
      return [
        {
          text: "利用者コード",
          sortable: false,
          value: "str_user_cd",
          align: "start",
          width: "120px",
        },
        {
          text: "氏名",
          sortable: false,
          value: "str_user_name",
          align: "start",
          width: "125px",
        },
        {
          text: "氏名カナ",
          sortable: false,
          value: "str_user_kana",
          align: "start",
          width: "150px",
        },
        {
          text: "事業所",
          sortable: false,
          value: "str_jigyo_name",
          align: "start",
          width: "150px",
        },
        {
          text: "所属・グループ",
          sortable: false,
          value: "str_busho_name",
          align: "start",
          width: "160px",
        },
        {
          text: "ログインID",
          sortable: false,
          value: "str_login",
          align: "start",
          width: "100px",
        },
        {
          text: "初期パスワード",
          sortable: false,
          value: "str_password",
          align: "start",
          width: "160px",
        },
        {
          text: "メールアドレス",
          sortable: false,
          value: "str_mail",
          align: "start",
          width: "160px",
        },
        {
          text: "内線",
          sortable: false,
          value: "str_naisen",
          align: "start",
          width: "100px",
        },
        {
          text: "エラーメッセージ",
          sortable: false,
          value: "error",
          align: "start",
          width: "200px",
        },
      ];
    },
    mailerror() {
      if (this.mail != "") {
        return [(value) => /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(value) || "メールアドレスは半角英数字または半角記号を入力してください。"];
      } else {
        return [];
      }
    },
    filter_jigyo() {
      let wkArray = [];
      let allSelect = [{ num_jigyo_no: 0, str_jigyo_name: "すべて" }];
      return wkArray.concat(allSelect, this.jigyosyos);
    },
  },
  watch: {
    selectedFile: async function (val) {
      if (val != null) {
        this.import_list = await this.csvToArray(val);
      } else {
        this.import_list = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "./src/assets/css/TraderMaster.scss";
</style>
