<template>
  <v-main>
    <!-- ヘッダー -->
    <MainHeader />
    <!-- 画面コンポーネント -->
    <router-view />
    <!-- フッター -->
    <MainFooter />
  </v-main>
</template>

<script>
import MainHeader from "../components/common/MainHeader";
import MainFooter from "../components/common/MainFooter";

export default {
  components: {
    MainHeader,
    MainFooter,
  },
};
</script>
