<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：ManualList.vue
  モジュール名：マニュアル一覧画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <!-- タイトル -->
    <title-card title="マニュアル一覧"> </title-card>
    <!-- データ -->
    <data-card>
      <v-sheet class="pa-2">
        <v-card v-for="manual in manual_list" :key="manual.title" :href="manual.url" target="_blank" class="mb-2" outlined>
          <v-card-title>{{ manual.title }}<v-spacer /><v-icon>open_in_new</v-icon></v-card-title>
          <v-card-text>開くにはこちらをクリック（新しいタブが開きます）</v-card-text>
        </v-card>
      </v-sheet>
    </data-card>
    <!-- *** 表示部 ここまで *** -->
  </v-container>
</template>

<script>
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "ManualList",
  data() {
    return {
      manual_list: [
        {
          title: "利用者版",
          url: "https://befosc.com/manual/Befosc_manual_user_v2.pdf",
        },
        {
          title: "管理者版",
          url: "https://befosc.com/manual/Befosc_manual_master_v3.pdf",
        },
        {
          title: "クライアントアプリ版",
          url: "https://befosc.com/manual/Befosc_manual_client_v2.pdf",
        },
        {
          title: "スマホアプリ版",
          url: "https://befosc.com/manual/Befosc_manual_smartphone_v2.pdf",
        },
      ],
    };
  },
  components: {
    TitleCard,
    DataCard,
  },
};
</script>
