<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：EmailSend.vue
  モジュール名：一斉メール送信画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <title-card title="一斉メール送信">
      <v-btn color="teal" dark depressed @click="showPreview()">送信</v-btn>
    </title-card>
    <data-card>
      <v-container @change="change_flg = true" fluid>
        <v-form ref="select_form">
          <!-- 件名入力欄 -->
          <div class="text--primary">
            <span style="vertical-align: middle">件名</span>
            <span class="red--text">*</span>
          </div>
          <v-text-field single-line outlined dense v-model="Title" ref="title" :rules="[required]" maxlength="60" counter="60" @click="chkCursorTitle()"></v-text-field>

          <!-- 本文入力欄 -->
          <div class="text--primary">
            <span style="vertical-align: middle">本文</span>
            <span class="red--text">*</span>
          </div>
          <v-textarea single-line outlined rows="14" v-model="Honbun" ref="honbun" :rules="[required]" maxlength="3000" counter="3000" @click="chkCursorHonbun()"></v-textarea>

          <!-- 差し込み機能 -->
          <v-sheet>
            <div>差し込み機能&nbsp;<small class="grey--text">※メール送信時に注文内容に合わせて置き換えられます。実際の送信内容を確認するにはプレビュー表示を押してください。</small></div>
            <div>
              <v-chip v-for="item in items" :key="item.title" class="ma-2" @click="insertCode(item.code)">{{ item.title }}</v-chip>
            </div>
          </v-sheet>
        </v-form>
      </v-container>
    </data-card>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** 送信プレビュー用ダイアログ ここから *** -->
    <v-dialog v-model="dialog_sample" max-width="800px" persistent no-click-animation>
      <v-card class="px-3">
        <v-card-title class="px-1">送信プレビュー</v-card-title>
        <v-sheet outlined>
          <v-card-text class="black--text py-2">件名：{{ "運営からのお知らせ " + Title }}</v-card-text>
        </v-sheet>
        <v-sheet outlined>
          <v-card-text class="black--text" style="white-space: pre-wrap">{{ "運営からお知らせが届きました。\n" + sample + "\n以上" }}</v-card-text>
        </v-sheet>
        <v-card-actions class="px-0">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="mail_send"> 送信 </v-btn>
          <v-btn color="blue darken-1" text @click="dialog_sample = false"> 閉じる </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *** 送信プレビュー用ダイアログ ここまで *** -->

    <!-- *** 通知領域 ここから *** -->
    <v-snackbar v-model="snackbar0" :timeout="timeout" color="green" text outlined top>
      <strong>{{ msg }}</strong>
    </v-snackbar>
    <v-snackbar v-model="snackbar1" :timeout="timeout" color="red" text outlined top>
      <strong>{{ msg }}</strong>
    </v-snackbar>
    <!-- *** 通知領域 ここまで *** -->
  </v-container>
</template>

<script>
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "App",
  data() {
    return {
      //エラーメッセージ
      msg: "",
      datas: [],
      KigyoCd: 0,
      MailtmpNo: 1,
      snackbar0: false,
      snackbar1: false,
      //エラー表示時間
      timeout: 3000,
      //ポップアップ表示、非表示
      dialog: false,
      //テキストボックス
      Title: "",
      Honbun: "",
      required: (value) => !!value || "必ず入力してください。",
      //変更フラグ
      change_flg: false,
      //ボタン判別
      BtnVal: 0,
      //登録済み
      SavedTitle: "",
      SavedHonbun: "",
      //確認ダイアログメッセージ
      message1: "",
      message2: "",
      //置き換えコード
      items: [{ title: "%企業名%", code: "%企業名%" }],
      //サンプル
      sample: "",
      //ダイアログ
      dialog_sample: false,
      //事業所情報
      jigyo_info: [],
      //テキストカーソルがあるか
      TitleCursor: false,
      HonbunCursor: false,
    };
  },
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    //プレビュー表示
    async showPreview() {
      if (this.$refs.select_form.validate()) {
        //本文置き換え
        this.sample = this.Honbun;
        // //%企業名%
        this.sample = this.sample.replace(/%企業名%/g, "○○○○○○株式会社");
        this.dialog_sample = true;
      }
    },

    //差し込みコード挿入
    insertCode(code) {
      let title;
      let honbun;
      let startPos;
      let endPos;
      if (this.TitleCursor) {
        title = this.$refs.title.$refs.input;
        startPos = title.selectionStart;
        endPos = title.selectionEnd;
        this.Title = this.Title.substring(0, startPos) + code + this.Title.substring(endPos, this.Title.length);
      }
      if (this.HonbunCursor) {
        honbun = this.$refs.honbun.$refs.input;
        startPos = honbun.selectionStart;
        endPos = honbun.selectionEnd;
        this.Honbun = this.Honbun.substring(0, startPos) + code + this.Honbun.substring(endPos, this.Honbun.length);
      }
    },

    chkCursorTitle() {
      this.TitleCursor = false;
      this.HonbunCursor = false;
      this.TitleCursor = true;
    },
    chkCursorHonbun() {
      this.TitleCursor = false;
      this.HonbunCursor = false;
      this.HonbunCursor = true;
    },

    async mail_send() {
      const self = this;
      await this.$axios
        .get("/oshirase_mail/send_mail", {
          params: {
            mail_title: self.Title,
            mail_honbun: self.Honbun,
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (d["data"] == true) {
              self.msg = "メールが送信されました。";
              self.snackbar0 = true;
            }
          });
        })
        .catch(function (error) {
          console.log(error);
          self.msg = "メールが送信できませんでした。";
          self.snackbar1 = true;
        });
      self.dialog_sample = false;
    },
  },
};
</script>
<style lang="scss" scoped>
// @import "./src/assets/css/TraderMaster.scss";
</style>
