<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：OrderSheet.vue
  モジュール名：弁当注文書（帳票）
  備考：
-------------------------------------------------->
<style lang="scss" scoped>
@import "./src/assets/css/OrderSheet.scss";
</style>
<template>
  <v-col>
    <section class="sheet">
      <label class="title text-center">弁当注文書</label>
      <label class="text-center">{{ this.display_date }}</label>

      <!-- 注文先の店舗詳細情報 -->
      <label>{{ this.gyosha_name }} 御中</label>

      <!-- 注文内容 -->
      <div style="display: flex; justify-content: center" class="mt-5">
        <table style="border-collapse: collapse">
          <tr>
            <th class="table-header-menu"><label class="tbl-header-text">品名</label></th>
            <th class="table-header-price"><label class="tbl-header-text">単価</label></th>
            <th class="table-header-counter"><label class="tbl-header-text">個数</label></th>
            <th class="table-header-total"><label class="tbl-header-text">金額</label></th>
          </tr>
          <tbody>
            <tr v-if="this.orderlist.length > 0">
              <td>{{ this.orderlist[0].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[0].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[0].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[0].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="this.orderlist.length > 1">
              <td>{{ this.orderlist[1].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[1].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[1].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[1].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="this.orderlist.length > 2">
              <td>{{ this.orderlist[2].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[2].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[2].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[2].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="this.orderlist.length > 3">
              <td>{{ this.orderlist[3].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[3].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[3].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[3].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="this.orderlist.length > 4">
              <td>{{ this.orderlist[4].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[4].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[4].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[4].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="this.orderlist.length > 5">
              <td>{{ this.orderlist[5].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[5].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[5].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[5].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="this.orderlist.length > 6">
              <td>{{ this.orderlist[6].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[6].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[6].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[6].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="this.orderlist.length > 7">
              <td>{{ this.orderlist[7].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[7].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[7].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[7].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="this.orderlist.length > 8">
              <td>{{ this.orderlist[8].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[8].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[8].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[8].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="this.orderlist.length > 9">
              <td>{{ this.orderlist[9].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[9].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[9].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[9].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="this.orderlist.length > 10">
              <td>{{ this.orderlist[10].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[10].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[10].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[10].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="this.orderlist.length > 11">
              <td>{{ this.orderlist[11].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[11].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[11].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[11].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="this.orderlist.length > 12">
              <td>{{ this.orderlist[12].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[12].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[12].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[12].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="this.orderlist.length > 13">
              <td>{{ this.orderlist[13].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[13].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[13].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[13].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
            <tr v-if="this.orderlist.length > 14">
              <td>{{ this.orderlist[14].str_bento_name }}</td>
              <td class="text-right">{{ this.orderlist[14].num_kingaku }}</td>
              <td class="text-right">{{ this.orderlist[14].num_chumonsu }}</td>
              <td class="text-right">{{ this.orderlist[14].total_kingaku }}</td>
            </tr>
            <tr v-else>
              <td></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
              <td class="text-right"></td>
            </tr>
          </tbody>
          <tr>
            <td class="tbl-border-noborder"></td>
            <th colspan="2">計</th>
            <td class="text-right">{{ this.total_kingaku.toLocaleString() }}</td>
          </tr>
        </table>
      </div>

      <!-- 注文元の詳細情報 -->
      <div style="display: flex; justify-content: right" class="mt-10">
        <table style="border-collapse: collapse">
          <tr>
            <th class="table-header">会社名</th>
            <td class="tbl-header-data">{{ this.kigyo_name }}</td>
          </tr>
          <tr>
            <th class="table-header">住所</th>
            <td class="tbl-header-data">{{ this.address }}</td>
          </tr>
          <tr>
            <th class="table-header">電話</th>
            <td class="tbl-header-data">{{ this.tel }}</td>
          </tr>
          <tr>
            <th class="table-header">FAX</th>
            <td class="tbl-header-data">{{ this.fax }}</td>
          </tr>
        </table>
      </div>
    </section>
  </v-col>
</template>

<script>
import moment from "moment";
export default {
  props: {
    today: {
      type: String,
      required: false,
    },
    gyosha_data: {
      type: Array,
      required: false,
    },
    jigyo_data: {
      type: Number,
      required: false,
    },
  },
  name: "MenuCapture",
  data() {
    return {
      date_data: "",
      display_date: "",
      gyosha_name: "",
      kigyo_name: "",
      address: "",
      tel: "",
      fax: "",
      orderlist: [],
      total_kingaku: 0,
      jigyo_no: 0,
    };
  },
  methods: {
    change() {
      this.date_data = moment(this.today).format("YYYYMMDD");
      this.display_date = moment(this.today).format("YYYY年MM月DD日");
      this.gyosha_name = this.gyosha_data[0].str_gyosha_name;
      this.jigyo_no = this.gyosha_data[0].num_jigyo_no;
      this.kigyo_name = this.$store.state.kigyoName;
    },
    total() {
      let count = this.orderlist.length;
      //合計計算
      for (var l = 0; l < count; l++) {
        this.total_kingaku += Number(this.orderlist[l].num_chu_gokei);
      }
    },
    async serchdata() {
      const self = this;
      const axios = this.$axios;
      const store = this.$store;
      await axios
        .get("/t_chu_jokyo_get/order_fax", {
          params: {
            KigyoCd: store.state.kigyoCode, //セッション(Vuex)
            JigyoNo: self.jigyo_no,
            Chumonbi: this.date_data,
            GyoshaNo: this.gyosha_data[0].num_gyosha_no,
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              self.orderlist = d["data"];
              console.log(self.orderlist);
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async serchjigyo() {
      const self = this;
      const axios = this.$axios;
      const store = this.$store;
      await axios
        .get("/m_jigyo_get/jigyosyo_fax", {
          params: {
            KigyoCd: store.state.kigyoCode, //セッション(Vuex)
            JigyoNo: this.jigyo_no,
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then((d) => {
            if (Object.keys(d["data"]).length != 0) {
              self.address = d["data"][0].str_address;
              self.tel = d["data"][0].str_tel1 + " ";
              if (d["data"].str_tel2 != null) {
                self.tel += "/ " + d["data"][0].str_tel2;
              }
              self.fax = d["data"][0].str_fax;
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  async mounted() {
    await this.change();
    await this.serchdata();
    await this.total();
    await this.serchjigyo();
  },
};
</script>
