<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：SimebiUpdate.vue
  モジュール名：締日登録画面
  備考：
-------------------------------------------------->
<template>
  <v-container class="d-print-none" fluid>
    <!-- *** 表示部 ここから *** -->
    <title-card title="締日登録"> </title-card>
    <data-card>
      <v-form ref="select_form">
        <v-sheet class="px-4 py-2">
          <div class="text--primary">
            <span style="vertical-align: middle">月の締日を選択してください。</span>
          </div>
          <v-select outlined dense v-model="selected" :items="simedays" @change="update()" style="width: 200px" backgroundColor="white" no-data-text="データがありません。"></v-select>
          <div>※選択後、自動更新されます</div>
        </v-sheet>
      </v-form>
    </data-card>
    <!-- *** 表示部 ここまで *** -->
  </v-container>
</template>

<script>
// import moment from "moment";
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "SimebiUpdate",
  data() {
    return {
      // msg: "",
      simedays: ["末日", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"],
      selected: "末日",
      // snackbar: false,
      // snackbar_color: "",
      //エラー表示時間
      // timeout: 3000,
    };
  },
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    //更新処理消込
    async update() {
      const self = this;
      const axios = this.$axios;
      const store = this.$store;
      const params = new URLSearchParams();
      if (this.selected == "末日") {
        params.append("Simebi", "0");
      } else {
        params.append("Simebi", this.selected);
      }
      params.append("tKigyoCd", store.state.kigyoCode);
      params.append("sUser", store.state.userCode);
      params.append("sAccess", "WEB");

      await axios
        .post("/m_kigyo_post/update_simebi", params)
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (d.msg == "UPDATE OK") {
              /* -- 2024.05.08 MCS)KONNO MOD START --*/
              // self.snackbar_color = "safe";
              // self.msg = "更新しました。";
              // self.snackbar = true;
              self.$root.snackbar.showMessage("更新しました。", "success");
              /* -- 2024.05.08 MCS)KONNO MOD END --*/
              self.searchsimebi();
            }
          });
        })
        .catch(function (error) {
          console.log(error);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.snackbar_color = "error";
          // self.msg = "更新できませんでした。";
          // self.snackbar = true;
          self.$root.snackbar.showMessage("更新できませんでした。", "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        });
    },

    //締日取得
    async searchsimebi() {
      const self = this;
      //注文場所を指定して検索
      await this.$axios
        .get("/m_kigyo_get/info", {
          params: {
            KigyoCd: this.$store.state.kigyoCode, //セッション(Vuex)
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              var data = d["data"];
              if (data.num_simebi === null || data.num_simebi == 0) {
                self.selected = "末日";
              } else {
                self.selected = data.num_simebi.toString();
              }
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },

  async created() {
    await this.searchsimebi(); //弁当業者名
  },
};
</script>
