<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：ClientApp.vue
  モジュール名：ビフォサック専用ブラウザ・ダウンロード画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <!-- タイトル -->
    <title-card title="ビフォサック専用ブラウザのダウンロード">
      <v-btn color="teal" dark depressed @click="downloadZip">ダウンロード</v-btn>
    </title-card>
    <!-- データ -->
    <!-- <data-card>
      <v-sheet class="pa-4">
      </v-sheet>
    </data-card> -->
    <!-- *** 表示部 ここまで *** -->
  </v-container>
</template>

<script>
import TitleCard from "@/components/admin/TitleCard.vue";
// import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "ClientApp",
  components: {
    TitleCard,
    // DataCard,
  },
  /*-- 2024.04.02 MCS)SAITO ADD START --*/
  methods: {
    downloadZip() {
      try {
        const url = "https://github.com/mcs-future-com/BefoscClient/archive/refs/heads/main.zip";
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "main.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } catch (error) {
        console.log(error);
      }
    },
  },
  /*-- 2024.04.02 MCS)SAITO ADD END --*/
};
</script>
