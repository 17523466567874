<template>
  <footer class="text-center my-4" style="position: sticky; top: 100vh">
    <small class="text">{{ copyright }}</small>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      copyright: "Copyright © 弁当注文ビフォサック All Rights Reserved.",
    };
  },
};
</script>
