import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import css from "./assets/css/style.css";
import VueMeta from "vue-meta";

Vue.use(VueMeta);
Vue.config.productionTip = false;

Vue.use({
  install(Vue) {
    Vue.prototype.$axios = axios.create({
      baseURL: `${process.env.VUE_APP_API_ORIGIN}/`,
    });
  },
});

new Vue({
  router,
  store,
  vuetify,
  css,
  render: (h) => h(App),
}).$mount("#app");
