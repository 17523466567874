<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：OrderReservation.vue
  モジュール名：注文予約画面
  備考：
-------------------------------------------------->
<template>
  <v-container class="pa-0 d-flex" fluid>
    <!-- *** 表示部 ここから *** -->
    <!-- カレンダーパネル -->
    <v-sheet width="calc(100vw - 340px)">
      <!-- ヘッダ -->
      <v-sheet tile height="64px" class="d-flex align-center overflow-hidden" color="grey lighten-4">
        <v-btn depressed outlined color="grey darken-1" class="ma-4" @click="setToday"> 今日 </v-btn>
        <v-btn icon @click="$refs.calendar.prev()">
          <v-icon>chevron_left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ fmtDate_YYYYM(calendar) }}</v-toolbar-title>
        <v-btn icon @click="$refs.calendar.next()">
          <v-icon>chevron_right</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="mx-5" style="white-space: nowrap">{{ fmtDate_M(calendar) }}の合計金額&#65306;{{ fmtPrice(price_monthly) }}円</span>
      </v-sheet>
      <!-- カレンダー -->
      <v-sheet height="calc(100vh - 152px)" width="calc(100% - 1px)">
        <v-calendar ref="calendar" v-model="calendar" :events="comp_calendar" locale="ja-jp" :day-format="(timestamp) => new Date(timestamp.date).getDate()" :month-format="(timestamp) => new Date(timestamp.date).getMonth() + 1 + ' /'" :event-ripple="false" :now="select_date" @change="changeCalendar" @click:date="selectDate" @click:day="selectDate" event-more-text="他{0}件"></v-calendar>
      </v-sheet>
    </v-sheet>

    <v-divider style="z-index: 2" vertical></v-divider>

    <!-- 予約一覧 -->
    <v-sheet width="340px">
      <v-sheet class="d-flex align-center px-3" color="grey lighten-4" height="64px">
        <div class="mx-auto">{{ fmtDate_YYYYMD(select_date) }}の予約一覧</div>
      </v-sheet>

      <v-divider class="my-0"></v-divider>

      <v-sheet height="calc(100dvh - 266px)" class="overflow-y-auto py-2" color="grey lighten-5">
        <v-container>
          <span v-show="order_list_daily.length == 0">注文予約が登録されていません</span>
          <v-row>
            <v-col v-for="(order, i) in order_list_daily" :key="i" cols="12" class="pt-0 pb-2">
              <v-card class="px-3 py-3" @click="selectOrder(i)" outlined>
                <v-row>
                  <v-col class="pb-0">
                    <div style="display: flex">
                      <div class="d-flex align-center" style="width: calc(100% - 36px)">
                        <div>
                          <div>注文場所：{{ order.str_jigyo_name }}</div>
                          <div>業&emsp;&emsp;者：{{ order.str_gyosha_name }}</div>
                          <div>弁&emsp;&emsp;当：{{ order.str_bento_name }} {{ order.char_kosuu }}個</div>
                        </div>
                      </div>
                      <div style="width: 36px" v-show="order_list_daily[i].str_chumonbi >= $store.state.nowDate">
                        <v-btn icon @click.stop="deleteOrder(i)">
                          <v-icon>delete_outline</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <div align="right">合計&nbsp;{{ order.char_goukei }}円</div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>

      <v-divider class="my-0"></v-divider>

      <!-- ボタン -->
      <v-list height="112px" class="py-2 align-items-center overflow-hidden">
        <!-- 予約 -->
        <v-list-item>
          <v-list-item-content class="pa-0">
            <v-btn color="primary" @click="openOrder" :disabled="fmtDate_YYYYMMDD(select_date) < $store.state.nowDate"> 予約登録 </v-btn>
          </v-list-item-content>
        </v-list-item>

        <!-- まとめて予約 -->
        <v-list-item>
          <v-list-item-content class="pa-0">
            <v-btn color="success" @click="openOrder_mult" :disabled="fmtDate_YYYYMMDD(select_date) < $store.state.nowDate"> まとめて予約登録 </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-sheet>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** メニュー用ダイアログ（単日予約） ここから *** -->
    <v-dialog v-model="dialog_menu" max-width="600px" persistent no-click-animation>
      <v-card>
        <v-toolbar flat color="orange lighten-1" dark>
          <v-toolbar-title>{{ comp_gyosha_name }}</v-toolbar-title>
        </v-toolbar>
        <!-- メニュー表のみ -->
        <v-card-text class="py-0">
          <v-data-table :headers="menu_headers" :items="bento_list" :items-per-page="5" @click:row="selectMenu" sort-by="num_bentono" no-data-text="弁当情報がありません。"></v-data-table>
        </v-card-text>
        <!-- メニュー表 & 献立表 -->
        <!-- <v-tabs fixed-tabs color="orange lighten-1">
          <v-tab v-for="tab in tab_list" :key="tab">
            {{ tab }}
          </v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text class="py-0">
                <v-data-table :headers="menu_headers" :items="bento_list" :items-per-page="5" @click:row="selectMenu" sort-by="num_bentono" no-data-text="弁当情報がありません。"></v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>画像</p>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs> -->
        <!-- ボタン -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_menu = false"> 閉じる </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *** メニュー用ダイアログ（単日予約） ここまで *** -->

    <!-- *** メニュー用ダイアログ（まとめて予約） ここから *** -->
    <v-dialog v-model="dialog_menu_mult" max-width="600px" persistent no-click-animation>
      <v-card>
        <v-toolbar flat color="orange lighten-1" dark>
          <v-toolbar-title>{{ comp_gyosha_name_mult }}</v-toolbar-title>
        </v-toolbar>
        <!-- メニュー表のみ -->
        <v-card-text class="py-0">
          <v-data-table :headers="menu_headers" :items="bento_list_mult" :items-per-page="5" @click:row="selectMenu_mult" sort-by="num_bentono" no-data-text="弁当情報がありません。"></v-data-table>
        </v-card-text>
        <!-- メニュー表 & 献立表 -->
        <!-- <v-tabs fixed-tabs color="orange lighten-1">
          <v-tab v-for="tab in tab_list" :key="tab">
            {{ tab }}
          </v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text class="py-0">
                <v-data-table :headers="menu_headers" :items="bento_list_mult" :items-per-page="5" @click:row="selectMenu_mult" sort-by="num_bentono" no-data-text="弁当情報がありません。"></v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>画像</p>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs> -->
        <!-- ボタン -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_menu_mult = false"> 閉じる </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *** メニュー用ダイアログ（まとめて予約） ここまで *** -->

    <!-- *** メニュー用ダイアログ（修正・削除） ここから *** -->
    <v-dialog v-model="dialog_menu_edit" max-width="600px" persistent no-click-animation>
      <v-card>
        <v-toolbar flat color="orange lighten-1" dark>
          <v-toolbar-title>{{ comp_gyosha_name_edit }}</v-toolbar-title>
        </v-toolbar>
        <!-- メニュー表のみ -->
        <v-card-text class="py-0">
          <v-data-table :headers="menu_headers" :items="bento_list_edit" :items-per-page="5" @click:row="selectMenu_edit" sort-by="num_bentono" no-data-text="弁当情報がありません。"></v-data-table>
        </v-card-text>
        <!-- メニュー表 & 献立表 -->
        <!-- <v-tabs fixed-tabs color="orange lighten-1">
          <v-tab v-for="tab in tab_list" :key="tab">
            {{ tab }}
          </v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-text class="py-0">
                <v-data-table :headers="menu_headers" :items="bento_list_edit" :items-per-page="5" @click:row="selectMenu_edit" sort-by="num_bentono" no-data-text="弁当情報がありません。"></v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>画像</p>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs> -->
        <!-- ボタン -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_menu_edit = false"> 閉じる </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *** メニュー用ダイアログ（修正・削除） ここまで *** -->

    <!-- *** 予約用ダイアログ ここから *** -->
    <v-dialog v-model="dialog_order" max-width="600px" persistent no-click-animation>
      <v-card :loading="loading_order">
        <!-- タイトル -->
        <v-card-title>{{ fmtDate_YYYYMD(select_date) }}の予約登録</v-card-title>
        <!-- 内容 -->
        <v-card-text class="py-0">
          <v-container>
            <v-form ref="order">
              <v-row>
                <v-col cols="12">
                  <!-- 注文場所 -->
                  <div class="mx-2">
                    <div class="d-flex align-center"><v-icon>place</v-icon>&ensp;注文場所</div>
                    <v-select class="pt-1" v-model="select_jigyo" :items="jigyo_list" item-text="str_jigyo_name" item-value="num_jigyo_no" placeholder="注文場所を選択してください。" no-data-text="注文場所が登録されていません。" :rules="comp_jigyo_rule" @change="selectJigyo" outlined dense></v-select>
                  </div>

                  <!-- 弁当業者 -->
                  <div class="mx-2">
                    <div class="d-flex align-center"><v-icon>local_shipping</v-icon>&ensp;弁当業者</div>
                    <v-select class="pt-1" v-model="select_gyosha" :items="gyosha_list" item-text="str_gyosha_name" item-value="num_gyosha_no" placeholder="弁当業者を選択してください。" no-data-text="弁当業者が登録されていません。" :rules="comp_gyosha_rule" @change="selectGyosha" outlined dense></v-select>
                  </div>

                  <!-- 弁当-->
                  <div class="mx-2">
                    <div class="d-flex align-center"><v-icon>bento</v-icon>&ensp;弁当<v-spacer></v-spacer><v-btn color="orange darken-1" class="px-1" height="24" text outlined @click="dialog_menu = true"> メニュー表 </v-btn></div>
                    <v-select class="pt-1" v-model="select_bento" :items="bento_list" item-text="str_bento_name" item-value="num_bento_no" placeholder="弁当を選択してください。" no-data-text="弁当が登録されていません。" :rules="comp_bento_rule" @change="selectBento" outlined dense></v-select>
                  </div>

                  <!-- 個数 -->
                  <div class="mx-2">
                    <div class="d-flex align-center"><v-icon>tag</v-icon>&ensp;個数</div>
                    <v-select class="pt-1" v-model="select_count" :items="count_list" :rules="comp_count_rule" placeholder="個数を選択してください。" @change="selectCount" outlined dense></v-select>
                  </div>

                  <!-- 合計金額 -->
                  <div class="mx-2">
                    <h3 align="right">合計金額&#65306;{{ fmtPrice(price_order) }}円</h3>
                  </div>
                </v-col>
              </v-row>

              <v-divider class="my-4"></v-divider>

              <v-row>
                <v-col cols="6">
                  <!-- 受付状況 -->
                  <div class="pl-2">本日の受付状況</div>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">締切時間</th>
                          <th class="text-left">受付状況</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ fmtTime_HM(comp_simetime) }}</td>
                          <td>
                            <font :color="shime_color">{{ shime_state }}</font>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <!-- ボタン -->
                <v-col cols="6">
                  <v-card-actions class="mt-12 pb-0 px-0">
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="clickOrder" :disabled="loading_order"> 確定 </v-btn>
                    <v-btn color="blue darken-1" text @click="dialog_order = false" :disabled="loading_order"> 閉じる </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- *** 予約用ダイアログ ここまで *** -->

    <!-- *** まとめて予約用ダイアログ ここから *** -->
    <v-dialog v-model="dialog_order_mult" max-width="800px" persistent no-click-animation>
      <v-card :loading="loading_order_mult">
        <!-- タイトル -->
        <v-card-title>まとめて予約登録</v-card-title>
        <!-- 内容 -->
        <v-card-text class="py-0">
          <v-container>
            <v-form ref="order_mult">
              <v-row>
                <v-col cols="6">
                  <!-- 注文場所 -->
                  <div class="mx-2">
                    <div class="d-flex align-center"><v-icon>place</v-icon>&ensp;注文場所</div>
                    <v-select class="pt-1" v-model="select_jigyo_mult" :items="jigyo_list" item-text="str_jigyo_name" item-value="num_jigyo_no" placeholder="注文場所を選択してください。" no-data-text="注文場所が登録されていません。" :rules="comp_jigyo_rule" @change="selectJigyo_mult" outlined dense></v-select>
                  </div>

                  <!-- 弁当業者 -->
                  <div class="mx-2">
                    <div class="d-flex align-center"><v-icon>local_shipping</v-icon>&ensp;弁当業者</div>
                    <v-select class="pt-1" v-model="select_gyosha_mult" :items="gyosha_list_mult" item-text="str_gyosha_name" item-value="num_gyosha_no" placeholder="弁当業者を選択してください。" no-data-text="弁当業者が登録されていません。" :rules="comp_gyosha_rule" @change="selectGyosha_mult" outlined dense></v-select>
                  </div>

                  <!-- 弁当-->
                  <div class="mx-2">
                    <div class="d-flex align-center"><v-icon>bento</v-icon>&ensp;弁当<v-spacer></v-spacer><v-btn color="orange darken-1" class="px-1" height="24" text outlined @click="dialog_menu_mult = true"> メニュー表 </v-btn></div>
                    <v-select class="pt-1" v-model="select_bento_mult" :items="bento_list_mult" item-text="str_bento_name" item-value="num_bento_no" placeholder="弁当を選択してください。" no-data-text="弁当が登録されていません。" :rules="comp_bento_rule" @change="selectBento_mult" outlined dense></v-select>
                  </div>

                  <!-- 個数 -->
                  <div class="mx-2">
                    <div class="d-flex align-center"><v-icon>tag</v-icon>&ensp;個数</div>
                    <v-select class="pt-1" v-model="select_count_mult" :items="count_list" :rules="comp_count_rule" placeholder="個数を選択してください。" @change="selectCount_mult" outlined dense></v-select>
                  </div>
                </v-col>
                <v-col cols="6">
                  <!-- 開始日 -->
                  <div class="mx-2 mt-1 mb-8">
                    <div class="d-flex align-center"><v-icon>event</v-icon>&ensp;開始日</div>
                    <v-menu v-model="dialog_dtp_st" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="pt-0" :value="fmtDate_YYYYMD(select_date_st)" readonly v-bind="attrs" v-on="on" hide-details></v-text-field>
                      </template>
                      <v-date-picker v-model="select_date_st" @input="dialog_dtp_st = false" locale="jp-ja" :min="comp_min_date" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
                    </v-menu>
                  </div>
                  <!-- 終了日 -->
                  <div class="mx-2 mt-1 mb-8">
                    <div class="d-flex align-center"><v-icon>event</v-icon>&ensp;終了日</div>
                    <v-menu v-model="dialog_dtp_ed" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="pt-0" :value="fmtDate_YYYYMD(select_date_ed)" readonly v-bind="attrs" v-on="on" hide-details outline></v-text-field>
                      </template>
                      <v-date-picker v-model="select_date_ed" @input="dialog_dtp_ed = false" locale="jp-ja" :min="select_date_st" :max="comp_max_date" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
                    </v-menu>
                  </div>
                  <!-- 曜日 -->
                  <div class="mx-2 mt-1 mb-8">
                    <v-container class="px-0 py-0" fluid>
                      <div class="d-flex align-center"><v-icon>date_range</v-icon>&ensp;予約する曜日を選択</div>
                      <v-slide-group v-model="select_week" class="py-1" multiple mandatory>
                        <!-- 月 -->
                        <v-slide-item v-slot="{ active, toggle }" value="1">
                          <v-btn class="mx-1" :input-value="active" active-class="primary white--text" depressed fab small @click="toggle">月</v-btn>
                        </v-slide-item>
                        <!-- 火 -->
                        <v-slide-item v-slot="{ active, toggle }" value="2">
                          <v-btn class="mx-1" :input-value="active" active-class="primary white--text" depressed fab small @click="toggle">火</v-btn>
                        </v-slide-item>
                        <!-- 水 -->
                        <v-slide-item v-slot="{ active, toggle }" value="3">
                          <v-btn class="mx-1" :input-value="active" active-class="primary white--text" depressed fab small @click="toggle">水</v-btn>
                        </v-slide-item>
                        <!-- 木 -->
                        <v-slide-item v-slot="{ active, toggle }" value="4">
                          <v-btn class="mx-1" :input-value="active" active-class="primary white--text" depressed fab small @click="toggle">木</v-btn>
                        </v-slide-item>
                        <!-- 金 -->
                        <v-slide-item v-slot="{ active, toggle }" value="5">
                          <v-btn class="mx-1" :input-value="active" active-class="primary white--text" depressed fab small @click="toggle">金</v-btn>
                        </v-slide-item>
                        <!-- 土 -->
                        <v-slide-item v-slot="{ active, toggle }" value="6">
                          <v-btn class="mx-1" :input-value="active" active-class="primary white--text" depressed fab small @click="toggle">土</v-btn>
                        </v-slide-item>
                        <!-- 日 -->
                        <v-slide-item v-slot="{ active, toggle }" value="0">
                          <v-btn class="mx-1" :input-value="active" active-class="primary white--text" depressed fab small @click="toggle">日</v-btn>
                        </v-slide-item>
                      </v-slide-group>
                    </v-container>
                  </div>
                  <!-- 合計金額 -->
                  <div class="mx-2 pt-8">
                    <h3 align="right">合計金額（１回毎）&#65306;{{ fmtPrice(price_order_mult) }}円</h3>
                  </div>
                </v-col>
              </v-row>

              <v-divider class="my-4"></v-divider>

              <v-row>
                <v-col cols="6">
                  <!-- 受付状況 -->
                  <div class="pl-2">本日の受付状況</div>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">締切時間</th>
                          <th class="text-left">受付状況</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ fmtTime_HM(comp_simetime_mult) }}</td>
                          <td>
                            <font :color="shime_color_mult">{{ shime_state_mult }}</font>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <!-- ボタン -->
                <v-col cols="6">
                  <v-card-actions class="mt-12 pb-0 px-0">
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="clickOrder_mult" :disabled="loading_order_mult"> 確定 </v-btn>
                    <v-btn color="blue darken-1" text @click="dialog_order_mult = false" :disabled="loading_order_mult"> 閉じる </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- *** まとめて予約用ダイアログ ここまで *** -->

    <!-- *** 修正・削除用ダイアログ ここから *** -->
    <v-dialog v-model="dialog_order_edit" max-width="600px" persistent no-click-animation>
      <v-card>
        <!-- タイトル -->
        <v-card-title>予約の変更</v-card-title>
        <!-- 内容 -->
        <v-card-text class="py-0">
          <v-container>
            <v-form ref="order_edit">
              <v-row>
                <v-col cols="12">
                  <!-- 注文場所 -->
                  <div class="mx-2">
                    <div class="d-flex align-center"><v-icon>place</v-icon>&ensp;注文場所</div>
                    <v-select class="pt-1" v-model="select_jigyo_edit" :items="jigyo_list" item-text="str_jigyo_name" item-value="num_jigyo_no" placeholder="注文場所を選択してください。" no-data-text="注文場所が登録されていません。" :rules="comp_jigyo_rule" :disabled="!edit_enable" @change="selectJigyo_edit" outlined dense></v-select>
                  </div>

                  <!-- 弁当業者 -->
                  <div class="mx-2">
                    <div class="d-flex align-center"><v-icon>local_shipping</v-icon>&ensp;弁当業者</div>
                    <v-select class="pt-1" v-model="select_gyosha_edit" :items="gyosha_list_edit" item-text="str_gyosha_name" item-value="num_gyosha_no" placeholder="弁当業者を選択してください。" no-data-text="弁当業者が登録されていません。" :rules="comp_gyosha_rule" :disabled="!edit_enable" @change="selectGyosha_edit" outlined dense></v-select>
                  </div>

                  <!-- 弁当-->
                  <div class="mx-2">
                    <div class="d-flex align-center"><v-icon>bento</v-icon>&ensp;弁当<v-spacer></v-spacer><v-btn color="orange darken-1" class="px-1" height="24" text outlined @click="dialog_menu_edit = true" v-show="edit_enable"> メニュー表 </v-btn></div>
                    <v-select class="pt-1" v-model="select_bento_edit" :items="bento_list_edit" item-text="str_bento_name" item-value="num_bento_no" placeholder="弁当を選択してください。" no-data-text="弁当が登録されていません。" :rules="comp_bento_rule" :disabled="!edit_enable" @change="selectBento_edit" outlined dense></v-select>
                  </div>

                  <!-- 個数 -->
                  <div class="mx-2">
                    <div class="d-flex align-center"><v-icon>tag</v-icon>&ensp;個数</div>
                    <v-select class="pt-1" v-model="select_count_edit" :items="count_list" :rules="comp_count_rule" placeholder="個数を選択してください。" :disabled="!edit_enable" @change="selectCount_edit" outlined dense></v-select>
                  </div>

                  <!-- 合計金額 -->
                  <div class="mx-2">
                    <h3 align="right">合計金額&#65306;{{ fmtPrice(price_order_edit) }}円</h3>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <p v-show="!edit_enable" class="my-0 grey--text">※確定済の注文を変更することはできません。</p>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="clickUpdate_edit" v-show="edit_enable"> 保存 </v-btn>
          <v-btn color="red darken-1" text @click="clickDelete_edit" v-show="edit_enable"> 削除 </v-btn>
          <v-btn color="blue darken-1" text @click="clickCloseOrder_edit"> 閉じる </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *** 修正・削除用ダイアログ ここまで *** -->
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  name: "OrderReservation",
  data: () => ({
    // 共通
    today: moment().format("yyyy-MM-DD"),
    calendar: moment().format("yyyy-MM-DD"), // カレンダー表示月
    order_list_monthly: [], // 予約リスト（３か月）
    order_list_daily: [], // 予約リスト（１日）
    holiday_list: [], // 祝日リスト
    event_list: [], // イベントリスト
    jigyo_list: [], // 事業所リスト
    count_list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], // 個数リスト
    tab_list: ["メニュー表", "献立表"], // タブ項目
    price_monthly: 0, // 月の合計金額
    menu_headers: [
      { text: "弁当名", align: "start", sortable: false, value: "str_bento_name" },
      { text: "金額（円）", align: "end", value: "char_kingaku" },
      { text: "カロリー（kcal）", align: "end", value: "char_bento_calory" },
    ], // メニュー表項目
    // snackbar: false, // スナックバー状態
    // snackbar_msg: "", // スナックバー内容
    // snackbar_color: "", // スナックバー色
    // timeout: 3000, // スナックバー表示時間
    jp_week: ["日", "月", "火", "水", "木", "金", "土"], // 曜日
    // 単日予約
    gyosha_list: [], // 業者リスト
    bento_list: [], // 弁当リスト
    gyosha_info: [], // 業者情報
    bento_info: [], // 弁当情報
    select_date: moment().format("yyyy-MM-DD"), // 選択した日付
    select_jigyo: 0, // 選択した事業所（注文場所）
    select_gyosha: 0, // 選択した業者
    select_bento: 0, // 選択した弁当
    select_count: 1, // 選択した個数
    price: 0, // 単品価格
    price_order: 0, // 合計金額
    dialog_order: false, // まとめて予約ダイアログ状態
    dialog_menu: false, // メニューダイアログ状態
    loading_order: false, // ローディング状態
    kondate: null, // 献立データ
    shime_state: "",
    shime_color: "",
    // まとめて予約
    gyosha_list_mult: [], // 業者リスト
    bento_list_mult: [], // 弁当リスト
    order_info: [], // 予約情報
    gyosha_info_mult: [], // 業者情報
    bento_info_mult: [], // 弁当情報
    select_jigyo_mult: 0, // 選択した事業所（注文場所）
    select_gyosha_mult: 0, // 選択した業者
    select_bento_mult: 0, // 選択した弁当
    select_count_mult: 1, // 選択した個数
    select_date_st: moment().format("yyyy-MM-DD"), // 選択した開始日
    select_date_ed: moment().format("yyyy-MM-DD"), // 選択した終了日
    select_week: ["1", "2", "3", "4", "5"], // 選択した曜日
    price_mult: 0, // 単品価格
    price_order_mult: 0, // 合計金額
    dialog_order_mult: false, // まとめて予約ダイアログ状態
    dialog_menu_mult: false, // メニューダイアログ状態
    dialog_dtp_st: false, // 開始日ダイアログ状態
    dialog_dtp_ed: false, // 終了日ダイアログ状態
    loading_order_mult: false, // ローディング状態
    kondate_mult: null, // 献立データ
    shime_state_mult: "",
    shime_color_mult: "",
    // 修正・削除
    gyosha_list_edit: [], // 業者リスト
    bento_list_edit: [], // 弁当リスト
    gyosha_info_edit: [], // 業者情報
    bento_info_edit: [], // 弁当情報
    select_date_edit: 0, // 選択した日付
    select_jigyo_edit: 0, // 選択した事業所（注文場所）
    select_gyosha_edit: 0, // 選択した業者
    select_bento_edit: 0, // 選択した弁当
    select_count_edit: 1, // 選択した個数
    price_edit: 0, // 単品価格
    price_order_edit: 0, // 合計金額
    dialog_order_edit: false, // 修正・削除ダイアログ状態
    dialog_menu_edit: false, // メニューダイアログ状態
    loading_order_edit: false, // ローディング状態
    kondate_edit: null, // 献立データ
    edit_enable: true, // 保存・削除ボタン表示
  }),
  methods: {
    //----------------------------------------------------------------
    // 初期化処理
    //----------------------------------------------------------------
    async init() {
      // 祝日リスト読み込み
      this.getHoliday(moment().format("yyyy"));
      // 当日の予約一覧読み込み
      await this.getOrdersDaily();
      // 事業所リスト読み込み
      await this.getJigyo();
      this.resetJigyo();
      // 弁当業者リスト読み込み
      await this.setGyosha();
      this.resetGyosha();
      // 弁当業者情報読み込み
      await this.setGyoshaInfo();
      // 弁当リスト読み込み
      await this.setBento();
      this.resetBento();
    },

    //----------------------------------------------------------------
    // ボタン押下時処理
    //----------------------------------------------------------------

    // 「今日」ボタン押下時
    setToday() {
      // カレンダーを当月にセット
      this.calendar = moment().format("yyyy-MM-DD");
      // 予約日を当日にセット
      this.select_date = moment().format("yyyy-MM-DD");
    },

    // 「予約登録」ボタン押下時
    async openOrder() {
      // 最新の注文情報を取得
      const LastOrder = await this.getLastOrder();
      /* -- 2024/03/25 MCS)SAITO ADD START -- */
      var lastjigyono = 0;
      if (Object.keys(LastOrder).length != 0) {
        lastjigyono = LastOrder.num_jigyo_no;
      }
      /* -- 2024/03/25 MCS)SAITO ADD END -- */
      /* -- 2024/03/25 MCS)SAITO MOD START -- */
      // // 注文情報が存在する場合
      // if (Object.keys(LastOrder).length != 0) {
      // 注文情報が存在する場合かつ、最後に注文した注文場所が利用者の所属する事業所と同じ場合
      if (Object.keys(LastOrder).length != 0 && lastjigyono == this.$store.state.jigyoNo) {
        /* -- 2024/03/25 MCS)SAITO MOD END -- */
        // 事業所をセット
        this.select_jigyo = LastOrder.num_jigyo_no;
        // 弁当業者リスト読み込み
        await this.setGyosha();
        // 業者をセット
        this.select_gyosha = LastOrder.num_gyosha_no;
        // 弁当業者情報読み込み
        await this.setGyoshaInfo();
        // 弁当リスト読み込み
        await this.setBento();
        // 弁当をセット
        this.select_bento = LastOrder.num_bento_no;
        // 弁当情報を取得
        await this.setBentoInfo();
        // 個数をセット
        this.select_count = LastOrder.num_kosuu;
        // 金額を算出
        this.price = this.bento_info.num_kingaku;
        this.price_order = this.sumPrice(this.price, this.select_count);
      } else {
        // 事業所を初期化
        this.resetJigyo();
        // 弁当業者リスト読み込み
        await this.setGyosha();
        // 業者を初期化
        this.resetGyosha();
        // 弁当業者情報読み込み
        await this.setGyoshaInfo();
        // 弁当リスト読み込み
        await this.setBento();
        // 弁当を初期化
        this.resetBento();
        // 個数を初期化
        this.resetCount();
        // 金額を初期化
        this.resetPrice();
      }
      // エラーチェック初期化
      this.$nextTick(() => {
        if (this.$refs["order"]) {
          this.$refs.order.resetValidation();
        }
      });
      // ダイアログを開く
      this.dialog_order = true;
    },

    // 「まとめて予約登録」ボタン押下時
    async openOrder_mult() {
      // 事業所を初期化
      this.resetJigyo_mult();
      // 弁当業者リスト読み込み
      await this.setGyosha_mult();
      // 業者を初期化
      this.resetGyosha_mult();
      // 弁当業者情報読み込み
      await this.setGyoshaInfo_mult();
      // 弁当リスト読み込み
      await this.setBento_mult();
      // 弁当を初期化
      this.resetBento_mult();
      // 個数を初期化
      this.resetCount_mult();
      // 金額を初期化
      this.resetPrice_mult();
      // 日付範囲を初期化
      this.resetDate_mult();
      // エラーチェック初期化
      this.$nextTick(() => {
        if (this.$refs["order_mult"]) {
          this.$refs.order_mult.resetValidation();
        }
      });
      // ダイアログを開く
      this.dialog_order_mult = true;
    },

    // 「予約」ボタン押下時（単日）
    async clickOrder() {
      // 入力チェック
      if (!this.$refs.order.validate()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("未選択項目があります。予約内容を確認してください。");
        this.$root.snackbar.showMessage("未選択項目があります。予約内容を確認してください。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 締め処理チェック
      if (moment(this.select_date).format("yyyyMMDD") == this.$store.state.nowDate && !(await this.chkOrderJokyo(this.select_jigyo, this.select_gyosha))) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("本日の注文は締め切られたため、注文できません。");
        this.$root.snackbar.showMessage("本日の注文は締め切られたため、注文できません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // アニメーション開始
      this.loading_order = true;
      // 予約情報登録
      await this.insertOrder();
      // 予約情報を再取得
      await this.getOrdersMonthly();
      await this.getOrdersDaily();
      // 月の合計金額を再取得
      await this.getPriceMonthly();
      // アニメーション終了
      this.loading_order = false;
      // ダイアログを閉じる
      this.dialog_order = false;
    },

    // 「予約」ボタン押下時（まとめて予約）
    async clickOrder_mult() {
      // 入力チェック
      if (!this.$refs.order_mult.validate()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("未選択項目があります。予約内容を確認してください。");
        this.$root.snackbar.showMessage("未選択項目があります。予約内容を確認してください。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 締め処理チェック
      if (moment(this.select_date_st).format("yyyyMMDD") == this.$store.state.nowDate && !(await this.chkOrderJokyo(this.select_jigyo_mult, this.select_gyosha_mult))) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("本日の注文は締め切られたため、開始日を明日以降に設定してください。");
        this.$root.snackbar.showMessage("本日の注文は締め切られたため、開始日を明日以降に設定してください。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // アニメーション開始
      this.loading_order_mult = true;
      // 予約情報登録
      await this.insertOrder_mult();
      // 予約情報を再取得
      await this.getOrdersMonthly();
      await this.getOrdersDaily();
      // 月の合計金額を再取得
      await this.getPriceMonthly();
      // アニメーション終了
      this.loading_order_mult = false;
      // ダイアログを閉じる
      this.dialog_order_mult = false;
    },

    // 「保存」ボタン押下時（修正・削除）
    async clickUpdate_edit() {
      // 入力チェック
      if (!this.$refs.order_edit.validate()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("未選択項目があります。予約内容を確認してください。");
        this.$root.snackbar.showMessage("未選択項目があります。予約内容を確認してください。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 締め処理チェック
      if (moment(this.select_date_edit).format("yyyyMMDD") == this.$store.state.nowDate && !(await this.chkOrderJokyo(this.select_jigyo_edit, this.select_gyosha_edit))) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("この注文は確定済みのため、変更できません。");
        this.$root.snackbar.showMessage("この注文は確定済みのため、変更できません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // アニメーション開始
      this.loading_order_edit = true;
      // 予約情報更新
      await this.fncUpdateOrder();
      // 予約情報を再取得
      await this.getOrdersMonthly();
      await this.getOrdersDaily();
      // 月の合計金額を再取得
      await this.getPriceMonthly();
      // アニメーション終了
      this.loading_order_edit = false;
      // ダイアログを閉じる
      this.dialog_order_edit = false;
    },

    // 「削除」ボタン押下時（修正・削除）
    async clickDelete_edit() {
      // 入力チェック
      if (!window.confirm("削除します。よろしいですか？")) {
        return;
      }
      // 締め処理チェック
      if (moment(this.select_date_edit).format("yyyyMMDD") == this.$store.state.nowDate && !(await this.chkOrderJokyo(this.select_jigyo_edit, this.select_gyosha_edit))) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("この注文は確定済みのため、削除できません。");
        this.$root.snackbar.showMessage("この注文は確定済みのため、削除できません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // アニメーション開始
      this.loading_order_edit = true;
      // 予約情報削除
      await this.fncDeleteOrder();
      // 予約情報を再取得
      await this.getOrdersMonthly();
      await this.getOrdersDaily();
      // 月の合計金額を再取得
      await this.getPriceMonthly();
      // アニメーション終了
      this.loading_order_edit = false;
      // ダイアログを閉じる
      this.dialog_order_edit = false;
    },

    // 「閉じる」ボタン押下時（修正・削除）
    clickCloseOrder_edit() {
      // 取得した予約内容を初期化
      this.order_info = [];
      // 選択情報を初期化
      this.resetJigyo_edit();
      this.resetGyosha_edit();
      this.resetBento_edit();
      this.resetCount_edit();
      this.resetPrice_edit();
      // ダイアログを閉じる
      this.dialog_order_edit = false;
    },

    // ゴミ箱ボタン押下時（サイドメニュー）
    async deleteOrder(i) {
      // 予約内容を退避
      this.order_info = this.order_list_daily[i];
      this.select_date_edit = moment(this.order_info.str_chumonbi).format("yyyy-MM-DD");
      // 予約内容をセット
      this.setOrderInfo();
      // 入力チェック
      if (!window.confirm("削除します。よろしいですか？")) {
        return;
      }
      // 締め処理チェック
      if (moment(this.select_date_edit).format("yyyyMMDD") == this.$store.state.nowDate && !(await this.chkOrderJokyo(this.select_jigyo_edit, this.select_gyosha_edit))) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("この注文は確定済みのため、削除できません。");
        this.$root.snackbar.showMessage("この注文は確定済みのため、削除できません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 予約情報削除
      await this.fncDeleteOrder();
      // 予約情報を再取得
      await this.getOrdersMonthly();
      await this.getOrdersDaily();
      // 月の合計金額を再取得
      await this.getPriceMonthly();
    },

    //----------------------------------------------------------------
    // イベント処理
    //----------------------------------------------------------------

    // カレンダー変更時処理
    async changeCalendar() {
      // イベントリスト読み込み
      await this.getEvent();
      // 予約情報を取得
      await this.getOrdersMonthly();
      // 月の合計金額を取得
      await this.getPriceMonthly();
    },

    // 予約選択時処理
    async selectOrder(i) {
      // 予約内容を退避
      this.order_info = this.order_list_daily[i];
      this.select_date_edit = moment(this.order_info.str_chumonbi).format("yyyy-MM-DD");
      // 修正・削除可否チェック
      await this.checkKakutei();
      // 予約内容をセット
      this.setOrderInfo();
      // 業者情報を取得
      await this.setGyosha_edit();
      // 弁当情報を取得
      await this.setBento_edit();
      // ダイアログを開く
      this.dialog_order_edit = true;
    },

    // 日付選択時処理
    async selectDate({ date }) {
      // 日付をセット
      this.select_date = moment(date).format("yyyy-MM-DD");
      // 予約一覧を取得
      await this.getOrdersDaily();
    },

    // 注文場所変更時処理（単日予約）
    async selectJigyo() {
      // 弁当業者リストを更新
      await this.setGyosha();
      this.resetGyosha();
      // 弁当業者情報読み込み
      await this.setGyoshaInfo();
      // 弁当リストを更新
      await this.setBento();
      this.resetBento();
      // 個数を初期化
      this.resetCount();
      // 金額を初期化
      this.resetPrice();
      // エラーチェック初期化
      this.$refs.order.resetValidation();
    },

    // 注文場所変更時処理（まとめて予約）
    async selectJigyo_mult() {
      // 弁当業者リストを更新
      await this.setGyosha_mult();
      await this.resetGyosha_mult();
      // 弁当業者情報読み込み
      await this.setGyoshaInfo_mult();
      // 弁当リストを更新
      await this.setBento_mult();
      this.resetBento_mult();
      // 個数を初期化
      this.resetCount_mult();
      // 金額を初期化
      this.resetPrice_mult();
      // エラーチェック初期化
      this.$refs.order_mult.resetValidation();
    },

    // 注文場所変更時処理（修正・削除）
    async selectJigyo_edit() {
      // 弁当業者リストを更新
      await this.setGyosha_edit();
      await this.resetGyosha_edit();
      // 弁当業者情報読み込み
      await this.setGyoshaInfo_edit();
      // 弁当リストを更新
      await this.setBento_edit();
      this.resetBento_edit();
      // 個数を初期化
      this.resetCount_edit();
      // 金額を初期化
      this.resetPrice_edit();
      // エラーチェック初期化
      this.$refs.order_edit.resetValidation();
    },

    // 弁当業者変更時処理（単日予約）
    async selectGyosha() {
      // 弁当業者情報読み込み
      await this.setGyoshaInfo();
      // 弁当リストを更新
      await this.setBento();
      this.resetBento();
      // 個数を初期化
      this.resetCount();
      // 金額を初期化
      this.resetPrice();
      // エラーチェック初期化
      this.$refs.order.resetValidation();
    },

    // 弁当業者変更時処理（まとめて予約）
    async selectGyosha_mult() {
      // 弁当業者情報読み込み
      await this.setGyoshaInfo_mult();
      // 弁当リストを更新
      await this.setBento_mult();
      this.resetBento_mult();
      // 個数を初期化
      this.resetCount_mult();
      // 金額を初期化
      this.resetPrice_mult();
      // エラーチェック初期化
      this.$refs.order_mult.resetValidation();
    },

    // 弁当業者変更時処理（修正・削除）
    async selectGyosha_edit() {
      // 弁当業者情報読み込み
      await this.setGyoshaInfo_edit();
      // 弁当リストを更新
      await this.setBento_edit();
      this.resetBento_edit();
      // 個数を初期化
      this.resetCount_edit();
      // 金額を初期化
      this.resetPrice_edit();
      // エラーチェック初期化
      this.$refs.order_edit.resetValidation();
    },

    // 弁当変更時処理（単日予約）
    async selectBento() {
      // 弁当情報を更新
      await this.setBentoInfo();
      // 個数を初期化
      this.resetCount();
      // 金額を算出
      this.price = this.bento_info.num_kingaku;
      this.price_order = this.sumPrice(this.price, this.select_count);
    },

    // 弁当変更時処理（まとめて予約）
    async selectBento_mult() {
      // 弁当情報を更新
      await this.setBentoInfo_mult();
      // 個数を初期化
      this.resetCount_mult();
      // 金額を算出
      this.price_mult = this.bento_info_mult.num_kingaku;
      this.price_order_mult = this.sumPrice(this.price_mult, this.select_count_mult);
    },

    // 弁当変更時処理（修正・削除）
    async selectBento_edit() {
      // 弁当情報を更新
      await this.setBentoInfo_edit();
      // 個数を初期化
      this.resetCount_edit();
      // 金額を算出
      this.price_edit = this.bento_info_edit.num_kingaku;
      this.price_order_edit = this.sumPrice(this.price_edit, this.select_count_edit);
    },

    // 個数変更時処理（単日予約）
    selectCount() {
      // 合計金額を算出
      this.price_order = this.sumPrice(this.price, this.select_count);
    },

    // 個数変更時処理（まとめて予約）
    selectCount_mult() {
      // 合計金額を算出
      this.price_order_mult = this.sumPrice(this.price_mult, this.select_count_mult);
    },

    // 個数変更時処理（修正・削除）
    selectCount_edit() {
      // 合計金額を算出
      this.price_order_edit = this.sumPrice(this.price_edit, this.select_count_edit);
    },

    // メニュー表選択時処理（単日予約）
    async selectMenu(row) {
      // 選択した弁当を反映
      this.select_bento = row.num_bento_no;
      // 弁当変更時処理
      await this.selectBento();
      // ダイアログを閉じる
      this.dialog_menu = false;
    },

    // メニュー表選択時処理（まとめて予約）
    async selectMenu_mult(row) {
      // 選択した弁当を反映
      this.select_bento_mult = row.num_bento_no;
      // 弁当変更時処理
      await this.selectBento_mult();
      // ダイアログを閉じる
      this.dialog_menu_mult = false;
    },

    // メニュー表選択時処理（修正・削除）
    async selectMenu_edit(row) {
      // 選択した弁当を反映
      this.select_bento_edit = row.num_bento_no;
      // 弁当変更時処理
      await this.selectBento_edit();
      // ダイアログを閉じる
      this.dialog_menu_edit = false;
    },

    //----------------------------------------------------------------
    // メソッド
    //----------------------------------------------------------------

    // 事業所選択リセット処理（単日予約）
    resetJigyo() {
      if (this.jigyo_list.length != 0) {
        // データがある場合、所属事業所を選択
        this.select_jigyo = this.$store.state.jigyoNo;
      } else {
        // データがない場合、選択解除（通常はあり得ない）
        this.select_jigyo = 0;
      }
    },

    // 業者選択リセット処理（単日予約）
    resetGyosha() {
      if (this.gyosha_list.length != 0) {
        // データがある場合、先頭項目を選択
        this.select_gyosha = this.gyosha_list[0].num_gyosha_no;
      } else {
        // データがない場合、選択解除（通常はあり得ない）
        this.select_gyosha = 0;
      }
      this.gyosha_info = [];
    },

    // 弁当選択リセット処理（単日予約）
    resetBento() {
      this.select_bento = 0;
      this.bento_info = [];
    },

    // 個数選択リセット処理（単日予約）
    resetCount() {
      this.select_count = 1;
    },

    // 金額リセット処理（単日予約）
    resetPrice() {
      this.price = 0;
      this.price_order = 0;
    },

    // 事業所選択リセット処理（まとめて予約）
    resetJigyo_mult() {
      if (this.jigyo_list.length != 0) {
        // データがある場合、所属事業所を選択
        this.select_jigyo_mult = this.$store.state.jigyoNo;
      } else {
        // データがない場合、選択解除（通常はあり得ない）
        this.select_jigyo_mult = 0;
      }
    },

    // 業者選択リセット処理（まとめて予約）
    resetGyosha_mult() {
      if (this.gyosha_list_mult.length != 0) {
        // データがある場合、先頭項目を選択
        this.select_gyosha_mult = this.gyosha_list[0].num_gyosha_no;
      } else {
        // データがない場合、選択解除（通常はあり得ない）
        this.select_gyosha_mult = 0;
      }
      this.gyosha_info_mult = [];
    },

    // 弁当選択リセット処理（まとめて予約）
    resetBento_mult() {
      this.select_bento_mult = 0;
      this.bento_info_mult = [];
    },

    // 個数選択リセット処理（まとめて予約）
    resetCount_mult() {
      this.select_count_mult = 1;
    },

    // 金額リセット処理（まとめて予約）
    resetPrice_mult() {
      this.price_mult = 0;
      this.price_order_mult = 0;
    },

    // 日付範囲リセット処理（まとめて予約）
    resetDate_mult() {
      this.select_date_st = moment().format("yyyy-MM-DD");
      this.select_date_ed = moment().format("yyyy-MM-DD");
      this.select_week = ["1", "2", "3", "4", "5"];
    },

    // 事業所選択リセット処理（修正・削除）
    resetJigyo_edit() {
      if (this.jigyo_list.length != 0) {
        // データがある場合、所属事業所を選択
        this.select_jigyo_edit = this.$store.state.jigyoNo;
      } else {
        // データがない場合、選択解除（通常はあり得ない）
        this.select_jigyo_edit = 0;
      }
    },

    // 業者選択リセット処理（修正・削除）
    resetGyosha_edit() {
      if (this.gyosha_list_edit.length != 0) {
        // データがある場合、先頭項目を選択
        this.select_gyosha_edit = this.gyosha_list[0].num_gyosha_no;
      } else {
        // データがない場合、選択解除（通常はあり得ない）
        this.select_gyosha_edit = 0;
      }
      this.gyosha_info_edit = [];
    },

    // 弁当選択リセット処理（修正・削除）
    resetBento_edit() {
      this.select_bento_edit = 0;
      this.bento_info_edit = [];
    },

    // 個数選択リセット処理（修正・削除）
    resetCount_edit() {
      this.select_count_edit = 1;
    },

    // 金額リセット処理（修正・削除）
    resetPrice_edit() {
      this.price_edit = 0;
      this.price_order_edit = 0;
    },

    // 予約内容セット処理（修正・削除）
    setOrderInfo() {
      this.select_jigyo_edit = this.order_info.num_jigyo_no;
      this.select_gyosha_edit = this.order_info.num_gyosha_no;
      this.select_bento_edit = this.order_info.num_bento_no;
      this.select_count_edit = this.order_info.num_kosuu;
      this.price_edit = this.order_info.num_kingaku;
      this.price_order_edit = this.order_info.num_goukei;
    },

    // 合計金額算出処理
    sumPrice(price, count) {
      return Number(price) * Number(count);
    },

    // 事業所情報取得処理（共通）
    async getJigyo() {
      const KigyoCd = this.$store.state.kigyoCode;
      this.jigyo_list = await this.$axios
        .get("/m_jigyo_get/jigyosyo_list" + "?KigyoCd=" + KigyoCd)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    // 弁当業者リストセット処理（単日）
    async setGyosha() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo;
      this.gyosha_list = await this.getGyosha(KigyoCd, JigyoNo);
    },

    // 弁当業者リストセット処理（まとめて予約）
    async setGyosha_mult() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo_mult;
      this.gyosha_list_mult = await this.getGyosha(KigyoCd, JigyoNo);
    },

    // 弁当業者リストセット処理（修正・削除）
    async setGyosha_edit() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo_edit;
      this.gyosha_list_edit = await this.getGyosha(KigyoCd, JigyoNo);
    },

    // 弁当業者リスト取得処理
    async getGyosha(KigyoCd, JigyoNo) {
      let wkArray = await this.$axios
        .get("/m_gyosha_get/gyosha_list" + "?KigyoCd=" + KigyoCd + "&JigyoNo=" + JigyoNo)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      return wkArray;
    },

    // 弁当業者詳細情報セット処理（単日）
    async setGyoshaInfo() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo;
      const GyoshaNo = this.select_gyosha;
      this.gyosha_info = await this.getGyoshaInfo(KigyoCd, JigyoNo, GyoshaNo);
    },

    // 弁当業者詳細情報セット処理（まとめて予約）
    async setGyoshaInfo_mult() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo_mult;
      const GyoshaNo = this.select_gyosha_mult;
      this.gyosha_info_mult = await this.getGyoshaInfo(KigyoCd, JigyoNo, GyoshaNo);
    },

    // 弁当業者詳細情報セット処理（修正・削除）
    async setGyoshaInfo_edit() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo_edit;
      const GyoshaNo = this.select_gyosha_edit;
      this.gyosha_info_edit = await this.getGyoshaInfo(KigyoCd, JigyoNo, GyoshaNo);
    },

    // 弁当業者詳細情報取得処理
    async getGyoshaInfo(KigyoCd, JigyoNo, GyoshaNo) {
      let wkArray = await this.$axios
        .get("/m_gyosha_get/gyosha_info" + "?KigyoCd=" + KigyoCd + "&JigyoNo=" + JigyoNo + "&GyoshaNo=" + GyoshaNo)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      return wkArray;
    },

    // 弁当リストセット処理（単日）
    async setBento() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo;
      const GyoshaNo = this.select_gyosha;
      this.bento_list = await this.getBento(KigyoCd, JigyoNo, GyoshaNo);
    },

    // 弁当リストセット処理（まとめて予約）
    async setBento_mult() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo_mult;
      const GyoshaNo = this.select_gyosha_mult;
      this.bento_list_mult = await this.getBento(KigyoCd, JigyoNo, GyoshaNo);
    },

    // 弁当リストセット処理（修正・削除）
    async setBento_edit() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo_edit;
      const GyoshaNo = this.select_gyosha_edit;
      this.bento_list_edit = await this.getBento(KigyoCd, JigyoNo, GyoshaNo);
    },

    // 弁当リスト取得処理
    async getBento(KigyoCd, JigyoNo, GyoshaNo) {
      let wkArray = await this.$axios
        .get("/m_bento_get/bento_list" + "?KigyoCd=" + KigyoCd + "&JigyoNo=" + JigyoNo + "&GyoshaNo=" + GyoshaNo)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      return wkArray;
    },

    // 弁当詳細情報セット処理（単日）
    async setBentoInfo() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo;
      const GyoshaNo = this.select_gyosha;
      const BentoNo = this.select_bento;
      this.bento_info = await this.getBentoinfo(KigyoCd, JigyoNo, GyoshaNo, BentoNo);
    },

    // 弁当詳細情報セット処理（まとめて予約）
    async setBentoInfo_mult() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo_mult;
      const GyoshaNo = this.select_gyosha_mult;
      const BentoNo = this.select_bento_mult;
      this.bento_info_mult = await this.getBentoinfo(KigyoCd, JigyoNo, GyoshaNo, BentoNo);
    },

    // 弁当詳細情報セット処理（修正・削除）
    async setBentoInfo_edit() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo_edit;
      const GyoshaNo = this.select_gyosha_edit;
      const BentoNo = this.select_bento_edit;
      this.bento_info_edit = await this.getBentoinfo(KigyoCd, JigyoNo, GyoshaNo, BentoNo);
    },

    // 弁当詳細情報取得処理
    async getBentoinfo(KigyoCd, JigyoNo, GyoshaNo, BentoNo) {
      let wkArray = await this.$axios
        .get("/m_bento_get/bento_info" + "?KigyoCd=" + KigyoCd + "&JigyoNo=" + JigyoNo + "&GyoshaNo=" + GyoshaNo + "&BentoNo=" + BentoNo)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      return wkArray;
    },

    // 最新注文内容セット処理
    async setLastOrder() {
      const LastOrder = await this.getLastOrder();
      if (Object.keys(LastOrder).length != 0) {
        // 注文内容をセット
        this.select_jigyo = LastOrder.num_jigyo_no;
        this.select_gyosha = LastOrder.num_gyosha_no;
        this.select_bento = LastOrder.num_bento_no;
        this.select_count = LastOrder.num_kosuu;
        // 弁当情報を取得
        await this.setBentoInfo();
        // 金額を算出
        this.price = this.bento_info.num_kingaku;
        this.price_order = this.sumPrice(this.price, this.select_count);
      }
    },

    // 最新注文内容取得処理
    async getLastOrder() {
      const KigyoCd = this.$store.state.kigyoCode;
      const UserCd = this.$store.state.userCode;
      let wkArray = await this.$axios
        .get("/t_chu_yoyaku_get/yoyaku_last" + "?KigyoCd=" + KigyoCd + "&UserCd=" + UserCd)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      return wkArray;
    },

    // 祝日情報取得処理
    async getHoliday(year) {
      if (year < moment().add(2, "y").format("yyyy")) {
        let data = await this.$axios
          .get("https://holidays-jp.github.io/api/v1/" + year + "/date.json")
          .then(function (response) {
            return response.data;
          })
          .catch(function (error) {
            console.log(error);
            return [];
          });
        // イベント形式に変換
        let wkArray = [];
        let wkObj = JSON.parse(JSON.stringify(data));
        if (wkObj.length != 0) {
          Object.keys(wkObj).forEach(function (key) {
            let event = {};
            event["name"] = wkObj[key];
            event["start"] = moment(key).toDate();
            event["end"] = moment(key).toDate();
            event["color"] = "grey";
            event["timed"] = false;
            event["jigyo"] = "";
            event["seq"] = "";
            event["holiday"] = true;
            wkArray.push(event);
          });
        }
        this.holiday_list = wkArray;
      }
    },

    // イベント情報取得処理
    async getEvent() {
      const KigyoCd = this.$store.state.kigyoCode;
      const Year = moment(this.calendar).format("yyyy");
      let data = await this.$axios
        .get("/t_calendar_get/list" + "?KigyoCd=" + KigyoCd + "&Year=" + Year)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      // イベント形式に変換
      let wkArray = [];
      if (data.length != 0) {
        data.forEach(function (element) {
          let event = {};
          event["name"] = element.str_title;
          event["start"] = moment(element.str_date_st).toDate();
          event["end"] = moment(element.str_date_ed).toDate();
          event["color"] = "grey";
          event["timed"] = false;
          event["seq"] = element.num_seq_no;
          event["holiday"] = false;
          wkArray.push(event);
        });
      }
      this.event_list = wkArray;
    },

    // 予約情報取得処理（３か月）
    async getOrdersMonthly() {
      const KigyoCd = this.$store.state.kigyoCode;
      const UserCd = this.$store.state.userCode;
      const Chumonbi = moment(this.calendar).format("yyyyMMDD");
      let data = await this.$axios
        .get("/t_chu_yoyaku_get/yoyaku_list_monthly" + "?KigyoCd=" + KigyoCd + "&UserCd=" + UserCd + "&Chumonbi=" + Chumonbi)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      // イベント形式に変換
      let wkArray = [];
      if (data.length != 0) {
        data.forEach(function (element) {
          let order = {};
          order["name"] = element.str_bento_name + " " + element.num_kosuu + "個";
          order["start"] = moment(element.str_chumonbi).toDate();
          order["end"] = moment(element.str_chumonbi).toDate();
          let wkColor = "orange";
          // // 色の系統
          // switch (element.num_gyosha_no) {
          //   case 1:
          //     wkColor = "orange";
          //     break;
          //   case 2:
          //     wkColor = "green";
          //     break;
          //   case 3:
          //     wkColor = "red";
          //     break;
          //   case 4:
          //     wkColor = "blue";
          //     break;
          //   default:
          //     wkColor = "blue-grey";
          //     break;
          // }
          order["color"] = wkColor + " lighten-1";
          order["timed"] = false;
          order["jigyo"] = element.num_jigyo_no;
          order["seq"] = element.num_seq_no;
          order["holiday"] = false;
          wkArray.push(order);
        });
      }
      this.order_list_monthly = wkArray;
    },

    // 予約情報取得処理（１日）
    async getOrdersDaily() {
      const KigyoCd = this.$store.state.kigyoCode;
      const UserCd = this.$store.state.userCode;
      const Chumonbi = moment(this.select_date).format("yyyyMMDD");
      this.order_list_daily = await this.$axios
        .get("/t_chu_yoyaku_get/yoyaku_list_daily" + "?KigyoCd=" + KigyoCd + "&UserCd=" + UserCd + "&Chumonbi=" + Chumonbi)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    // 当月予約金額取得処理（共通）
    async getPriceMonthly() {
      const KigyoCd = this.$store.state.kigyoCode;
      const UserCd = this.$store.state.userCode;
      const Chumonbi = moment(this.calendar).format("yyyyMM");
      this.price_monthly = await this.$axios
        .get("/t_chu_yoyaku_get/monthly_price" + "?KigyoCd=" + KigyoCd + "&UserCd=" + UserCd + "&Chumonbi=" + Chumonbi)
        .then(function (response) {
          return response.data["data"].price;
        })
        .catch(function (error) {
          console.log(error);
          return 0;
        });
    },

    // 予約状況件数取得処理
    async chkOrderJokyo(JigyoNo, GyoshaNo) {
      const KigyoCd = this.$store.state.kigyoCode;
      const Chumonbi = moment().format("yyyyMMDD");
      let wkBoolean = await this.$axios
        .get("/t_chu_jokyo_get/jokyo_count" + "?KigyoCd=" + KigyoCd + "&JigyoNo=" + JigyoNo + "&GyoshaNo=" + GyoshaNo + "&Chumonbi=" + Chumonbi)
        .then(function (response) {
          if (response.data["data"] > 0) {
            return false;
          }
          return true;
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
      return wkBoolean;
    },

    // 予約処理用配列セット処理（単日予約）
    async insertOrder() {
      const JigyoCd = this.select_jigyo;
      const Chu_st = this.select_date;
      const Chu_ed = this.select_date;
      const Chu_week = "";
      const GyoshaNo = this.select_gyosha;
      const BentoNo = this.select_bento;
      const Kingaku = this.price;
      const Kosuu = this.select_count;
      const Goukei = this.price_order;
      await this.fncInsertOrder(JigyoCd, Chu_st, Chu_ed, Chu_week, GyoshaNo, BentoNo, Kingaku, Kosuu, Goukei);
    },

    // 予約処理用配列セット処理（まとめて予約）
    async insertOrder_mult() {
      const JigyoCd = this.select_jigyo_mult;
      const Chu_st = this.select_date_st;
      const Chu_ed = this.select_date_ed;
      const Chu_week = this.select_week.join("");
      const GyoshaNo = this.select_gyosha_mult;
      const BentoNo = this.select_bento_mult;
      const Kingaku = this.price_mult;
      const Kosuu = this.select_count_mult;
      const Goukei = this.price_order_mult;
      await this.fncInsertOrder(JigyoCd, Chu_st, Chu_ed, Chu_week, GyoshaNo, BentoNo, Kingaku, Kosuu, Goukei);
    },

    // 予約登録処理
    async fncInsertOrder(JigyoCd, Chu_st, Chu_ed, Chu_week, GyoshaNo, BentoNo, Kingaku, Kosuu, Goukei) {
      const params = new URLSearchParams();
      params.append("KigyoCd", this.$store.state.kigyoCode);
      params.append("JigyoNo", JigyoCd);
      params.append("UserCd", this.$store.state.userCode);
      params.append("Chu_st", Chu_st);
      params.append("Chu_ed", Chu_ed);
      params.append("Chu_week", Chu_week);
      params.append("GyoshaNo", GyoshaNo);
      params.append("BentoNo", BentoNo);
      params.append("Kingaku", Kingaku);
      params.append("Kosuu", Kosuu);
      params.append("Goukei", Goukei);
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      let result = await this.$axios
        .post("/t_chu_yoyaku_post/insert", params)
        .then(function (response) {
          if (response.status == 200) {
            return true;
          } else {
            return false;
          }
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
      if (result == true) {
        // 予約成功
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("予約を登録しました。");
        this.$root.snackbar.showMessage("予約を登録しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      } else {
        // 予約失敗
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("予約の登録時にエラーが発生しました。");
        this.$root.snackbar.showMessage("予約の登録時にエラーが発生しました。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      }
    },

    // 予約更新処理
    async fncUpdateOrder() {
      const params = new URLSearchParams();
      params.append("JigyoNo", this.select_jigyo_edit);
      params.append("GyoshaNo", this.select_gyosha_edit);
      params.append("BentoNo", this.select_bento_edit);
      params.append("Kingaku", this.price_edit);
      params.append("Kosuu", this.select_count_edit);
      params.append("Goukei", this.price_order_edit);
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      params.append("tKigyoCd", this.order_info.str_kigyo_cd);
      params.append("tJigyoNo", this.order_info.num_jigyo_no);
      params.append("tUserCd", this.order_info.str_user_cd);
      params.append("tChumonbi", this.order_info.str_chumonbi);
      params.append("tSeqNo", this.order_info.num_seq_no);
      let result = await this.$axios
        .post("/t_chu_yoyaku_post/update", params)
        .then(function (response) {
          if (response.status == 200) {
            return true;
          } else {
            return false;
          }
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
      if (result == true) {
        // 更新成功
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("予約を保存しました。");
        this.$root.snackbar.showMessage("予約を保存しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      } else {
        // 更新失敗
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("予約の保存時にエラーが発生しました。");
        this.$root.snackbar.showMessage("予約の保存時にエラーが発生しました。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      }
    },

    // 予約削除処理
    async fncDeleteOrder() {
      const params = new URLSearchParams();
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      params.append("tKigyoCd", this.order_info.str_kigyo_cd);
      params.append("tJigyoNo", this.order_info.num_jigyo_no);
      params.append("tUserCd", this.order_info.str_user_cd);
      params.append("tChumonbi", this.order_info.str_chumonbi);
      params.append("tSeqNo", this.order_info.num_seq_no);
      let result = await this.$axios
        .post("/t_chu_yoyaku_post/delete", params)
        .then(function (response) {
          if (response.status == 200) {
            return true;
          } else {
            return false;
          }
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
      if (result == true) {
        // 削除成功
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("予約を削除しました。");
        this.$root.snackbar.showMessage("予約を削除しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      } else {
        // 削除失敗
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("予約の削除時にエラーが発生しました。");
        this.$root.snackbar.showMessage("予約の削除時にエラーが発生しました。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      }
    },

    // 受付状況を判断（単日予約）
    checkShime() {
      const simetime = this.comp_simetime;
      if (simetime != "") {
        if (moment(this.select_date).format("yyyyMMDD") == this.$store.state.nowDate) {
          if (this.gyosha_info.num_chu_jokyo == null) {
            if (simetime > this.$store.state.nowTime) {
              this.shime_state = "受付中";
              this.shime_color = "blue";
            } else {
              this.shime_state = "締切";
              this.shime_color = "red";
            }
          } else {
            this.shime_state = "締切";
            this.shime_color = "red";
          }
        } else if (moment(this.select_date).format("yyyyMMDD") > this.$store.state.nowDate) {
          this.shime_state = "受付中";
          this.shime_color = "blue";
        } else {
          this.shime_state = "締切";
          this.shime_color = "red";
        }
      } else {
        this.shime_state = "-";
        this.shime_color = "black";
      }
    },

    // 受付状況を判断（まとめて予約）
    checkShime_mult() {
      const simetime_mult = this.comp_simetime_mult;
      if (simetime_mult != "") {
        if (this.gyosha_info_mult.num_chu_jokyo == null) {
          if (simetime_mult > this.$store.state.nowTime) {
            this.shime_state_mult = "受付中";
            this.shime_color_mult = "blue";
          } else {
            this.shime_state_mult = "締切";
            this.shime_color_mult = "red";
          }
        } else {
          this.shime_state_mult = "締切";
          this.shime_color_mult = "red";
        }
      } else {
        this.shime_state_mult = "-";
        this.shime_color_mult = "black";
      }
    },

    // 注文確定判定
    async checkKakutei() {
      const chumonbi = this.order_info.str_chumonbi;
      const jigyo = this.order_info.num_jigyo_no;
      const gyosha = this.order_info.num_gyosha_no;
      let result = chumonbi > this.$store.state.nowDate || (chumonbi == this.$store.state.nowDate && (await this.chkOrderJokyo(jigyo, gyosha)));
      this.edit_enable = result;
    },

    // メッセージ表示（正常）
    // noticeOK(msg) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "green";
    //   this.snackbar = true;
    // },

    // メッセージ表示（エラー）
    // noticeNG(msg) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "red";
    //   this.snackbar = true;
    // },

    // 日付を"yyyyMMDD"形式で返却
    fmtDate_YYYYMMDD(date) {
      if (date != "") {
        return moment(date).format("YYYYMMDD");
      } else {
        return "";
      }
    },

    // 日付を"yyyy年 M月"形式で返却
    fmtDate_YYYYM(date) {
      if (date != "") {
        return moment(date).format("YYYY年 M月");
      } else {
        return "";
      }
    },

    // 日付を"yyyy年 M月 D日"形式で返却
    fmtDate_YYYYMD(date) {
      if (date != "") {
        let fmtDate = moment(date).format("YYYY年 M月 D日");
        let fmtWeek = "（" + this.jp_week[moment(date).day()] + "）";
        return fmtDate + fmtWeek;
      } else {
        return "";
      }
    },

    // 日付を"M月"形式で返却
    fmtDate_M(date) {
      if (date != "") {
        return moment(date).format("M月");
      } else {
        return "";
      }
    },

    // 時刻を"H:M"形式で返却
    fmtTime_HM(time) {
      if (time != "") {
        return String(time).substring(0, 2).replace(/^0/, "") + ":" + String(time).substring(2, 4);
      } else {
        return "--:--";
      }
    },

    // 金額をカンマ区切りで返却
    fmtPrice(price) {
      return Number(price).toLocaleString();
    },
  },

  computed: {
    // ストアの現在時刻を返却
    comp_time() {
      return this.$store.getters.getTime;
    },

    // 祝日リストと予約リストを返却
    comp_calendar() {
      let wkEventList = [];
      return wkEventList.concat(this.holiday_list, this.event_list, this.order_list_monthly);
    },

    // 選択中の弁当業者名を返却（単日）
    comp_gyosha_name() {
      if (this.gyosha_list.length != 0 && this.gyosha_list.some((u) => u.num_gyosha_no == this.select_gyosha)) {
        return this.gyosha_list.find((i) => i.num_gyosha_no == this.select_gyosha).str_gyosha_name;
      } else {
        return "";
      }
    },

    // 選択中の弁当業者名を返却（まとめて予約）
    comp_gyosha_name_mult() {
      if (this.gyosha_list_mult.length != 0 && this.gyosha_list_mult.some((u) => u.num_gyosha_no == this.select_gyosha_mult)) {
        return this.gyosha_list_mult.find((i) => i.num_gyosha_no == this.select_gyosha_mult).str_gyosha_name;
      } else {
        return "";
      }
    },

    // 選択中の弁当業者名を返却（修正・削除）
    comp_gyosha_name_edit() {
      if (this.gyosha_list_edit.length != 0 && this.gyosha_list_edit.some((u) => u.num_gyosha_no == this.select_gyosha_edit)) {
        return this.gyosha_list_edit.find((i) => i.num_gyosha_no == this.select_gyosha_edit).str_gyosha_name;
      } else {
        return "";
      }
    },

    // 選択中の弁当業者名の締切時間を返却（単日予約）
    comp_simetime() {
      if (this.gyosha_info.str_simetime != undefined) {
        return this.gyosha_info.str_simetime;
      } else {
        return "";
      }
    },

    // 選択中の弁当業者名の締切時間を返却（まとめて予約）
    comp_simetime_mult() {
      if (this.gyosha_info_mult.str_simetime != undefined) {
        return this.gyosha_info_mult.str_simetime;
      } else {
        return "";
      }
    },

    // 日付範囲の最小値を返却（まとめて予約）
    comp_min_date() {
      // 今日の日付
      return moment().format("yyyy-MM-DD");
    },

    // 日付範囲の最大値を返却（まとめて予約）
    comp_max_date() {
      // 開始日で選択した日の半年後
      return moment(this.select_date_st).add(6, "M").format("yyyy-MM-DD");
    },

    //----------------------------------------------------------------
    // バリデーションルール
    //----------------------------------------------------------------

    // 日付（単日のみ）
    comp_date_rule() {
      return [(value) => !(value == "") || "日付を選択してください。", moment(this.select_date).format("yyyyMMDD") >= this.$store.state.nowDate || "過去日は選択できません。"];
    },

    // 事業所（単日予約・まとめて予約共通）
    comp_jigyo_rule() {
      return [(value) => !(value == 0) || "注文場所を選択してください。"];
    },

    // 弁当業者（単日予約・まとめて予約共通）
    comp_gyosha_rule() {
      return [(value) => !(value == 0) || "弁当業者を選択してください。"];
    },

    // 弁当（単日予約・まとめて予約共通）
    comp_bento_rule() {
      return [(value) => !(value == 0) || "弁当を選択してください。"];
    },

    // 個数（単日予約・まとめて予約共通）
    comp_count_rule() {
      return [(value) => !(value == 0) || "個数を選択してください。"];
    },

    // 予約する曜日（まとめて予約のみ）
    comp_week_rule() {
      return [() => this.select_week.length != 0 || "１つ以上選択してください。"];
    },
  },

  watch: {
    // 祝日情報取得（年が変わった場合のみ）
    calendar: function (newVal, oldVal) {
      let newYear = moment(newVal).format("yyyy");
      let oldYear = moment(oldVal).format("yyyy");
      if (newYear != oldYear) {
        this.getHoliday(newYear);
      }
    },
    // 開始日変更時処理（まとめて予約）
    select_date_st: function () {
      // 日付チェック
      if (this.select_date_st > this.select_date_ed) {
        // 終了日より開始日が後の場合、終了日に開始日をセット
        this.select_date_ed = this.select_date_st;
      } else if (this.select_date_ed > moment(this.select_date_st).add(6, "M").format("yyyy-MM-DD")) {
        // 開始日の6か月後より終了日が後の場合、終了日に開始日の6か月後の日付をセット
        this.select_date_ed = moment(this.select_date_st).add(6, "M").format("yyyy-MM-DD");
      }
    },
    comp_time: function () {
      // 1秒ごとに受付状況を確認
      this.checkShime();
      this.checkShime_mult();
    },
  },

  mounted() {
    // 初期化
    this.init();
  },
};
</script>
