<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：ManualList.vue
  モジュール名：契約情報画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <!-- タイトル -->
    <title-card title="契約情報"> </title-card>
    <!-- データ -->
    <data-card>
      <v-sheet class="pa-2">
        <v-card-title class="pa-2"> 企業情報 </v-card-title>
        <v-divider></v-divider>
        <v-container class="mb-4" fluid>
          <v-row>
            <v-col cols="6" class="pa-0 pr-1">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>企業コード</td>
                      <td>{{ kigyoInfo.str_kigyo_cd }}</td>
                    </tr>
                    <tr>
                      <td>企業名</td>
                      <td>{{ kigyoInfo.str_kigyo_name }}</td>
                    </tr>
                    <tr>
                      <td>郵便番号</td>
                      <td>{{ kigyoInfo.str_post }}</td>
                    </tr>
                    <tr>
                      <td>住所</td>
                      <td>{{ kigyoInfo.str_address }}</td>
                    </tr>
                    <tr>
                      <td>担当者名</td>
                      <td>{{ kigyoInfo.str_tanto_name }}</td>
                    </tr>
                    <tr>
                      <td>担当者名カナ</td>
                      <td>{{ kigyoInfo.str_tanto_kana }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <!-- <v-divider class="my-1" vertical></v-divider> -->
            <v-col cols="6" class="pa-0 pl-1">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>所属・グループ名</td>
                      <td>{{ kigyoInfo.str_busho_name }}</td>
                    </tr>
                    <tr>
                      <td>電話番号１</td>
                      <td>{{ kigyoInfo.str_tel1 }}</td>
                    </tr>
                    <tr>
                      <td>電話番号２</td>
                      <td>{{ kigyoInfo.str_tel2 }}</td>
                    </tr>
                    <tr>
                      <td>ＦＡＸ番号</td>
                      <td>{{ kigyoInfo.str_fax }}</td>
                    </tr>
                    <tr>
                      <td>内線番号</td>
                      <td>{{ kigyoInfo.str_naisen }}</td>
                    </tr>
                    <tr>
                      <td>メールアドレス</td>
                      <td>{{ kigyoInfo.str_mail }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
        <v-card-title class="pa-2">プラン</v-card-title>
        <v-divider></v-divider>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>プラン名</td>
                <td v-if="[10, 50, 100, 150, 200, 250, 300].includes(kigyoInfo.num_plan)">{{ kigyoInfo.num_plan }}プラン</td>
                <td v-if="![10, 50, 100, 150, 200, 250, 300].includes(kigyoInfo.num_plan)">不明</td>
              </tr>
              <!-- <tr>
                <td>オプション</td>
                <td>
                  <v-chip v-if="kigyoInfo.num_option_fax" class="mr-2">FAX</v-chip>
                  <v-chip v-if="kigyoInfo.num_option_tab" class="mr-2">タブレット</v-chip>
                  <v-chip v-if="kigyoInfo.num_option_ic" class="mr-2">ICカードリーダー</v-chip>
                </td>
              </tr> -->
            </tbody>
          </template>
        </v-simple-table>
      </v-sheet>
    </data-card>
    <!-- *** 表示部 ここまで *** -->
  </v-container>
</template>

<style lang="scss" scoped>
table {
  font-size: 1rem !important;
  // 列幅指定
  & th:nth-child(1),
  td:nth-child(1) {
    width: 160px;
  }
  & th:nth-child(2),
  td:nth-child(2) {
    width: calc(100% - 160px);
  }
}
.v-data-table {
  & td {
    font-size: 1rem !important;
  }
  & tr:hover {
    background: initial !important;
  }
}
</style>

<script>
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "ContractDetail",
  data() {
    return {
      kigyoInfo: [],
    };
  },
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    //----------------------------------------------------------------
    // 初期化処理
    //----------------------------------------------------------------
    async init() {
      await this.getKigyoInfo();
    },

    //----------------------------------------------------------------
    // 企業情報取得処理
    //----------------------------------------------------------------
    async getKigyoInfo() {
      const KigyoCd = this.$store.state.kigyoCode;
      this.kigyoInfo = await this.$axios
        .get("/m_kigyo_get/info" + "?KigyoCd=" + KigyoCd)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },
  },
  mounted() {
    // 初期化
    this.init();
  },
};
</script>
