<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：NotFound.vue
  モジュール名：404エラー画面
  備考：
-------------------------------------------------->
<template>
  <div class="mt-10 text-center">
    <div><h1>404 Not Found</h1></div>
    <div><img src="@/assets/images/NotFound.gif" class="notfound" /></div>
    <div>
      <p>ご指定のページが見つかりませんでした。</p>
      <p>アクセスしようとしたページは存在しないか、現在利用できない可能性があります。</p>
      <p>ご不明な点がございましたら、<a href="https://mcs-future.com/contact">こちら</a>までご連絡ください。</p>
      <p>{{ count }}秒後に、自動的に<RouterLink to="/">認証画面</RouterLink>へ移動します。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data: () => ({
    count: 10,
  }),
  methods: {
    countdown() {
      this.count--;
    },
  },
  mounted() {
    setInterval(() => {
      this.countdown();
    }, 1000);
  },
  watch: {
    count: function (val) {
      if (val < 0) {
        this.$router.push("/");
      }
    },
  },
};
</script>
