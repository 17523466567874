<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：MonthlyClosing.vue
  モジュール名：月次締め処理画面
  備考：
-------------------------------------------------->
<style lang="scss" scoped>
@import "./src/assets/css/OrderSheet.scss";
</style>
<template>
  <v-container class="d-print-none" fluid>
    <!-- *** 表示部 ここから *** -->
    <title-card title="月次締め処理">
      <v-btn class="ml-2" color="teal" dark depressed @click="monthlyclosing()">処理開始</v-btn>
    </title-card>
    <data-card class="py-2">
      <v-form ref="select_form">
        <v-sheet class="px-4 py-2 d-flex" style="align-items: center">
          <span>処理年月：</span>
          <v-responsive class="mr-2" max-width="120px">
            <v-text-field hide-details outlined dense v-bind:value="this.year" maxlength="4" type="number" onkeydown="if( event.keyCode === 69 || event.keyCode === 189 || event.keyCode === 110 || event.keyCode === 190) {return false;}" oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" v-model.number="year" :rules="[required, limit_length]" v-model="year" backgroundColor="white" suffix="年"></v-text-field>
          </v-responsive>
          <v-responsive class="mr-2" max-width="120px">
            <v-select outlined hide-details dense v-model="month" v-bind:value="this.month" :items="months" backgroundColor="white"></v-select>
          </v-responsive>
        </v-sheet>

        <v-sheet class="px-4 py-2 d-flex" style="align-items: center" height="56px">
          <span>注文日&emsp;：</span>
          <span>{{ this.sDate.substring(0, 4) }} 年 {{ this.sDate.substring(4, 6) }} 月 {{ this.sDate.substring(6, 8) }} 日～ {{ this.eDate.substring(0, 4) }} 年 {{ this.eDate.substring(4, 6) }} 月 {{ this.eDate.substring(6, 8) }} 日</span>
        </v-sheet>

        <v-sheet class="px-4 py-2 d-flex" style="align-items: center" height="56px">
          <span>締め処理：</span>
          <span style="color: blue" v-if="this.dataCount > 0">処理済み</span><label style="color: red" v-else>未処理</label>
        </v-sheet>
      </v-form>
    </data-card>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** 確認用ダイアログ ここから *** -->
    <v-dialog v-model="show" :max-width="400">
      <v-card>
        <v-card-title primary-title> 確認メッセージ </v-card-title>
        <v-card-text class="pa-4">
          <p>{{ confirmmessage }}</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="okAction"> OK </v-btn>
          <v-btn @click="cancelAction"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col height="3000px"></v-col>
    <!-- *** 確認用ダイアログ ここから *** -->
  </v-container>
</template>

<style>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<script>
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "MonthlyClosing",
  data() {
    return {
      // msg: "",
      // snackbar: false,
      // snackbar_color: "",
      // timeout: 3000,
      months: [1 + "月", 2 + "月", 3 + "月", 4 + "月", 5 + "月", 6 + "月", 7 + "月", 8 + "月", 9 + "月", 10 + "月", 11 + "月", 12 + "月"], //実際に表示される月
      year: String(new Date().getFullYear()), //今年
      month: new Date().getMonth() + 1 + "月", //今月
      select: "",
      total: [],
      data: [],
      reloadFlg: true,
      required: (value) => !!value || "必ず入力してください。",
      limit_length: (value) => value.length >= 4 || "西暦を入力してください。",
      select_reporttype: (value) => !!value || "「月別個人品名集計表」「月別個人集計表」「月別所属グループ別個人集計表」のどれかを選択してください。",
      kigyo: [],
      simebi: "",
      sDate: "",
      eDate: "",
      dataCount: 0,
      show: false,
      confirmmessage: "",
      ZeneDate: "", //前月の締め(至)
    };
  },
  components: {
    TitleCard,
    DataCard,
  },
  async created() {
    const self = this;
    //締日の取得
    const KigyoCd = this.$store.state.kigyoCode;
    this.kigyo = await this.$axios
      .get("/m_kigyo_get/info" + "?KigyoCd=" + KigyoCd)
      .then(function (response) {
        return response.data["data"];
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
    if (self.kigyo.length != 0) {
      if (self.kigyo["num_simebi"] === null) {
        self.simebi = 0;
      } else {
        self.simebi = self.kigyo["num_simebi"].toString();
      }
    }
    //締め日範囲計算
    this.CalcSimebi();
    //締めデータ存在するか
    this.IsSimeExsist();
    this.$watch(
      // 2つのプロパティを含めた値を評価させる
      () => [this.$data.year, this.$data.month],
      (value, oldValue) => {
        if (value[0] != oldValue[0] || value[1] != oldValue[1]) {
          //締め日範囲計算
          this.CalcSimebi();
          //締めデータ存在するか
          this.IsSimeExsist();
        }
      }
    );
  },
  methods: {
    async monthlyclosing() {
      if (this.msg != "") {
        this.msg = "";
      }
      //注文予約情報があるか
      const self = this;
      await this.$axios
        .get("/t_chu_yoyaku_get/monthly", {
          params: {
            KigyoCd: this.$store.state.kigyoCode,
            Chumonbi_s: this.sDate,
            Chumonbi_e: this.eDate,
            ChumonJigyo: 0,
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length == 0) {
              self.msg = self.sDate.substring(0, 4) + "年" + self.sDate.substring(4, 6) + "月" + self.sDate.substring(6, 8) + "日～" + self.eDate.substring(0, 4) + "年" + self.eDate.substring(4, 6) + "月" + self.eDate.substring(6, 8) + "日に該当する注文予約データが存在しません。";
            }
          });
        })
        .catch(function (error) {
          console.log(error);
          return;
        });
      if (this.msg != "") {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG(this.msg);
        this.$root.snackbar.showMessage(this.msg, "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      if (this.dataCount > 0) {
        // 更新確認ダイアログ
        this.confirmmessage = "月次締め処理を再実行します。個人請求データ及び弁当業者支払いデータは再作成されます。よろしいですか？";
        this.show = true;
      } else {
        this.updateGetsuji();
      }
    },
    print() {
      window.print();
    },
    async updateGetsuji() {
      //弁当業者支払テーブルと利用者請求テーブルを登録する

      // 利用者請求テーブルの更新
      const params = new URLSearchParams();
      params.append("Simetuki", this.year + this.select);
      params.append("KigyoNo", this.$store.state.kigyoCode);

      //利用者請求情報の削除
      var results = await this.$axios
        .post("/t_user_seikyu_post/delete", params)
        .then(function () {
          return "";
        })
        .catch(function (error) {
          return error;
        });
      if (results != "") {
        console.log(results);
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG(results);
        this.$root.snackbar.showMessage(results, "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }

      //弁当業者支払情報の削除
      results = await this.$axios
        .post("/t_gyosha_siharai_post/delete", params)
        .then(function () {
          return "";
        })
        .catch(function (error) {
          return error;
        });
      if (results != "") {
        console.log(results);
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG(results);
        this.$root.snackbar.showMessage(results, "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }

      //締め処理履歴情報の削除
      results = await this.$axios
        .post("/t_sime_rireki_post/delete", params)
        .then(function () {
          return "";
        })
        .catch(function (error) {
          return error;
        });
      if (results != "") {
        console.log(results);
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG(results);
        this.$root.snackbar.showMessage(results, "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }

      // 利用者請求テーブルの登録
      //dataとセッション情報
      params.append("Chumonbi_s", this.sDate);
      params.append("Chumonbi_e", this.eDate);
      params.append("sUser", this.$store.state.userCode);

      //登録
      var results3 = await this.$axios
        .post("/t_user_seikyu_post/insert", params)
        .then(function () {
          return "";
        })
        .catch(function (error) {
          return error;
        });
      if (results3 != "") {
        console.log(results3);
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG(results3);
        this.$root.snackbar.showMessage(results3, "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }

      // 弁当業者支払テーブルの登録
      //登録
      results3 = await this.$axios
        .post("/t_gyosha_siharai_post/insert", params)
        .then(function () {
          return "";
        })
        .catch(function (error) {
          return error;
        });
      if (results3 != "") {
        console.log(results3);
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG(results3);
        this.$root.snackbar.showMessage(results3, "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }

      // 締め処理履歴テーブルの登録
      //登録
      results3 = await this.$axios
        .post("/t_sime_rireki_post/insert", params)
        .then(function () {
          return "";
        })
        .catch(function (error) {
          return error;
        });
      if (results3 == "") {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("更新しました。");
        this.$root.snackbar.showMessage("更新しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      } else {
        console.log(results3);
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG(results3);
        this.$root.snackbar.showMessage(results3, "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }

      //締め処理ステータス更新
      this.IsSimeExsist();
    },
    // 締め日範囲計算
    async CalcSimebi() {
      var wkYear = 0;
      var wkMonth = 0;
      var wkDay = 0;
      var wkDate = new Date();
      var wkZenyear = "";
      var wkZenMonth = "";
      var wkZendata = [];
      this.ZeneDate = "";
      const self = this;
      if (this.$refs.select_form.validate()) {
        wkYear = Number(this.year);
        if (this.month.substring(0, this.month.length - 1) == "1") {
          wkYear = Number(this.year) - 1;
          wkMonth = 12;
        }
        if (wkMonth != 12) {
          wkMonth = Number(this.month.substring(0, this.month.length - 1)) - 1;
        }
        //月末締めか
        if (this.simebi == 0) {
          if (wkMonth == 12) {
            wkYear = Number(this.year);
          }
          wkMonth = Number(this.month.substring(0, this.month.length - 1));
          wkDay = 1;
        } else {
          if (wkMonth != 12) {
            wkMonth = Number(this.month.substring(0, this.month.length - 1)) - 1;
          }
          wkDay = this.kigyo["num_simebi"] + 1;
        }
        //もし日付が正しくない場合は、翌月の1日
        wkDate = new Date(wkYear.toString(), (wkMonth - 1).toString(), wkDay.toString());
        if (wkDate.getFullYear() != wkYear || wkDate.getMonth() + 1 != wkMonth || wkDate.getDate() != wkDay) {
          wkMonth += 1;
          wkDay = 1;
        }
        if (wkMonth.toString().length != 2) {
          this.sDate = wkYear.toString() + "0" + wkMonth.toString();
        } else {
          this.sDate = wkYear.toString() + wkMonth.toString();
        }
        if (wkDay.toString().length != 2) {
          this.sDate = this.sDate + "0" + wkDay.toString();
        } else {
          this.sDate = this.sDate + wkDay.toString();
        }
        if (this.simebi.length != 2 && this.simebi != 0) {
          //締め日が一桁の時、先頭に0を追加する
          wkDay = "0" + this.simebi;
        } else {
          if (this.simebi == 0) {
            wkDay = new Date(wkYear, wkMonth, 0).getDate();
          } else {
            wkDay = this.simebi;
          }
        }
        //もし日付が正しくない場合は、翌月の1日
        wkMonth = Number(this.month.substring(0, this.month.length - 1));
        if (wkMonth.toString().length != 2) {
          //月が一桁の時、先頭に0を追加する
          this.select = "0" + wkMonth;
        } else {
          this.select = wkMonth;
        }
        wkDate = new Date(this.year.toString(), Number(this.month.substring(0, this.month.length - 1) - 1).toString(), wkDay.toString());
        if (wkDate.getFullYear() != this.year || wkDate.getMonth() + 1 != Number(this.month.substring(0, this.month.length - 1)) || wkDate.getDate() != wkDay) {
          wkDay = new Date(wkYear, wkMonth, 0).getDate();
        }
        if (wkDay.toString().length != 2) {
          wkDay = "0" + wkDay;
        }
        if (wkMonth.toString().length != 2) {
          //月が一桁の時、先頭に0を追加する
          this.eDate = this.year + "0" + wkMonth + wkDay;
        } else {
          this.eDate = this.year + wkMonth + wkDay;
        }

        //前月の締め処理履歴を抽出
        if (this.month.substring(0, this.month.length - 1) == "1") {
          wkZenyear = (Number(this.year) - 1).toString();
          wkZenMonth = "12";
        } else {
          wkZenyear = this.year;
          wkZenMonth = (Number(this.month.substring(0, this.month.length - 1)) - 1).toString();
          if (wkZenMonth.toString().length != 2) {
            //月が一桁の時、先頭に0を追加する
            wkZenMonth = "0" + wkZenMonth;
          }
        }
        wkZendata = await this.$axios
          .get("/t_sime_rireki_get/select_itaru", {
            params: {
              KigyoCd: this.$store.state.kigyoCode,
              Simetuki: wkZenyear + wkZenMonth,
            },
          })
          .then(function (response) {
            return response.data["data"];
          })
          .catch(function (error) {
            console.log(error);
            return [];
          });
        if (wkZendata.length != 0) {
          self.ZeneDate = wkZendata[0]["str_kikan_to"].toString();
        }
        if (this.ZeneDate != "") {
          wkDate = new Date(this.ZeneDate.substring(0, 4), Number(this.ZeneDate.substring(4, 6)) - 1, this.ZeneDate.substring(6, 8));
          wkDate.setDate(wkDate.getDate() + 1);
          console.log(Number(wkDate.getFullYear().toString() + (wkDate.getMonth() + 1).toString().padStart(2, "0") + wkDate.getDate().toString().padStart(2, "0")));
          if (Number(wkDate.getFullYear().toString() + (wkDate.getMonth() + 1).toString().padStart(2, "0") + wkDate.getDate().toString().padStart(2, "0")) != Number(this.sDate)) {
            this.sDate = wkDate.getFullYear().toString() + (wkDate.getMonth() + 1).toString().padStart(2, "0") + wkDate.getDate().toString().padStart(2, "0");
          }
        }
      } else {
        return;
      }
    },
    //利用者請求テーブル、弁当業者支払テーブルに存在するかどうか
    async IsSimeExsist() {
      const self = this;
      this.dataCount = 0;
      await this.$axios
        .get("/t_user_seikyu_get/seikyu_info", {
          params: {
            KigyoCd: this.$store.state.kigyoCode,
            Simetuki: this.year + this.select,
          },
        })
        .then(function (response) {
          if (response.data["data"]["count"] != 0) {
            self.dataCount += response.data["data"]["count"];
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      await this.$axios
        .get("/t_gyosha_siharai_get/gyosha_siharai_info", {
          params: {
            KigyoCd: this.$store.state.kigyoCode,
            Simetuki: this.year + this.select,
          },
        })
        .then(function (response) {
          if (response.data["data"]["count"] != 0) {
            self.dataCount += response.data["data"]["count"];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //okボタン押下時
    okAction() {
      this.show = false;
      this.updateGetsuji();
    },
    //キャンセルボタン押下時
    cancelAction() {
      this.show = false;
    },
    // メッセージ表示（正常）
    // noticeOK(msg) {
    //   this.msg = msg;
    //   this.snackbar_color = "green";
    //   this.snackbar = true;
    // },
    // メッセージ表示（エラー）
    // noticeNG(msg) {
    //   this.msg = msg;
    //   this.snackbar_color = "red";
    //   this.snackbar = true;
    // },
  },
};
</script>
