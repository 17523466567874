<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：StartPage.vue
  モジュール名：スタート画面
  備考：
-------------------------------------------------->
<template>
  <v-container>
    <v-card width="500px" class="mx-auto py-2" outlined>
      <!-- タイトル -->
      <v-card-title class="px-8 justify-center">
        <h3>ようこそ</h3>
      </v-card-title>
      <!-- フォーム -->
      <v-card-text class="px-8 py-0">
        <v-form ref="auth">
          <!-- 企業コード -->
          <v-text-field dense :disabled="!canInput" :type="'url'" prepend-icon="business" label="企業コード" v-model="auth_kigyo" maxlength="6" @keypress.prevent.enter.exact="clickAuth" :rules="kigyo_rule" ref="kigyo" outlined flat />
        </v-form>
      </v-card-text>
      <!-- ボタン -->
      <v-card-actions class="px-8 justify-center">
        <v-btn :disabled="!canInput" class="info px-10" color="warning" @click="clickAuth" depressed block>ログインページへ</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "StartPage",
  data: () => ({
    canInput: false,
    kigyo: [],
    auth_kigyo: "",
    // snackbar: false,
    // snackbar_msg: "",
    // snackbar_timeout: 3000,
  }),
  methods: {
    //----------------------------------------------------------------
    // 初期化処理
    //----------------------------------------------------------------
    async init() {
      // ストアの初期化
      this.$store.commit("reset");
      // サーバー接続チェック
      if (!(await this.checkServer())) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("サーバーにアクセスできません。", -1);
        this.$root.snackbar.showMessage("サーバーにアクセスできません。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // データベース接続チェック
      if (!(await this.checkDB())) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("データベースにアクセスできません。", -1);
        this.$root.snackbar.showMessage("データベースにアクセスできません。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 入力項目のロック解除
      this.canInput = true;
    },

    //----------------------------------------------------------------
    // イベント処理
    //----------------------------------------------------------------

    // ログインボタン押下時処理
    async clickAuth() {
      // 入力チェック
      if (!this.$refs.kigyo.validate()) {
        return;
      }
      // 会社情報取得
      await this.getKigyo();
      // 登録チェック
      if (this.kigyo.length == 0) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("企業情報が登録されていません。");
        this.$root.snackbar.showMessage("企業情報が登録されていません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }

      //---2024.02.25 ADA START---
      // WebView2にメッセージを送信
      try {
        window.chrome.webview.postMessage({
          mode: "kigyo",
          kigyoCode: this.auth_kigyo,
        });
      } catch (error) {
        // エラーが発生した場合の処理
        //        console.error('エラーが発生しました:', error.message);
      }
      //---2024.02.25 ADA END---

      // ログイン画面へ遷移
      this.$router.push("/login/" + this.auth_kigyo);
    },

    //----------------------------------------------------------------
    // メソッド
    //----------------------------------------------------------------

    // サーバー接続チェック
    async checkServer() {
      let wkBoolean = await this.$axios
        .get("/auth/server", { timeout: 10000 })
        .then(function (response) {
          return response.data["result"];
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
      return wkBoolean;
    },

    // データベース接続チェック
    async checkDB() {
      let wkBoolean = await this.$axios
        .get("/auth/db", { timeout: 10000 })
        .then(function (response) {
          return response.data["result"];
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
      return wkBoolean;
    },

    // 企業情報取得処理
    async getKigyo() {
      const KigyoCd = this.auth_kigyo;
      this.kigyo = await this.$axios
        .get("/m_kigyo_get/info" + "?KigyoCd=" + KigyoCd)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    // メッセージ表示（エラー）
    // noticeNG(msg, time = 3000) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "red";
    //   this.snackbar_timeout = time;
    //   this.snackbar = true;
    // },
  },
  computed: {
    //----------------------------------------------------------------
    // バリデーションルール
    //----------------------------------------------------------------

    // 企業コード
    kigyo_rule() {
      return [!!this.auth_kigyo || "企業コードを入力してください。", !!this.auth_kigyo.match("^[0-9a-zA-Z]+$") || "半角英数字のみ入力可能です。"];
    },
  },
  mounted() {
    // 初期化
    this.init();
  },
};
</script>
