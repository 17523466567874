<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：MultipleOrder.vue
  モジュール名：一括予約登録画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <!-- タイトル -->
    <title-card title="一括予約登録"> </title-card>
    <!-- データ -->
    <data-card class="px-2">
      <!-- 利用者選択 -->
      <v-card-title class="pa-2">利用者選択</v-card-title>
      <v-card class="mb-2" outlined>
        <v-sheet class="px-4 py-2 d-flex" style="align-items: center">
          <span>事業所：</span>
          <v-responsive class="mr-6" min-width="240px" max-width="240px">
            <v-select v-model="filter_jigyo" item-text="str_jigyo_name" item-value="num_jigyo_no" :items="filter_jigyo_list" no-data-text="データがありません。" hide-details outlined dense></v-select>
          </v-responsive>
          <span>所属・グループ：</span>
          <v-responsive class="mr-6" min-width="240px" max-width="240px">
            <v-select v-model="filter_busho" item-text="str_busho_name" item-value="num_busho_no" :items="filter_busho_list" no-data-text="データがありません。" hide-details outlined dense></v-select>
          </v-responsive>
        </v-sheet>
        <v-divider></v-divider>
        <v-data-table v-model="select_user" item-key="str_user_cd" :headers="user_headers" :items="user_list" :fixed-header="true" :hide-default-footer="true" :items-per-page="-1" no-data-text="利用者のデータがありません" height="calc(100vh - 580px)" no-results-text="該当するデータがありません。" style="white-space: pre-line" show-select dense>
          <template v-slot:[`item.str_chumonbi`]="{ item }">
            <span v-if="item.str_chumonbi == null">なし</span>
            <span v-if="item.str_chumonbi != null">{{ fmtDate_YYYYMD(item.str_chumonbi) }}</span>
          </template>
        </v-data-table>
      </v-card>

      <v-divider></v-divider>

      <!-- 注文内容 -->
      <v-card-title class="px-0 py-2">
        <span class="px-2">注文内容</span>
        <v-spacer></v-spacer>
        <v-btn @click="onClickOrder()" class="ml-2" color="teal" height="" dark depressed>予約登録</v-btn>
      </v-card-title>
      <v-card class="mb-2" outlined>
        <v-sheet class="pa-4" style="align-items: center">
          <v-form ref="order">
            <v-row>
              <v-col cols="3" class="pb-0">
                <!-- 注文場所 -->
                <div>
                  <div class="d-flex align-center"><v-icon>place</v-icon>&ensp;注文場所</div>
                  <v-select class="pt-1" v-model="select_jigyo" :items="jigyo_list" item-text="str_jigyo_name" item-value="num_jigyo_no" placeholder="選択してください" no-data-text="注文場所が登録されていません。" :rules="jigyo_rule" @change="onChangeJigyo" outlined dense></v-select>
                </div>
              </v-col>
              <!-- 弁当業者 -->
              <v-col cols="3" class="pb-0">
                <div>
                  <div class="d-flex align-center"><v-icon>local_shipping</v-icon>&ensp;弁当業者</div>
                  <v-select class="pt-1" v-model="select_gyosha" :items="gyosha_list" item-text="str_gyosha_name" item-value="num_gyosha_no" placeholder="選択してください" no-data-text="弁当業者が登録されていません。" :rules="gyosha_rule" @change="onChangeGyosha" outlined dense :disabled="select_jigyo == 0"></v-select>
                </div>
              </v-col>
              <!-- 弁当-->
              <v-col cols="3" class="pb-0">
                <div>
                  <div class="d-flex align-center">
                    <v-icon>bento</v-icon>&ensp;弁当
                    <!-- <v-spacer></v-spacer><v-btn color="orange darken-1" class="px-1" height="24" text outlined @click="dialog_menu = true"> メニュー表 </v-btn> -->
                  </div>
                  <v-select class="pt-1" v-model="select_bento" :items="bento_list" item-text="str_bento_name" item-value="num_bento_no" placeholder="選択してください" no-data-text="弁当が登録されていません。" :rules="bento_rule" @change="onChangeBento" outlined dense :disabled="select_gyosha == 0"></v-select>
                </div>
              </v-col>
              <!-- 個数 -->
              <v-col cols="3" class="pb-0">
                <div>
                  <div class="d-flex align-center"><v-icon>tag</v-icon>&ensp;個数</div>
                  <v-select class="pt-1" v-model="select_count" :items="count_list" :rules="count_rule" placeholder="選択してください" @change="onChangeCount" hide-details outlined dense></v-select>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <!-- 開始日 -->
                <div class="mt-1">
                  <div class="d-flex align-center"><v-icon>event</v-icon>&ensp;開始日</div>
                  <v-menu v-model="dialog_dtp_st" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="pt-0" :value="fmtDate_YYYYMD(select_date_st)" readonly v-bind="attrs" v-on="on" hide-details outlined dense></v-text-field>
                    </template>
                    <v-date-picker v-model="select_date_st" @input="dialog_dtp_st = false" locale="jp-ja" :min="min_date" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="3">
                <!-- 終了日 -->
                <div class="mt-1">
                  <div class="d-flex align-center"><v-icon>event</v-icon>&ensp;終了日</div>
                  <v-menu v-model="dialog_dtp_ed" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="pt-0" :value="fmtDate_YYYYMD(select_date_ed)" readonly v-bind="attrs" v-on="on" hide-details outlined dense></v-text-field>
                    </template>
                    <v-date-picker v-model="select_date_ed" @input="dialog_dtp_ed = false" locale="jp-ja" :min="select_date_st" :max="max_date" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="6">
                <!-- 曜日 -->
                <div class="mt-1">
                  <v-container class="px-0 py-0" fluid>
                    <div class="d-flex align-center"><v-icon>date_range</v-icon>&ensp;予約する曜日を選択</div>
                    <v-slide-group v-model="select_week" class="py-1" multiple mandatory>
                      <v-slide-item v-for="week in jp_week" :key="week.value" v-slot="{ active, toggle }" :value="week.value">
                        <v-btn class="mx-1" :input-value="active" active-class="primary white--text" depressed fab small @click="toggle">{{ week.name }}</v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-container>
                </div>
                <!-- 合計金額 -->
                <!-- <div class="mx-2 pt-8">
                  <h3 align="right">合計金額（１回毎）&#65306;{{ fmtPrice(price_order) }}円</h3>
                </div> -->
              </v-col>
            </v-row>

            <!-- <v-divider class="my-4"></v-divider> -->

            <!-- <v-row> -->
            <!-- 受付状況 -->
            <!-- <v-col cols="6">
                <div class="pl-2">本日の受付状況</div>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">締切時間</th>
                        <th class="text-left">受付状況</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ fmtTime_HM(simetime) }}</td>
                        <td>
                          <font :color="shime_color">{{ shime_state }}</font>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col> -->
            <!-- ボタン -->
            <!-- <v-col cols="6">
                <v-card-actions class="mt-12 pb-0 px-0">
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="clickOrder" :disabled="loading_order"> 確定 </v-btn>
                  <v-btn color="blue darken-1" text @click="dialog_order = false" :disabled="loading_order"> 閉じる </v-btn>
                </v-card-actions>
              </v-col> -->
            <!-- </v-row> -->
          </v-form>
        </v-sheet>
      </v-card>
    </data-card>
    <!-- *** 表示部 ここまで *** -->
  </v-container>
</template>

<script>
import moment from "moment";
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "SystemLog",
  data: () => ({
    // スナックバー
    // snackbar: false,
    // snackbar_msg: "",
    // snackbar_color: "",
    // snackbar_timeout: 3000,
    // 共通
    calendar: moment().format("yyyy-MM-DD"),
    calendar_list: [], // カレンダーリスト
    user_list: [], // 利用者リスト
    jigyo_list: [], // 事業所リスト
    busho_list: [], // 事業所リスト
    gyosha_list: [], // 業者リスト
    bento_list: [], // 弁当リスト
    count_list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], // 個数リスト
    order_info: [], // 予約情報
    gyosha_info: [], // 業者情報
    bento_info: [], // 弁当情報
    tab_list: ["メニュー表", "献立表"], // タブ項目
    select_user: [], // 選択した利用者
    select_jigyo: 0, // 選択した事業所（注文場所）
    select_gyosha: 0, // 選択した業者
    select_bento: 0, // 選択した弁当
    select_count: 1, // 選択した個数
    select_date_st: moment().format("yyyy-MM-DD"), // 選択した開始日
    select_date_ed: moment().format("yyyy-MM-DD"), // 選択した終了日
    select_week: ["1", "2", "3", "4", "5"], // 選択した曜日
    filter_jigyo: 0, //  選択した事業所（絞り込み条件）
    filter_busho: 0, // 選択した部署（絞り込み条件）
    price: 0, // 単品価格
    price_order: 0, // 合計金額
    dialog_menu: false, // メニューダイアログ
    dialog_dtp_st: false, // 開始日ダイアログ
    dialog_dtp_ed: false, // 終了日ダイアログ
    dialog_calendar: false, // カレンダーダイアログ
    // dialog_confirm: false, // 確認ダイアログ
    loading_order: false, // ローディング状態
    shime_state: "",
    shime_color: "",
  }),
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    //----------------------------------------------------------------
    // 初期化処理
    //----------------------------------------------------------------
    async init() {
      // 利用者リスト読み込み
      await this.getUser();
      // 部署リスト読み込み
      await this.getBusho();
      // 事業所リスト読み込み
      await this.getJigyo();
      // 弁当業者リスト読み込み
      // await this.setGyosha();
      // 弁当業者情報読み込み
      // await this.setGyoshaInfo();
      // 弁当リスト読み込み
      // await this.setBento();
    },

    //----------------------------------------------------------------
    // イベント処理
    //----------------------------------------------------------------

    // 注文場所変更時処理
    async onChangeJigyo() {
      // 弁当業者リストを更新
      await this.setGyosha();
      // 弁当業者情報読み込み
      await this.setGyoshaInfo();
      // 弁当リストを更新
      await this.setBento();
      // エラーチェック初期化
      this.$refs.order.resetValidation();
    },

    // 弁当業者変更時処理
    async onChangeGyosha() {
      // 弁当業者情報読み込み
      await this.setGyoshaInfo();
      // 弁当リストを更新
      await this.setBento();
      // エラーチェック初期化
      this.$refs.order.resetValidation();
    },

    // 弁当変更時処理
    async onChangeBento() {
      // 弁当情報を更新
      await this.setBentoInfo();
      // 金額を算出
      this.price = this.bento_info.num_kingaku;
      this.price_order = this.sumPrice(this.price, this.select_count);
    },

    // 個数変更時処理
    onChangeCount() {
      // 合計金額を算出
      this.price_order = this.sumPrice(this.price, this.select_count);
    },

    // メニュー表選択時処理
    async onClickMenu(row) {
      // 選択した弁当を反映
      this.select_bento = row.num_bento_no;
      // 弁当変更時処理
      await this.onChangeBento();
      // ダイアログを閉じる
      this.dialog_menu = false;
    },

    // 「予約カレンダー」ボタンクリック時
    async onClickCalendar() {
      this.dialog_calendar = true;
    },

    // 「今日」ボタン押下時（カレンダー）
    onClickToday() {
      // カレンダーを当月にセット
      this.calendar = moment().format("yyyy-MM-DD");
    },

    // 「予約登録」ボタンクリック時
    async onClickOrder() {
      // 入力チェック
      if (this.select_user.length == 0) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("利用者が選択されていません。");
        this.$root.snackbar.showMessage("利用者が選択されていません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      if (!this.$refs.order.validate()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("未選択項目があります。予約内容を確認してください。");
        this.$root.snackbar.showMessage("未選択項目があります。予約内容を確認してください。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 締め処理チェック
      if (moment(this.select_date_st).format("yyyyMMDD") == this.$store.state.nowDate && !(await this.chkOrderJokyo(this.select_jigyo, this.select_gyosha))) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("本日の注文は締め切られたため、開始日を明日以降に設定してください。");
        this.$root.snackbar.showMessage("本日の注文は締め切られたため、開始日を明日以降に設定してください。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 予約情報登録
      await this.insertOrder();
      // 利用者リスト読み込み
      await this.getUser();
    },

    //----------------------------------------------------------------
    // メソッド
    //----------------------------------------------------------------

    // 合計金額算出処理
    sumPrice(price, count) {
      return Number(price) * Number(count);
    },

    // 利用者情報取得処理
    async getUser() {
      const KigyoCd = this.$store.state.kigyoCode;
      this.user_list = await this.$axios
        .get("/m_user_get/order_list" + "?KigyoCd=" + KigyoCd)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    // 部署情報取得処理
    async getBusho() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.filter_jigyo;
      this.busho_list = await this.$axios
        .get("/m_busho_get/busho_list" + "?KigyoCd=" + KigyoCd + "&JigyoNo=" + JigyoNo)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    // 事業所情報取得処理
    async getJigyo() {
      const KigyoCd = this.$store.state.kigyoCode;
      this.jigyo_list = await this.$axios
        .get("/m_jigyo_get/jigyosyo_list" + "?KigyoCd=" + KigyoCd)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    // 弁当業者リストセット処理
    async setGyosha() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo;
      this.gyosha_list = await this.getGyosha(KigyoCd, JigyoNo);
    },

    // 弁当業者リスト取得処理
    async getGyosha(KigyoCd, JigyoNo) {
      let wkArray = await this.$axios
        .get("/m_gyosha_get/gyosha_list" + "?KigyoCd=" + KigyoCd + "&JigyoNo=" + JigyoNo)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      return wkArray;
    },

    // 弁当業者詳細情報セット処理
    async setGyoshaInfo() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo;
      const GyoshaNo = this.select_gyosha;
      this.gyosha_info = await this.getGyoshaInfo(KigyoCd, JigyoNo, GyoshaNo);
    },

    // 弁当業者詳細情報取得処理
    async getGyoshaInfo(KigyoCd, JigyoNo, GyoshaNo) {
      let wkArray = await this.$axios
        .get("/m_gyosha_get/gyosha_info" + "?KigyoCd=" + KigyoCd + "&JigyoNo=" + JigyoNo + "&GyoshaNo=" + GyoshaNo)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      return wkArray;
    },

    // 弁当リストセット処理
    async setBento() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo;
      const GyoshaNo = this.select_gyosha;
      this.bento_list = await this.getBento(KigyoCd, JigyoNo, GyoshaNo);
    },

    // 弁当リスト取得処理
    async getBento(KigyoCd, JigyoNo, GyoshaNo) {
      let wkArray = await this.$axios
        .get("/m_bento_get/bento_list" + "?KigyoCd=" + KigyoCd + "&JigyoNo=" + JigyoNo + "&GyoshaNo=" + GyoshaNo)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      return wkArray;
    },

    // 弁当詳細情報セット処理
    async setBentoInfo() {
      const KigyoCd = this.$store.state.kigyoCode;
      const JigyoNo = this.select_jigyo;
      const GyoshaNo = this.select_gyosha;
      const BentoNo = this.select_bento;
      this.bento_info = await this.getBentoinfo(KigyoCd, JigyoNo, GyoshaNo, BentoNo);
    },

    // 弁当詳細情報取得処理
    async getBentoinfo(KigyoCd, JigyoNo, GyoshaNo, BentoNo) {
      let wkArray = await this.$axios
        .get("/m_bento_get/bento_info" + "?KigyoCd=" + KigyoCd + "&JigyoNo=" + JigyoNo + "&GyoshaNo=" + GyoshaNo + "&BentoNo=" + BentoNo)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      return wkArray;
    },

    // 予約状況件数取得処理
    async chkOrderJokyo(JigyoNo, GyoshaNo) {
      const KigyoCd = this.$store.state.kigyoCode;
      const Chumonbi = moment().format("yyyyMMDD");
      let wkBoolean = await this.$axios
        .get("/t_chu_jokyo_get/jokyo_count" + "?KigyoCd=" + KigyoCd + "&JigyoNo=" + JigyoNo + "&GyoshaNo=" + GyoshaNo + "&Chumonbi=" + Chumonbi)
        .then(function (response) {
          if (response.data["data"] > 0) {
            return false;
          }
          return true;
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
      return wkBoolean;
    },

    // 予約処理用配列セット処理
    async insertOrder() {
      // 選択された利用者コードを抽出
      let wkUserList = [];
      this.select_user.forEach((element) => {
        wkUserList.push(element.str_user_cd);
      });
      // パラメタセット
      const JigyoCd = this.select_jigyo;
      const UserList = wkUserList;
      const Chu_st = this.select_date_st;
      const Chu_ed = this.select_date_ed;
      const Chu_week = this.select_week.join(",");
      const GyoshaNo = this.select_gyosha;
      const BentoNo = this.select_bento;
      const Kingaku = this.price;
      const Kosuu = this.select_count;
      const Goukei = this.price_order;
      // 登録処理
      await this.fncInsertOrder(JigyoCd, UserList, Chu_st, Chu_ed, Chu_week, GyoshaNo, BentoNo, Kingaku, Kosuu, Goukei);
    },

    // 予約登録処理
    async fncInsertOrder(JigyoCd, UserList, Chu_st, Chu_ed, Chu_week, GyoshaNo, BentoNo, Kingaku, Kosuu, Goukei) {
      const params = new URLSearchParams();
      params.append("KigyoCd", this.$store.state.kigyoCode);
      params.append("JigyoNo", JigyoCd);
      params.append("UserList", UserList);
      params.append("Chu_st", Chu_st);
      params.append("Chu_ed", Chu_ed);
      params.append("Chu_week", Chu_week);
      params.append("GyoshaNo", GyoshaNo);
      params.append("BentoNo", BentoNo);
      params.append("Kingaku", Kingaku);
      params.append("Kosuu", Kosuu);
      params.append("Goukei", Goukei);
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "BAT");
      let result = await this.$axios
        .post("/t_chu_yoyaku_post/insert_multi", params)
        .then(function (response) {
          if (response.status == 200) {
            return true;
          } else {
            return false;
          }
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
      if (result == true) {
        // 予約成功
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("予約を登録しました。");
        this.$root.snackbar.showMessage("予約を登録しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        this.select_user = [];
      } else {
        // 予約失敗
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("予約の登録時にエラーが発生しました。");
        this.$root.snackbar.showMessage("予約の登録時にエラーが発生しました。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      }
    },

    // 受付状況を判断
    checkShime() {
      const simetime = this.simetime;
      if (simetime != "") {
        if (this.gyosha_info.num_chu_jokyo == null) {
          if (simetime > this.$store.state.nowTime) {
            this.shime_state = "受付中";
            this.shime_color = "blue";
          } else {
            this.shime_state = "締切";
            this.shime_color = "red";
          }
        } else {
          this.shime_state = "締切";
          this.shime_color = "red";
        }
      } else {
        this.shime_state = "-";
        this.shime_color = "black";
      }
    },

    // 注文確定判定
    async checkKakutei() {
      const chumonbi = this.order_info.str_chumonbi;
      const jigyo = this.order_info.num_jigyo_no;
      const gyosha = this.order_info.num_gyosha_no;
      let result = chumonbi > this.$store.state.nowDate || (chumonbi == this.$store.state.nowDate && (await this.chkOrderJokyo(jigyo, gyosha)));
      this.edit_enable = result;
    },

    // メッセージ表示（正常）
    // noticeOK(msg) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "green";
    //   this.snackbar = true;
    // },

    // メッセージ表示（エラー）
    // noticeNG(msg) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "red";
    //   this.snackbar = true;
    // },

    // 日付を"yyyy年 M月 D日"形式で返却
    fmtDate_YYYYMD(date) {
      if (date != "") {
        let fmtDate = moment(date).format("YYYY年 M月 D日");
        let week = this.jp_week.find((item) => item.value === String(moment(date).day())).name;
        let fmtWeek = " (" + week + ")";
        return fmtDate + fmtWeek;
      } else {
        return "";
      }
    },

    // 時刻を"H:M"形式で返却
    fmtTime_HM(time) {
      if (time != "") {
        return String(time).substring(0, 2).replace(/^0/, "") + ":" + String(time).substring(2, 4);
      } else {
        return "--:--";
      }
    },

    // 金額をカンマ区切りで返却
    fmtPrice(price) {
      return Number(price).toLocaleString();
    },
  },

  computed: {
    // 利用者選択テーブル ヘッダー
    user_headers() {
      return [
        {
          text: "利用者コード",
          sortable: true,
          value: "str_user_cd",
          align: "start",
          width: "120px",
        },
        {
          text: "氏名",
          sortable: true,
          value: "str_user_name",
          align: "start",
          width: "140px",
        },
        {
          text: "事業所",
          sortable: true,
          value: "str_jigyo_name",
          align: "start",
          width: "140px",
          filter: (value) => {
            if (this.filter_jigyo == 0) return true;
            return value == this.jigyo_list.find((i) => i.num_jigyo_no == this.filter_jigyo).str_jigyo_name;
          },
        },
        {
          text: "所属・グループ",
          sortable: true,
          value: "str_busho_name",
          align: "start",
          width: "140px",
          filter: (value) => {
            if (this.filter_busho == 0) return true;
            return value == this.busho_list.find((i) => i.num_busho_no == this.filter_busho).str_busho_name;
          },
        },
        {
          text: "最終予約日",
          sortable: true,
          value: "str_chumonbi",
          align: "start",
          width: "160px",
        },
      ];
    },

    // メニュー選択テーブル ヘッダー
    menu_headers() {
      return [
        { text: "弁当名", align: "start", sortable: false, value: "str_bento_name" },
        { text: "金額（円）", align: "end", sortable: false, value: "char_kingaku" },
        { text: "カロリー（kcal）", align: "end", sortable: false, value: "char_bento_calory" },
      ];
    },

    // 日本の曜日
    jp_week() {
      return [
        { name: "月", value: "1" },
        { name: "火", value: "2" },
        { name: "水", value: "3" },
        { name: "木", value: "4" },
        { name: "金", value: "5" },
        { name: "土", value: "6" },
        { name: "日", value: "0" },
      ];
    },

    // 事業所絞り込み条件
    filter_jigyo_list() {
      let list = [{ str_jigyo_name: "すべて", num_jigyo_no: 0 }];
      return list.concat(this.jigyo_list);
    },

    // 部署絞り込み条件
    filter_busho_list() {
      let list = [{ str_busho_name: "すべて", num_busho_no: 0 }];
      return list.concat(this.busho_list);
    },

    // ストアの現在時刻を返却
    time() {
      return this.$store.getters.getTime;
    },

    // // 祝日リストと予約リストを返却
    // calendar_list() {
    //   let wkEventList = [];
    //   return wkEventList.concat(this.holiday_list, this.event_list, this.order_list_monthly);
    // },

    // 選択中の弁当業者名を返却
    gyosha_name() {
      if (this.gyosha_list.length != 0 && this.gyosha_list.some((u) => u.num_gyosha_no == this.select_gyosha)) {
        return this.gyosha_list.find((i) => i.num_gyosha_no == this.select_gyosha).str_gyosha_name;
      } else {
        return "";
      }
    },

    // 選択中の弁当業者名の締切時間を返却
    simetime() {
      if (this.gyosha_info.str_simetime != undefined) {
        return this.gyosha_info.str_simetime;
      } else {
        return "";
      }
    },

    // 日付範囲の最小値を返却
    min_date() {
      // 今日の日付
      return moment().format("yyyy-MM-DD");
    },

    // 日付範囲の最大値を返却
    max_date() {
      // 開始日で選択した日の半年後
      return moment(this.select_date_st).add(6, "M").format("yyyy-MM-DD");
    },

    //----------------------------------------------------------------
    // バリデーションルール
    //----------------------------------------------------------------
    // 事業所
    jigyo_rule() {
      return [(value) => !(value == 0) || "注文場所を選択してください。"];
    },

    // 弁当業者
    gyosha_rule() {
      return [(value) => !(value == 0) || "弁当業者を選択してください。"];
    },

    // 弁当
    bento_rule() {
      return [(value) => !(value == 0) || "弁当を選択してください。"];
    },

    // 個数
    count_rule() {
      return [(value) => !(value == 0) || "個数を選択してください。"];
    },

    // 予約する曜日
    week_rule() {
      return [() => this.select_week.length != 0 || "１つ以上選択してください。"];
    },
  },

  watch: {
    // 開始日変更時処理
    select_date_st: function () {
      // 日付チェック
      if (this.select_date_st > this.select_date_ed) {
        // 終了日より開始日が後の場合、終了日に開始日をセット
        this.select_date_ed = this.select_date_st;
      } else if (this.select_date_ed > moment(this.select_date_st).add(6, "M").format("yyyy-MM-DD")) {
        // 開始日の6か月後より終了日が後の場合、終了日に開始日の6か月後の日付をセット
        this.select_date_ed = moment(this.select_date_st).add(6, "M").format("yyyy-MM-DD");
      }
    },
    time: function () {
      // 1秒ごとに受付状況を確認
      this.checkShime();
    },
  },

  mounted() {
    // 初期化
    this.init();
  },
};
</script>
