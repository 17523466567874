<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：OfficeMaster.vue
  モジュール名：事業所マスタ画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <title-card title="事業所マスタ">
      <v-btn color="teal" dark depressed @click="createNew()">新規登録</v-btn>
    </title-card>
    <data-card>
      <v-data-table :headers="headers" :items="datas" item-key="item.key" :fixed-header="true" :hide-default-footer="true" :items-per-page="-1" no-data-text="事業所のデータがありません。" height="calc(100vh - 188px)" style="white-space: pre-line" @click:row="clickRow"></v-data-table>
    </data-card>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** 編集用ダイアログ ここから *** -->
    <v-dialog v-model="dialog" width="800" persistent no-click-animation>
      <v-card class="pa-4">
        <h4>事業所情報</h4>
        <v-form ref="select_form" class="mr-5 ml-5 w-50 mt-5">
          <v-row>
            <v-col>
              <div>事業所名<span class="red--text">*</span></div>
              <v-text-field outlined class="text" v-model="Jigyosyo" dense maxlength="50" @change="change_flg = true" :rules="[required]"></v-text-field>
              <div>事業所名カナ<span class="red--text">*</span></div>
              <v-text-field outlined class="text" dense v-model="JigyosyoKana" maxlength="50" @change="change_flg = true" :rules="[required, kana]"></v-text-field>
              <div>郵便番号<span class="red--text">*</span></div>
              <v-text-field outlined class="text" dense v-model="post" maxlength="8" @change="change_flg = true" hint="ハイフンありで入力してください。" :rules="[required, post_]"></v-text-field>
              <div>住所<span class="red--text">*</span></div>
              <v-text-field outlined class="text" dense v-model="Address" maxlength="50" @change="change_flg = true" :rules="[required]"></v-text-field>
            </v-col>
            <v-col>
              <div>電話番号<span class="red--text">*</span></div>
              <v-text-field outlined class="text" dense v-model="Tel1" maxlength="15" @change="change_flg = true" hint="ハイフンありで入力してください。" :rules="[required, telephone]"></v-text-field>
              <!-- <div>電話番号2</div>
              <v-text-field outlined class="text" dense v-model="Tel2" maxlength="15" @change="change_flg = true" :rules="[telephone]"></v-text-field> -->
              <div>FAX</div>
              <v-text-field outlined class="text" dense v-model="Fax" maxlength="15" @change="change_flg = true" hint="ハイフンありで入力してください。" :rules="[fax]"></v-text-field>
              <div>代表メールアドレス<span class="red--text">*</span></div>
              <v-text-field outlined class="text" dense v-model="mail" maxlength="50" @change="change_flg = true" :rules="[required, mail_]"></v-text-field>
              <!-- <div>内線</div>
              <v-text-field outlined class="text" dense v-model="naisen" maxlength="6" @change="change_flg = true" :rules="[naisen_]"></v-text-field> -->
            </v-col>
          </v-row>
        </v-form>
        <!-- 下部のボタン群 -->
        <v-col align="right">
          <v-btn v-show="isNew" height="40" width="70" color="blue darken-1" text @click="btn_insert()" class="mr-5">登録</v-btn>
          <v-btn v-show="isExisting" height="40" width="70" color="green darken-1" text @click="btn_update()" class="mr-5">保存</v-btn>
          <v-btn v-show="isExisting" height="40" color="red darken-1" text @click="btn_delete()" class="mr-5">削除</v-btn>
          <v-btn height="40" width="70" color="blue darken-1" text @click="dialogdisp(false)">閉じる</v-btn>
        </v-col>
      </v-card>

      <!-- <template v-slot:activator="{}"> -->
      <!-- テーブル -->
      <!-- <div class="cr-orange"> -->
      <!-- <v-card class="mx-auto pr-15 pl-15 pt-5 pb-5 cr-orange" elevation="1"> -->
      <!-- </v-card> -->
      <!-- </div> -->
      <!-- </template> -->
    </v-dialog>
    <!-- *** 編集用ダイアログ ここまで *** -->
  </v-container>
</template>

<script>
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "OfficeMaster",
  data() {
    return {
      //エラーメッセージ
      // msg: "",
      datas: [],
      KigyoNo: 0,
      // snackbar: false,
      //エラー表示時間
      // timeout: 3000,
      //ポップアップ表示、非表示
      dialog: false,
      //ボタン表示、非表示
      isExisting: false,
      isNew: false,
      //テキストボックス
      Jigyosyo: "",
      JigyosyoKana: "",
      Tel1: "",
      Tel2: "",
      Fax: "",
      Address: "",
      mail: "",
      naisen: "",
      post: "",
      jigyosyono: "",
      change_flg: false,
      required: (value) => !!value || "必ず入力してください。",
      kana: (value) => /^[ア-ンヴ゛゜ァ-ォャ-ョー]+$/.test(value) || "事業所名カナはカナ文字を入力してください。",
      telephone: (value) => /^[0-9- ]*$/.test(value) || "電話番号は半角数字を入力してください。",
      fax: (value) => /^[0-9- ]*$/.test(value) || "FAX番号は半角数字を入力してください。",
      post_: (value) => /^[0-9- ]+$/.test(value) || "郵便番号は半角数字を入力してください。",
      naisen_: (value) => /^[0-9 ]+$/.test(value) || "内線は半角数字を入力してください。",
      mail_: (value) => /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(value) || "メールアドレスは半角英数字または半角記号を入力してください。",
      snackbar_color: "",
    };
  },
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    //入力ダイアログ表示、非表示
    dialogdisp(dispflg) {
      if (dispflg) {
        this["dialog"] = true;
      } else {
        if (this.change_flg == true) {
          if (window.confirm("保存せずに終了します。\n入力内容は破棄されますが、よろしいですか？")) {
            this["dialog"] = false;
            this.change_flg = false;
          }
        } else {
          this["dialog"] = false;
        }
      }
    },
    //新規作成時
    createNew() {
      this.Jigyosyo = "";
      this.JigyosyoKana = "";
      this.Address = "";
      this.Tel1 = "";
      this.Tel2 = "";
      this.Fax = "";
      this.post = "";
      this.naisen = "";
      this.mail = "";
      this["isExisting"] = false;
      this["isNew"] = true;
      this["dialog"] = true;
      this.$nextTick(() => {
        if (this.$refs["select_form"]) {
          this.$refs.select_form.resetValidation();
        }
      });
    },
    //テーブル行選択時
    clickRow(row) {
      this.Jigyosyo = row["str_jigyo_name"];
      this.JigyosyoKana = row["str_jigyo_kana"];
      this.Address = row["str_address"];
      this.Tel1 = row["str_tel1"];
      this.Tel2 = row["str_tel2"];
      this.Fax = row["str_fax"];
      this.jigyosyono = row["num_jigyo_no"];
      this.post = row["str_post"];
      this.naisen = row["str_naisen"];
      this.mail = row["str_mail"];
      this["isExisting"] = true;
      this["isNew"] = false;
      this.dialogdisp(true);
    },
    btn_insert() {
      if (this.$refs.select_form.validate()) {
        this.insertjigyosho();
      }
    },
    btn_update() {
      if (this.$refs.select_form.validate()) {
        this.updatejigyosho();
      }
    },
    btn_delete() {
      if (window.confirm("削除します。よろしいですか？")) {
        this.deletejigyosho();
      }
    },
    //事業所検索用
    async serchjigyosho() {
      const self = this;
      await this.$axios
        .get("/m_jigyo_get/jigyosyo_list", {
          params: {
            KigyoCd: this.$store.state.kigyoCode, //セッション(Vuex)
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              self.datas = d["data"];
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //事業所登録用
    async insertjigyosho() {
      const self = this;
      const axios = this.$axios;
      const store = this.$store;
      const params = new URLSearchParams();
      params.append("KigyoCd", store.state.kigyoCode);
      params.append("JigyoName", self.Jigyosyo);
      params.append("JigyoKana", self.JigyosyoKana);
      params.append("Address", self.Address);
      params.append("Tel1", self.Tel1);
      params.append("Tel2", self.Tel2);
      params.append("Fax", self.Fax);
      params.append("Post", self.post);
      params.append("sUser", store.state.userCode);
      params.append("sAccess", "WEB");
      params.append("Naisen", self.naisen);
      params.append("Mail", self.mail);
      await axios
        .post("/m_jigyo_post/insert", params)
        .then(function () {
          self.serchjigyosho();
          self.dialog = false;
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.snackbar_color = "safe";
          // self.msg = "保存しました。";
          // self.snackbar = true;
          self.$root.snackbar.showMessage("保存しました。", "success");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
          self.change_flg = false;
        })
        .catch(function (error) {
          console.log(error);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.snackbar_color = "error";
          // self.msg = "保存できませんでした。";
          // self.snackbar = true;
          self.$root.snackbar.showMessage("保存できませんでした。", "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        });
    },
    //事業所更新用
    async updatejigyosho() {
      const self = this;
      const axios = this.$axios;
      const store = this.$store;
      const params = new URLSearchParams();
      params.append("KigyoCd", store.state.kigyoCode);
      params.append("JigyoNo", self.jigyosyono);
      params.append("JigyoName", self.Jigyosyo);
      params.append("Address", self.Address);
      params.append("Tel1", self.Tel1);
      params.append("Tel2", self.Tel2);
      params.append("Fax", self.Fax);
      params.append("JigyoKana", self.JigyosyoKana);
      params.append("Post", self.post);
      params.append("sUser", store.state.userCode);
      params.append("sAccess", "WEB");
      params.append("Naisen", self.naisen);
      params.append("Mail", self.mail);
      await axios
        .post("/m_jigyo_post/update", params)
        .then(function () {
          self.serchjigyosho();
          self.dialog = false;
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.snackbar_color = "safe";
          // self.msg = "保存しました。";
          // self.snackbar = true;
          self.$root.snackbar.showMessage("保存しました。", "success");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
          self.change_flg = false;
        })
        .catch(function (error) {
          console.log(error);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.snackbar_color = "error";
          // self.msg = "保存できませんでした。";
          // self.snackbar = true;
          self.$root.snackbar.showMessage("保存できませんでした。", "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        });
    },
    //事業所削除用
    async deletejigyosho() {
      const self = this;
      const axios = this.$axios;
      const store = this.$store;
      const params = new URLSearchParams();
      params.append("KigyoCd", store.state.kigyoCode);
      params.append("JigyoNo", self.jigyosyono);
      params.append("sUser", store.state.userCode);
      params.append("sAccess", "WEB");
      await axios
        .post("/m_jigyo_post/delete", params)
        .then(function () {
          self.serchjigyosho();
          self.dialog = false;
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.snackbar_color = "safe";
          // self.msg = "削除しました。";
          // self.snackbar = true;
          self.$root.snackbar.showMessage("削除しました。", "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
          self.change_flg = false;
        })
        .catch(function (error) {
          console.log(error);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.snackbar_color = "error";
          // self.msg = "削除できませんでした。";
          // self.snackbar = true;
          self.$root.snackbar.showMessage("削除できませんでした。", "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        });
    },
  },
  async created() {
    await this.serchjigyosho();
  },
  computed: {
    headers() {
      return [
        /* -- 2024.05.15 MCS)KONNO MOD START --*/
        // { text: "事業所番号", sortable: false, value: "num_jigyo_no", align: "start", width: "100px" },
        // { text: "事業所名", sortable: false, value: "str_jigyo_name", align: "start", width: "240px" },
        // // { text: "事業所カナ", sortable: false, value: "str_jigyo_kana", align: "start", width: "150px" },
        // { text: "郵便番号", sortable: false, value: "str_post", align: "start", width: "100px" },
        // { text: "住所", sortable: false, value: "str_address", align: "start", width: "220px" },
        // { text: "電話", sortable: false, value: "str_tel1", align: "start", width: "130px" },
        // { text: "FAX", sortable: false, value: "str_fax", align: "start", width: "130px" },
        // { text: "代表メールアドレス", sortable: false, value: "str_mail", align: "start", width: "200px" },
        { text: "事業所番号", sortable: true, value: "num_jigyo_no", align: "start", width: "110px" },
        { text: "事業所名", sortable: true, value: "str_jigyo_name", align: "start", width: "240px" },
        // { text: "事業所カナ", sortable: true, value: "str_jigyo_kana", align: "start", width: "150px" },
        { text: "郵便番号", sortable: true, value: "str_post", align: "start", width: "100px" },
        { text: "住所", sortable: true, value: "str_address", align: "start", width: "220px" },
        { text: "電話", sortable: true, value: "str_tel1", align: "start", width: "130px" },
        { text: "FAX", sortable: true, value: "str_fax", align: "start", width: "130px" },
        { text: "代表メールアドレス", sortable: true, value: "str_mail", align: "start", width: "200px" },
        /* -- 2024.05.15 MCS)KONNO MOD END --*/
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "./src/assets/css/TraderMaster.scss";
</style>
