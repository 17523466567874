<template>
  <v-sheet class="d-flex" style="align-items: center; background-color: transparent">
    <!-- <div class="mr-4" style="line-height: 36px">{{ fmtDateYYYY(value) }}</div>
    <v-btn @click="onClickPrevious()" class="mr-2" color="teal" dark depressed>
      <v-icon left>navigate_before</v-icon>
      <span>前年</span>
    </v-btn>
    <v-btn @click="onClickThisYear()" class="mr-2" color="teal" dark depressed>
      <span>今年</span>
    </v-btn>
    <v-btn @click="onClickNext()" class="mr-2" color="teal" dark depressed>
      <span>翌年</span>
      <v-icon right>navigate_next</v-icon>
    </v-btn> -->
    <span style="font-size: 1.2rem; line-height: 36px; white-space: nowrap">{{ fmtDateYYYY(value) }}</span>
    <v-divider vertical class="mx-2"></v-divider>
    <v-btn @click="onClickPrevious()" class="mx-1 px-2" color="teal" style="min-width: initial !important" dark depressed>
      <!-- <v-icon left>navigate_before</v-icon>
      <span>前年</span> -->
      <v-icon>navigate_before</v-icon>
    </v-btn>
    <v-btn @click="onClickToday()" class="mx-1" color="teal" dark depressed>
      <span>今年</span>
    </v-btn>
    <v-btn @click="onClickNext()" class="mx-1 px-2" color="teal" style="min-width: initial !important" dark depressed>
      <!-- <span>翌年</span>
      <v-icon right>navigate_next</v-icon> -->
      <v-icon>navigate_next</v-icon>
    </v-btn>
  </v-sheet>
</template>

<script>
import * as script from "@/utils/script.js";

export default {
  name: "BaseYearSelector",
  data: () => ({
    dialog: false,
    date: "",
  }),
  props: {
    value: {
      type: String,
      default: "",
      required: true,
    },
  },
  methods: {
    // 「今年」ボタン押下時
    async onClickThisYear() {
      const today = new Date();
      // 表示日を当日に変更
      const date = script.formatDate(today, "YYYY-MM-DD");
      // イベント発火
      this.$emit("input", date);
      this.$emit("change");
    },

    // 「前年」ボタン押下時
    async onClickPrevious() {
      // 表示日を前日に変更
      const date = script.formatDate(script.subtractDate(this.value, 1, "y"), "YYYY-MM-DD");
      // イベント発火
      this.$emit("input", date);
      this.$emit("change");
    },

    // 「翌年」ボタン押下時
    async onClickNext() {
      // 表示日を翌日に変更
      const date = script.formatDate(script.addDate(this.value, 1, "y"), "YYYY-MM-DD");
      // イベント発火
      this.$emit("input", date);
      this.$emit("change");
    },

    // 日付フォーマット（YYYY年）
    fmtDateYYYY(string) {
      return script.formatDate(string, "YYYY年");
    },
  },
};
</script>
