<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：App.vue
  モジュール名：ルートコンポーネント
  備考：
-------------------------------------------------->
<template>
  <v-app>
    <!-- システムバー -->
    <SystemBar />
    <!-- 確認ダイアログ -->
    <ConfirmDialog ref="confirm" />
    <!-- スナックバー -->
    <SnackBar ref="snackbar" />
    <!-- 画面コンポーネント -->
    <router-view />
  </v-app>
</template>

<script>
import SystemBar from "./components/common/SystemBar";
import ConfirmDialog from "./components/common/ConfirmDialog";
import SnackBar from "./components/common/SnackBar";

export default {
  components: {
    SystemBar,
    SnackBar,
    ConfirmDialog,
  },
  methods: {
    // ログアウト
    logout() {
      this.$store.commit("logout");
      this.$router.push("/login");
      this.$refs.snackbar.showMessage("ログアウトしました。", "info");
    },
  },
  mounted() {
    this.$root.confirm = this.$refs.confirm;
    this.$root.snackbar = this.$refs.snackbar;
  },
  metaInfo: {
    title: "弁当注文ビフォサック",
    titleTemplate: "%s | 企業・団体向け弁当注文予約管理システム",
  },
};
</script>
