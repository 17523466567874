<template>
  <v-dialog v-model="dialog" width="600px" @keydown.esc="onClickCansel" persistent no-click-animation>
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text class="py-2 text--primary" style="white-space: pre-wrap">{{ message }}</v-card-text>
      <v-card-actions class="mt-4">
        <v-container fluid>
          <v-row>
            <v-col cols="6" class="pa-0 pr-1">
              <v-btn @click.native="onClickAgree" color="primary" dark large block depressed>はい</v-btn>
            </v-col>
            <v-col cols="6" class="pa-0 pl-1">
              <v-btn @click.native="onClickCansel" color="blue-grey" dark large block depressed>いいえ</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: "確認",
  }),
  methods: {
    openDialog(message) {
      this.dialog = true;
      this.message = message;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    onClickAgree() {
      this.resolve(true);
      this.dialog = false;
    },
    onClickCansel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
