<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：AppBar.vue
  モジュール名：アプリバー
  備考：
-------------------------------------------------->
<template>
  <v-app-bar height="64px" elevation="1" app clipped-left>
    <v-app-bar-nav-icon v-if="$store.state.menuIcon" @click="clickViewMenu"></v-app-bar-nav-icon>
    <!-- タイトル -->
    <v-toolbar-title>
      <!-- <h2 class="logo">BEFOSC</h2> -->
      <div class="d-flex align-items-center"><img src="@/assets/images/logo_mojinashi.webp" style="height: 54px" alt="弁当注文ビフォサック" /></div>
    </v-toolbar-title>
    <v-divider vertical class="mx-4"></v-divider>
    <!-- 会社名 -->
    <v-toolbar-title>
      <h3>{{ $store.state.kigyoName }}</h3>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- 通知領域 -->
    <v-menu offset-y nudge-left="200" v-if="$store.state.isLogin && $store.state.adminAuth == 0" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" class="mx-3" style="background-color: #eeeeee">
          <v-badge color="red" :content="notice_list.filter((notice) => notice.new).length" :value="notice_list.filter((notice) => notice.new).length">
            <v-icon size="30">notifications</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-card width="350px">
        <div v-show="notice_list.length == 0" class="px-4 py-4">現在、新しいお知らせはありません。</div>
        <v-expansion-panels accordion>
          <v-expansion-panel v-for="notice in notice_list" :key="notice.no">
            <v-expansion-panel-header @click="clickNotice(notice.kigyo, notice.no, notice.new)">
              <div>
                <div class="d-flex align-center mb-1">
                  <v-chip color="blue darken-1" text-color="white" label small v-if="notice.kigyo == 'SYSTEM'">運営からのお知らせ</v-chip>
                  <v-chip color="green darken-1" text-color="white" label small v-if="notice.kigyo != 'SYSTEM'">管理者からのお知らせ</v-chip>
                  <v-chip color="red darken-1" text-color="red darken-1" class="ml-1" label small outlined v-if="notice.new">未読</v-chip>
                  <v-chip color="grey darken-1" text-color="grey darken-1" class="ml-1" label small outlined v-if="!notice.new">既読</v-chip>
                </div>
                <div class="d-flex align-center">
                  {{ notice.title }}
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content style="white-space: pre-wrap">{{ notice.content }}</v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-menu>

    <!-- ユーザー情報 -->
    <v-menu offset-y v-if="$store.state.isLogin">
      <template v-slot:activator="{ on, attrs }">
        <v-card flat color="grey lighten-3" v-bind="attrs" v-on="on" class="pl-4 pr-2 py-1">
          <div style="display: flex">
            <div class="d-flex align-center">
              <h4>{{ $store.state.userName }}</h4>
            </div>
            <div class="d-flex align-center ml-2">
              <v-icon large>account_circle</v-icon>
            </div>
          </div>
        </v-card>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon x-large>account_circle</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle v-if="showBelong">
                <small class="d-inline mr-2">{{ $store.state.jigyoName }}</small>
                <small class="d-inline">{{ $store.state.bushoName }}</small>
              </v-list-item-subtitle>
              <v-list-item-title>{{ $store.state.userName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-card-actions class="pa-0">
          <v-btn block text class="pa-5" href="https://befosc.com/contact/" target="_blank">ご意見・ご要望はこちら<v-spacer /><v-icon>forum</v-icon></v-btn>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn block text @click="clickLogout" class="pa-5">ログアウト<v-spacer /><v-icon>logout</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
/* -- 2024.05.16 MCS)KONNO DEL START -- */
// import moment from "moment";
/* -- 2024.05.16 MCS)KONNO DEL END -- */

export default {
  name: "AppBar",
  data() {
    return {
      // お知らせリスト
      notice_list: [],
    };
  },
  methods: {
    //----------------------------------------------------------------
    // ボタン押下時処理
    //----------------------------------------------------------------
    // ログアウトボタン
    clickLogout() {
      const kigyoCd = this.$store.state.kigyoCode;
      this.$store.commit("reset");
      this.$router.push("/login/" + kigyoCd);
    },

    // ハンバーガーボタン（管理者画面のみ）
    clickViewMenu() {
      this.$store.dispatch("view", !this.$store.state.menuView);
    },

    //----------------------------------------------------------------
    // イベント処理
    //----------------------------------------------------------------
    // お知らせ選択時
    /* -- 2024.05.16 MCS)KONNO MOD START -- */
    // async clickNotice(i) {
    //   await this.checkNotice(i);
    // },
    async clickNotice(kigyo, no, isnew) {
      if (isnew) {
        // お知らせ既読処理
        await this.checkNotice(kigyo, no);
        // お知らせ再読み込み
        await this.getNotice();
      }
    },
    /* -- 2024.05.16 MCS)KONNO MOD END -- */

    //----------------------------------------------------------------
    // メソッド
    //----------------------------------------------------------------
    // お知らせ一覧取得
    async getNotice() {
      const KigyoCd = this.$store.state.kigyoCode;
      const UserCd = this.$store.state.userCode;
      const tKigyoCd = this.$store.state.kigyoCode;
      let data = await this.$axios
        .get("/t_notice_get/list" + "?KigyoCd=" + KigyoCd + "&UserCd=" + UserCd + "&tKigyoCd=" + tKigyoCd)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      // 形式変換
      let wkArray = [];
      /* -- 2024.05.16 MCS)KONNO DEL START -- */
      // const today = moment().format("yyyyMMDD");
      /* -- 2024.05.16 MCS)KONNO DEL END -- */
      if (data.length != 0) {
        data.forEach(function (element) {
          /* -- 2024.05.16 MCS)KONNO DEL START -- */
          // if (today >= element.str_date_st && today <= element.str_date_ed) {
          /* -- 2024.05.16 MCS)KONNO DEL END -- */
          let notice = {};
          notice["no"] = element.num_notice_no;
          notice["title"] = element.str_title;
          notice["content"] = String(element.str_content);
          notice["important"] = element.num_imp_flg;
          notice["new"] = element.str_user_cd == "" ? true : false;
          /* -- 2024.05.16 MCS)KONNO MOD START -- */
          // notice["system"] = element.str_kigyo_cd == "SYSTEM" ? true : false;
          notice["kigyo"] = element.str_kigyo_cd;
          /* -- 2024.05.16 MCS)KONNO MOD END -- */
          wkArray.push(notice);
          /* -- 2024.05.16 MCS)KONNO DEL START -- */
          // }
          /* -- 2024.05.16 MCS)KONNO DEL END -- */
        });
      }
      this.notice_list = wkArray;
    },

    // お知らせ既読処理
    /* -- 2024.05.16 MCS)KONNO MOD START -- */
    // async checkNotice(i) {
    async checkNotice(kigyo, no) {
      /* -- 2024.05.16 MCS)KONNO MOD END -- */
      const params = new URLSearchParams();
      params.append("KigyoCd", this.$store.state.kigyoCode);
      params.append("NoticeNo", no);
      params.append("UserCd", this.$store.state.userCode);
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      /* -- 2024.05.16 MCS)KONNO ADD START -- */
      params.append("NoticeKigyoCd", kigyo);
      /* -- 2024.05.16 MCS)KONNO ADD END -- */
      await this.$axios
        .post("/t_notice_post/read", params)
        .then((response) => {
          return response.status == 200;
        })
        .catch((error) => {
          console.log(error.response.data.error);
          return false;
        });
    },
  },
  computed: {
    showBelong() {
      return !(this.$store.state.jigyoName == "" && this.$store.state.bushoName == "");
    },
    isLogin() {
      return this.$store.getters.getLogin;
    },
  },
  mounted() {
    if (this.$store.state.isLogin && this.$store.state.adminAuth == 0) {
      this.getNotice();
    }
  },
  watch: {
    isLogin: function (val) {
      if (val && this.$store.state.adminAuth == 0) {
        this.getNotice();
      }
    },
  },
};
</script>
