<template>
  <header class="text-center my-4">
    <div><img src="@/assets/images/logo_full.webp" style="height: 114px" alt="befosc" /></div>
  </header>
</template>

<script>
export default {
  name: "MainHeader",
};
</script>
