<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：ReserveList.vue
  モジュール名：予約状況一覧画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <!-- タイトル -->
    <title-card title="予約状況一覧">
      <v-btn color="teal" dark depressed @click="clickOrder()">新規予約</v-btn>
    </title-card>
    <!-- データ -->
    <data-card>
      <!-- 日付選択 -->
      <v-sheet class="px-4 py-2 d-flex">
        <base-date-selector v-model="select_date" @change="onChangeDate()"></base-date-selector>
        <v-spacer></v-spacer>
        <!-- <v-btn class="ml-2" color="teal" @click="dialog_calendar = true" dark depressed>
          <v-icon>event</v-icon>
        </v-btn> -->
        <v-btn @click="print_list()" class="ml-2" color="teal" dark depressed> 注文予約リスト </v-btn>
        <v-btn @click="onClickDelete()" class="ml-2" color="teal" dark depressed> 予約削除 </v-btn>
      </v-sheet>
      <v-divider></v-divider>
      <!-- 絞り込み条件 -->
      <v-sheet class="px-4 py-2">
        <v-form class="d-flex" style="align-items: center" ref="select_form">
          <span>注文場所：</span>
          <v-responsive>
            <v-select outlined dense v-model="jigyosyo" item-text="str_jigyo_name" item-value="num_jigyo_no" style="width: 200px" :items="filter_jigyo" backgroundColor="white" no-data-text="データがありません。" hide-details></v-select>
          </v-responsive>
        </v-form>
      </v-sheet>
      <v-divider></v-divider>
      <!-- テーブル -->
      <v-data-table v-model="select_order" :headers="daily_headers" :items="indexedItems" item-key="id" :fixed-header="true" :hide-default-footer="true" :items-per-page="-1" no-data-text="予約が登録されていません。" no-results-text="該当するデータがありません。" height="calc(100dvh - 347px)" @click:row="selectOrder" disable-pagination show-select></v-data-table>
      <v-divider></v-divider>
      <!-- 個数 -->
      <v-sheet class="pa-2" style="align-items: center">
        <template v-for="order in order_list_monthly">
          <v-chip v-if="order.date == select_date" class="mr-2" :key="order.str_gyosha_no" label>{{ order.name }}</v-chip>
        </template>
        <v-chip v-if="order_list_daily.length == 0" label>注文なし</v-chip>
      </v-sheet>
    </data-card>
    <!-- *** 表示部 ここまで *** -->
  </v-container>
</template>

<script>
import moment from "moment";
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";
import BaseDateSelector from "@/components/common/BaseDateSelector.vue";

export default {
  name: "ReserveList",
  data: () => ({
    // 共通
    today: moment().format("yyyy-MM-DD"),
    calendar: moment().format("yyyy-MM-DD"), // カレンダー表示月
    order_list_monthly: [], // 予約リスト（３か月）
    order_list_daily: [], // 予約リスト（１日）
    // holiday_list: [], // 祝日リスト
    // event_list: [], // イベントリスト
    jigyo_list: [], // 事業所リスト
    // count_list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], // 個数リスト
    // tab_list: ["メニュー表", "献立表"], // タブ項目
    // jp_week: ["日", "月", "火", "水", "木", "金", "土"], // 曜日
    select_date: moment().format("yyyy-MM-DD"), // 選択した日付
    // price_monthly: 0, // 月の合計金額
    /* -- 2024.04.05 MCS)SAITO ADD START -- */
    jigyosyos: [],
    jigyosyo: 0,
    /* -- 2024.04.05 MCS)SAITO ADD END -- */
    // テーブルヘッダ
    // menu_headers: [
    //   { text: "弁当名", align: "start", sortable: false, value: "str_bento_name" },
    //   { text: "金額（円）", align: "end", value: "char_kingaku" },
    //   { text: "カロリー（kcal）", align: "end", value: "char_bento_calory" },
    // ], // メニュー表
    /* -- 2024/04/05 MCS)SAITO DEL START -- */
    // daily_headers: [
    //   { text: "ユーザー名", align: "start", sortable: false, value: "str_user_name" },
    //   { text: "注文場所", align: "start", sortable: false, value: "str_jigyo_name" },
    //   { text: "弁当業者名", align: "start", sortable: false, value: "str_gyosha_name" },
    //   { text: "弁当名", align: "start", sortable: false, value: "str_bento_name" },
    //   { text: "金額（円）", align: "end", sortable: false, value: "char_kingaku" },
    //   { text: "個数（個）", align: "end", sortable: false, value: "char_kosuu" },
    //   { text: "合計（円）", align: "end", sortable: false, value: "char_goukei" },
    // ], // 予約一覧
    /* -- 2024/04/05 MCS)SAITO DEL END -- */
    // ダイアログ
    // dialog_calendar: false,
    // スナックバー
    // snackbar: false, // スナックバー状態
    // snackbar_msg: "", // スナックバー内容
    // snackbar_color: "", // スナックバー色
    // timeout: 3000, // スナックバー表示時間
    select_order: [],
  }),
  components: {
    TitleCard,
    DataCard,
    BaseDateSelector,
  },
  methods: {
    //----------------------------------------------------------------
    // 初期化処理
    //----------------------------------------------------------------
    async init() {
      /* -- 2024.04.05 MCS)SAITO ADD START -- */
      await this.serchjigyosho();
      /* -- 2024.04.05 MCS)SAITO ADD END -- */
      // 祝日リスト読み込み
      // this.getHoliday(moment().format("yyyy"));
      // 当日の予約一覧読み込み
      await this.getOrdersDaily();
      // 予約情報を取得
      await this.getOrdersMonthly();
    },

    //----------------------------------------------------------------
    // ボタン押下時処理
    //----------------------------------------------------------------

    // 「今日」ボタン押下時
    // setToday() {
    //   // カレンダーを当月にセット
    //   this.calendar = moment().format("yyyy-MM-DD");
    //   // 予約日を当日にセット
    //   this.select_date = moment().format("yyyy-MM-DD");
    // },

    // 「新規予約」ボタン押下時
    async clickOrder() {
      // パラメタ取得
      let user = "";
      let date = moment(this.$store.state.nowDate).format("yyyy-MM-DD");
      let jigyo = 0;
      this.$router.push({ name: "ReservationStatus", params: { yoyaku_user: user, yoyaku_date: date, yoyaku_jigyo: jigyo, from: "list" } });
    },

    /*--- 2024.02.26 MCS)KONNO ADD START ---*/
    /* -- 2024.05.08 MCS)KONNO MOD START --*/
    // // カレンダー選択時
    // async onChangeCalendar() {
    //   // ダイアログを閉じる
    //   this.dialog_daily = false;
    //   // 予約一覧を取得
    //   await this.getOrdersDaily();
    // },

    // // 「今日」ボタン押下時
    // async onClickToday() {
    //   // 表示日を当日に変更
    //   this.select_date = moment().format("yyyy-MM-DD");
    //   // 予約一覧を取得
    //   await this.getOrdersDaily();
    // },

    // // 「前日」ボタン押下時
    // async onClickPrevious() {
    //   // 表示日を前日に変更
    //   this.select_date = moment(this.select_date).subtract(1, "d").format("yyyy-MM-DD");
    //   // 予約一覧を取得
    //   await this.getOrdersDaily();
    // },

    // // 「翌日」ボタン押下時
    // async onClickNext() {
    //   // 表示日を翌日に変更
    //   this.select_date = moment(this.select_date).add(1, "d").format("yyyy-MM-DD");
    //   // 予約一覧を取得
    //   await this.getOrdersDaily();
    // },

    // 日付変更時
    async onChangeDate() {
      // 予約一覧を取得
      await this.getOrdersDaily();
      // 選択されている予約をクリア
      this.select_order = [];
    },
    /* -- 2024.05.08 MCS)KONNO MOD END --*/
    /*--- 2024.02.26 MCS)KONNO ADD END ---*/

    /* -- 2024.05.10 MCS)KONNO ADD START --*/
    // 「予約削除」ボタンクリック時
    async onClickDelete() {
      // 入力チェック
      if (this.select_order.length == 0) {
        this.$root.snackbar.showMessage("予約が選択されていません。", "warning");
        return;
      }
      // 締め処理チェック
      if (window.confirm("選択した予約を削除してもよろしいですか？") == false) {
        return;
      }
      // 予約情報削除
      await this.deleteOrder();
      // 選択されている予約をクリア
      this.select_order = [];
      // 当日の予約一覧読み込み
      await this.getOrdersDaily();
      // 予約情報を取得
      await this.getOrdersMonthly();
    },
    /* -- 2024.05.10 MCS)KONNO ADD END --*/

    //----------------------------------------------------------------
    // イベント処理
    //----------------------------------------------------------------

    // カレンダー変更時処理
    // async changeCalendar() {
    //   // イベントリスト読み込み
    //   await this.getEvent();
    //   // 予約情報を取得
    //   await this.getOrdersMonthly();
    // },

    // 日付選択時処理
    // async selectDate({ date }) {
    //   // 日付をセット
    //   this.select_date = moment(date).format("yyyy-MM-DD");
    //   // 予約一覧を取得
    //   await this.getOrdersDaily();
    //   /* -- 2024.05.08 MCS)KONNO MOD START --*/
    //   // // ダイアログを開く
    //   // this.dialog_daily = true;
    //   // ダイアログを閉じる
    //   this.dialog_calendar = false;
    //   /* -- 2024.05.08 MCS)KONNO MOD END --*/
    // },

    // 予約選択時処理
    async selectOrder(row) {
      // パラメタ取得
      let user = row.str_user_cd;
      let date = moment(row.str_chumonbi).format("yyyy-MM-DD");
      let jigyo = row.num_jigyo_no;
      this.$router.push({ name: "ReservationStatus", params: { yoyaku_user: user, yoyaku_date: date, yoyaku_jigyo: jigyo, from: "list" } });
    },

    //----------------------------------------------------------------
    // メソッド
    //----------------------------------------------------------------

    // 祝日情報取得処理
    // async getHoliday(year) {
    //   if (year < moment().add(2, "y").format("yyyy")) {
    //     let data = await this.$axios
    //       .get("https://holidays-jp.github.io/api/v1/" + year + "/date.json")
    //       .then(function (response) {
    //         return response.data;
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //         return [];
    //       });
    //     // イベント形式に変換
    //     let wkArray = [];
    //     let wkObj = JSON.parse(JSON.stringify(data));
    //     if (wkObj.length != 0) {
    //       Object.keys(wkObj).forEach(function (key) {
    //         let event = {};
    //         event["name"] = wkObj[key];
    //         event["start"] = moment(key).toDate();
    //         event["end"] = moment(key).toDate();
    //         event["color"] = "grey";
    //         event["timed"] = false;
    //         event["jigyo"] = "";
    //         event["seq"] = "";
    //         event["holiday"] = true;
    //         wkArray.push(event);
    //       });
    //     }
    //     this.holiday_list = wkArray;
    //   }
    // },

    // イベント情報取得処理
    // async getEvent() {
    //   const KigyoCd = this.$store.state.kigyoCode;
    //   const Year = moment(this.calendar).format("yyyy");
    //   let data = await this.$axios
    //     .get("/t_calendar_get/list" + "?KigyoCd=" + KigyoCd + "&Year=" + Year)
    //     .then(function (response) {
    //       return response.data["data"];
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //       return [];
    //     });
    //   // イベント形式に変換
    //   let wkArray = [];
    //   if (data.length != 0) {
    //     data.forEach(function (element) {
    //       let event = {};
    //       event["name"] = element.str_title;
    //       event["start"] = moment(element.str_date_st).toDate();
    //       event["end"] = moment(element.str_date_ed).toDate();
    //       event["color"] = "grey";
    //       event["timed"] = false;
    //       event["seq"] = element.num_seq_no;
    //       event["holiday"] = false;
    //       wkArray.push(event);
    //     });
    //   }
    //   this.event_list = wkArray;
    // },

    // 予約情報取得処理（３か月）
    async getOrdersMonthly() {
      const KigyoCd = this.$store.state.kigyoCode;
      const Chumonbi = moment(this.calendar).format("yyyyMMDD");
      let data = await this.$axios
        .get("/t_chu_yoyaku_get/yoyaku_list_gyosha_m" + "?KigyoCd=" + KigyoCd + "&Chumonbi=" + Chumonbi)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      // イベント形式に変換
      let wkArray = [];
      if (data.length != 0) {
        data.forEach(function (element) {
          let order = {};
          order["name"] = element.str_gyosha_name + "：" + element.total + "個";
          order["date"] = moment(element.str_chumonbi).format("yyyy-MM-DD");
          wkArray.push(order);
        });
      }
      this.order_list_monthly = wkArray;
    },

    // 予約情報取得処理（１日）
    async getOrdersDaily() {
      const KigyoCd = this.$store.state.kigyoCode;
      const Chumonbi = moment(this.select_date).format("yyyyMMDD");
      this.order_list_daily = await this.$axios
        .get("/t_chu_yoyaku_get/yoyaku_list_gyosha_d" + "?KigyoCd=" + KigyoCd + "&Chumonbi=" + Chumonbi)
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
    },

    /* -- 2024.05.10 MCS)KONNO ADD START --*/
    // 予約登録処理
    async deleteOrder() {
      // 選択された利用者コードを抽出
      let OrderList = [];
      this.select_order.forEach((element) => {
        let order = {};
        order["JigyoNo"] = element.num_jigyo_no;
        order["UserCd"] = element.str_user_cd;
        order["SeqNo"] = element.num_seq_no;
        OrderList.push(order);
      });
      // パラメタセット
      const params = new URLSearchParams();
      params.append("KigyoCd", this.$store.state.kigyoCode);
      params.append("Chumonbi", moment(this.select_date).format("yyyyMMDD"));
      params.append("OrderList", JSON.stringify(OrderList));
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      let result = await this.$axios
        .post("/t_chu_yoyaku_post/delete_multi", params)
        .then(function (response) {
          if (response.status == 200) {
            return true;
          } else {
            return false;
          }
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
      if (result == true) {
        // 予約削除成功
        this.$root.snackbar.showMessage("予約を削除しました。", "success");
      } else {
        // 予約削除失敗
        this.$root.snackbar.showMessage("予約の削除時にエラーが発生しました。", "error");
      }
    },
    /* -- 2024.05.10 MCS)KONNO ADD END --*/

    // メッセージ表示（正常）
    // noticeOK(msg) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "green";
    //   this.snackbar = true;
    // },

    // メッセージ表示（エラー）
    // noticeNG(msg) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "red";
    //   this.snackbar = true;
    // },

    // 日付を"yyyy年 M月"形式で返却
    // fmtDate_YYYYM(date) {
    //   if (date != "") {
    //     return moment(date).format("YYYY年 M月");
    //   } else {
    //     return "";
    //   }
    // },

    // 日付を"yyyy年 M月 D日"形式で返却
    // fmtDate_YYYYMD(date) {
    //   if (date != "") {
    //     let fmtDate = moment(date).format("YYYY年 M月 D日");
    //     let fmtWeek = "（" + this.jp_week[moment(date).day()] + "）";
    //     return fmtDate + fmtWeek;
    //   } else {
    //     return "";
    //   }
    // },

    /* -- 2024/04/05 MCS)SAITO ADD START -- */
    async serchjigyosho() {
      const self = this;
      await this.$axios
        .get("/m_jigyo_get/jigyosyo_select", {
          params: {
            KigyoCd: this.$store.state.kigyoCode, //セッション(Vuex)
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              self.jigyosyos = d["data"];
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    /* -- 2024/04/05 MCS)SAITO ADD END -- */

    /* -- 2024/04/15 MCS)TEZUKA ADD START -- */
    print_list() {
      try {
        const Chumonbi = moment(this.select_date).format("yyyyMMDD");
        window.chrome.webview.postMessage({
          mode: "chumon_list",
          kigyoCode: this.$store.state.kigyoCode,
          jigyosyo: this.jigyosyo,
          chomonbi: Chumonbi,
        });
      } catch (error) {
        // エラーが発生した場合の処理
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // alert("注文リストはクライアントアプリを利用してください。");
        this.$root.snackbar.showMessage("注文予約リストはクライアントアプリを利用してください。", "info");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      }
    },
    /* -- 2024/04/15 MCS)TEZUKA ADD END -- */
  },

  computed: {
    // ストアの現在時刻を返却
    // comp_time() {
    //   return this.$store.getters.getTime;
    // },

    // 祝日リストと予約リストを返却
    // comp_calendar() {
    //   let wkEventList = [];
    //   return wkEventList.concat(this.holiday_list, this.event_list, this.order_list_monthly);
    // },

    /* -- 2024/04/05 MCS)SAITO ADD START -- */
    filter_jigyo() {
      let wkArray = [];
      let allSelect = [{ num_jigyo_no: 0, str_jigyo_name: "すべて" }];
      return wkArray.concat(allSelect, this.jigyosyos);
    },

    daily_headers() {
      return [
        { text: "ユーザー名", align: "start", sortable: true, value: "str_user_name" },
        {
          text: "注文場所",
          align: "start",
          sortable: true,
          value: "str_jigyo_name",
          filter: (value) => {
            if (this.jigyosyo == 0) return true;
            return value == this.jigyosyos.find((i) => i.num_jigyo_no == this.jigyosyo).str_jigyo_name;
          },
        },
        { text: "弁当業者名", align: "start", sortable: true, value: "str_gyosha_name" },
        { text: "弁当名", align: "start", sortable: true, value: "str_bento_name" },
        {
          text: "金額（円）",
          align: "end",
          sortable: true,
          value: "char_kingaku",
          sort: (a, b) => {
            const numA = Number(a.replace(/,/g, ""));
            const numB = Number(b.replace(/,/g, ""));
            return numA - numB;
          },
        },
        {
          text: "個数（個）",
          align: "end",
          sortable: true,
          value: "char_kosuu",
          sort: (a, b) => {
            var numA = 0;
            var numB = 0;
            if (a != null) {
              numA = Number(a.replace(/,/g, ""));
            }
            if (b != null) {
              numB = Number(b.replace(/,/g, ""));
            }
            return numA - numB;
          },
        },
        {
          text: "合計（円）",
          align: "end",
          sortable: true,
          value: "char_goukei",
          sort: (a, b) => {
            var numA = 0;
            var numB = 0;
            if (a != null) {
              numA = Number(a.replace(/,/g, ""));
            }
            if (b != null) {
              numB = Number(b.replace(/,/g, ""));
            }
            return numA - numB;
          },
        },
      ];
    },
    /* -- 2024/04/05 MCS)SAITO ADD END -- */

    indexedItems() {
      return this.order_list_daily.map((item, index) => ({
        id: index,
        ...item,
      }));
    },
  },

  // watch: {
  //   // 祝日情報取得（年が変わった場合のみ）
  //   calendar: function (newVal, oldVal) {
  //     let newYear = moment(newVal).format("yyyy");
  //     let oldYear = moment(oldVal).format("yyyy");
  //     if (newYear != oldYear) {
  //       this.getHoliday(newYear);
  //     }
  //   },
  // },

  /* -- 2024.05.07 MCS)KONNO ADD START -- */
  created() {
    if (this.$route.params.date != undefined) {
      this.select_date = this.$route.params.date;
    }
  },
  /* -- 2024.05.07 MCS)KONNO ADD START -- */

  mounted() {
    // 初期化
    this.init();
  },
};
</script>
