<template>
  <v-main>
    <!-- アプリバー -->
    <AppBar />
    <!-- 画面コンポーネント -->
    <router-view />
  </v-main>
</template>

<script>
import AppBar from "../components/common/AppBar";

export default {
  components: {
    AppBar,
  },
};
</script>
