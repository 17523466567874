<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：PreviewReports.vue
  モジュール名：帳票プレビュー画面
  備考：
-------------------------------------------------->
<style lang="scss" scoped>
@import "./src/assets/css/OrderSheet.scss";
</style>
<template>
  <v-container>
    <!-- <v-btn class="mr-5 d-print-none" height="40" width="100" color="teal" dark @click="downloadCSV">CSV</v-btn>
    <v-btn class="mr-5 d-print-none" height="40" width="100" color="teal" dark @click="print()">印刷</v-btn>
    <v-btn class="d-print-none" height="40" width="100" color="teal" dark @click="post">閉じる</v-btn> -->
    <!-- 月別集計表 --><!-- 月別個別集計表 -->
    <!-- <v-container class="mt-10" style="background-color: white; border: solid 2px black; height: 700px; width: 950px">
      <v-row>
        <v-col left>{{ this.year }}年{{ this.month }}月</v-col>
        <v-col class="text-right mr-5">1</v-col>
      </v-row>
      <center>
        <h1>{{ this.title }}</h1>
        
        <v-data-table
          :headers="headers"
          :items="this.list"
          item-key="item.key"
          :footer-props="{
            //disableItemsPerPage : true,
            'items-per-page-options': [], //表示件数非表示
          }"
          no-data-text="データがありません。"
          v-if="this.title == '月別集計表'"
        >
        </v-data-table>
        <v-data-table
          :headers="headers"
          :items="this.list"
          item-key="item.key"
          :footer-props="{
            //disableItemsPerPage : true,
            'items-per-page-options': [], //表示件数非表示
          }"
          no-data-text="データがありません。"
          v-else
        ></v-data-table>
        <table></table>
      </center>
    </v-container> -->

    <!-- <section class="sheet d-print-block">
      <v-row>
        <v-col left>{{ this.year }}年{{ this.month }}月</v-col>
        <v-col class="text-right mr-5">1</v-col>
      </v-row>
      <center>
      <h1 class="mt-5 mb-5">{{ this.title }}</h1>
      <table style="border-collapse: collapse">
      <tr v-if="this.title == '月別個人品名集計表'"><th>利用者コード</th><th>所 属・グループ</th><th>氏 名</th><th>品 名</th><th>単 価</th><th>個 数</th><th>金 額</th></tr>
      <tr v-else-if="this.title == '月別個人集計表'"><th>利用者コード</th><th>所 属</th><th>氏 名</th><th>金 額</th></tr>
        <tbody v-if="this.title == '月別個人品名集計表'">
          <tr v-if="this.list.length > 0">
            <td>{{ this.list[0].str_user_cd }}</td>
            <td>{{ this.list[0].str_busho_name }}</td>
            <td>{{ this.list[0].str_user_name }}</td>
            <td>{{ this.list[0].str_bento_name }}</td>
            <td class="text-right">{{ this.list[0].char_kingaku }}</td>
            <td class="text-right">{{ this.list[0].sum_kosuu }}</td>
            <td class="text-right">{{ this.list[0].char_goukei }}</td>
          </tr>
          <tr v-for="data in this.list.slice(0,20)" v-bind:key="data.str_user_cd" value="if (count == 1) {this.lenght = 0} else {this.length = count * 20 - 1}">
            <td width="100px">{{ data.str_user_cd }}</td>
            <td width="70px">{{ data.str_busho_name }}</td>
            <td width="120px">{{ data.str_user_name }}</td>
            <td width="130px">{{ data.str_bento_name }}</td>
            <td class="text-right">{{ data.char_kingaku }}</td>
            <td class="text-right">{{ data.sum_kosuu }}</td>
            <td class="text-right">{{ data.char_goukei }}</td>
          </tr>
        </tbody>
        <tbody v-else-if="this.title == '月別個人集計表'">
          <tr v-for="data in this.list.slice(0,20)" v-bind:key="data.str_user_cd">
            <td width="100px">{{ data.str_user_cd }}</td>
            <td width="70px">{{ data.str_busho_name }}</td>
            <td width="120px">{{ data.str_user_name }}</td>
            <td class="text-right">{{ data.to_char }}</td>
          </tr>
        </tbody>
      </table>
      </center>
    </section>
 -->
    <v-col v-if="this.title == '月別個人品名集計表'">
      <v-col v-for="count in this.page" :key="count">
        <section class="sheet-report">
          <v-row>
            <!-- 2023.12.07 MCS)SAITO MOD START -->
            <!-- <v-col left>{{ year }}年{{ month }}月分</v-col>
            <v-col class="text-right mr-5">{{count}} ページ</v-col> -->
            <v-col left style="font-size: 9pt">{{ year }}年{{ month }}月分</v-col>
            <v-col class="text-right mr-5" style="font-size: 9pt">{{ count }} ページ</v-col>
            <!-- 2023.12.07 MCS)SAITO MOD END -->
          </v-row>
          <!-- 2023.12.13 MCS)SAITO ADD START -->
          <v-col class="pa-0 mt-1" style="font-size: 9pt">{{ (total[Number(group_list[count - 1])] || {}).str_jigyo_name }}</v-col>
          <!-- 2023.12.13 MCS)SAITO ADD END -->
          <center>
            <!-- 2023.12.07 MCS)SAITO MOD START -->
            <!-- <h1 class="mt-5 mb-5">{{title}}</h1> -->
            <h1 class="mt-5 mb-5" style="font-size: 14pt">{{ title }}</h1>
            <!-- 2023.12.07 MCS)SAITO MOD END -->
            <!-- 2023.12.13 MCS)SAITO ADD START -->
            <h1 class="mt-5 mb-5" style="font-size: 14pt">{{ sDate.substring(0, 4) }}年{{ sDate.substring(4, 6) }}月{{ sDate.substring(6, 8) }}日～{{ eDate.substring(0, 4) }}年{{ eDate.substring(4, 6) }}月{{ eDate.substring(6, 8) }}日</h1>
            <!-- 2023.12.13 MCS)SAITO ADD END -->
            <table style="border-collapse: collapse; table-layout: fixed">
              <!-- 2023.12.07 MCS)SAITO MOD START -->
              <!-- <tr><th>利用者コード</th><th>所 属・グループ</th><th>氏 名</th><th>品 名</th><th>単 価</th><th>個 数</th><th>金 額</th></tr> -->
              <tr>
                <th style="font-size: 10pt">利用者コード</th>
                <th style="font-size: 10pt">所 属・グループ</th>
                <th style="font-size: 10pt">氏 名</th>
                <th style="font-size: 10pt">品 名</th>
                <th style="font-size: 10pt">単 価</th>
                <th style="font-size: 10pt">個 数</th>
                <th style="font-size: 10pt">金 額</th>
              </tr>
              <!-- 2023.12.07 MCS)SAITO MOD END -->
              <tbody>
                <!-- 2023.12.13 MCS)SAITO MOD START -->
                <!-- <tr v-for="data in list.slice(length_list[Number(count-1)],20 * count)" v-bind:key="data.row_number"> -->
                <tr v-for="data in list.slice(Number(length_s[count - 1]), Number(length_e[count - 1]))" v-bind:key="data.row_number">
                  <!-- 2023.12.13 MCS)SAITO MOD END -->
                  <!-- 2023.12.07 MCS)SAITO MOD START -->
                  <!-- <td width="100px">{{ data.str_user_cd }}</td>
                  <td width="120px">{{ data.str_busho_name }}</td>
                  <td width="150px">{{ data.str_user_name }}</td>
                  <td width="150px">{{ data.str_bento_name }}</td>
                  <td class="text-right">{{ data.char_kingaku }}</td>
                  <td class="text-right">{{ data.sum_kosuu }}</td>
                  <td width="55px" class="text-right">{{ data.char_goukei }}</td> -->
                  <td width="100px" style="font-size: 7pt">
                    <p style="max-height: 10px">{{ data.str_user_cd }}</p>
                  </td>
                  <td width="120px" style="font-size: 7pt; overflow: hidden">
                    <p style="max-height: 10px">{{ data.str_busho_name }}</p>
                  </td>
                  <td width="150px" style="font-size: 7pt; overflow: hidden">
                    <p style="max-height: 10px">{{ data.str_user_name }}</p>
                  </td>
                  <td width="150px" style="font-size: 7pt; overflow: hidden">
                    <p style="max-height: 10px">{{ data.str_bento_name }}</p>
                  </td>
                  <td class="text-right" style="font-size: 9pt">{{ data.char_kingaku }}</td>
                  <td class="text-right" style="font-size: 9pt">{{ data.sum_kosuu }}</td>
                  <td width="55px" class="text-right" style="font-size: 9pt">{{ data.char_goukei }}</td>
                  <!-- 2023.12.07 MCS)SAITO MOD END -->
                </tr>
              </tbody>
            </table>
          </center>
        </section>
      </v-col>
    </v-col>
    <v-col v-else-if="this.title == '月別個人集計表'" style="height: auto">
      <v-col v-for="count in this.page" :key="count">
        <section class="sheet-report mt-5">
          <v-row>
            <!-- 2023.12.07 MCS)SAITO MOD START -->
            <!-- <v-col left>{{ year }}年{{ month }}月分</v-col>
            <v-col class="text-right mr-5">{{count}} ページ</v-col> -->
            <v-col left style="font-size: 9pt">{{ year }}年{{ month }}月分</v-col>
            <v-col class="text-right mr-5" style="font-size: 9pt">{{ count }} ページ</v-col>
            <!-- 2023.12.07 MCS)SAITO MOD END -->
          </v-row>
          <!-- 2023.12.13 MCS)SAITO ADD START -->
          <v-col class="pa-0 mt-1" style="font-size: 9pt">{{ (total[Number(group_list[count - 1])] || {}).str_jigyo_name }}</v-col>
          <!-- 2023.12.13 MCS)SAITO ADD END -->
          <center>
            <!-- 2023.12.07 MCS)SAITO MOD START -->
            <!-- <h1 class="mt-5 mb-5">{{title}}</h1> -->
            <h1 class="mt-5 mb-5" style="font-size: 14pt">{{ title }}</h1>
            <!-- 2023.12.07 MCS)SAITO MOD END -->
            <!-- 2024.01.23 MCS)SAITO ADD START -->
            <h1 class="mt-5 mb-5" style="font-size: 14pt">{{ sDate.substring(0, 4) }}年{{ sDate.substring(4, 6) }}月{{ sDate.substring(6, 8) }}日～{{ eDate.substring(0, 4) }}年{{ eDate.substring(4, 6) }}月{{ eDate.substring(6, 8) }}日</h1>
            <!-- 2023.01.23 MCS)SAITO ADD END -->
            <table style="border-collapse: collapse">
              <!-- 2023.12.07 MCS)SAITO MOD START -->
              <!-- <tr><th>利用者コード</th><th>所 属・グループ</th><th>氏 名</th><th>金 額</th></tr> -->
              <tr>
                <th style="font-size: 10pt">利用者コード</th>
                <th style="font-size: 10pt">所 属・グループ</th>
                <th style="font-size: 10pt">氏 名</th>
                <th style="font-size: 10pt">金 額</th>
              </tr>
              <!-- 2023.12.07 MCS)SAITO MOD END -->
              <tbody>
                <!-- 2023.12.13 MCS)SAITO MOD START -->
                <!-- <tr v-for="data in list.slice(length_list[Number(count-1)],20 * count)" v-bind:key="data.str_user_cd"> -->
                <tr v-for="data in list.slice(Number(length_s[count - 1]), Number(length_e[count - 1]))" v-bind:key="data.str_user_cd">
                  <!-- 2023.12.13 MCS)SAITO MOD END -->
                  <!-- 2023.12.07 MCS)SAITO MOD START -->
                  <!-- <td width="100px">{{ data.str_user_cd }}</td>
                <td width="120px">{{ data.str_busho_name }}</td>
                <td width="150px">{{ data.str_user_name }}</td>
                <td width="120px" class="text-right">{{ data.to_char }}</td> -->
                  <td width="100px" style="font-size: 7pt">
                    <p style="max-height: 10px">{{ data.str_user_cd }}</p>
                  </td>
                  <td width="120px" style="font-size: 7pt; overflow: hidden">
                    <p style="max-height: 10px">{{ data.str_busho_name }}</p>
                  </td>
                  <td width="150px" style="font-size: 7pt; overflow: hidden">
                    <p style="max-height: 10px">{{ data.str_user_name }}</p>
                  </td>
                  <td width="120px" class="text-right" style="font-size: 9pt">
                    <p style="max-height: 10px">{{ data.to_char }}</p>
                  </td>
                  <!-- 2023.12.07 MCS)SAITO MOD END -->
                </tr>
              </tbody>
            </table>
          </center>
        </section>
      </v-col>
    </v-col>
    <v-col v-else-if="this.title == '月別所属グループ別個人集計表'" style="height: auto">
      <v-col v-for="count in this.page" :key="count">
        <section class="sheet-report mt-5">
          <v-row>
            <v-col left>{{ year }}年{{ month }}月分</v-col>
            <v-col class="text-right mr-5">{{ count }} ページ</v-col>
          </v-row>
          <v-col class="pa-0 mt-1">{{ (total[Number(group_list[count - 1])] || {}).str_busho_name }}</v-col>
          <v-col class="pa-0 mt-1" style="font-size: 9pt">{{ (total[Number(group_list[count - 1])] || {}).str_jigyo_name }}</v-col>
          <center>
            <h1 class="mt-5 mb-5">{{ title }}</h1>
            <!-- 2024.01.23 MCS)SAITO ADD START -->
            <h1 class="mt-5 mb-5" style="font-size: 14pt">{{ sDate.substring(0, 4) }}年{{ sDate.substring(4, 6) }}月{{ sDate.substring(6, 8) }}日～{{ eDate.substring(0, 4) }}年{{ eDate.substring(4, 6) }}月{{ eDate.substring(6, 8) }}日</h1>
            <!-- 2023.01.23 MCS)SAITO ADD END -->
            <table style="border-collapse: collapse">
              <tr>
                <th>利用者コード</th>
                <th>氏 名</th>
                <th>金 額</th>
              </tr>
              <tbody>
                <tr v-for="data in list.slice(Number(length_s[count - 1]), Number(length_e[count - 1]))" v-bind:key="data.str_user_cd">
                  <!-- 2023.12.07 MCS)SAITO MOD START -->
                  <!-- <td width="150px">{{ data.str_user_cd }}</td>
                  <td width="200px">{{ data.str_user_name }}</td>
                  <td width="120px" class="text-right">{{ data.to_char }}</td> -->
                  <td width="150px" style="font-size: 7pt">
                    <p style="max-height: 6px">{{ data.str_user_cd }}</p>
                  </td>
                  <td width="200px" style="font-size: 7pt">
                    <p style="max-height: 6px">{{ data.str_user_name }}</p>
                  </td>
                  <td width="120px" class="text-right" style="font-size: 9pt">{{ data.to_char }}</td>
                  <!-- 2023.12.07 MCS)SAITO MOD END -->
                </tr>
              </tbody>
            </table>
          </center>
          <table v-show="group_list[count - 1] != group_list[count]" style="border-collapse: collapse" class="table-right">
            <tr>
              <th>所属合計金額</th>
              <td width="120px" class="text-right">{{ (total[Number(group_list[count - 1])] || {}).to_char }}</td>
            </tr>
          </table>
        </section>
      </v-col>
    </v-col>
    <!-- 隠し項目 -->
    <label style="display: none; visibility: hidden">{{ this.list }}</label>
  </v-container>
</template>

<style>
.v-data-footer__select {
  display: none;
}

/* 印刷専用のCSS */
@media print {
  .v-data-footer__select {
    display: none;
  }
  .v-data-footer__icons-before {
    display: none;
  }
  .v-data-footer__icons-after {
    display: none;
  }
  .btn_print {
    display: none;
  }
  .v-select__selections {
    display: all;
  }
}
</style>

<script>
import store from "@/store";
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    list: {
      type: Array,
      required: false,
    },
    year: {
      type: Number,
      required: false,
    },
    month: {
      type: String,
      required: false,
    },
    /*--- 2023.12.07 MCS)SAITO ADD START ---*/
    btnpushflg: {
      type: Boolean,
      required: false,
    },
    /*--- 2023.12.07 MCS)SAITO ADD END ---*/
    /*--- 2023.12.13 MCS)SAITO ADD START ---*/
    sDate: {
      type: String,
      required: false,
    },
    eDate: {
      type: String,
      required: false,
    },
    jigyosyono: {
      type: Number,
      required: false,
    },
    /*--- 2023.12.13 MCS)SAITO ADD END ---*/
  },
  // props: {
  //   title: String,
  //   list: Array,
  //   year: Number,
  //   month: String,
  // },
  name: "PreviewReports",
  data() {
    return {
      page: 1,
      length_list: [],
      nowToday: "",
      total: [],
      group_list: [],
      length_s: [],
      length_e: [],
    };
  },
  // computed: {
  //   headers() {
  //     if (this.title == "月別個人品名集計表") {
  //       return [
  //         { text: "所 属", sortable: false, value: "str_busho_name", align: "start" },
  //         { text: "利用者コード", sortable: false, value: "str_user_cd", align: "start" },
  //         { text: "氏 名", sortable: false, value: "str_user_name", align: "start" },
  //         { text: "品 名", sortable: false, value: "str_bento_name", align: "start" },
  //         { text: "単 価", sortable: false, value: "char_kingaku", align: "end" },
  //         { text: "個 数", sortable: false, value: "sum_kosuu", align: "end" },
  //         { text: "金 額", sortable: false, value: "char_goukei", align: "end" },
  //       ];
  //     } else {
  //       return [
  //         { text: "所 属", sortable: false, value: "str_busho_name", align: "start" },
  //         { text: "利用者コード", sortable: false, value: "str_user_cd", align: "start" },
  //         { text: "氏 名", sortable: false, value: "str_user_name", align: "start" },
  //         { text: "金 額", sortable: false, value: "to_char", align: "end" },
  //       ];
  //     }
  //   },
  // },
  async created() {
    if (this.title == "月別所属グループ別個人集計表") {
      const self = this;
      await this.$axios
        .get("/t_chu_yoyaku_get/group", {
          params: {
            KigyoCd: this.$store.state.kigyoCode,
            Chumonbi: this.year + this.month + "%",
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              self.total = d["data"];
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    if (this.title == "月別所属グループ別個人集計表") {
      this.page = this.total.length;
    } else if (Number(this.list.length) % 20 != 0) {
      this.page = Math.floor(Number(this.list.length) / 20) + 1;
    } else {
      this.page = Math.floor(Number(this.list.length) / 20);
    }

    if (this.title == "月別個人品名集計表" || this.title == "月別個人集計表") {
      this.length_list.push(0);
      for (var i = 1; this.page > i; i++) {
        this.length_list.push(Number(i * 20));
      }
    } else {
      this.length_s.push(0);
      this.group_list.push(0);
      var score = 0;
      for (var v = 0; this.total.length - 1 > v; v++) {
        if (Number(this.total[v].count) > 20) {
          for (var j = 0; Math.floor(Number(this.list.length) / 20) > j; j++) {
            this.length_e.push(score + 20);
            score = score + 20;
            this.length_s.push(score);
            this.page++;
            this.group_list.push(v);
          }
          if (Number(this.total[v].count) % 20 != 0) {
            score = score + (Number(this.total[v].count) % 20);
            this.length_e.push(score);
            this.length_s.push(score);
            this.group_list.push(v + 1);
          }
        } else {
          this.group_list.push(v + 1);
          this.length_e.push(score + Number(this.total[v].count));
          score = score + Number(this.total[v].count);
          this.length_s.push(score);
          if (v == this.total.length - 2) {
            this.length_e.push(Number(this.list.length));
          }
        }
      }
    }
  },
  /*--- 2023.12.07 MCS)SAITO ADD START ---*/
  //最初に2ページ以上ある帳票を出力した後に、1ページのみを出力すると2ページ目以降に空のページが作成されてしまうため
  watch: {
    async btnpushflg(newbtnpush) {
      if (newbtnpush == true) {
        /*--- 2023.12.13 MCS)SAITO ADD START ---*/
        this.total = [];
        var oldjigyoNo = "";
        var newjigyoNo = "";
        var totalbento = [];
        var sumCount = 0;
        if (this.title == "月別個人品名集計表") {
          await this.getbentojigyo();
          oldjigyoNo = this.total[0]["num_jigyo_no"];
          newjigyoNo = this.total[0]["num_jigyo_no"];
          for (var z = 0; this.total.length - 1 > z; z++) {
            newjigyoNo = this.total[z]["num_jigyo_no"];
            if (oldjigyoNo != newjigyoNo) {
              totalbento.push(sumCount);
              sumCount = 0;
            }
            sumCount = sumCount + Number(this.total[z]["count"]);
            oldjigyoNo = this.total[z]["num_jigyo_no"];
          }
          newjigyoNo = this.total[z]["num_jigyo_no"];
          if (oldjigyoNo != newjigyoNo) {
            totalbento.push(sumCount);
            sumCount = 0;
          }
          sumCount = sumCount + Number(this.total[z]["count"]);
          totalbento.push(sumCount);
        } else {
          await this.getuserjigyo();
        }
        /*--- 2023.12.13 MCS)SAITO ADD END ---*/
        if (this.title == "月別所属グループ別個人集計表") {
          await this.getgroup();
        }
        /*--- 2023.12.13 MCS)SAITO MOD START ---*/
        // if (this.title == "月別所属グループ別個人集計表") {
        //   this.page = this.total.length;
        // } else if (Number(this.list.length) % 20 != 0) {
        //   this.page = (Math.floor(Number(this.list.length) / 20)) + 1;
        // } else {
        //   this.page = (Math.floor(Number(this.list.length) / 20));
        // }

        // if (this.title == '月別個人品名集計表' || this.title == '月別個人集計表') {
        //   this.length_list.push(0);
        //   for (var i = 1; this.page > i; i++) {
        //     this.length_list.push((Number(i * 20)));
        //   }
        // } else {
        //   this.length_s = [];
        //   this.length_e = [];
        //   this.group_list = [];
        //   this.length_s.push(0);
        //   this.group_list.push(0)
        //   var score = 0;
        //   for (var v = 0; this.total.length - 1 > v; v++) {
        //     if (Number(this.total[v].count) > 20) {
        //       for (var j = 0; (Math.floor(Number(this.total[v].count) / 20)) > j; j++) {
        //         this.length_e.push(score + 20);
        //         score = score + 20;
        //         this.length_s.push(score);
        //         this.page ++;
        //         this.group_list.push(v)
        //       }
        //       if (Number(this.total[v].count) % 20 != 0) {
        //         score = score + (Number(this.total[v].count) % 20)
        //         this.length_e.push(score)
        //         this.length_s.push(score);
        //         this.group_list.push(v+1)
        //       }
        //     } else {
        //       this.group_list.push(v+1)
        //       this.length_e.push(score + (Number(this.total[v].count)))
        //       score = score + (Number(this.total[v].count))
        //       this.length_s.push(score)
        //       if (v == this.total.length - 2) {
        //         this.length_e.push((score + 20))
        //       }
        //     }
        //   }
        //   //最後のページの処理
        //   if (Number(this.total[v].count) > 20) {
        //     for (var x = 0; (Math.floor(Number(this.total[v].count) / 20)) > x; x++) {
        //       this.length_e.push(this.list.length);
        //       score = score + 20;
        //       this.length_s.push(score);
        //       this.page ++;
        //       this.group_list.push(v)
        //     }
        //     if (Number(this.total[v].count) % 20 != 0) {
        //       score = score + (Number(this.total[v].count) % 20)
        //       this.length_e.push(score)
        //       this.length_s.push(score);
        //       this.group_list.push(v+1)
        //     }
        //   } else {
        //     this.group_list.push(v+1)
        //     this.length_e.push(score + (Number(this.total[v].count)))
        //     score = score + (Number(this.total[v].count))
        //     this.length_s.push(score)
        //     // if (v == this.total.length - 2) {
        //     //   this.length_e.push((Number(this.list.length)))
        //     // }
        //   }
        // }
        var score = 0;
        if (this.title == "月別個人品名集計表") {
          this.page = totalbento.length;
          this.length_s = [];
          this.length_e = [];
          this.group_list = [];
          this.length_s.push(0);
          this.group_list.push(0);
          var index = 0;
          for (var b = 0; this.total.length - 1 > b; b++) {
            if (Number(totalbento[index]) > 20) {
              for (var y = 0; Math.floor(Number(totalbento[index]) / 20) > y; y++) {
                this.length_e.push(score + 20);
                score = score + 20;
                this.length_s.push(score);
                this.page++;
                this.group_list.push(b);
              }
              if (Number(totalbento[index]) % 20 != 0) {
                score = score + (Number(totalbento[index]) % 20);
                this.length_e.push(score);
                this.length_s.push(score);
                if (this.total[b]["num_jigyo_no"] != this.total[b + 1]["num_jigyo_no"]) {
                  this.group_list.push(b + 1);
                }
              }
            } else {
              if (this.total[b]["num_jigyo_no"] != this.total[b + 1]["num_jigyo_no"]) {
                this.group_list.push(b + 1);
              }
              this.length_e.push(score + Number(totalbento[index]));
              score = score + Number(totalbento[index]);
              this.length_s.push(score);
              if (b == this.total.length - 2) {
                this.length_e.push(score + 20);
              }
            }
            index += 1;
          }
          //最後のページの処理
          if (Number(totalbento[index]) > 20) {
            for (var a = 0; Math.floor(Number(totalbento[index]) / 20) > a; a++) {
              this.length_e.push(score + 20);
              score = score + 20;
              this.length_s.push(score);
              this.page++;
              this.group_list.push(b);
            }
            if (Number(totalbento[index]) % 20 != 0) {
              score = score + (Number(totalbento[index]) % 20);
              this.length_e.push(score);
              this.length_s.push(score);
              if (this.total[b]["num_jigyo_no"] != this.total[b + 1]["num_jigyo_no"]) {
                this.group_list.push(b + 1);
              }
            }
          } else {
            if (b > 0) {
              if (this.total[b - 1]["num_jigyo_no"] != this.total[b]["num_jigyo_no"]) {
                this.group_list.push(b + 1);
              }
            }
            this.length_e.push(score + Number(totalbento[index]));
            score = score + Number(totalbento[index]);
            this.length_s.push(score);
          }
        } else {
          this.page = this.total.length;
          this.length_s = [];
          this.length_e = [];
          this.group_list = [];
          this.length_s.push(0);
          this.group_list.push(0);
          for (var v = 0; this.total.length - 1 > v; v++) {
            if (Number(this.total[v].count) > 20) {
              for (var j = 0; Math.floor(Number(this.total[v].count) / 20) > j; j++) {
                this.length_e.push(score + 20);
                score = score + 20;
                this.length_s.push(score);
                this.page++;
                this.group_list.push(v);
              }
              if (Number(this.total[v].count) % 20 != 0) {
                score = score + (Number(this.total[v].count) % 20);
                this.length_e.push(score);
                this.length_s.push(score);
                this.group_list.push(v + 1);
              }
            } else {
              this.group_list.push(v + 1);
              this.length_e.push(score + Number(this.total[v].count));
              score = score + Number(this.total[v].count);
              this.length_s.push(score);
              if (v == this.total.length - 2) {
                this.length_e.push(score + 20);
              }
            }
          }
          //最後のページの処理
          if (Number(this.total[v].count) > 20) {
            for (var x = 0; Math.floor(Number(this.total[v].count) / 20) > x; x++) {
              this.length_e.push(score + 20);
              score = score + 20;
              this.length_s.push(score);
              this.page++;
              this.group_list.push(v);
            }
            if (Number(this.total[v].count) % 20 != 0) {
              score = score + (Number(this.total[v].count) % 20);
              this.length_e.push(score);
              this.length_s.push(score);
              this.group_list.push(v + 1);
            }
          } else {
            this.group_list.push(v + 1);
            this.length_e.push(score + Number(this.total[v].count));
            score = score + Number(this.total[v].count);
            this.length_s.push(score);
          }
        }
      }
      /*--- 2023.12.13 MCS)SAITO MOD END ---*/
    },
  },
  /*--- 2023.12.07 MCS)SAITO ADD END ---*/
  methods: {
    downloadCSV() {
      var csv = "";
      if (this.title == "月別個人品名集計表") {
        csv = "\ufeff" + "利用者コード,所 属,氏 名,品 名,単 価,個 数,金 額\n";
        this.list.forEach((el) => {
          var line = el["str_user_cd"] + "," + el["str_busho_name"] + "," + el["str_user_name"] + "," + el["str_bento_name"] + "," + el["num_kingaku"] + "," + el["sum_kosuu"] + "," + el["sum_goukei"] + "\n";
          csv += line;
        });
        let blob = new Blob([csv], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "月別個人品名集計表_" + this.year + "" + this.month + ".csv";
        link.click();
      } else if (this.title == "月別個人集計表") {
        //月別個別集計表
        csv = "\ufeff" + "利用者コード,所 属,氏 名,金 額\n";
        this.list.forEach((el) => {
          var line = el["str_user_cd"] + "," + el["str_busho_name"] + "," + el["str_user_name"] + "," + el["total"] + "\n";
          csv += line;
        });
        let blob = new Blob([csv], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = this.nowToday + "" + store.state.nowTime + "月別個人集計表.csv";
        link.click();
      } else if (this.title == "月別所属グループ別個人集計表") {
        //月別個別集計表
        csv = "\ufeff" + "利用者コード,氏 名,金 額\n";
        this.list.forEach((el) => {
          var line = el["str_user_cd"] + "," + el["str_user_name"] + "," + el["total"] + "\n";
          csv += line;
        });
        let blob = new Blob([csv], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = this.nowToday + "" + store.state.nowTime + "月別所属グループ別個人集計表.csv";
        link.click();
      }
    },
    print() {
      window.print();
    },
    post() {
      this.$router.push("/admin/report");
    },
    /*--- 2023.12.13 MCS)SAITO ADD START ---*/
    async getbentojigyo() {
      const self = this;
      await this.$axios
        .get("/t_chu_yoyaku_get/bentojigyo", {
          params: {
            KigyoCd: this.$store.state.kigyoCode,
            Chumonbi_s: this.sDate,
            Chumonbi_e: this.eDate,
            Jigyono: this.jigyosyono,
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              self.total = d["data"];
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async getuserjigyo() {
      const self = this;
      await this.$axios
        .get("/t_chu_yoyaku_get/userjigyo", {
          params: {
            KigyoCd: this.$store.state.kigyoCode,
            Chumonbi_s: this.sDate,
            Chumonbi_e: this.eDate,
            Jigyosyono: this.jigyosyono,
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              self.total = d["data"];
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    /*--- 2023.12.13 MCS)SAITO ADD END ---*/
    /*--- 2023.12.07 MCS)SAITO ADD START ---*/
    async getgroup() {
      const self = this;
      await this.$axios
        .get("/t_chu_yoyaku_get/group", {
          params: {
            KigyoCd: this.$store.state.kigyoCode,
            /*--- 2024.01.23 MCS)SAITO MOD START ---*/
            // Chumonbi: this.year + this.month + "%",
            Chumonbi_s: this.sDate,
            Chumonbi_e: this.eDate,
            ChumonJigyo: this.jigyosyono,
            /*--- 2024.01.23 MCS)SAITO MOD END ---*/
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              self.total = d["data"];
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    /*--- 2023.12.07 MCS)SAITO ADD END ---*/
  },
};
</script>
