<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：DeptsMaster.vue
  モジュール名：所属・グループマスタ画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <title-card title="所属・グループマスタ">
      <v-btn color="teal" dark depressed @click="createNew()">新規登録</v-btn>
    </title-card>
    <data-card>
      <v-data-table :headers="headers" :items="datas" item-key="item.key" :fixed-header="true" :hide-default-footer="true" :items-per-page="-1" no-data-text="所属・グループのデータがありません。" height="calc(100vh - 188px)" @click:row="clickRow" disable-pagination> </v-data-table>
    </data-card>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** 編集用ダイアログ ここから *** -->
    <v-dialog v-model="dialog" width="500" persistent no-click-animation>
      <v-card @change="change_flg = true">
        <v-card-title>所属・グループ情報</v-card-title>
        <v-form ref="select_form" class="mr-5 ml-5 w-50">
          <!-- 制限なし -->
          <p>所属・グループ名<span class="red--text">*</span></p>
          <v-text-field outlined dense v-model="BushoName" maxlength="20" :rules="[required]"></v-text-field>
          <!-- 数字のみ -->
          <p>電話番号</p>
          <v-text-field outlined dense v-model="Tel" maxlength="15" hint="ハイフンありで入力してください。" :rules="[telephone]" single-line></v-text-field>
          <!-- 数字のみ -->
          <p>内線</p>
          <v-text-field outlined dense v-model="Naisen" maxlength="6" :rules="[naisenno]"></v-text-field>
        </v-form>
        <!-- 下部のボタン群 -->
        <v-col align="right">
          <div v-bind:class="{ button: true }">
            <div v-show="isExisting">
              <v-btn height="40" width="70" color="blue darken-1" text @click="btn_insert()" class="mr-5">登録</v-btn>
              <v-btn height="40" width="70" color="blue darken-1" text @click="dialogdisp(false)">閉じる</v-btn>
            </div>
            <div v-show="isNew">
              <v-btn height="40" width="70" color="green darken-1" text @click="btn_update()" class="mr-5">保存</v-btn>
              <v-btn height="40" width="70" color="red darken-1" text @click="deletebusho()" class="mr-5">削除</v-btn>
              <v-btn height="40" width="70" color="blue darken-1" text @click="dialogdisp(false)">閉じる</v-btn>
            </div>
          </div>
        </v-col>
      </v-card>
    </v-dialog>
    <!-- *** 編集用ダイアログ ここまで *** -->
  </v-container>
</template>

<script>
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "App",
  data() {
    return {
      //エラーメッセージ
      // msg: "",
      datas: [],
      KigyoCd: 0,
      // snackbar0: false,
      // snackbar1: false,
      //エラー表示時間
      // timeout: 3000,
      //ポップアップ表示、非表示
      dialog: false,
      cfmdialog: false,
      //ボタン表示、非表示
      isExisting: false,
      isNew: false,
      //変更フラグ
      change_flg: false,
      //テキストボックス
      BushoNo: "",
      BushoName: "",
      Tel: "",
      Naisen: "",
      required: (value) => !!value || "必ず入力してください。",
      no: (value) => /^[0-9a-zA-Z]+$/.test(value) || "所属・グループ番号は英数字を入力してください。",
      telephone: (value) => /^[0-9-]*$/.test(value) || "電話番号は半角数字を入力してください。",
      naisenno: (value) => /^[0-9-]*$/.test(value) || "内線は半角数字を入力してください。",
    };
  },
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    //入力ダイアログ表示、非表示
    dialogdisp(dispflg) {
      if (dispflg) {
        this["isExisting"] = false;
        this["isNew"] = true;
        this["dialog"] = true; //入力ダイアログを表示
      } else {
        if (this.change_flg == true) {
          if (window.confirm("保存せずに終了します。\n入力内容は破棄されますが、よろしいですか？")) {
            this.BushoNo = "";
            (this.BushoName = ""), (this.Tel = ""), (this.Naisen = ""), (this.change_flg = false);
            this.resetValidation();
            this["dialog"] = false; //入力ダイアログを非表示
          }
        } else {
          this.resetValidation();
          this["dialog"] = false; //入力ダイアログを非表示
        }
      }
    },

    //検証エラーリセット
    resetValidation() {
      this.BushoNo = "";
      (this.BushoName = ""), (this.Tel = ""), (this.Naisen = ""), this.$refs.select_form.resetValidation();
    },

    reset() {
      this.$refs.form.reset();
    },

    //新規作成時
    createNew() {
      this.BushoNo = "";
      this.BushoName = "";
      this.Tel = "";
      this.Naisen = "";
      this["isExisting"] = true;
      this["isNew"] = false;
      this["dialog"] = true;
    },

    //テーブル行選択時
    clickRow(row) {
      this.BushoNo = row["num_busho_no"];
      this.BushoName = row["str_busho_name"];
      this.Tel = row["str_tel"];
      this.Naisen = row["str_naisen"];
      this.dialogdisp(true);
    },

    //登録ボタンが押された時
    btn_insert() {
      //入力チェック
      if (this.$refs.select_form.validate()) {
        this.insertbusho();
      }
    },

    //修正ボタンが押された時
    btn_update() {
      //入力チェック
      if (this.$refs.select_form.validate()) {
        this.updatebusho();
      }
    },

    //所属・グループ検索用
    async serchbusho() {
      const self = this;
      await this.$axios
        .get("/m_busho_get/busho_list", {
          params: {
            KigyoCd: this.$store.state.kigyoCode, //セッション(Vuex)
          },
        })
        .then(function (response) {
          Promise.resolve(response.data).then(function (d) {
            if (Object.keys(d["data"]).length != 0) {
              self.datas = d["data"];
            } else {
              self.datas = [];
              //self.gyosyas.push("no data");
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //所属・グループ登録用
    async insertbusho() {
      //所属・グループ最大値
      var MaxBushoNo = 0;
      const self = this;
      const axios = this.$axios;
      const store = this.$store;
      MaxBushoNo = this.$axios
        .get("/m_busho_get/busho_max", {
          params: {
            KigyoCd: this.$store.state.kigyoCode,
            BushoNo: this.$store.state.bushoNo,
          },
        })
        .then(function (response) {
          return response.data.MaxBushoNo;
        })
        .catch(function (error) {
          console.log(error);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.msg = "エラーが発生しました。";
          // self.snackbar1 = true;
          self.$root.snackbar.showMessage("エラーが発生しました。", "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        });
      Promise.resolve(MaxBushoNo).then(function (d) {
        MaxBushoNo = d;
        const params = new URLSearchParams();
        params.append("KigyoCd", store.state.kigyoCode);
        params.append("MaxBusho", MaxBushoNo);
        params.append("BushoName", self.BushoName);
        params.append("Tel", self.Tel);
        params.append("Naisen", self.Naisen);
        params.append("sUser", store.state.userCode);
        params.append("sAccess", "WEB");
        axios
          .post("/m_busho_post/insert", params)
          .then((response) => {
            console.log(response.data);
            self.change_flg_save(); //変更フラグをfalse
            self.serchbusho();
            self.dialogdisp(false);
            /* -- 2024.05.08 MCS)KONNO MOD START --*/
            // self.msg = "登録しました。";
            // self.snackbar0 = true;
            self.$root.snackbar.showMessage("登録しました。", "success");
            /* -- 2024.05.08 MCS)KONNO MOD END --*/
          })
          .catch((error) => {
            console.log(error.response.data.error);
            /* -- 2024.05.08 MCS)KONNO MOD START --*/
            // self.msg = "エラーが発生しました。 error:" + error.response.data.error;
            // self.snackbar1 = true;
            self.$root.snackbar.showMessage("エラーが発生しました。 error:" + error.response.data.error, "error");
            /* -- 2024.05.08 MCS)KONNO MOD END --*/
          });
      });
    },

    //所属・グループ更新用
    async updatebusho() {
      const self = this;
      const axios = this.$axios;
      const store = this.$store;
      const params = new URLSearchParams();
      params.append("KigyoCd", store.state.kigyoCode);
      params.append("BushoNo", self.BushoNo);
      params.append("BushoName", self.BushoName);
      params.append("Tel", self.Tel);
      params.append("Naisen", self.Naisen);
      params.append("sUser", store.state.userCode);
      params.append("sAccess", "WEB");
      await axios
        .post("/m_busho_post/update", params)
        .then((response) => {
          console.log(response.data);
          self.change_flg_save(); //変更フラグをfalse
          self.serchbusho();
          self.dialogdisp(false);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.msg = "保存しました。";
          // self.snackbar0 = true;
          self.$root.snackbar.showMessage("保存しました。", "success");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        })
        .catch((error) => {
          console.log(error.response.data.error.code);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // self.msg = "エラーが発生しました。 error:" + error.response.data.error;
          // self.snackbar1 = true;
          self.$root.snackbar.showMessage("エラーが発生しました。 error:" + error.response.data.error, "warning");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        });
    },

    //所属・グループ削除用
    async deletebusho() {
      if (window.confirm("削除します。よろしいですか？")) {
        const self = this;
        const axios = this.$axios;
        const store = this.$store;
        const params = new URLSearchParams();
        params.append("KigyoCd", store.state.kigyoCode);
        params.append("BushoNo", self.BushoNo);
        params.append("sUser", store.state.userCode);
        params.append("sAccess", "WEB");
        await axios
          .post("/m_busho_post/delete", params)
          .then((response) => {
            console.log(response.data);
            self.change_flg_save(); //変更フラグをfalse
            self.serchbusho();
            self.dialogdisp(false);
            /* -- 2024.05.08 MCS)KONNO MOD START --*/
            // self.msg = "削除しました。";
            // self.snackbar0 = true;
            self.$root.snackbar.showMessage("削除しました。", "success");
            /* -- 2024.05.08 MCS)KONNO MOD END --*/
          })
          .catch((error) => {
            console.log(error.response.data.error.code);
            /* -- 2024.05.08 MCS)KONNO MOD START --*/
            // self.msg = "エラーが発生しました。 error:" + error.response.data.error;
            // self.snackbar1 = true;
            self.$root.snackbar.showMessage("エラーが発生しました。 error:" + error.response.data.error, "error");
            /* -- 2024.05.08 MCS)KONNO MOD END --*/
          });
      }
    },

    //変更フラグ処理
    change_flg_save() {
      this.change_flg = false;
    },
  },

  async created() {
    await this.serchbusho();
  },

  computed: {
    headers() {
      return [
        /* -- 2024.05.15 MCS)KONNO MOD START --*/
        // { text: "所属・グループ番号", sortable: false, value: "num_busho_no", align: "start", width: "150px" },
        // { text: "所属・グループ名", sortable: false, value: "str_busho_name", align: "start", width: "150px" },
        // { text: "電話番号", sortable: false, value: "str_tel", align: "start", width: "100px" },
        // { text: "内線", sortable: false, value: "str_naisen", align: "start", width: "100px" },
        { text: "所属・グループ番号", sortable: true, value: "num_busho_no", align: "start", width: "150px" },
        { text: "所属・グループ名", sortable: true, value: "str_busho_name", align: "start", width: "150px" },
        { text: "電話番号", sortable: true, value: "str_tel", align: "start", width: "100px" },
        { text: "内線", sortable: true, value: "str_naisen", align: "start", width: "100px" },
        /* -- 2024.05.15 MCS)KONNO MOD START --*/
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
// @import "./src/assets/css/TraderMaster.scss";
</style>
