<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：BentoMaster.vue
  モジュール名：弁当マスタ画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <title-card title="メニューマスタ">
      <v-btn color="teal" dark depressed @click="fncNewData">新規登録</v-btn>
    </title-card>
    <data-card>
      <v-sheet class="px-4 py-2 d-flex" style="align-items: center" height="56px">
        <span>注文場所：</span>
        <v-responsive class="mr-4" max-width="200px">
          <v-select backgroundColor="white" hide-details dense outlined item-text="liJigyoName" item-value="liJigyoNo" :items="JigyoFilterItems" v-model="JigyoFilterSelect"></v-select>
        </v-responsive>
        <span>弁当業者：</span>
        <v-responsive class="mr-4" max-width="200px">
          <v-select backgroundColor="white" hide-details dense outlined item-text="liGyoshaName" item-value="liGyoshaNo" :items="GyoshaFilterItems" v-model="GyoshaFilterSelect" :disabled="JigyoFilterSelect == ''"></v-select>
        </v-responsive>
      </v-sheet>
      <v-divider></v-divider>
      <v-data-table
        item-value="select_value"
        :headers="[
          {
            text: '注文場所',
            value: 'itemJigyoName',
            width: '280px',
            align: 'start',
            filter: (value) => {
              if (this.JigyoFilterSelect == 0) return true;
              return value == this.JigyoFilterItems.find((i) => i.liJigyoNo == this.JigyoFilterSelect).liJigyoName;
            },
          },
          {
            text: '弁当業者',
            value: 'itemGyoshaName',
            width: '280px',
            align: 'start',
            filter: (value) => {
              if (this.GyoshaFilterSelect == 0) return true;
              return value == this.GyoshaFilterItems.find((i) => i.liGyoshaNo == this.GyoshaFilterSelect).liGyoshaName;
            },
          },
          { text: '弁当名', value: 'itemBentoName', align: 'start' },
          { text: '金額', value: 'itemKingaku', align: 'end' },
          { text: 'カロリー', value: 'itemCalory', align: 'end' },
        ]"
        :items="datas"
        :fixed-header="true"
        :hide-default-footer="true"
        :items-per-page="-1"
        height="calc(100vh - 245px)"
        @click:row="select_row"
        no-data-text="弁当のデータがありません。"
        no-results-text="該当するデータがありません。"
      />
    </data-card>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** 編集用ダイアログ ここから *** -->
    <v-dialog v-model="dialog" width="600px" persistent no-click-animation>
      <v-card>
        <v-form ref="ref_form" class="pa-4">
          <table>
            <tr class="pa-5">
              <td style="width: 600px" class="pa-1">
                <v-col
                  >注文場所<span class="red--text" v-if="!JigyoSetDisable">*</span><br />
                  <!-- 2024.03.22 MCS)SAITO MOD START -->
                  <!-- <v-select outlined class="text" dense item-text="liJigyoName" item-value="liJigyoNo" :items="JigyoSetItems" v-model="JigyoSetSelect" :disabled="JigyoSetDisable"></v-select> -->
                  <v-select outlined class="text" dense style="width: 540px" item-text="liJigyoName" item-value="liJigyoNo" :items="JigyoSetItems" v-model="JigyoSetSelect" :disabled="JigyoSetDisable"></v-select>
                  <!-- 2024.03.22 MCS)SAITO MOD END --></v-col
                >
                <v-col
                  >弁当業者<span class="red--text" v-if="!GyoshaSetDisable">*</span><br />
                  <!-- 2024.03.22 MCS)SAITO MOD START -->
                  <!-- <v-select outlined class="text" dense item-text="liGyoshaName" item-value="liGyoshaNo" :items="GyoshaSetItems" v-model="GyoshaSetSelect" :disabled="GyoshaSetDisable"></v-select> -->
                  <v-select outlined class="text" dense style="width: 540px" item-text="liGyoshaName" item-value="liGyoshaNo" :items="GyoshaSetItems" v-model="GyoshaSetSelect" :disabled="GyoshaSetDisable"></v-select>
                  <!-- 2024.03.22 MCS)SAITO MOD END --></v-col
                >
                <v-col
                  >弁当名<span class="red--text">*</span><br />
                  <v-text-field outlined class="text" v-model="txtBentoName" dense maxlength="40" @change="change_flg = true" :rules="[(value) => !!value || '弁当名が入力されていません。']"> </v-text-field
                ></v-col>
                <v-row class="px-3">
                  <v-col cols="6"
                    >金額<span class="red--text">*</span><br />
                    <table style="width: -webkit-fill-available">
                      <tr>
                        <td>
                          <!-- 2024.04.30 MCS)ADACHI MOD START -->
                          <!-- <v-text-field outlined width="50%" class="text" dense v-model="txtBentoKingaku" style="text-align-last: end" type="number" step="1" @change="change_flg = true" :rules="[(value) => !!value || '弁当金額が入力されていません。', (value) => value != 0 || '金額が正しくありません。', (value) => value < 100000 || '金額が大きすぎます', (value) => !!value.match(/^[0-9]+$/) || '小数は入力できません。']"></v-text-field> -->
                          <v-text-field outlined width="50%" class="text" dense v-model="txtBentoKingaku" style="text-align-last: end" type="number" step="1" @change="change_flg = true" :rules="[(value) => !!String(value) || '弁当金額が入力されていません。', (value) => value >= 0 || '金額が正しくありません。', (value) => value < 100000 || '金額が大きすぎます', (value) => !!String(value).match(/^[0-9]+$/) || '小数は入力できません。']"></v-text-field>
                          <!-- 2024.04.30 MCS)ADACHI MOD END -->
                        </td>
                        <td style="width: 30px">円</td>
                      </tr>
                    </table></v-col
                  >
                  <v-col cols="6"
                    >カロリー<span class="red--text">*</span><br />
                    <table style="width: -webkit-fill-available">
                      <tr>
                        <td>
                          <!-- 2024.04.30 MCS)ADACHI MOD START -->
                          <!-- <v-text-field outlined class="text" dense v-model="txtBentoCal" style="text-align-last: end" type="number" step="0.01" @change="change_flg = true" :rules="[(value) => !!value || 'カロリーが入力されていません。', (value) => value >= 0 || 'カロリーが正しくありません。', (value) => value < 10000 || 'カロリーが高すぎます', (value) => !!value.match(/^[0-9]+$/) || '小数は入力できません。']"> </v-text-field> -->
                          <v-text-field outlined class="text" dense v-model="txtBentoCal" style="text-align-last: end" type="number" step="0.01" @change="change_flg = true" :rules="[(value) => !!String(value) || 'カロリーが入力されていません。', (value) => value >= 0 || 'カロリーが正しくありません。', (value) => value < 10000 || 'カロリーが高すぎます']"> </v-text-field>
                          <!-- 2024.04.30 MCS)ADACHI MOD END -->
                        </td>
                        <td style="width: 30px">kcal</td>
                      </tr>
                    </table></v-col
                  >
                </v-row>
                <!-- 2024.04.30 MCS)ADACHI ADD START -->
                <v-divider class="my-4"></v-divider>
                <v-row>
                  <v-col class="" style="width: 540px" v-bind:style="mongonstyles">弁当の金額が変更となる場合、以下を設定してください。開始日に自動更新されます。</v-col>
                </v-row>

                <v-row class="px-3">
                  <v-col cols="6"
                    >新金額<br />
                    <table style="width: -webkit-fill-available">
                      <tr>
                        <td>
                          <v-text-field outlined width="50%" class="text" dense v-model="txtNewBentoKingaku" style="text-align-last: end" type="number" step="1" @change="change_flg = true" :rules="[(value) => !!String(value) || value + '弁当金額が入力されていません。', (value) => value >= 0 || '金額が正しくありません。', (value) => value < 100000 || '金額が大きすぎます', (value) => !!String(value).match(/^[0-9]+$/) || '小数は入力できません。']"></v-text-field>
                        </td>
                        <td style="width: 30px">円</td>
                      </tr>
                    </table></v-col
                  >
                  <div class="mx-3 my-5">
                    <div class="d-flex align-center"><v-icon>event</v-icon>&ensp;開始日</div>
                    <v-menu v-model="dialog_dtp_st" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-row>
                          <v-text-field class="pt-0 px-0 ml-3 my-5" style="width: 170px" :value="fmtDate_YYYYMD(select_date_st)" readonly v-bind="attrs" v-on="on" hide-details></v-text-field>
                          <v-btn class="mr-5 my-8" height="30" width="40" style="font-size: 8pt" color="blue darken-1" text @click="fncDateClear">クリア</v-btn>
                        </v-row>
                      </template>
                      <v-date-picker v-model="select_date_st" @change="dialog_dtp_st = false" locale="jp-ja" :min="comp_min_date" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
                    </v-menu>
                  </div>
                </v-row>
                <!-- 2024.04.30 MCS)ADACHI ADD END -->
              </td>
              <!-- <td rowspan="5" style="vertical-align: top;" class="pr-1 pl-1 pt-4 pb-6" >
						<v-row align="center">
							<v-text-field style="width:250px">{{this.filename}}</v-text-field>
							<v-btn class="mx-auto" height="20px" width="70px" @click="selectimage">参照</v-btn></v-row>
						<div :class="{ 'over': isDragOver }"
								@dragover.prevent="onDrag('over')"
								@dragleave="onDrag('leave')"
								@drop.stop="onDrop">
							<v-img v-if="this.path == ''">imgファイルを1枚ずつドラッグ&ドロップ<v-card width="400px" height="300px"/></v-img>
							<v-img v-else v-bind:src="this.path" max-height="260px" max-width="350px">
							<input type="file" style="display: none;" ref="input" accept="image/jpeg, image/jpg, image/png" @change="selectedFile"></v-img>
						</div>
					</td> -->
            </tr>
          </table>
          <p style="text-align-last: end">
            <v-btn height="40" width="70" color="blue darken-1" text @click="btn_insert()" v-show="InsertDisplay" class="mr-5">登録</v-btn>
            <v-btn height="40" width="70" color="green darken-1" text @click="btn_update()" v-show="UpdateDisplay" class="mr-5">保存</v-btn>
            <v-btn height="40" width="70" color="red darken-1" text @click="btn_delete()" v-show="UpdateDisplay" class="mr-5">削除</v-btn>
            <v-btn height="40" width="70" color="blue darken-1" text @click="dialogdisp(false)">閉じる</v-btn>
          </p>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- *** 編集用ダイアログ ここまで *** -->
  </v-container>
</template>

<script>
/* -- 2024.04.30 MCS)ADACHI ADD START -- */
import moment from "moment";
/* -- 2024.04.30 MCS)ADACHI ADD END -- */
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "BentoMaster",
  // props: ["value"],
  data: () => ({
    //エラーメッセージ
    // msg: "",
    // snackbar: false,
    // snackbar_color: "",
    //エラー表示時間
    // timeout: 3000,
    //ポップアップ表示、非表示
    dialog: false,
    //ポップアップ上の登録ボタン表示非表示
    InsertDisplay: false,
    //ポップアップ上の修正ボタン表示非表示
    UpdateDisplay: false,

    //ダイアログ
    txtJigyoName: "",
    txtGyoshaName: "",
    txtBentoName: "",
    txtBentoKingaku: "0",
    txtBentoCal: "0",
    // hidJigyoNo: 0,
    // hidGyoshaNo: 0,
    hidBentoNo: 0,

    //イメージ関連
    isDragOver: false,
    file: {},
    filename: "",
    path: "",

    //事業所フィルター選択
    JigyoFilterItems: [],
    JigyoFilterSelect: 0,
    //業者フィルター選択
    GyoshaFilterItems: [],
    GyoshaFilterSelect: 0,
    //事業所選択
    JigyoSetItems: [],
    JigyoSetSelect: 0,
    JigyoSetDisable: false,
    //業者選択
    GyoshaSetItems: [],
    GyoshaSetSelect: 0,
    GyoshaSetDisable: false,

    //データテーブル
    datas: [],

    //変更時フラグ
    change_flg: false,

    /* -- 2024.04.30 MCS)ADACHI ADD START -- */
    txtNewBentoKingaku: "0",
    // 選択した開始日
    select_date_st: moment().format("yyyy-MM-DD"),
    jp_week: ["日", "月", "火", "水", "木", "金", "土"], // 曜日
    dialog_dtp_st: false, // 開始日ダイアログ状態
    mongonstyles: {
      fontSize: "10pt",
    },
    /* -- 2024.04.30 MCS)ADACHI ADD END -- */
  }),
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    async btn_insert() {
      /* -- 2024.04.30 MCS)ADACHI MOD START -- */
      // if (this.$refs.ref_form.validate) {
      //   //dataとセッション情報
      //   const params = new URLSearchParams();
      //   params.append("KigyoNo", this.$store.state.kigyoCode);
      //   params.append("GyoshaNo", this.GyoshaSetSelect);
      //   params.append("JigyoNo", this.JigyoSetSelect);
      //   // params.append("BentoNo", this.hidBentoNo);
      //   params.append("BentoName", this.txtBentoName);
      //   params.append("BentoKingaku", this.txtBentoKingaku);
      //   params.append("BentoCalory", this.txtBentoCal);
      //   params.append("sUser", this.$store.state.userCode);
      //   params.append("sAccess", "WEB");
      //   /* -- 2024.04.30 MCS)ADACHI ADD START -- */
      //   params.append("NewBentoKingaku", this.txtNewBentoKingaku);
      //   if(this.select_date_st != ""){
      //     params.append("NewStartDate", moment(this.select_date_st).format("yyyyMMDD"));
      //   }else{
      //     params.append("NewStartDate", this.select_date_st);
      //   }
      //   /* -- 2024.04.30 MCS)ADACHI ADD END -- */

      //   //登録
      //   var results = await this.$axios
      //     .post("/m_bento_post/insert", params)
      //     .then(function () {
      //       return "";
      //     })
      //     .catch(function (error) {
      //       return error;
      //     });
      //   if (results == "") {
      //     this.noticeOK("登録しました。");
      //     this.change_flg = false;
      //     //リスト再読み込み
      //     this["dialog"] = false;
      //     // this.ReadBentolist(this.hidJigyoNo, this.hidGyoshaNo);
      //     this.ReadBentolist();
      //   } else {
      //     console.log(results);
      //     this.noticeNG(results);
      //   }
      // }
      if (!this.$refs.ref_form.validate()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("未入力項目があります。予約内容を確認してください。");
        this.$root.snackbar.showMessage("未入力項目があります。予約内容を確認してください。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      //dataとセッション情報
      const params = new URLSearchParams();
      params.append("KigyoNo", this.$store.state.kigyoCode);
      params.append("GyoshaNo", this.GyoshaSetSelect);
      params.append("JigyoNo", this.JigyoSetSelect);
      params.append("BentoName", this.txtBentoName);
      params.append("BentoKingaku", this.txtBentoKingaku);
      params.append("BentoCalory", this.txtBentoCal);
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      params.append("NewBentoKingaku", this.txtNewBentoKingaku);
      if (this.select_date_st != "") {
        params.append("NewStartDate", moment(this.select_date_st).format("yyyyMMDD"));
      } else {
        params.append("NewStartDate", this.select_date_st);
      }
      //登録
      var results = await this.$axios
        .post("/m_bento_post/insert", params)
        .then(function () {
          return "";
        })
        .catch(function (error) {
          return error;
        });
      if (results == "") {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("登録しました。");
        this.$root.snackbar.showMessage("登録しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        this.change_flg = false;
        //リスト再読み込み
        this["dialog"] = false;
        this.ReadBentolist();
      } else {
        console.log(results);
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG(results);
        this.$root.snackbar.showMessage(results, "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      }
      /* -- 2024.04.30 MCS)ADACHI MOD END -- */
    },
    //更新時処理
    async btn_update() {
      /* -- 2024.04.30 MCS)ADACHI MOD START -- */
      // if (this.$refs.ref_form.validate) {
      //   //dataとセッション情報
      //   const params = new URLSearchParams();
      //   params.append("KigyoNo", this.$store.state.kigyoCode);
      //   params.append("GyoshaNo", this.GyoshaSetSelect);
      //   params.append("JigyoNo", this.JigyoSetSelect);
      //   params.append("BentoNo", this.hidBentoNo);
      //   params.append("BentoName", this.txtBentoName);
      //   params.append("BentoKingaku", this.txtBentoKingaku);
      //   params.append("BentoCalory", this.txtBentoCal);
      //   params.append("BentoImg", "");
      //   params.append("sUser", this.$store.state.userCode);
      //   params.append("sAccess", "WEB");
      //   /* -- 2024.04.30 MCS)ADACHI ADD START -- */
      //   params.append("NewBentoKingaku", this.txtNewBentoKingaku);
      //   if(this.select_date_st != ""){
      //     params.append("NewStartDate", moment(this.select_date_st).format("yyyyMMDD"));
      //   }else{
      //     params.append("NewStartDate", this.select_date_st);
      //   }
      //   /* -- 2024.04.30 MCS)ADACHI ADD END -- */

      //   //登録
      //   var results = await this.$axios
      //     .post("/m_bento_post/update", params)
      //     .then(function () {
      //       return "";
      //     })
      //     .catch(function (error) {
      //       return error;
      //     });
      //   if (results == "") {
      //     this.noticeOK("保存しました。");
      //     this.change_flg = false;
      //     this.dialogdisp(false);
      //     // this.ReadBentolist(this.hidJigyoNo, this.hidGyoshaNo);
      //     this.ReadBentolist();
      //   } else {
      //     console.log(results);
      //     this.noticeNG(results);
      //   }
      //   //イメージ保存処理
      //   // const params_image = new URLSearchParams();
      //   // params_image.append("KigyoNo", store.state.kigyoCode);
      //   // params_image.append("GyoshaNo", this.GyoshaFilterSelect);
      //   // params_image.append("JigyosyoNo", this.JigyoFilterSelect);
      //   // params_image.append("BentoNo", this.bento_number);
      //   // params_image.append("bento_img", this.path);
      //   // //登録
      //   // axios
      //   // .get("/m_bento_post/image_update",	{params:{
      //   // 		KigyoNo: store.state.kigyoCode,	//セッション(Vuex)
      //   // 		GyoshaNo: this.GyoshaFilterSelect,	//セッション(Vuex)
      //   // 		JigyosyoNo: this.JigyoFilterSelect,	//セッション(Vuex)
      //   // 		BentoNo: this.bento_number,	//セッション(Vuex)
      //   // 		bento_img:this.path
      //   // 	}
      //   // })
      //   // .then(function () {
      //   // 	this.noticeOK('修正しました。');
      //   // 	this.dialogdisp(false)
      //   // 	this.ReadBentolist(this.JigyoFilterSelect,this.GyoshaFilterSelect)
      //   // })
      //   // .catch(function (error) {
      //   // 	this.noticeNG(error);
      //   // });
      // }
      if (!this.$refs.ref_form.validate()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("未入力項目があります。予約内容を確認してください。");
        this.$root.snackbar.showMessage("未入力項目があります。予約内容を確認してください。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      //dataとセッション情報
      const params = new URLSearchParams();
      params.append("KigyoNo", this.$store.state.kigyoCode);
      params.append("GyoshaNo", this.GyoshaSetSelect);
      params.append("JigyoNo", this.JigyoSetSelect);
      params.append("BentoNo", this.hidBentoNo);
      params.append("BentoName", this.txtBentoName);
      params.append("BentoKingaku", this.txtBentoKingaku);
      params.append("BentoCalory", this.txtBentoCal);
      params.append("BentoImg", "");
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      params.append("NewBentoKingaku", this.txtNewBentoKingaku);
      if (this.select_date_st != "") {
        params.append("NewStartDate", moment(this.select_date_st).format("yyyyMMDD"));
      } else {
        params.append("NewStartDate", this.select_date_st);
      }
      //登録
      var results = await this.$axios
        .post("/m_bento_post/update", params)
        .then(function () {
          return "";
        })
        .catch(function (error) {
          return error;
        });
      if (results == "") {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("保存しました。");
        this.$root.snackbar.showMessage("保存しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        this.change_flg = false;
        this.dialogdisp(false);
        this.ReadBentolist();
      } else {
        console.log(results);
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG(results);
        this.$root.snackbar.showMessage(results, "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      }
    },

    //削除時処理
    async btn_delete() {
      if (window.confirm("削除します。よろしいですか？")) {
        //dataとセッション情報
        var params = new URLSearchParams();
        params.append("KigyoNo", this.$store.state.kigyoCode);
        params.append("GyoshaNo", this.GyoshaSetSelect);
        params.append("JigyoNo", this.JigyoSetSelect);
        params.append("BentoNo", this.hidBentoNo);
        params.append("sUser", this.$store.state.userCode);
        params.append("sAccess", "WEB");

        //削除
        var results = await this.$axios
          .post("/m_bento_post/delete", params)
          .then(function () {
            return "";
          })
          .catch(function (error) {
            return error;
          });
        if (results == "") {
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // this.noticeOK("削除しました。");
          this.$root.snackbar.showMessage("削除しました。", "success");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
          this.change_flg = false;
          this.dialogdisp(false);
          // this.ReadBentolist(this.hidJigyoNo, this.hidGyoshaNo);
          this.ReadBentolist();
        } else {
          console.log(results);
          /* -- 2024.05.08 MCS)KONNO MOD START --*/
          // this.noticeNG(results);
          this.$root.snackbar.showMessage(results, "error");
          /* -- 2024.05.08 MCS)KONNO MOD END --*/
        }
      }
    },

    //開始日初期化
    async fncDateClear() {
      this.select_date_st = "";
      this.fmtDate_YYYYMD(this.select_date_st);
    },

    //入力ダイアログ表示、非表示
    dialogdisp(dispflg) {
      if (dispflg) {
        this["dialog"] = true;
      } else {
        if (this.change_flg == true) {
          if (window.confirm("保存せずに終了します。\n入力内容は破棄されますが、よろしいですか？")) {
            this["dialog"] = false;
            this.change_flg = false;
          }
        } else {
          this["dialog"] = false;
        }
      }
    },
    //選択検知(選択フラグを配列に代入)
    select_row(row) {
      //テキストボックス初期化
      this.txtBentoName = "";
      this.txtBentoKingaku = "";
      this.txtBentoCal = "0";
      /* -- 2024.04.30 MCS)ADACHI ADD START -- */
      this.txtNewBentoKingaku = "";
      this.select_date_st = "";
      /* -- 2024.04.30 MCS)ADACHI ADD END -- */

      //選択した項目の情報をテキストボックスに転記
      // this.txtJigyoName = this.JigyoSetSelect.liJigyoName;
      // this.txtGyoshaName = this.GyoshaSetSelect.liGyoshaName;
      // this.hidJigyoNo = this.JigyoSetSelect.liJigyoNo;
      // this.hidGyoshaNo = this.GyoshaSetSelect.liGyoshaNo;
      this.JigyoSetSelect = row.dtJigyoNo;
      this.JigyoSetDisable = true;
      this.GyoshaSetSelect = row.dtGyoshaNo;
      this.GyoshaSetDisable = true;
      this.hidBentoNo = row.dtBentoNo;
      this.txtBentoName = row.dtBentoName;
      this.txtBentoKingaku = row.dtKingaku;
      this.txtBentoCal = row.dtBentoCalory;
      /* -- 2024.04.30 MCS)ADACHI ADD START -- */
      this.txtNewBentoKingaku = row.dtNewKingaku;
      if (row.dtNewStartDate != "        ") {
        this.select_date_st = moment(row.dtNewStartDate).format("yyyy-MM-DD");
      } else {
        this.select_date_st = "";
      }
      /* -- 2024.04.30 MCS)ADACHI ADD END -- */

      var getImage = "";
      // if (row.vdtBentoImg != ''){
      // 	getImage = ''
      // }
      this.path = getImage; //row.str_bentoimg'';
      //ポップアップ上の登録ボタン表示非表示
      this["InsertDisplay"] = false;
      //ポップアップ上の修正ボタン表示非表示
      this["UpdateDisplay"] = true;
      this["dialog"] = true;
      this.$nextTick(() => {
        if (this.$refs["ref_form"]) {
          this.$refs.ref_form.resetValidation();
        }
      });
    },
    //新規追加
    fncNewData: async function () {
      // if ((this.JigyoFilterSelect == undefined || this.JigyoFilterSelect.liJigyoNo > 0) & (this.GyoshaFilterSelect == undefined || this.GyoshaFilterSelect.liGyoshaNo > 0)) {
      this["dialog"] = true;
      this.txtBentoName = "";
      this.txtBentoKingaku = "0";
      this.txtBentoCal = "0";
      /* -- 2024.04.30 MCS)ADACHI ADD START -- */
      this.txtNewBentoKingaku = "0";
      this.select_date_st = "";
      /* -- 2024.04.30 MCS)ADACHI ADD END -- */
      this.path = "";
      // this.txtJigyoName = this.JigyoSetSelect.liJigyoName;
      // this.txtGyoshaName = this.GyoshaSetSelect.liGyoshaName;
      // this.hidJigyoNo = this.JigyoSetSelect.liJigyoNo;
      // this.hidGyoshaNo = this.GyoshaSetSelect.liGyoshaNo;
      // this.hidBentoNo = await this.fncBentoNoMax(this.JigyoFilterSelect.liJigyoNo, this.GyoshaFilterSelect.liGyoshaNo);
      /* -- 2024.03.26 MCS)SAITO MOD START --*/
      // this.JigyoSetSelect = 0;
      if (this.JigyoFilterSelect != 0) {
        this.JigyoSetSelect = this.JigyoFilterSelect;
      }
      /* -- 2024.03.26 MCS)SAITO MOD END --*/
      this.JigyoSetDisable = false;
      /* -- 2024.03.26 MCS)SAITO MOD START --*/
      // this.GyoshaSetSelect = 0;
      if (this.GyoshaFilterSelect != 0) {
        this.GyoshaSetSelect = this.GyoshaFilterSelect;
      }
      /* -- 2024.03.26 MCS)SAITO MOD END --*/
      this.GyoshaSetDisable = false;

      //ポップアップ上の登録ボタン表示非表示
      this["InsertDisplay"] = true;
      //ポップアップ上の修正ボタン表示非表示
      this["UpdateDisplay"] = false;
      this["dialog"] = true;
      this.$nextTick(() => {
        if (this.$refs["ref_form"]) {
          this.$refs.ref_form.resetValidation();
        }
      });
      // } else if (this.JigyoFilterSelect == undefined || this.JigyoFilterSelect.liJigyoNo == 0) {
      //   this.noticeNG("事業所を選択してください。");
      // } else if (this.GyoshaFilterSelect.liGyoshaNo == undefined || this.GyoshaFilterSelect.liGyoshaNo == 0) {
      //   this.noticeNG("弁当業者を選択してください。");
      // }
    },

    //業務名ドロップダウンの作成
    async setGyoshaList() {
      // ---2024.03.22 MCS)SAITO ADD START
      var self = this;
      // ---2024.03.22 MCS)SAITO ADD END
      let kigyoCode = this.$store.state.kigyoCode;
      // ---2024.03.22 MCS)SAITO ADD START
      let response_data;
      var select_jigyono = 0;
      // ポップアップが表示されていれば、ポップアップ上の弁当業者を設定
      if (this.dialog == true) {
        select_jigyono = self.JigyoSetSelect;
      } else {
        select_jigyono = self.JigyoFilterSelect;
      }
      // ---2024.03.22 MCS)SAITO ADD END
      // ---2024.03.22 MCS)SAITO MOD START
      // let response_data = await this.$axios
      //   .get("/m_gyosha_get/gyosha_list2", {
      //     params: {
      //       KigyoCd: kigyoCode, //セッション(Vuex)
      //       // JigyoNo: Number(selected_jigyosyo),
      //     },
      //   })
      //   .then(function (response) {
      //     return response.data.data;
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //     // this.noticeNG(error);
      //   });
      if (select_jigyono != 0) {
        response_data = await this.$axios
          .get("/m_gyosha_get/gyosha_list", {
            params: {
              KigyoCd: kigyoCode, //セッション(Vuex)
              JigyoNo: Number(select_jigyono),
            },
          })
          .then(function (response) {
            return response.data.data;
          })
          .catch(function (error) {
            console.log(error);
            // this.noticeNG(error);
          });
      } else {
        response_data = [];
      }
      // ---2024.03.22 MCS)SAITO MOD END
      let wkGyoshaFilterItems = [];
      let wkGyoshaSetItems = [];
      wkGyoshaFilterItems.push({ liGyoshaName: "すべて", liGyoshaNo: 0 });
      response_data.forEach(function (rows) {
        wkGyoshaFilterItems.push({
          liGyoshaName: rows["str_gyosha_name"],
          liGyoshaNo: rows["num_gyosha_no"],
        });
        wkGyoshaSetItems.push({
          liGyoshaName: rows["str_gyosha_name"],
          liGyoshaNo: rows["num_gyosha_no"],
        });
      });
      // ---2024.03.22 MCS)SAITO MOD START
      // this.GyoshaFilterItems = wkGyoshaFilterItems;
      // this.GyoshaSetItems = wkGyoshaSetItems;
      if (this.dialog == true) {
        this.GyoshaSetItems = wkGyoshaSetItems;
      } else {
        this.GyoshaFilterItems = wkGyoshaFilterItems;
      }
      // ---2024.03.22 MCS)SAITO MOD END
    },
    //事業所ドロップダウンの作成
    async setJigyoList() {
      let kigyoCode = this.$store.state.kigyoCode;
      let response_data = await this.$axios
        .get("/m_jigyo_get/jigyosyo_list", {
          params: {
            KigyoCd: kigyoCode, //セッション(Vuex)
          },
        })
        .then(function (response) {
          return response.data.data;
        })
        .catch(function (error) {
          console.log(error);
          // tthis.noticeNG(error);
        });
      let wkJigyoFilterItems = [];
      let wkJigyoSetItems = [];
      wkJigyoFilterItems.push({ liJigyoName: "すべて", liJigyoNo: 0 });
      response_data.forEach(function (rows) {
        wkJigyoFilterItems.push({
          liJigyoName: rows["str_jigyo_name"],
          liJigyoNo: rows["num_jigyo_no"],
        });
        wkJigyoSetItems.push({
          liJigyoName: rows["str_jigyo_name"],
          liJigyoNo: rows["num_jigyo_no"],
        });
      });
      this.JigyoFilterItems = wkJigyoFilterItems;
      this.JigyoSetItems = wkJigyoSetItems;
    },
    //弁当リスト読み込み
    async ReadBentolist() {
      let kigyoCode = this.$store.state.kigyoCode;
      let response_data = await this.$axios
        .get("/m_bento_get/bento_list2", {
          params: {
            KigyoCd: kigyoCode, //セッション(Vuex)
          },
        })
        .then(function (response) {
          return response.data.data;
        })
        .catch(function (error) {
          console.log(error);
          // this.noticeNG(error);
        });
      let wkBentoList = [];
      response_data.forEach(function (rows) {
        wkBentoList.push({
          dtJigyoNo: rows.num_jigyo_no,
          dtGyoshaNo: rows.num_gyosha_no,
          dtBentoNo: rows.num_bento_no,
          dtBentoName: rows.str_bento_name,
          dtKingaku: Number(rows.num_kingaku),
          dtBentoCalory: rows.num_bento_calory,
          dtBentoImg: rows.str_bento_img,
          itemJigyoName: rows.str_jigyo_name,
          itemGyoshaName: rows.str_gyosha_name,
          itemBentoName: rows.str_bento_name,
          itemKingaku: Number(rows.num_kingaku).toLocaleString(),
          itemCalory: Math.round(rows.num_bento_calory),
          /* -- 2024.04.30 MCS)ADACHI ADD START -- */
          dtNewKingaku: Number(rows.num_new_kingaku),
          dtNewStartDate: rows.str_new_start_date,
          /* -- 2024.04.30 MCS)ADACHI ADD END -- */
        });
      });
      this.datas = wkBentoList;
    },
    //弁当連番最大値取得
    async fncBentoNoMax(selected_jigyosyo, selected_gyosya) {
      let kigyoCode = this.$store.state.kigyoCode;
      let maxBentoNo = 0;
      await this.$axios
        .get("/m_bento_get/bento_max", {
          params: {
            KigyoCd: kigyoCode, //セッション(Vuex)
            JigyoNo: Number(selected_jigyosyo), //セッション(Vuex)
            GyoshaNo: Number(selected_gyosya),
          },
        })
        .then(function (response) {
          maxBentoNo = response.data.data.max_bento_no;
        })
        .catch(function (error) {
          console.log(error);
          return -1;
          // this.noticeNG(error);
        });

      return maxBentoNo;
    },
    //Drag & Drop
    onDrag(type) {
      this.isDragOver = type === "over";
    },
    onDrop(event) {
      this.isDragOver = false;
      event.preventDefault(); //Dropした時画像ブラウザの抑制
      const files = event.dataTransfer.files;
      this.file = files[0];
      if (!this.file.name.match(".(jpeg|jpg|png)$")) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("拡張子が jpeg, jpg, png 以外のファイルはアップロードできません。");
        this.$root.snackbar.showMessage("拡張子が jpeg, jpg, png 以外のファイルはアップロードできません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      if (files.length !== 1 || files[0].type.indexOf("image") !== 0) {
        return;
      }
      this.readImage(files[0]);
    },
    onChange(event) {
      const files = event.target.files;
      if (files.length !== 1 || files[0].type.indexOf("image") !== 0) {
        return;
      }
      this.readImage(files[0]);
    },
    readImage(file) {
      this.filename = file.name;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = this.loadImage;
    },
    loadImage(e) {
      let image = new Image();
      this.image = image;
      this.path = e.target.result;
    },
    selectimage() {
      this.$refs.input.click();
    },
    async selectedFile(event) {
      this.isUpload = true;

      const files = event.target.files;
      this.file = files[0];
      if (!this.file.name.match(".(jpeg|jpg|png)$")) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("拡張子が jpeg, jpg, png 以外のファイルはアップロードできません。");
        this.$root.snackbar.showMessage("拡張子が jpeg, jpg, png 以外のファイルはアップロードできません。", "warning");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      this.readImage(files[0]);
    },
    binary_image: function (base64_image) {
      if (base64_image == "") {
        return "";
      } else {
        // let raw = window.atob(base64_image.replace(/^.*,/, ''));
        // var HEX = '';
        // for (let i = 0; i < raw.length; i++ ) {
        // 	var _hex = raw.charCodeAt(i).toString(16)
        // 	HEX += (_hex.length==2?_hex:'0'+_hex);
        // }
        // return '¥x' + HEX.toUpperCase();
        let binaryString = "";
        const bytes = new Uint8Array(base64_image);
        const len = bytes.byteLength;
        console.log(String.fromCharCode(bytes[0]));
        for (let i = 0; i < len; i++) {
          binaryString += String.fromCharCode(bytes[i]);
        }
        return "¥x" + binaryString;
      }
    },
    decrypt_image(hex16_image) {
      if (hex16_image == "") {
        return "";
      } else {
        // return window.btoa(hex16_image);
        let binaryString = "";
        const bytes = new Uint8Array(hex16_image);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binaryString += String.fromCharCode(bytes[i]);
        }
        return binaryString;
      }
    },

    // メッセージ表示（正常）
    // noticeOK(msg) {
    //   this.msg = msg;
    //   this.snackbar_color = "green";
    //   this.snackbar = true;
    // },

    // メッセージ表示（エラー）
    // noticeNG(msg) {
    //   this.msg = msg;
    //   this.snackbar_color = "red";
    //   this.snackbar = true;
    // },

    /* -- 2024.04.30 MCS)ADACHI ADD START -- */
    // 日付を"yyyy年 M月 D日"形式で返却
    fmtDate_YYYYMD(date) {
      if (date != "") {
        let fmtDate = moment(date).format("YYYY年 M月 D日");
        let fmtWeek = "（" + this.jp_week[moment(date).day()] + "）";
        return fmtDate + fmtWeek;
      } else {
        return "";
      }
    },
    /* -- 2024.04.30 MCS)ADACHI ADD END -- */
  },
  computed: {
    image: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },

    /* -- 2024.04.30 MCS)ADACHI ADD START -- */
    // 日付範囲の最小値を返却（まとめて予約）
    comp_min_date() {
      // 今日の日付
      return moment().format("yyyy-MM-DD");
    },
    /* -- 2024.04.30 MCS)ADACHI ADD END -- */
  },
  mounted: async function () {
    await this.setJigyoList();
    // this.JigyoFilterSelect = this.JigyoFilterItems.find((i) => i.liJigyoNo == this.$store.state.jigyoNo);
    await this.setGyoshaList();
    await this.ReadBentolist();
    //2024.03.22 MCS)SAITO ADD START
    this.$watch(
      // 2つのプロパティを含めた値を評価させる
      () => [this.$data.JigyoSetSelect, this.$data.JigyoFilterSelect],
      (value, oldValue) => {
        if (value[0] != oldValue[0] || value[1] != oldValue[1]) {
          //登録画面or一覧画面の弁当業者を切り替え
          this.setGyoshaList();
        }
      }
    );
    //2024.03.22 MCS)SAITO ADD END
  },
  // watch: {
  //   JigyoFilterSelect: async function () {
  //     if (this.JigyoFilterSelect != undefined && Object.keys(this.JigyoFilterSelect).length != 0) {
  //       await this.setGyoshaList(this.JigyoFilterSelect.liJigyoNo);
  //       this.GyoshaFilterSelect = {};
  //       this.datas = [];
  //     }
  //   },
  //   GyoshaFilterSelect: function () {
  //     if (Object.keys(this.GyoshaFilterSelect).length != 0) {
  //       this.ReadBentolist(this.JigyoFilterSelect.liJigyoNo, this.GyoshaFilterSelect.liGyoshaNo);
  //     }
  //   },
  // },
};
</script>
<style lang="scss" scoped>
// @import "./src/assets/css/BentoMaster.scss";
</style>
