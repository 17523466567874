<!--------------------------------------------------
  システム名：弁当注文ビフォサック
  プログラム名：SystemNotice.vue
  モジュール名：運営からのお知らせ画面
  備考：
-------------------------------------------------->
<template>
  <v-container fluid>
    <!-- *** 表示部 ここから *** -->
    <!-- タイトル -->
    <title-card title="運営からのお知らせ">
      <v-btn v-if="$store.state.adminAuth == 2" color="teal" dark depressed @click="btnCreateNew()">新規登録</v-btn>
    </title-card>
    <!-- データ（管理者：表示のみ） -->
    <data-card v-if="$store.state.adminAuth == 1">
      <template v-for="(notice, i) in notice_list">
        <v-sheet :key="i">
          <v-card flat>
            <v-card-title>{{ notice.title }}</v-card-title>
            <v-card-text style="white-space: pre-wrap">{{ notice.content }}</v-card-text>
          </v-card>
          <v-divider v-if="notice_list.length - 1 > i" class="mx-2"></v-divider>
        </v-sheet>
      </template>
      <v-card-text v-if="notice_list.length == 0">
        <span class="text--primary">現在、新しいお知らせはありません。</span>
      </v-card-text>
    </data-card>
    <!-- データ（システム管理者：編集可） -->
    <data-card v-if="$store.state.adminAuth == 2">
      <v-sheet class="pa-2">
        <span v-if="notice_list.length == 0" class="text--primary">現在、新しいお知らせはありません。</span>
        <template v-for="(notice, i) in notice_list">
          <v-card class="mb-2" :key="i" @click="clickNotice(i)" outlined>
            <v-card-title>{{ notice.title }}</v-card-title>
            <v-card-text style="white-space: pre-wrap">{{ notice.content }}</v-card-text>
          </v-card>
        </template>
      </v-sheet>
    </data-card>
    <!-- *** 表示部 ここまで *** -->

    <!-- *** 編集用ダイアログ ここから *** -->
    <v-dialog v-model="dialog" width="800" persistent no-click-animation>
      <v-card @change="change_flg = true">
        <v-card-title>お知らせ情報</v-card-title>
        <v-form ref="select_form" class="mx-5 w-50">
          <!-- お知らせ情報 -->
          <div>タイトル<span class="red--text">*</span></div>
          <v-text-field outlined dense v-model="txtTitle" maxlength="60" counter="60" :rules="title_rule"></v-text-field>
          <div>内容<span class="red--text">*</span></div>
          <v-textarea outlined dense v-model="txtContent" maxlength="1000" counter="1000" :rules="content_rule" rows="10" no-resize></v-textarea>
          <!-- <v-divider></v-divider>
          <v-checkbox class="mb-4" v-model="chkImportant" label="重要なお知らせ" hint="※重要なお知らせとして登録すると、常に上位に表示されます。" persistent-hint></v-checkbox> -->
          <v-divider></v-divider>
          <v-checkbox v-model="chkForever" label="無期限"></v-checkbox>
          <v-row v-show="!chkForever">
            <v-col cols="6">
              <!-- 開始日 -->
              <div class="mx-2 mt-1">
                <div class="d-flex align-center">開始日</div>
                <v-menu v-model="dialog_dtp_st" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="pt-0" :value="date_yyyyMD(dateSt)" :rules="date_st_rule" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="dateSt" @input="dialog_dtp_st = false" locale="jp-ja" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col cols="6">
              <!-- 終了日 -->
              <div class="mx-2 mt-1">
                <div class="d-flex align-center">終了日</div>
                <v-menu v-model="dialog_dtp_ed" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field class="pt-0" :value="date_yyyyMD(dateEd)" :rules="date_ed_rule" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="dateEd" @input="dialog_dtp_ed = false" locale="jp-ja" :day-format="(date) => new Date(date).getDate()" no-title></v-date-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <!-- ボタン群 -->
        <v-col align="right">
          <v-btn v-show="modeInsert" height="40" width="70" color="blue darken-1" text @click="btnInsert()" class="mr-5">登録</v-btn>
          <v-btn v-show="modeEdit" height="40" width="70" color="green darken-1" text @click="btnUpdate()" class="mr-5">保存</v-btn>
          <v-btn v-show="modeEdit" height="40" width="70" color="red darken-1" text @click="btnDelete()" class="mr-5">削除</v-btn>
          <v-btn height="40" width="70" color="blue darken-1" text @click="dialogdisp(false)">閉じる</v-btn>
        </v-col>
      </v-card>
    </v-dialog>
    <!-- *** 編集用ダイアログ ここまで *** -->
  </v-container>
</template>

<script>
import moment from "moment";
import TitleCard from "@/components/admin/TitleCard.vue";
import DataCard from "@/components/admin/DataCard.vue";

export default {
  name: "SystemNotice",
  data() {
    return {
      // お知らせリスト
      notice_list: [],
      // 変更フラグ
      change_flg: false,
      // スナックバー
      // snackbar: false,
      // snackbar_msg: "",
      // snackbar_color: "",
      // timeout: 3000,
      // 選択項目
      selectNotice: 0,
      // 入力項目
      txtTitle: "",
      txtContent: "",
      dateSt: "",
      dateEd: "",
      chkForever: false,
      chkImportant: false,
      // ポップアップ
      dialog: false,
      dialog_dtp_st: false,
      dialog_dtp_ed: false,
      // 編集モード
      modeInsert: false,
      modeEdit: false,
    };
  },
  components: {
    TitleCard,
    DataCard,
  },
  methods: {
    //----------------------------------------------------------------
    // 初期化処理
    //----------------------------------------------------------------
    init() {
      // お知らせリスト取得
      this.getNotice();
    },

    //----------------------------------------------------------------
    // ボタン押下時処理
    //----------------------------------------------------------------
    // 新規登録ボタン押下時
    btnCreateNew() {
      this.initForm();
      this.modeInsert = true;
      this.modeEdit = false;
      this["dialog"] = true;
    },

    // 登録ボタン押下時
    async btnInsert() {
      // 入力チェック
      if (!this.$refs.select_form.validate()) {
        return;
      }
      // 登録処理
      if (await this.insertNotice()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("登録しました。");
        this.$root.snackbar.showMessage("登録しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      } else {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("登録時にエラーが発生しました。");
        this.$root.snackbar.showMessage("登録時にエラーが発生しました。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 変更フラグを初期化
      this.resetChangeFlg();
      // お知らせ一覧を再取得
      this.getNotice();
      // ダイアログを閉じる
      this.dialogdisp(false);
    },

    // 保存ボタン押下時
    async btnUpdate() {
      // 入力チェック
      if (!this.$refs.select_form.validate()) {
        return;
      }
      // 修正処理
      if (await this.updateNotice()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("保存しました。");
        this.$root.snackbar.showMessage("保存しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      } else {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("保存時にエラーが発生しました。");
        this.$root.snackbar.showMessage("保存時にエラーが発生しました。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 変更フラグを初期化
      this.resetChangeFlg();
      // お知らせ一覧を再取得
      this.getNotice();
      // ダイアログを閉じる
      this.dialogdisp(false);
    },

    // 削除ボタン押下時
    async btnDelete() {
      // 入力チェック
      if (!window.confirm("削除します。よろしいですか？")) {
        return;
      }
      // 削除処理
      if (await this.deleteNotice()) {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeOK("削除しました。");
        this.$root.snackbar.showMessage("削除しました。", "success");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
      } else {
        /* -- 2024.05.08 MCS)KONNO MOD START --*/
        // this.noticeNG("削除時にエラーが発生しました。");
        this.$root.snackbar.showMessage("削除時にエラーが発生しました。", "error");
        /* -- 2024.05.08 MCS)KONNO MOD END --*/
        return;
      }
      // 変更フラグを初期化
      this.resetChangeFlg();
      // お知らせ一覧を再取得
      this.getNotice();
      // ダイアログを閉じる
      this.dialogdisp(false);
    },

    //----------------------------------------------------------------
    // イベント処理
    //----------------------------------------------------------------
    // お知らせ選択時
    async clickNotice(i) {
      if (this.$store.state.adminAuth == 2) {
        await this.editNotice(i);
      }
    },

    //----------------------------------------------------------------
    // メソッド
    //----------------------------------------------------------------
    // 入力フォーム初期化
    initForm() {
      this.selectNotice = 0;
      this.txtTitle = "";
      this.txtContent = "";
      this.dateSt = moment().format("yyyy-MM-DD");
      this.dateEd = moment().format("yyyy-MM-DD");
      this.chkForever = false;
      this.chkImportant = false;
      this.$nextTick(() => {
        if (this.$refs["select_form"]) {
          this.$refs.select_form.resetValidation();
        }
      });
    },

    // お知らせ編集
    editNotice(i) {
      this.initForm();
      this.selectNotice = this.notice_list[i].no;
      this.txtTitle = this.notice_list[i].title;
      this.txtContent = String(this.notice_list[i].content).replace("<br>", "");
      this.dateSt = this.notice_list[i].date_st == "00000000" ? moment().format("yyyy-MM-DD") : moment(this.notice_list[i].date_st).format("yyyy-MM-DD");
      this.dateEd = this.notice_list[i].date_ed == "99999999" ? moment().format("yyyy-MM-DD") : moment(this.notice_list[i].date_ed).format("yyyy-MM-DD");
      this.chkForever = this.notice_list[i].date_st == "00000000" && this.notice_list[i].date_ed == "99999999" ? true : false;
      this.chkImportant = this.notice_list[i].important == 1 ? true : false;
      this.dialogdisp(true);
      this.$nextTick(() => {
        if (this.$refs["select_form"]) {
          this.$refs.select_form.resetValidation();
        }
      });
    },

    // 入力ダイアログ表示、非表示
    dialogdisp(dispflg) {
      if (dispflg) {
        this.modeInsert = false;
        this.modeEdit = true;
        this.dialog = true; //入力ダイアログを表示
      } else {
        if (this.change_flg == true) {
          if (window.confirm("保存せずに終了します。\n入力内容は破棄されますが、よろしいですか？")) {
            this.dialog = false; //入力ダイアログを非表示
          }
        } else {
          this.dialog = false; //入力ダイアログを非表示
        }
      }
    },

    // お知らせ一覧取得
    async getNotice() {
      /* -- 2024.05.16 MCS)KONNO MOD START -- */
      // const KigyoCd = this.$store.state.kigyoCode;
      // const UserCd = this.$store.state.userCode;
      // const tKigyoCd = "SYSTEM";
      const KigyoCd = "SYSTEM";
      /* -- 2024.05.16 MCS)KONNO MOD END -- */
      let data = await this.$axios
        /* -- 2024.05.16 MCS)KONNO MOD START -- */
        // .get("/t_notice_get/list" + "?KigyoCd=" + KigyoCd + "&UserCd=" + UserCd + "&tKigyoCd=" + tKigyoCd)
        .get("/t_notice_get/list_management" + "?KigyoCd=" + KigyoCd)
        /* -- 2024.05.16 MCS)KONNO MOD END -- */
        .then(function (response) {
          return response.data["data"];
        })
        .catch(function (error) {
          console.log(error);
          return [];
        });
      // 形式変換
      let wkArray = [];
      /* -- 2024.05.16 MCS)KONNO DEL START -- */
      // const auth = this.$store.state.adminAuth;
      // const today = moment().format("yyyyMMDD");
      /* -- 2024.05.16 MCS)KONNO DEL END -- */
      if (data.length != 0) {
        data.forEach(function (element) {
          /* -- 2024.05.16 MCS)KONNO DEL START -- */
          // if (auth == 2 || (today >= element.str_date_st && today <= element.str_date_ed)) {
          /* -- 2024.05.16 MCS)KONNO DEL END -- */
          let notice = {};
          notice["no"] = element.num_notice_no;
          notice["title"] = element.str_title;
          notice["content"] = String(element.str_content);
          notice["date_st"] = element.str_date_st;
          notice["date_ed"] = element.str_date_ed;
          notice["important"] = element.num_imp_flg;
          wkArray.push(notice);
          /* -- 2024.05.16 MCS)KONNO DEL START -- */
          // }
          /* -- 2024.05.16 MCS)KONNO DEL END -- */
        });
      }
      this.notice_list = wkArray;
    },

    // お知らせ登録処理
    async insertNotice() {
      const params = new URLSearchParams();
      params.append("KigyoCd", "SYSTEM");
      params.append("Title", this.txtTitle);
      params.append("Content", this.txtContent.replace("<br>", "\n"));
      params.append("DateSt", this.chkForever ? "00000000" : moment(this.dateSt).format("yyyyMMDD"));
      params.append("DateEd", this.chkForever ? "99999999" : moment(this.dateEd).format("yyyyMMDD"));
      params.append("ImpFlg", this.chkImportant ? 1 : 0);
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      let result = await this.$axios
        .post("/t_notice_post/insert", params)
        .then((response) => {
          return response.status == 200;
        })
        .catch((error) => {
          console.log(error.response.data.error);
          return false;
        });
      return result;
    },

    // お知らせ更新処理
    async updateNotice() {
      const params = new URLSearchParams();
      params.append("Title", this.txtTitle);
      params.append("Content", String(this.txtContent).replace("<br>", "\n"));
      params.append("DateSt", this.chkForever ? "00000000" : moment(this.dateSt).format("yyyyMMDD"));
      params.append("DateEd", this.chkForever ? "99999999" : moment(this.dateEd).format("yyyyMMDD"));
      params.append("ImpFlg", this.chkImportant ? 1 : 0);
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      params.append("tKigyoCd", "SYSTEM");
      params.append("tNoticeNo", this.selectNotice);
      let result = await this.$axios
        .post("/t_notice_post/update", params)
        .then((response) => {
          return response.status == 200;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
      return result;
    },

    // お知らせ削除処理
    async deleteNotice() {
      const params = new URLSearchParams();
      params.append("sUser", this.$store.state.userCode);
      params.append("sAccess", "WEB");
      params.append("tKigyoCd", "SYSTEM");
      params.append("tNoticeNo", this.selectNotice);
      let result = await this.$axios
        .post("/t_notice_post/delete", params)
        .then((response) => {
          return response.status == 200;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
      return result;
    },

    //変更フラグ処理
    resetChangeFlg() {
      this.change_flg = false;
    },

    // メッセージ表示（正常）
    // noticeOK(msg) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "green";
    //   this.snackbar = true;
    // },

    // メッセージ表示（エラー）
    // noticeNG(msg) {
    //   this.snackbar_msg = msg;
    //   this.snackbar_color = "red";
    //   this.snackbar = true;
    // },
  },

  computed: {
    // 日付を"yyyy年 M月 D日"形式で返却
    date_yyyyMD() {
      return (date) => {
        if (date != "") {
          return moment(date).format("yyyy年 M月 D日");
        } else {
          return "";
        }
      };
    },
    //----------------------------------------------------------------
    // バリデーションルール
    //----------------------------------------------------------------
    // タイトル
    title_rule() {
      return [(value) => !!value || "タイトルは必須入力です。"];
    },
    // 内容
    content_rule() {
      return [(value) => !!value || "本文は必須入力です。"];
    },
    // 開始日
    date_st_rule() {
      if (!this.chkForever) {
        return [() => this.dateSt <= this.dateEd || "開始日は終了日より前に設定してください。"];
      }
      return [];
    },
    // 終了日
    date_ed_rule() {
      if (!this.chkForever) {
        return [() => this.dateEd >= this.dateSt || "終了日は開始日より後に設定してください。"];
      }
      return [];
    },
  },

  mounted() {
    this.init();
  },
};
</script>
