<template>
  <v-card class="overflow-y-auto" height="calc(100dvh - 186px)" flat outlined>
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: "DataCard",
};
</script>
